// @ts-strict-ignore
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Grid, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Typography, Radio, RadioGroup, TextField } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import Hr from "@dashboard/components/Hr";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import { extensionMountPoints, mapToMenuItemsForCustomerDetails, useExtensions } from "@dashboard/apps/hooks/useExtensions";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { storeListListUrl } from "../../urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import useMetadataChangeTrigger from "@dashboard/utils/metadata/useMetadataChangeTrigger";

import FileUploadSection from '@dashboard/tng/components/FileUploadSection';
import ImageUploadSection from '@dashboard/tng/components/ImageUploadSection';
import TextFieldWithLabel from '@dashboard/tng/components/TextFieldWithLabel';
import SelectField from '@dashboard/tng/components/SelectField';

import { updateLocalStorageData, useVendor } from '@context/VendorContext';
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import { getVendorByVid, updateVendorInfo } from "@dashboard/stores/services/REST_apis/vendor";
import { Box, ChevronRightIcon, Text } from "@saleor/macaw-ui-next";
import DaumAddress from "../../services/api/DaumAddress";
import MenuTabs from "@dashboard/stores/components/MenuTabs";
import { sanitizeData } from "@dashboard/stores/services/utils/data";
import api from '../../services/REST_apis/client';
import { getCategory, getCategory2 } from "@dashboard/stores/services/GQ_apis/category";

export interface StoreInfoParam {
  id: string;
}
export interface StoreInfoPageFormData {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  note: string;
}

export interface StoreInfoPageProps {
  customerId: string;
  customer: CustomerDetailsQuery["user"];
  disabled: boolean;
  errors: AccountErrorFragment[];
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (
    data: StoreInfoPageFormData,
  ) => SubmitPromise<AccountErrorFragment[]>;
  onDelete: () => void;
}

const initialState = {
  b_no: "",
  created_at: "",
  detail_info: {
    store_category: "",
    fashion_category: {
      top: {
        key: "",
        value: "",
      },
      middle: {
        key: "",
        value: "",
      },
      bottom: {
        key: "",
        value: "",
      },
    },
    age_range: { "max": "", "min": "" },
    gender_category: {
      Unisex: false,
      girl: false,
      boy: false,
    },
    store_style: {
      Americana: false,
      CampusLook: false,
      Casual: false,
      Contemporary: false,
      Lovely: false,
      ModernChic: false,
      OfficeLook: false,
      Romantic: false,
      SimpleBasic: false,
      Unique: false,
      Unisex: false,
      Vintage: false,
    },
    size_chart_id: "",
    parallel_import: false,
    life_category: {
      top: {
        key: "",
        value: "",
      },
      middle: {
        key: "",
        value: "",
      },
      bottom: {
        key: "",
        value: "",
      },
    },
    life_age_range: { "max": "", "min": "" },
    life_parallel_import: false,
    sale_countries: "",
    contact_name: "",
    contact_mobile: "",
    contact_phone: "",
    ///
    vendor_thumbnail: "",
    business_registration_image: "",
    business_registration_image_name: "",
    e_commerce_license_image: "",
    e_commerce_license_image_name: "",
    ///
    e_commerce_license_number: "",
    company_name: "",
    business_location: {
      country_area: "",
      city: "",
      address: "",
      postal_code: "",
      company_name: ""
    },
    business_customs_code: "",
    certification_info_1: { 'display': false, 'file': '', 'file_name': '', 'disable_option': 1 },
    certification_info_2: { 'display': false, 'file': '', 'file_name': '' },
    certification_info_3: { 'display': false, 'file': '', 'file_name': '' },
    customer_center_info: {
      op_start_hours: "",
      op_end_hours: "",
      lunch_start_hours: "",
      lunch_end_hours: "",
      working_days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    },
    easypay_sync_info: {
      mall_id: "",
      secret_key: "",
      etc1: "",
      etc2: "",
    },
    category_no: 0,
    category_no_life: 0,
  },
  etc: "",
  etc2: "",
  etc3: "",
  full_info_provided: false,
  introduction_en: "",
  introduction_ko: "",
  m_uid: "",
  manager_emails: "",
  shipping_info_id: "",
  store_name_en: "",
  store_name_ko: "",
  store_type: "",
  updated_at: "",

};

const StoreInfoPage: React.FC<StoreInfoPageProps> = ({
  customerId,
  customer,
  disabled,
  errors,
  saveButtonBar,
  onSubmit,
  onDelete,
}: StoreInfoPageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const { state, dispatch } = useVendor()
  const [formData, setFormData] = useState(initialState);
  const [selectedOption, setSelectedOption] = useState('option1');
  const [vId, setVId] = useState("");

  const { id } = useParams<StoreInfoParam>();
  const { user } = useUser();

  const [formDataDisabled, setFormDataDisabled] = useState(user.isSuperuser ? false : true);

  useEffect(() => {
    if (user.isSuperuser && id) {
      handleVendorData(id);
    } else {
      handleVendorData(state.id);
      setVId(state.id);
    }
  }, [state, id, user.isSuperuser]);


  useEffect(() => {
    if (formData && formData.detail_info) {
      const { certification_info_1: info1, certification_info_2: info2, certification_info_3: info3 } = formData.detail_info;

      if (!info1.display && !info2.display && !info3.display) {
        setSelectedOption(`option${info1.disable_option}`);
      } else {
        setSelectedOption('option1');
      }
    }
  }, [formData]);


  const [fashionDepth1Tabs, setFashionDepth1Tabs] = useState([]);
  const [fashionDepth2Tabs, setFashionDepth2Tabs] = useState([]);
  const [fashionDepth3Tabs, setFashionDepth3Tabs] = useState([]);

  const [lifeDepth1Tabs, setLifeDepth1Tabs] = useState([]);
  const [lifeDepth2Tabs, setLifeDepth2Tabs] = useState([]);
  const [lifeDepth3Tabs, setLifeDepth3Tabs] = useState([]);

  useEffect(() => {
    const fetchCategory = async (level, key, search) => {
      await setCategory(level, key, search);
    };
    if (formData.detail_info.category_no !== -1) {
      fetchCategory(0, "", "패션");
      fetchCategory(1, formData.detail_info.fashion_category.top.key, "패션");
      fetchCategory(2, formData.detail_info.fashion_category.middle.key, "패션");
    }
    if (formData.detail_info.category_no_life !== -1) {
      fetchCategory(0, "", "라이프");
      fetchCategory(1, formData.detail_info.life_category.top.key, "라이프");
      fetchCategory(2, formData.detail_info.life_category.middle.key, "라이프");
    }
  }, [])

  useEffect(() => {
    const fetchCategory = async (level, key, search) => {
      await setCategory(level, key, search);
    };
    fetchCategory(2, formData.detail_info.fashion_category.middle.key, "패션");
  }, [formData.detail_info.fashion_category.middle.key])

  useEffect(() => {
    const fetchCategory = async (level, key, search) => {
      await setCategory(level, key, search);
    };
    fetchCategory(2, formData.detail_info.life_category.middle.key, "라이프");
  }, [formData.detail_info.life_category.middle.key])


  const handleChangeCategory = (e) => {
    const { name, value } = e.target;
    const categorys = name.split('.');
    const dataFieldName = categorys[1];
    const dataLevel = categorys[2];
    const values = value.split(':');
    const selectedItemKey = values[0];
    const selectedItemValue = values[1];
    // const search = categorys[0] === 'fashion_category' ? "패션" : categorys[0] === 'life_category' ? "라이프" : "";
    // const level = categorys[1] === 'top' ? 0 : categorys[1] === 'middle' ? 1 : categorys[1] === 'bottom' ? 2 : -1;

    // Update formData
    setFormData((prevState) => {
      const newState = {
        ...prevState,
        detail_info: {
          ...prevState.detail_info,
          [dataFieldName]: {
            ...prevState.detail_info[dataFieldName],
            [dataLevel]: {
              key: parseInt(selectedItemKey),
              value: selectedItemValue,
            },
          },
        },
      };
      return newState;
    });
  };


  const setCategory = async (level, big, search) => {
    const data = formattingCategoryData(await getCategories(level, big, search))
    if (search === '패션') {
      if (level === 0) setFashionDepth1Tabs(data)
      else if (level === 1) setFashionDepth2Tabs(data)
      else if (level === 2) setFashionDepth3Tabs(data);
    } else if (search === '라이프') {
      if (level === 0) setLifeDepth1Tabs(data)
      else if (level === 1) setLifeDepth2Tabs(data)
      else if (level === 2) setLifeDepth3Tabs(data);
    }
  }

  const getCategories = useCallback(async (level, big, search) => {
    return big === "" ? await getCategory(level, search)
      .then((res) => {
        return res.data.categories.edges;
      })
      : await getCategory2(level, big).then((res) => {
        return res.data.categories.edges;
      })
  }, [])

  const formattingCategoryData = (data) => {
    return data.map((item) => {
      return {
        key: item.node.decryptId,
        value: item.node.decryptId + ":" + item.node.name,
        label: item.node.name,
        level: item.node.level,
      }
    });
  }

  const handleFashionStoreStyleChange = (e) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData(prevState => ({
        ...prevState,
        detail_info: {
          ...prevState.detail_info,
          store_style: {
            ...prevState.detail_info.store_style,
            [name]: checked
          }
        }
      }));
    }
  }

  const handleGenderChange = (e) => {
    const { name, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData(prevState => ({
        ...prevState,
        detail_info: {
          ...prevState.detail_info,
          gender_category: {
            ...prevState.detail_info.gender_category,
            [name]: checked
          }
        }
      }));
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    // 재귀적으로 상태를 업데이트하는 함수
    const updateState = (obj, keys, value) => {
      if (keys.length === 1) {
        value === 'true' ? value = true : value === 'false' ? value = false : value
        obj[keys[0]] = value;
      } else {
        const key = keys.shift();
        if (!obj[key]) obj[key] = {};  // 중첩된 객체가 없으면 새로 생성
        updateState(obj[key], keys, value);
      }
    };

    // formData를 복사하여 새로운 상태로 만듦
    const newFormData = { ...formData };
    updateState(newFormData, keys, value);

    // 상태 업데이트 및 dispatch 호출
    setFormData(newFormData);
  };


  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOption(value);

    const newResult = [
      {
        display: value === 'option1' && formData.detail_info.certification_info_1.file && formData.detail_info.certification_info_1.file_name ? true : false,
        file: formData.detail_info.certification_info_1.file,
        file_name: formData.detail_info.certification_info_1.file_name,
        disable_option: value == 'option1' ? 1 : Number(value[value.length - 1]),
      },
      {
        display: false,
        file: '',
        file_name: '',
        disable_option: undefined, // 선택적 속성으로 설정
      },
      {
        display: false,
        file: '',
        file_name: '',
        disable_option: undefined, // 선택적 속성으로 설정
      }
    ];

    setFormData(prevState => ({
      ...prevState,
      detail_info: {
        ...prevState.detail_info,
        certification_info_1: newResult[0],
        certification_info_2: newResult[1],
        certification_info_3: newResult[2],
      }
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    const keys = name.split('.');

    // 재귀적으로 상태를 업데이트하는 함수
    const updateState = (obj, keys, checked) => {
      if (keys.length === 1) {
        obj[keys[0]] = checked;
      } else {
        const key = keys.shift();
        if (!obj[key]) obj[key] = {};  // 중첩된 객체가 없으면 새로 생성
        updateState(obj[key], keys, checked);
      }
    };

    // formData를 복사하여 새로운 상태로 만듦
    const newFormData = { ...formData };
    updateState(newFormData, keys, checked);
    setFormData(newFormData);
  };

  const handleImageUpload = (files: File[], name = '', flag = false): boolean => {
    const file = files[0];

    if (file) {
      // 허용된 파일 타입 리스트
      let allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp',
      ];

      if (flag) {
        allowedTypes.shift(); // 'application/pdf' 제거
        if (!allowedTypes.includes(file.type)) {
          alert('이미지 파일만 첨부 가능합니다.');
          return false;  // false 반환하여 미리보기를 업데이트하지 않음
        }
      } else {
        if (!allowedTypes.includes(file.type)) {
          alert('PDF, 이미지 파일만 첨부 가능합니다.');
          return false;  // false 반환하여 미리보기를 업데이트하지 않음
        }
      }

      const newFormData = { ...formData };

      const updateState = (obj, key, result) => {
        obj['detail_info'][key] = result
      };

      const reader = new FileReader();
      reader.onloadend = () => {
        updateState(newFormData, name, reader.result);
        setFormData(newFormData);
      };

      reader.readAsDataURL(file);
      return true;
    }

    return false;
  };

  const handleFileUpload = (file: File, name: string = ''): boolean => {
    if (file) {
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp',
      ];

      if (!allowedTypes.includes(file.type)) {
        alert('PDF, 이미지 파일만 첨부 가능합니다.');
        return false;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const newFormData = {
          ...formData,
          detail_info: {
            ...formData.detail_info,
            [name]: reader.result,
            [`${name}_name`]: file.name
          }
        };
        setFormData(newFormData);

        console.log('File loaded and formData updated:', newFormData);
      };

      reader.readAsDataURL(file);

      return true;
    }
    return false;
  };

  const handleFileUpload2 = (file: File, name: string = ''): boolean => {
    if (file) {
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp',
      ];

      if (!allowedTypes.includes(file.type)) {
        alert('PDF, 이미지 파일만 첨부 가능합니다.');
        return false;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const newFormData = {
          ...formData,
          detail_info: {
            ...formData.detail_info,
            [name]: {
              ...formData.detail_info[name],
              file: reader.result,
              file_name: file.name
            }
          }
        };
        setFormData(newFormData);

        console.log('File loaded and formData updated(2):', newFormData);
      };

      reader.readAsDataURL(file);

      return true;
    }
    return false;
  };


  const initialForm: StoreInfoPageFormData = {
    email: customer?.email || "",
    firstName: customer?.firstName || "",
    isActive: customer?.isActive || false,
    lastName: customer?.lastName || "",
    note: customer?.note || ""
  };

  const onSelectAddress = (data) => {
    const address =
    {
      country_area: data.sido,
      city: data.sigungu,
      road: data.query,
      address: data.address,
      postal_code: data.zonecode,
      company_name: ""
    };
    setFormData(prevState => ({
      ...prevState,
      detail_info: {
        ...prevState.detail_info,
        business_location: {
          ...prevState.detail_info.business_location,
          ...address
        }
      }
    }));
  }

  const handleVendorData = async (vid) => {
    setFormData(sanitizeData(await getVendorData(vid), true));
  };

  const getVendorData = useCallback(async (vid) => {
    return await getVendorByVid(vid).then(data => { return data });
  }, []);

  const { makeChangeHandler: makeMetadataChangeHandler } = useMetadataChangeTrigger();
  const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
  const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

  const handleOnSubmit = useCallback(() => {
    const newData = { ...formData };
    updateVendorInfo(sanitizeData(newData, false))
      .then(response => {
        if (response.status === 200) {
          alert("등록되었습니다.");
          dispatch({
            type: 'SET_VENDOR',
            payload: response.data,
          });
          dispatch({
            type: 'UPDATE_DETAIL_INFO',
            payload: response.data.detail_info,
          });
          updateLocalStorageData(sanitizeData(response.data, false), false);
        } else {
          alert("오류가 발생했습니다.");
        }
      });
  }, [formData, dispatch]);

  return (
    <Form
      confirmLeave
      initial={initialForm}
      onSubmit={handleOnSubmit}
      disabled={disabled}
    >
      {({ change, data, isSaveDisabled, submit }) => {
        const changeMetadata = makeMetadataChangeHandler(change);

        return (
          <DetailPageLayout>
            <TopNav
              isAlignToRight={false}
              title={intl.formatMessage(sectionNames.storeInfo)}>
              {/* {extensionMenuItems.length > 0 && (
                <CardMenu menuItems={extensionMenuItems} />
              )} */}
              {
                user.isSuperuser && formData.store_name_ko !== "" && (
                  <Box display="flex">
                    <Box marginX={3} display="flex" alignItems="center">
                      <ChevronRightIcon />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Text variant="title" size="small">
                        {formData.store_name_ko}
                      </Text>
                    </Box>
                  </Box>
                )
              }
            </TopNav>
            <DetailPageLayout.Content>
              {
                user.isSuperuser && (
                  <MenuTabs vId={id} current={'storeInfo'} />
                )
              }
              <Card>
                <CardTitle title="정보 관리" />
                <CardContent>
                  <ImageUploadSection title="대표이미지" name="vendor_thumbnail" flag={true} onImageUpload={handleImageUpload} vendorThumbnail={formData.detail_info.vendor_thumbnail} />
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="쇼핑몰명 (한국어)"
                      name="store_name_ko"
                      value={formData.store_name_ko}
                      onChange={handleChange}
                      placeholder="Store Name (Korean)"
                      gridSizes={{ xs: 12, sm: 6 }}
                    // disabled={formDataDisabled}
                    />
                    <TextFieldWithLabel
                      label="쇼핑몰명 (영어)"
                      name="store_name_en"
                      value={formData.store_name_en}
                      onChange={handleChange}
                      placeholder="Store Name (English)"
                      gridSizes={{ xs: 12, sm: 6 }}
                    // disabled={formDataDisabled}
                    />
                  </Grid>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="한 줄 소개 (한국어)"
                      name="introduction_ko"
                      value={formData.introduction_ko}
                      onChange={handleChange}
                      placeholder="Introduction (Korean)"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="한 줄 소개 (영어)"
                      name="introduction_en"
                      value={formData.introduction_en}
                      onChange={handleChange}
                      placeholder="Introduction (English)"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                  <SelectField
                    label="카테고리"
                    placeHolder="Category"
                    name="detail_info.store_category"
                    value={formData.detail_info.store_category}
                    onChange={handleChange}
                    options={[
                      { value: '', label: 'None' },
                      { value: '명품/수입브랜드', label: '명품/수입브랜드' },
                      { value: '브랜드(패션/용품)', label: '브랜드(패션/용품)' },
                      { value: '프리오더', label: '프리오더' },
                      { value: '쇼핑몰', label: '쇼핑몰' },
                      { value: '라이프/유형', label: '라이프/유형' },
                      { value: '라이프/무형', label: '라이프/무형' },
                    ]}
                    gridSizes={{ xs: 12, sm: 12 }}
                    disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <FormControlLabel
                    control={<Checkbox name="detail_info.fashion_category"
                      checked={formData.detail_info.fashion_category.top.value !== ""
                        && formData.detail_info.fashion_category.middle.value !== ""
                        && formData.detail_info.fashion_category.bottom.value !== ""}
                      disabled />}
                    label="패션"
                  />
                  {
                    formData.detail_info.fashion_category.top.value !== ""
                    && formData.detail_info.fashion_category.middle.value !== ""
                    && formData.detail_info.fashion_category.bottom.value !== ""
                    && (
                      <div>
                        <Typography>패션 카테고리</Typography>
                        <Grid container spacing={3}>
                          {
                            fashionDepth1Tabs.length && (
                              <SelectField
                                label=""
                                placeHolder="top"
                                name="detail_info.fashion_category.top"
                                value={`${formData.detail_info.fashion_category.top.key}:${formData.detail_info.fashion_category.top.value}`}
                                // onChange={handleChange}
                                onChange={handleChangeCategory}
                                options={fashionDepth1Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                                // disabled={formDataDisabled}
                                disabled={!fashionDepth1Tabs.length}
                              />
                            )
                          }
                          {
                            fashionDepth2Tabs.length && (
                              <SelectField
                                label=""
                                placeHolder="middle"
                                name="detail_info.fashion_category.middle"
                                value={`${formData.detail_info.fashion_category.middle.key}:${formData.detail_info.fashion_category.middle.value}`}
                                onChange={handleChangeCategory}
                                options={fashionDepth2Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                                // disabled={formDataDisabled}
                                disabled={!fashionDepth2Tabs.length}
                              />
                            )
                          }
                          {
                            fashionDepth3Tabs.length && (
                              <SelectField
                                key={formData.detail_info.fashion_category.middle.key}
                                label=""
                                placeHolder="bottom"
                                name="detail_info.fashion_category.bottom"
                                value={`${formData.detail_info.fashion_category.bottom.key}:${formData.detail_info.fashion_category.bottom.value}`}
                                onChange={handleChangeCategory}
                                options={fashionDepth3Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                                // disabled={formDataDisabled}
                                disabled={!fashionDepth3Tabs.length}
                              />
                            )
                          }
                        </Grid>
                        <CardSpacer />
                        <Typography>성별</Typography>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox name="Unisex"
                              checked={formData.detail_info.gender_category.Unisex}
                              onChange={handleGenderChange} />}
                            label="남녀공용"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="girl"
                              checked={formData.detail_info.gender_category.girl}
                              onChange={handleGenderChange} />}
                            label="여아용"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="boy"
                              checked={formData.detail_info.gender_category.boy}
                              onChange={handleGenderChange} />}
                            label="남아용"
                            disabled={formDataDisabled}
                          />
                        </FormGroup>
                        <CardSpacer />
                        <Typography>연령대</Typography>
                        <Grid container spacing={3}>
                          <SelectField
                            label=""
                            placeHolder="min"
                            name="detail_info.age_range.min"
                            value={formData.detail_info.age_range.min}
                            onChange={handleChange}
                            options={[
                              { value: "0m", label: "0개월" },
                              { value: "1m", label: "1개월" },
                              { value: "2m", label: "2개월" },
                              { value: "3m", label: "3개월" },
                              { value: "4m", label: "4개월" },
                              { value: "5m", label: "5개월" },
                              { value: "6m", label: "6개월" },
                              { value: "7m", label: "7개월" },
                              { value: "8m", label: "8개월" },
                              { value: "9m", label: "9개월" },
                              { value: "10m", label: "10개월" },
                              { value: "11m", label: "11개월" },
                              { value: "1y", label: "1년" },
                              { value: "2y", label: "2년" },
                              { value: "3y", label: "3년" },
                              { value: "4y", label: "4년" },
                              { value: "5y", label: "5년" },
                              { value: "6y", label: "6년" },
                              { value: "7y", label: "7년" },
                              { value: "8y", label: "8년" },
                              { value: "9y", label: "9년" },
                              { value: "10y", label: "10년" },
                              { value: "11y", label: "11년" },
                              { value: "12y", label: "12년" },
                              { value: "13y", label: "13년" },
                              { value: "13y+", label: "13년 이상" }
                            ]}
                            gridSizes={{ xs: 12, sm: 6 }}
                            disabled={formDataDisabled}
                          />
                          <SelectField
                            label=""
                            placeHolder="max"
                            name="detail_info.age_range.max"
                            value={formData.detail_info.age_range.max}
                            onChange={handleChange}
                            options={[
                              { value: "0m", label: "0개월" },
                              { value: "1m", label: "1개월" },
                              { value: "2m", label: "2개월" },
                              { value: "3m", label: "3개월" },
                              { value: "4m", label: "4개월" },
                              { value: "5m", label: "5개월" },
                              { value: "6m", label: "6개월" },
                              { value: "7m", label: "7개월" },
                              { value: "8m", label: "8개월" },
                              { value: "9m", label: "9개월" },
                              { value: "10m", label: "10개월" },
                              { value: "11m", label: "11개월" },
                              { value: "1y", label: "1년" },
                              { value: "2y", label: "2년" },
                              { value: "3y", label: "3년" },
                              { value: "4y", label: "4년" },
                              { value: "5y", label: "5년" },
                              { value: "6y", label: "6년" },
                              { value: "7y", label: "7년" },
                              { value: "8y", label: "8년" },
                              { value: "9y", label: "9년" },
                              { value: "10y", label: "10년" },
                              { value: "11y", label: "11년" },
                              { value: "12y", label: "12년" },
                              { value: "13y", label: "13년" },
                              { value: "13y+", label: "13년 이상" }
                            ]}
                            gridSizes={{ xs: 12, sm: 6 }}
                            disabled={formDataDisabled}
                          />
                        </Grid>
                        <CardSpacer />
                        <Typography>스토어 스타일</Typography>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox name="SimpleBasic"
                              checked={formData.detail_info.store_style.SimpleBasic}
                              onChange={handleFashionStoreStyleChange} />}
                            label="심플베이직"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Casual"
                              checked={formData.detail_info.store_style.Casual}
                              onChange={handleFashionStoreStyleChange} />}
                            label="캐주얼"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="ModernChic"
                              checked={formData.detail_info.store_style.ModernChic}
                              onChange={handleFashionStoreStyleChange} />}
                            label="모던시크"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Lovely"
                              checked={formData.detail_info.store_style.Lovely}
                              onChange={handleFashionStoreStyleChange} />}
                            label="러블리"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Romantic"
                              checked={formData.detail_info.store_style.Romantic}
                              onChange={handleFashionStoreStyleChange} />}
                            label="로맨틱"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Unique"
                              checked={formData.detail_info.store_style.Unique}
                              onChange={handleFashionStoreStyleChange} />}
                            label="유니크"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Vintage"
                              checked={formData.detail_info.store_style.Vintage}
                              onChange={handleFashionStoreStyleChange} />}
                            label="빈티지"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="OfficeLook"
                              checked={formData.detail_info.store_style.OfficeLook}
                              onChange={handleFashionStoreStyleChange} />}
                            label="오피스룩"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="CampusLook"
                              checked={formData.detail_info.store_style.CampusLook}
                              onChange={handleFashionStoreStyleChange} />}
                            label="캠퍼스룩"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Americana"
                              checked={formData.detail_info.store_style.Americana}
                              onChange={handleFashionStoreStyleChange} />}
                            label="아메카지"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Contemporary"
                              checked={formData.detail_info.store_style.Contemporary}
                              onChange={handleFashionStoreStyleChange} />}
                            label="컨템포러리"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            control={<Checkbox name="Unisex"
                              checked={formData.detail_info.store_style.Unisex}
                              onChange={handleFashionStoreStyleChange} />}
                            label="유니섹스"
                            disabled={formDataDisabled}
                          />
                        </FormGroup>
                        <CardSpacer />
                        <Typography>수입방법</Typography>
                        <RadioGroup name="detail_info.parallel_import" defaultValue={formData.detail_info.parallel_import ? "true" : "false"} onChange={handleChange} row>
                          <FormControlLabel
                            name="detail_info.parallel_import"
                            value="true"
                            control={<Radio name="detail_info.parallel_import" />}
                            label="병행수입"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            name="detail_info.parallel_import"
                            value="false"
                            control={<Radio name="detail_info.parallel_import" />}
                            label="병행수입 아님"
                            disabled={formDataDisabled}
                          />
                        </RadioGroup>
                        <CardSpacer />
                      </div>
                    )
                  }
                  <FormControlLabel
                    control={<Checkbox name="detail_info.life_category"
                      checked={formData.detail_info.life_category.top.value !== ""
                        && formData.detail_info.life_category.middle.value !== ""
                        && formData.detail_info.life_category.bottom.value !== ""} disabled />}
                    label="라이프/유형"
                  />
                  {
                    formData.detail_info.life_category.top.value !== ""
                    && formData.detail_info.life_category.middle.value !== ""
                    && formData.detail_info.life_category.bottom.value !== "" && (
                      <div>
                        <Typography>라이프 카테고리</Typography>
                        <Grid container spacing={3}>
                          {
                            lifeDepth1Tabs.length && (
                              <SelectField
                                label=""
                                placeHolder="top"
                                name="detail_info.life_category.top"
                                value={`${formData.detail_info.life_category.top.key}:${formData.detail_info.life_category.top.value}`}
                                onChange={handleChangeCategory}
                                options={lifeDepth1Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                              // disabled={formDataDisabled}
                              />
                            )
                          }
                          {
                            lifeDepth2Tabs.length && (
                              <SelectField
                                label=""
                                placeHolder="middle"
                                name="detail_info.life_category.middle"
                                value={`${formData.detail_info.life_category.middle.key}:${formData.detail_info.life_category.middle.value}`}
                                onChange={handleChangeCategory}
                                options={lifeDepth2Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                              // disabled={formDataDisabled}
                              />
                            )
                          }
                          {
                            lifeDepth3Tabs.length && (
                              <SelectField
                                label=""
                                placeHolder="bottom"
                                name="detail_info.life_category.bottom"
                                value={`${formData.detail_info.life_category.bottom.key}:${formData.detail_info.life_category.bottom.value}`}
                                onChange={handleChangeCategory}
                                options={lifeDepth3Tabs}
                                gridSizes={{ xs: 12, sm: 4 }}
                              // disabled={formDataDisabled}
                              />
                            )
                          }
                        </Grid>
                        <CardSpacer />
                        <Typography>연령대</Typography>
                        <Grid container spacing={3}>
                          <SelectField
                            label=""
                            placeHolder="min"
                            name="detail_info.life_age_range.min"
                            value={formData.detail_info.life_age_range.min}
                            onChange={handleChange}
                            options={[
                              { value: 'fashion', label: '패션' },
                              { value: "0m", label: "0개월" },
                              { value: "1m", label: "1개월" },
                              { value: "2m", label: "2개월" },
                              { value: "3m", label: "3개월" },
                              { value: "4m", label: "4개월" },
                              { value: "5m", label: "5개월" },
                              { value: "6m", label: "6개월" },
                              { value: "7m", label: "7개월" },
                              { value: "8m", label: "8개월" },
                              { value: "9m", label: "9개월" },
                              { value: "10m", label: "10개월" },
                              { value: "11m", label: "11개월" },
                              { value: "1y", label: "1년" },
                              { value: "2y", label: "2년" },
                              { value: "3y", label: "3년" },
                              { value: "4y", label: "4년" },
                              { value: "5y", label: "5년" },
                              { value: "6y", label: "6년" },
                              { value: "7y", label: "7년" },
                              { value: "8y", label: "8년" },
                              { value: "9y", label: "9년" },
                              { value: "10y", label: "10년" },
                              { value: "11y", label: "11년" },
                              { value: "12y", label: "12년" },
                              { value: "13y", label: "13년" },
                              { value: "13y+", label: "13년 이상" }
                            ]}
                            gridSizes={{ xs: 12, sm: 6 }}
                            disabled={formDataDisabled}
                          />
                          <SelectField
                            label=""
                            placeHolder="max"
                            name="detail_info.life_age_range.max"
                            value={formData.detail_info.life_age_range.max}
                            onChange={handleChange}
                            options={[
                              { value: 'fashion', label: '패션' },
                              { value: "0m", label: "0개월" },
                              { value: "1m", label: "1개월" },
                              { value: "2m", label: "2개월" },
                              { value: "3m", label: "3개월" },
                              { value: "4m", label: "4개월" },
                              { value: "5m", label: "5개월" },
                              { value: "6m", label: "6개월" },
                              { value: "7m", label: "7개월" },
                              { value: "8m", label: "8개월" },
                              { value: "9m", label: "9개월" },
                              { value: "10m", label: "10개월" },
                              { value: "11m", label: "11개월" },
                              { value: "1y", label: "1년" },
                              { value: "2y", label: "2년" },
                              { value: "3y", label: "3년" },
                              { value: "4y", label: "4년" },
                              { value: "5y", label: "5년" },
                              { value: "6y", label: "6년" },
                              { value: "7y", label: "7년" },
                              { value: "8y", label: "8년" },
                              { value: "9y", label: "9년" },
                              { value: "10y", label: "10년" },
                              { value: "11y", label: "11년" },
                              { value: "12y", label: "12년" },
                              { value: "13y", label: "13년" },
                              { value: "13y+", label: "13년 이상" }
                            ]}
                            gridSizes={{ xs: 12, sm: 6 }}
                            disabled={formDataDisabled}
                          />
                        </Grid>
                        <CardSpacer />
                        <Typography>수입방법</Typography>
                        <RadioGroup name="detail_info.life_parallel_import"
                          defaultValue={formData.detail_info.life_parallel_import === true ? "true" : "false"}
                          onChange={handleChange}
                          row>
                          <FormControlLabel
                            name="detail_info.life_parallel_import"
                            value="true"
                            control={<Radio />}
                            label="병행수입"
                            disabled={formDataDisabled}
                          />
                          <FormControlLabel
                            name="detail_info.life_parallel_import"
                            value="false"
                            control={<Radio />}
                            label="병행수입 아님"
                            disabled={formDataDisabled}
                          />
                        </RadioGroup>
                        <CardSpacer />
                      </div>
                    )
                  }
                </CardContent>
              </Card>
              <Hr />
              <Card>
                <CardTitle title="사업자 정보" />
                <CardContent>
                  <FileUploadSection
                    title="사업자등록증"
                    onFileUpload={handleFileUpload}
                    name="business_registration_image"
                    file={formData.detail_info.business_registration_image}
                    file_name={formData.detail_info.business_registration_image_name}
                  />
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="사업자등록번호"
                    name="b_no"
                    value={formData.b_no}
                    onChange={handleChange}
                    placeholder="Business Registration Number"
                    gridSizes={{ xs: 12, sm: 12 }}
                    disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <FileUploadSection
                    title="통신판매업증"
                    name="e_commerce_license_image"
                    onFileUpload={handleFileUpload}
                    file={formData.detail_info.e_commerce_license_image}
                    file_name={formData.detail_info.e_commerce_license_image_name}
                  />
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="통신판매업 신고번호"
                    name="detail_info.e_commerce_license_number"
                    value={formData.detail_info.e_commerce_license_number}
                    onChange={handleChange}
                    placeholder="E-commerce Registration Number"
                    gridSizes={{ xs: 12, sm: 12 }}
                    disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="업체명"
                      name="detail_info.company_name"
                      value={formData.detail_info.company_name}
                      onChange={handleChange}
                      placeholder="Company Name"
                      gridSizes={{ xs: 12, sm: 6 }}
                      disabled={formDataDisabled}
                    />
                    <TextFieldWithLabel
                      label="대표자명"
                      name="detail_info.contact_name"
                      value={formData.detail_info.contact_name}
                      onChange={handleChange}
                      placeholder="Representative Name"
                      gridSizes={{ xs: 12, sm: 6 }}
                      disabled={formDataDisabled}
                    />
                  </Grid>
                  <CardSpacer />
                  <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
                    <TextFieldWithLabel
                      label="사업장 소재지"
                      name="detail_info.business_location.address"
                      value={formData.detail_info.business_location.address}
                      onChange={handleChange}
                      placeholder="Business Location"
                      gridSizes={{ xs: 12, sm: 12 }}
                    />
                    <TextField
                      fullWidth
                      name="detail_info.business_location.company_name"
                      value={formData.detail_info.business_location.company_name}
                      onChange={handleChange}
                      placeholder="상세주소"
                      style={{ marginTop: "19.5px", marginLeft: "8px" }}
                    />
                    <DaumAddress onSelectAddress={onSelectAddress} />
                  </Grid>
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="사업자 통관고유부호"
                    name="detail_info.business_customs_code"
                    value={formData.detail_info.business_customs_code}
                    onChange={handleChange}
                    placeholder="Business Customs Code"
                    gridSizes={{ xs: 12, sm: 12 }}
                  />
                  <CardSpacer />
                  <Typography>인증 정보</Typography>
                  <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                    <FormControlLabel value="option1" control={<Radio />} label="인증: 신고 대상" />
                    <FormControlLabel value="option2" control={<Radio />} label="상세페이지 별도표기" />
                    <FormControlLabel value="option3" control={<Radio />} label="인증: 신고 대상 아님" />
                  </RadioGroup>

                  <FileUploadSection
                    title="인증 정보 1"
                    onFileUpload={handleFileUpload2}
                    name="certification_info_1"
                    file={formData.detail_info.certification_info_1.file}
                    file_name={formData.detail_info.certification_info_1.file_name}
                    disabled={selectedOption === 'option2' || selectedOption === 'option3'}
                  />
                  <CardSpacer />
                  <FileUploadSection
                    title="인증 정보 2"
                    onFileUpload={handleFileUpload2}
                    name="certification_info_2"
                    file={formData.detail_info.certification_info_2.file}
                    file_name={formData.detail_info.certification_info_2.file_name}
                    disabled={selectedOption === 'option2' || selectedOption === 'option3'}
                  />
                  <CardSpacer />
                  <FileUploadSection
                    title="인증 정보 3"
                    onFileUpload={handleFileUpload2}
                    name="certification_info_3"
                    file={formData.detail_info.certification_info_3.file}
                    file_name={formData.detail_info.certification_info_3.file_name}
                    disabled={selectedOption === 'option2' || selectedOption === 'option3'}
                  />

                </CardContent>
              </Card>
              <Hr />
              <Card>
                <CardTitle title="고객센터 정보" />
                <CardContent>
                  <TextFieldWithLabel
                    label="고객센터번호"
                    name="detail_info.contact_phone"
                    value={formData.detail_info.contact_phone}
                    onChange={handleChange}
                    placeholder="Customer Center Number"
                    gridSizes={{ xs: 12, sm: 12 }}
                  />
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <SelectField
                      label="운영 시작 시간"
                      placeHolder="Operating Start Hours"
                      name="detail_info.customer_center_info.op_start_hours"
                      value={formData.detail_info.customer_center_info.op_start_hours}
                      onChange={handleChange}
                      options={[
                        { value: '', label: 'None' },
                        { value: '9', label: '9:00' },
                        { value: '10', label: '10:00' },
                        { value: '11', label: '11:00' }
                      ]}
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <SelectField
                      label="운영 종료 시간"
                      placeHolder="Operating End Hours"
                      name="detail_info.customer_center_info.op_end_hours"
                      value={formData.detail_info.customer_center_info.op_end_hours}
                      onChange={handleChange}
                      options={[
                        { value: '', label: 'None' },
                        { value: '17', label: '17:00' },
                        { value: '18', label: '18:00' },
                        { value: '19', label: '19:00' }
                      ]}
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <SelectField
                      label="점심 시작 시간"
                      placeHolder="Lunch Start Hours"
                      name="detail_info.customer_center_info.lunch_start_hours"
                      value={formData.detail_info.customer_center_info.lunch_start_hours}
                      onChange={handleChange}
                      options={[
                        { value: '', label: 'None' },
                        { value: '12', label: '12:00' },
                        { value: '1230', label: '12:30' },
                        { value: '13', label: '13:00' }
                      ]}
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <SelectField
                      label="점심 종료 시간"
                      placeHolder="Lunch End Hours"
                      name="detail_info.customer_center_info.lunch_end_hours"
                      value={formData.detail_info.customer_center_info.lunch_end_hours}
                      onChange={handleChange}
                      options={[
                        { value: '', label: 'None' },
                        { value: '13', label: '13:00' },
                        { value: '1330', label: '13:30' },
                        { value: '14', label: '14:00' }
                      ]}
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                  <Typography>휴일</Typography>
                  <FormGroup>
                    {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(day => (
                      <FormControlLabel
                        key={day}
                        control={
                          <Checkbox
                            name={`detail_info.customer_center_info.working_days.${day}`}
                            value={day}
                            checked={formData.detail_info.customer_center_info.working_days[day] ? formData.detail_info.customer_center_info.working_days[day] : false}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={intl.formatMessage({ id: `day.${day}`, defaultMessage: day.charAt(0).toUpperCase() + day.slice(1) })}
                      />
                    ))}
                  </FormGroup>
                  <CardSpacer />
                </CardContent>
              </Card>
              <Hr />
              <Card>
                <CardTitle title="EASYPAY 정보" />
                <CardContent>
                  <TextFieldWithLabel
                    label="MALL ID"
                    name="detail_info.easypay_sync_info.mall_id"
                    value={formData.detail_info.easypay_sync_info.mall_id}
                    onChange={handleChange}
                    placeholder="EASYPAY MALL ID"
                    gridSizes={{ xs: 12, sm: 12 }}
                  // disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="SECRET KEY"
                    name="detail_info.easypay_sync_info.secret_key"
                    value={formData.detail_info.easypay_sync_info.secret_key}
                    onChange={handleChange}
                    placeholder="EASYPAY SECRET KEY"
                    gridSizes={{ xs: 12, sm: 12 }}
                  // disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="EASYPAY INFO ETC 1"
                    name="detail_info.easypay_sync_info.etc1"
                    value={formData.detail_info.easypay_sync_info.etc1}
                    onChange={handleChange}
                    placeholder="EASYPAY ETC 1"
                    gridSizes={{ xs: 12, sm: 12 }}
                  // disabled={formDataDisabled}
                  />
                  <CardSpacer />
                  <TextFieldWithLabel
                    label="EASYPAY INFO ETC 2"
                    name="detail_info.easypay_sync_info.etc2"
                    value={formData.detail_info.easypay_sync_info.etc2}
                    onChange={handleChange}
                    placeholder="EASYPAY ETC 2"
                    gridSizes={{ xs: 12, sm: 12 }}
                  // disabled={formDataDisabled}
                  />
                  <CardSpacer />
                </CardContent>
              </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
              <div></div>
            </DetailPageLayout.RightSidebar>
            <Savebar
              disabled={isSaveDisabled}
              state={saveButtonBar}
              onSubmit={submit}
              onCancel={() => navigate(storeListListUrl())}
              onDelete={onDelete}
            />
          </DetailPageLayout>
        );
      }}
    </Form >
  );
};

StoreInfoPage.displayName = "StoreInfoPage";
export default StoreInfoPage;
