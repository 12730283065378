import { ExportScope, FileTypesEnum } from "@dashboard/graphql";
import { DUPLICATE_ACTION } from "@dashboard/products/views/ProductList/export";

export interface ExportSettingsInput {
  scope: ExportScope;
  fileType: FileTypesEnum;
  duplicateAction?: DUPLICATE_ACTION;
}

export interface ExportSettingsFormData {
  fileType: FileTypesEnum;
  scope: ExportScope;
}

export const exportSettingsInitialFormData = {
  fileType: FileTypesEnum.CSV,
  scope: ExportScope.IDS,
  duplicateAction: DUPLICATE_ACTION.UPDATE_EXISTING,
};

export const exportSettingsInitialFormDataWithIds = {
  fileType: FileTypesEnum.CSV,
  scope: ExportScope.IDS,
};
