import React from 'react';
import { Typography } from '@material-ui/core';
import ImageUploadCustom from "./ImageUploadCustom";
import CardSpacer from "@dashboard/components/CardSpacer";

interface ImageUploadSectionProps {
  title: string;
  onImageUpload: (files: File[], name?: string, flag?: boolean) => boolean;
  name?: string;
  flag?: boolean
  vendorThumbnail?: string;
}

const ImageUploadSection: React.FC<ImageUploadSectionProps> = ({ title, onImageUpload, name, flag=false, vendorThumbnail }) => (
  <>
    <Typography>{title}</Typography>
    <ImageUploadCustom onImageUpload={(fileList) => onImageUpload(Array.from(fileList), name, flag)} vendorThumbnail={vendorThumbnail} />
    <CardSpacer />
  </>
);

export default ImageUploadSection;
