import { ApolloClient, ApolloError } from "@apollo/client";
import { IMessageContext } from "@dashboard/components/messages";
import { DEMO_MODE } from "@dashboard/config";
import { useUserDetailsQuery } from "@dashboard/graphql";
import useLocalStorage from "@dashboard/hooks/useLocalStorage";
import useNavigator from "@dashboard/hooks/useNavigator";
import { commonMessages } from "@dashboard/intl";
import {
  checkIfCredentialsExist,
  isSupported as isCredentialsManagementAPISupported,
  login as loginWithCredentialsManagementAPI,
  saveCredentials,
} from "@dashboard/utils/credentialsManagement";
import { getAppMountUriForRedirect } from "@dashboard/utils/urls";
import {
  GetExternalAccessTokenData,
  LoginData,
  useAuth,
  useAuthState,
} from "@saleor/sdk";
import isEmpty from "lodash/isEmpty";
import { useEffect, useRef, useState } from "react";
import { IntlShape } from "react-intl";
import urlJoin from "url-join";

import { parseAuthError } from "../errors";
import {
  ExternalLoginInput,
  RequestExternalLoginInput,
  RequestExternalLogoutInput,
  UserContext,
  UserContextError,
} from "../types";
import { displayDemoMessage } from "../utils";
import { TGswitchLink } from "@scripts/global"

import api from '../../stores/services/REST_apis/client';
import { vendorInitialState } from '@dashboard/tng/data/vendorData'
import { useVendor } from "@context/VendorContext"


export interface UseAuthProviderOpts {
  intl: IntlShape;
  notify: IMessageContext;
  apolloClient: ApolloClient<any>;
}

export function useAuthProvider({
  intl,
  notify,
  apolloClient,
}: UseAuthProviderOpts): UserContext {
  const { login, getExternalAuthUrl, getExternalAccessToken, logout } =
    useAuth();
  const navigate = useNavigator();
  const { authenticated, authenticating, user } = useAuthState();
  const [requestedExternalPluginId] = useLocalStorage(
    "requestedExternalPluginId",
    null,
  );
  const [errors, setErrors] = useState<UserContextError[]>([]);
  const permitCredentialsAPI = useRef(true);

  useEffect(() => {
    if (authenticating && errors.length) {
      setErrors([]);
    }
  }, [authenticating]);

  useEffect(() => {
    if (authenticated) {
      permitCredentialsAPI.current = true;
    }
  }, [authenticated]);

  useEffect(() => {
    if (
      !authenticated &&
      !authenticating &&
      !requestedExternalPluginId &&
      permitCredentialsAPI.current
    ) {
      permitCredentialsAPI.current = false;
      loginWithCredentialsManagementAPI(handleLogin);
    }
  }, [authenticated, authenticating]);

  const { state, dispatch } = useVendor();

  const userDetails = useUserDetailsQuery({
    client: apolloClient,
    skip: !authenticated,
    // Don't change this to 'network-only' - update of intl provider's
    // state will cause an error
    fetchPolicy: "cache-and-network",
  });

  const handleLoginError = (error: ApolloError) => {
    const parsedErrors = parseAuthError(error);

    if (parsedErrors.length) {
      setErrors(parsedErrors);
    } else {
      setErrors(["unknownLoginError"]);
    }
  };

  const handleLogout = async () => {
    const returnTo = urlJoin(
      window.location.origin,
      getAppMountUriForRedirect(),
    );

    const result = await logout({
      input: JSON.stringify({
        returnTo,
      } as RequestExternalLogoutInput),
    });

    // Clear credentials from browser's credential manager only when exist.
    // Chrome 115 crash when calling preventSilentAccess() when no credentials exist.
    const hasCredentials = await checkIfCredentialsExist();
    if (isCredentialsManagementAPISupported && !!hasCredentials) {
      navigator.credentials.preventSilentAccess();
    }

    // Forget last logged in user data.
    // On next login, user details query will be refetched due to cache-and-network fetch policy.
    apolloClient.clearStore();

    const errors = result?.errors || [];

    const externalLogoutUrl = result
      ? JSON.parse(result.data?.externalLogout?.logoutData || null)?.logoutUrl
      : "";

    if (!errors.length) {
      if (externalLogoutUrl) {
        window.location.href = externalLogoutUrl;
      } else {
        navigate("/");
      }
    }
  };

  const checkEntry = async (uid: string) => {
    ;
    try {
      await api.post(`/api-tg/vendor/check-entry`, { uid });
    } catch (error) {
      throw error.response.status
    }
  };

  const getVendor = async (email: string) => {
    ;
    try {
      const response = await api.post(`/api-tg/vendor/me`, { email });
      return response.data;
    } catch (error) {
      console.error('Error fetching vendor:', error);
      throw error;
    }
  };

  // 대시보드 로그인
  const handleLogin = async (email: string, password: string) => {
    try {
      const result = await login({
        email,
        password,
        includeDetails: false,
      });

      // if (isEmpty(result.data?.tokenCreate?.user?.userPermissions)) {
      //   setErrors(["noPermissionsError"]);
      //   await handleLogout();
      // }

      if (result && !result.data?.tokenCreate?.errors.length) { // SUCCESS:: 로그인 성공
        if (DEMO_MODE) {
          displayDemoMessage(intl, notify);
        }
        try {
          const vendor = await getVendor(email); // 관리자 정보 불러오기
          dispatch({ type: 'SET_VENDOR', payload: vendor });
        } catch {
          console.log('VENDOR 테이블에 저장되지 않은 사용자:: ', email);
          localStorage.setItem('vendor_info', JSON.stringify(vendorInitialState));
        }

        saveCredentials(result.data?.tokenCreate?.user!, password);
      } else { // FAIL:: 로그인 실패
        const errors = result.data.tokenCreate.errors
        const error = errors[0]
        const code = error.code + ''

        try { // 입점신청 상태 확인
          switch (code) {
            case "ACCOUNT_NOT_CONFIRMED":
              const uid = errors[1].message
              await checkEntry(uid)
              alert('입점 신청 승인이 미완료되었습니다.\n내부 시스템에 따라 최대 2-3일 정도 소요될 수 있습니다.\n\n기타 문의사항은 시스템 관리자에게 문의주시기 바랍니다.')
              break;
            default:
              console.log(`입력된 EMAIL:: ${email} || 입력된 PW:: ${password}`)
              setErrors(["loginError"]);
          }
        } catch (error) {
          switch (error) {
            case 404:
              try {
                // 미승인 된 매니저 처리
                const vendor = await getVendor(email); // 관리자 정보 불러오기
                if (vendor) {
                  alert('미승인 사용자입니다.\n직원 등록 승인 후 이용해 주세요.')
                }
              } catch (error) {
                alert('상점 입점 신청이 완료되지 않았습니다.\n입점 신청 완료 후 서비스를 이용해주세요.')
                TGswitchLink('ENTRY_APPLY')
              }
              break
            case 500:
              alert('오류가 발생했습니다.\n계속될 경우 시스템 관리자에게 문의주시기 바랍니다.')
              break
            default:
              alert('에러 발생')
              console.log('입점신청 확인 에러:: ', error)
          }
        }

      }

      await logoutNonStaffUser(result.data?.tokenCreate!);

      return result.data?.tokenCreate;
    } catch (error) {
      if (error instanceof ApolloError) {
        handleLoginError(error);
      } else {
        switch (error) {
          case 'NO_EXIST_VENDOR':
            alert('가입되지 않은 이메일 계정입니다.\n회원가입 후 진행해 주시기 바랍니다.');
            break;
          default:
            alert('로그인 중 오류가 발생했습니다.')
            console.log(error)
            setErrors(["unknownLoginError"]);
        }
      }
    }
  };

  const handleRequestExternalLogin = async (
    pluginId: string,
    input: RequestExternalLoginInput,
  ) => {
    const result = await getExternalAuthUrl({
      pluginId,
      input: JSON.stringify(input),
    });

    return result?.data?.externalAuthenticationUrl;
  };

  const handleExternalLogin = async (
    pluginId: string | null,
    input: ExternalLoginInput,
  ) => {
    if (!pluginId) {
      return;
    }
    try {
      const result = await getExternalAccessToken({
        pluginId,
        input: JSON.stringify(input),
      });

      if (
        isEmpty(result.data?.externalObtainAccessTokens?.user?.userPermissions)
      ) {
        setErrors(["noPermissionsError"]);
        await handleLogout();
      }

      if (result && !result.data?.externalObtainAccessTokens?.errors.length) {
        if (DEMO_MODE) {
          displayDemoMessage(intl, notify);
        }
      } else {
        setErrors(["externalLoginError"]);
        await handleLogout();
      }

      await logoutNonStaffUser(result.data?.externalObtainAccessTokens!);

      return result?.data?.externalObtainAccessTokens;
    } catch (error) {
      if (error instanceof ApolloError) {
        handleLoginError(error);
      } else {
        setErrors(["unknownLoginError"]);
      }
    }
  };

  const logoutNonStaffUser = async (
    data: LoginData | GetExternalAccessTokenData,
  ) => {
    if (data?.user && !data.user.isStaff) {
      notify({
        status: "error",
        text: intl.formatMessage(commonMessages.unauthorizedDashboardAccess),
        title: intl.formatMessage(commonMessages.insufficientPermissions),
      });
      await handleLogout();
    }
  };

  return {
    login: handleLogin,
    requestLoginByExternalPlugin: handleRequestExternalLogin,
    loginByExternalPlugin: handleExternalLogin,
    logout: handleLogout,
    authenticating: authenticating && !errors.length,
    authenticated: authenticated && !!user?.isStaff && !errors.length,
    user: userDetails.data?.me,
    refetchUser: userDetails.refetch,
    errors,
  };
}
