import {
  ExportInfoInput,
  ExportProductsInput as ExportProductsInputType,
  ExportScope,
  FileTypesEnum,
  InputMaybe,
  ProductFilterInput,
  Scalars,
} from "@dashboard/graphql";

export class ProductsExportParameters {
  private readonly exportInfo?: InputMaybe<ExportInfoInput>;
  private readonly fileType: FileTypesEnum;
  private readonly filter?: InputMaybe<ProductFilterInput>;
  private readonly ids?: InputMaybe<Array<Scalars["ID"]>>;
  private readonly scope: ExportScope;

  constructor({
    exportInfo,
    fileType,
    filter,
    ids,
    scope,
  }: ExportProductsInputType) {
    this.exportInfo = exportInfo;
    this.fileType = fileType;
    this.filter = filter;
    this.ids = ids;
    this.scope = scope;
  }

  asExportProductsInput() {
    return {
      exportInfo: this.exportInfo,
      fileType: this.fileType,
      filter: this.filter,
      ids: this.ids,
      scope: this.scope,
    } satisfies ExportProductsInputType;
  }
}

export enum DUPLICATE_ACTION {
  CANCEL_EXPORT = 'CANCEL_EXPORT',
  EXPORT_AS_NEW = 'EXPORT_AS_NEW',
  UPDATE_EXISTING = 'UPDATE_EXISTING'
}

export type ExportProductsToShopifyInput = {
  /** Input with info about fields which should be exported. */
  exportInfo?: InputMaybe<ExportInfoInput>;
  /** Type of exported file. */
  fileType: FileTypesEnum;
  /** Filtering options for products. */
  filter?: InputMaybe<ProductFilterInput>;
  /** List of products IDs to export. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Determine which products should be exported. */
  scope: ExportScope;
  // shopify 추가
  duplicateAction?: DUPLICATE_ACTION;
};
export type ExportProductsToShopifyInputType = {
  /** Input with info about fields which should be exported. */
  exportInfo?: InputMaybe<ExportInfoInput>;
  /** Type of exported file. */
  fileType: FileTypesEnum;
  /** Filtering options for products. */
  filter?: InputMaybe<ProductFilterInput>;
  /** List of products IDs to export. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Determine which products should be exported. */
  scope: ExportScope;
  // shopify 추가
  duplicateAction?: DUPLICATE_ACTION;
};

export class ProductsExportToShopifyParameters {
  private readonly exportInfo?: InputMaybe<ExportInfoInput>;
  private readonly fileType: FileTypesEnum;
  private readonly filter?: InputMaybe<ProductFilterInput>;
  private readonly ids?: InputMaybe<Array<Scalars["ID"]>>;
  private readonly scope: ExportScope;
  private readonly duplicateAction: DUPLICATE_ACTION;

  constructor({
    exportInfo,
    fileType,
    filter,
    ids,
    scope,
    duplicateAction
  }: ExportProductsToShopifyInputType) {
    this.exportInfo = exportInfo;
    this.fileType = fileType;
    this.filter = filter;
    this.ids = ids;
    this.scope = scope;
    this.duplicateAction = duplicateAction;
  }

  asExportProductsInput() {
    return {
      exportInfo: this.exportInfo,
      fileType: this.fileType,
      filter: this.filter,
      ids: this.ids,
      scope: this.scope,
      duplicateAction: this.duplicateAction,
    } satisfies ExportProductsToShopifyInputType;
  }
}
