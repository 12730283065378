import api from "./client";

export const getDashboardSummary = async (email: string) => {
    try {
        const response = await api.post(`/api-tg/vendor/get-dashboard-summary`, { email: email })
            .then(data => { return data; });
        return response;
    } catch (error) {
        console.error('Error fetching dashboard data:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
}

export const getNoticeList = async (vid: string) => {
    try {
        const response = await api.post(`/api-tg/vendor/notice-list`, { id: vid })
            .then(data => { return data.data; });
        return response;
    } catch (error) {
        console.error('Error fetching notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const getNotice = async (id: string) => {
    try {
        const response = await api.get(`/api-tg/vendor/notice/${id}`)
            .then(data => {
                return data.data;
            });
        return response;
    } catch (error) {
        console.error('Error fetching notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const deleteNotice = async (id: string) => {
    try {
        const response = await api.delete(`/api-tg/vendor/notice/${id}`)
            .then(res => {
                return res.status;
            });
        return response;
    } catch (error) {
        console.error('Error deleting notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const modifyNotice = async (id: string, data: any) => {
    try {
        const response = await api.put(`/api-tg/vendor/notice/${id}`, data)
            .then(data => {
                return data.status;
            });
        return response;
    } catch (error) {
        console.error('Error modifying notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const writeNotice = async (data: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/notice`, data)
            .then(res => {
                return res.status;
            });
        return response;
    } catch (error) {
        console.error('Error register notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};