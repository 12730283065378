import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Box, ChevronRightIcon, Text } from "@saleor/macaw-ui-next";
import { Card } from '@material-ui/core';
import NoticeDataTable from "../../../tng/components/DataTable/NoticeDataTable";
import useNavigator from "@dashboard/hooks/useNavigator";
import { getNoticeList } from "@dashboard/stores/services/REST_apis/notice";

interface HomeNoticeCardProps {
    testId?: string;
}

interface noticeData {
    id: string;
    title: string;
    content: string;
    writer_name: string;
    writer_email: string;
    created_at: any;
}

export const HomeNoticeCard = ({
    testId,
}: HomeNoticeCardProps) => {
    const navigate = useNavigator();
    const intl = useIntl();
    const [vendorData, setVendorData] = useState(null);
    const [vId, setVId] = useState(undefined);
    const [noticeData, setNoticeData] = useState<noticeData[]>([]);

    useEffect(() => {
        const storedVendorData = JSON.parse(localStorage.getItem('vendor_info'));
        setVendorData(storedVendorData);
        const vendorId = storedVendorData?.id;
        setVId(vendorId);
    }, []);

    useEffect(() => {
        if (vendorData !== null && vId !== undefined) {
            getNoticeData(vId);
        }
    }, [vId])

    const columns: Column[] = [
        { id: 'title', label: '제목', minWidth: 100 },
        { id: 'writer_name', label: '작성자', minWidth: 50 },
        { id: 'created_at', label: '작성일자', minWidth: 50 },
    ];

    const handleNoticeData = (data: noticeData[]) => {
        const formattingData = data.map(e => {
            const dateCreatedAt = new Date(e.created_at);
            const newData = {
                ...e,
                created_at: dateCreatedAt.toLocaleDateString(),
            }
            return newData;
        })
        setNoticeData(formattingData);
    }

    const getNoticeData = useCallback(async (vid: string) => {
        const data = await getNoticeList(vid).then(data => {
            return data.data;
        });
        handleNoticeData(data);
    }, [])

    return (
        <Box data-test-id={testId}>
            <Box display="flex" paddingTop={7} marginBottom={2} style={{ cursor: 'pointer' }} justifyContent="space-between" onClick={() => navigate(`/notice/list`)} >
                <Text variant="heading" display="block">
                    공지사항
                </Text>
                <Box marginX={3} display="flex" alignItems="center">
                    <ChevronRightIcon />
                </Box>
            </Box>
            <Box>
                <Card>
                    <NoticeDataTable columns={columns} data={noticeData} defaultRowsPerPage={5} />
                </Card>
            </Box>
        </Box>
    );
};

HomeNoticeCard.displayName = "HomeNoticeCard";
export default HomeNoticeCard;
