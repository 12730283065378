import { defineMessages } from "react-intl";

export const orderTitleMessages = defineMessages({
  canceled: {
    defaultMessage: "취소/환불됨",
    id: "Z7lX6t",
    description: "canceled fulfillment, section header",
  },
  fulfilled: {
    id: "16iwCI",
    defaultMessage: "배송",
    description: "section header",
  },
  delivered: {
    id: "16iwCo",
    defaultMessage: "배송완료",
    description: "section header",
  },
  refunded: {
    id: "+SYLEL",
    defaultMessage: "반품처리됨",
    description: "refunded fulfillment, section header",
  },
  refundedAndReturned: {
    id: "LJPHdt",
    defaultMessage: "반품 및 환불됨",
    description: "cancelled fulfillment, section header",
  },
  replaced: {
    id: "jz2SlO",
    defaultMessage: "교환됨",
    description: "refunded fulfillment, section header",
  },
  returned: {
    id: "Z8TzWt",
    defaultMessage: "반품처리됨",
    description: "refunded fulfillment, section header",
  },
  waitingForApproval: {
    id: "DyGpz4",
    defaultMessage: "출하 승인 대기중",
    description: "unapproved fulfillment, section header",
  },
  unfulfilled: {
    defaultMessage: "배송 준비중",
    id: "/xXvjF",
    description: "section header",
  },
  fulfilledFrom: {
    id: "ZPOyI1",
    defaultMessage: "출고지 : {warehouseName}",
    description: "fulfilled fulfillment, section header",
  },
});
