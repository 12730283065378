// @ts-strict-ignore
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Grid, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Typography } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import Hr from "@dashboard/components/Hr";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import { extensionMountPoints, mapToMenuItemsForCustomerDetails, useExtensions } from "@dashboard/apps/hooks/useExtensions";
import { sectionNames } from "@dashboard/intl";
import { storeListListUrl } from "../../urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";

import TextFieldWithLabel from '@dashboard/tng/components/TextFieldWithLabel';
import { updateLocalStorageData, useVendor } from '@context/VendorContext';
import { getContactInfoByVid, updateVendorDetail, updateVendorInfo } from "@dashboard/stores/services/REST_apis/vendor";
import { sanitizeData } from "@dashboard/stores/services/utils/data";
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import MenuTabs from "@dashboard/stores/components/MenuTabs";

export interface ContactInfoPageParam {
  id: string;
}
export interface ContactInfoPageFormData {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  note: string;
}

export interface ContactInfoPageProps {
  customerId: string;
  customer: CustomerDetailsQuery["user"];
  disabled: boolean;
  errors: AccountErrorFragment[];
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (
    data: ContactInfoPageFormData,
  ) => SubmitPromise<AccountErrorFragment[]>;
  onDelete: () => void;
}

const initialState = {
  store_category: "",
  fashion_category: "",
  gender_category: "",
  age_range: [],
  store_style: "",
  size_chart_id: "",
  parallel_import: false,
  life_category: "",
  life_age_range: "",
  life_parallel_import: false,
  sale_countries: "",
  contact_name: "",
  contact_mobile: "",
  contact_phone: "",
  e_commerce_license_number: "",
  company_name: "",
  certification_info_1: "",
  certification_info_2: "",
  certification_info_3: "",
  contact_email: "",
  cs_manager_name: "",
  cs_manager_mobile: "",
  tax_invoice_manager_name: "",
  tax_invoice_manager_mobile: "",
  tax_invoice_manager_email: "",
  working_days: []
};

const ContactInfoPage: React.FC<ContactInfoPageProps> = ({
  customerId,
  customer,
  disabled,
  saveButtonBar,
  onSubmit,
  onDelete,
}: ContactInfoPageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [vId, setVId] = useState("");
  const [formData, setFormData] = useState(initialState);

  const { user } = useUser();
  const { id } = useParams<ContactInfoPageParam>();
  const { state, dispatch } = useVendor()

  useEffect(() => {
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   ...state.detail_info,
    // }));
    if (user.isSuperuser && id) {
      getContactData(id);
    } else {
      getContactData(state.id);
      setVId(state.id);
    }
  }, [state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const initialForm: ContactInfoPageFormData = {
    email: customer?.email || "",
    firstName: customer?.firstName || "",
    isActive: customer?.isActive || false,
    lastName: customer?.lastName || "",
    note: customer?.note || ""
  };

  const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
  const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

  const autoFillOnChange = (e) => {
    const { name, checked } = e.target;
    const name_column = name + "_name";
    const mobile_column = name + "_mobile";
    const email_column = name + "_email";

    if (checked) {
      setFormData({
        ...formData,
        [name_column]: formData.contact_name,
        [mobile_column]: formData.contact_mobile,
        [email_column]: formData.contact_email,
      })
    } else {
      setFormData({
        ...formData,
        [name_column]: "",
        [mobile_column]: "",
        [email_column]: "",
      })
    }
  }

  const handleFormData = (data) => {
    setFormData(sanitizeData(data, true));
  }

  const getContactData = useCallback(async (id) => {
    const data = await getContactInfoByVid(id).then(data => { return data.data; });
    handleFormData(data);
  }, [])

  const handleOnSubmit = useCallback(() => {
    updateVendorInfo({ id: vId, detail_info: sanitizeData(formData, false) })
      .then(response => {
        if (response.status === 200) {
          alert("등록되었습니다.");
          dispatch({
            type: 'SET_VENDOR',
            payload: response.data,
          });
          dispatch({
            type: 'UPDATE_DETAIL_INFO',
            payload: response.data.detail_info,
          });  // 상태를 업데이트하고 useVendor의 dispatch를 호출
          updateLocalStorageData(response.data, false);
        } else {
          alert("오류가 발생했습니다.")
        }
      });
  }, [formData])

  return (
    <Form
      confirmLeave
      initial={initialForm}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {({ isSaveDisabled, submit }) => {
        return (
          <DetailPageLayout>
            <TopNav title={intl.formatMessage(sectionNames.contactInfo)}>
              {extensionMenuItems.length > 0 && (
                <CardMenu menuItems={extensionMenuItems} />
              )}
            </TopNav>
            <DetailPageLayout.Content>
              {
                user.isSuperuser && (
                  <MenuTabs vId={id} current={'contactInfo'} />
                )
              }
              <Card>
                <CardTitle title="스토어 담당자" />
                <CardContent>
                  <Typography>재재에서 스토어를 관리하는 담당자 정보를 입력해주세요.(전화번호는 외부에 노출되지 않습니다)</Typography>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="담당자명"
                      name="contact_name"
                      value={formData.contact_name}
                      onChange={handleChange}
                      placeholder="Enter manager's name in Korean"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="담당자 이메일"
                      name="contact_email"
                      value={formData.contact_email}
                      onChange={handleChange}
                      placeholder="Enter manager's email"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="담당자 휴대폰번호"
                      name="contact_mobile"
                      value={formData.contact_mobile}
                      onChange={handleChange}
                      placeholder="Enter manager's mobile number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="유선 연락처"
                      name="contact_phone"
                      value={formData.contact_phone}
                      onChange={handleChange}
                      placeholder="Enter manager's landline number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                </CardContent>
              </Card>
              <Hr />
              <Card>
                <CardTitle title="CS 담당자" />
                <CardContent>
                  <Typography>재재 운영팀에 인입된 CS를 해당 담당자에게 전달해드립니다.(연락처는 외부에 노출되지 않습니다)</Typography>
                  <CardSpacer />
                  <FormGroup row>
                    <FormControlLabel
                      onChange={autoFillOnChange}
                      control={<Checkbox name="cs_manager" />}
                      label="스토어 담당자와 동일"
                    />
                  </FormGroup>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="CS 담당자명"
                      name="cs_manager_name"
                      value={formData.cs_manager_name}
                      onChange={handleChange}
                      placeholder="Enter CS representative's name"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="연락처"
                      name="cs_manager_mobile"
                      value={formData.cs_manager_mobile}
                      onChange={handleChange}
                      placeholder="Enter representative's contact number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                </CardContent>
              </Card>
              <Hr />
              <Card>
                <CardTitle title="세금계산서 담당자" />
                <CardContent>
                  <Typography>담당자의 연락처로 세금계산서를 전송해드립니다.</Typography>
                  <CardSpacer />
                  <FormGroup row>
                    <FormControlLabel
                      onChange={autoFillOnChange}
                      control={<Checkbox name="tax_invoice_manager" />}
                      label="스토어 담당자와 동일"
                    />
                  </FormGroup>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="세금계산서 담당자명"
                      name="tax_invoice_manager_name"
                      value={formData.tax_invoice_manager_name}
                      onChange={handleChange}
                      placeholder="Enter tax invoice manager's name"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="이메일"
                      name="tax_invoice_manager_email"
                      value={formData.tax_invoice_manager_email}
                      onChange={handleChange}
                      placeholder="Enter email"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="연락처"
                      name="tax_invoice_manager_mobile"
                      value={formData.tax_invoice_manager_mobile}
                      onChange={handleChange}
                      placeholder="Enter contact number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                </CardContent>
              </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
              <div></div>
            </DetailPageLayout.RightSidebar>
            <Savebar
              disabled={isSaveDisabled}
              state={saveButtonBar}
              onSubmit={handleOnSubmit}
              onCancel={() => navigate(storeListListUrl())}
              onDelete={onDelete}
            />
          </DetailPageLayout>
        );
      }}
    </Form>
  );
};

ContactInfoPage.displayName = "ContactInfoPage";
export default ContactInfoPage;
