import { useMutation } from "@apollo/client";
import { useVendor } from "@context/VendorContext";
import { useUser } from "@dashboard/auth";
import { Button } from "@dashboard/components/Button";
import { ConfirmButton } from "@dashboard/components/ConfirmButton";
import { ProductChannelListingUpdateDocument, ProductMediaCreateDocument, ProductUpdateDocument, ProductVariantBulkCreateDocument } from "@dashboard/graphql";
import useNotifier from "@dashboard/hooks/useNotifier";
import { buttonMessages } from "@dashboard/intl";
import { productBulkCreateMutation } from "@dashboard/products/mutations";
import { getCafe24ProductsInfo } from "@dashboard/stores/services/REST_apis/cafe24";
import DataTable from "@dashboard/tng/components/DataTable/ProductListDataTable";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    headerCell: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        height: '50px',
    },
    rowOdd: {
        backgroundColor: '#f9f9f9',
    },
    rowEven: {
        backgroundColor: '#ffffff',
    },
    button: {
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#303f9f',
        },
    },
    pagination: {
        '& .MuiIconButton-root': {
            backgroundColor: '#f5f5f5',
            color: '#3f51b5',
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: '#adb5bd'
        },
        '& .MuiIconButton-root:not(.Mui-disabled)': {
            border: '1px solid #96B7E5'
        },
        '& .MuiIconButton-root:not(.Mui-disabled):hover': {
            backgroundColor: '#96B7E5',
        },
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
    },
    imageCell: {
        paddingRight: '0 !important', // 이미지 셀의 패딩 제거
        textAlign: 'center', // 가운데 정렬
    },
    image: {
        maxWidth: '50px',
        maxHeight: '100px',
        display: 'block',
        margin: '0 auto', // 이미지 자체도 가운데 정렬
    },
});

// export enum ProductImportStep {
//     SETTINGS = 0,
//     SELECTING = 1,
// }

// function useSteps(): Array<Step<ProductImportStep>> {
//     const intl = useIntl();

//     return [
//         {
//             label: "Setting up products to import into Zaezae.",
//             value: ProductImportStep.SETTINGS,
//         },
//         {
//             label: "Selecting products to import into Zaezae.",
//             value: ProductImportStep.SELECTING,
//         },
//     ];
// }
// const ProductImportSteps = makeCreatorSteps<ProductImportStep>();
export interface ProductsListDialogProps {
    data: { products: any[], count: number },
    onClose: () => void;
    onSubmit: (data: any) => void;
    open: boolean;
    accessToken: string;
    loadMore: (offset) => void;
}

// Define the ProductsListDialog component
const ProductsListDialog: React.FC<ProductsListDialogProps> = ({
    data,
    onClose,
    open,
    accessToken,
    loadMore
}) => {
    // const [step, { next, prev, set: setStep }] = useWizard(
    //     ProductImportStep.SETTINGS,
    //     [ProductImportStep.SETTINGS, ProductImportStep.SELECTING],
    // );
    // const steps = useSteps();
    const [vId, setVId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const notify = useNotifier();
    const columns = [
        { id: 'product_code', label: 'product_code', },
        { id: 'product_name', label: 'product_name', },
    ]
    const [selectedRows, setSelectedRows] = useState<any[]>([]);  // 선택된 항목의 ID를 관리

    const [productBulkCreate] = useMutation(productBulkCreateMutation);
    const [productChannelLisringUpdate] = useMutation(ProductChannelListingUpdateDocument);
    const [productVariantBulkCreate] = useMutation(ProductVariantBulkCreateDocument);
    const [productMediaCreate] = useMutation(ProductMediaCreateDocument);
    const [productUpdate] = useMutation(ProductUpdateDocument);

    const { user } = useUser();
    const { state } = useVendor();

    useEffect(() => {
        setVId(state.id);
    })

    useEffect(() => {
        setSelectedRows([]);
    }, [open])

    const handleCheckboxClick = useCallback((data: any) => {
        setSelectedRows((prevSelectedRows) => {
            // 이미 선택된 데이터인지 확인
            const isAlreadySelected = prevSelectedRows.some((row) => row.id === data.id);

            if (isAlreadySelected) {
                // 이미 선택된 데이터는 제외
                return prevSelectedRows.filter((row) => row.id !== data.id);
            } else {
                // 새로 선택된 데이터 추가
                return [...prevSelectedRows, data];
            }
        });
    }, []);

    const formatCafe24Data = (data, products_data) => {
        return data.map((data) => {
            const additionalData = products_data[data.product_no].product
            const description = `${data.summary_description ? `<p>${data.summary_description}</p>` : ''}${additionalData.simple_description ? `<p>${additionalData.simple_description}</p>` : ''}${additionalData.description ? additionalData.description : ''}`;
            return {
                productType: "UHJvZHVjdFR5cGU6MzE=", // 공통 유형
                name: data.product_name,
                description: JSON.stringify({
                    blocks: [
                        {
                            type: "paragraph",
                            data: {
                                text: description
                            }
                        },
                    ]
                }),
                taxClass: additionalData.tax_type === "A" ? "VGF4Q2xhc3M6NA==" : additionalData.tax_type === "B" ? "VGF4Q2xhc3M6Mw==" : null,
                metadata: [
                    {
                        key: 'ZZ_VID',
                        value: user.isSuperuser ? 'VD_MASTER' : state.id,
                    },
                    {
                        key: 'CAFE24_PRODUCT_NO',
                        value: data.product_no
                    },
                    {
                        key: 'CAFE24_PRODUCT_CODE',
                        value: data.product_code
                    }],
            }
        })
    }
    const formatVariantData = (data, price) => {
        console.log(data, price)
        return data.map((variant) => {
            const variantName = variant.options ? variant.options.map(attr => attr.value).join(" ") : "";
            return {
                attributes: [
                    {
                        id: "QXR0cmlidXRlOjk4",
                        dropdown: {
                            value: "none"
                        }
                    }
                ],
                // sku: variant.variant_code,
                name: variantName,
                channelListings: [
                    {
                        channelId: "Q2hhbm5lbDoz",
                        price: price
                    },
                ],
                stocks: []
            };
        })
    };

    const fomatAttributeData = (product) => {
        return {
            attributes: [
                {
                    id: "QXR0cmlidXRlOjkx",
                    values: ["all"]
                },
                {
                    id: "QXR0cmlidXRlOjk3",
                    values: ["none"]
                },
                product.made_date &&
                {
                    id: "QXR0cmlidXRlOjc5",
                    date: product.made_date
                },
                product.product_material &&
                {
                    id: "QXR0cmlidXRlOjc0",
                    plainText: product.product_material
                },
                product.made_in_code && {
                    id: "QXR0cmlidXRlOjc3",
                    plainText: product.made_in_code
                },
            ].filter(Boolean)
        }
    }


    const importProducts = async () => {
        try {
            setLoading(true);
            const products_data = await getSelectedProductsInfo();
            const products = formatCafe24Data(selectedRows, products_data);

            // 상품 등록
            const { data } = await productBulkCreate({
                variables: { products }
            });

            const productResults = data.productBulkCreate.results.map(result => {
                const cafe24ProductNo = result.product.metadata.find(meta => meta.key === "CAFE24_PRODUCT_NO")?.value;
                const price = selectedRows.find(row => row.product_no === parseInt(cafe24ProductNo)).price;
                return { product_id: result.product.id, cafe24_product_no: cafe24ProductNo, price };
            });

            // 순차적으로 채널 업데이트, Attribute 업데이트, 변형 생성, 미디어 생성
            for (const result of productResults) {
                await updateProductChannelListing(result);
                await updateProductAttributes(result);
                await createProductVariants(result, products_data);
                await createProductMedia(result);
            }

            notify({
                text: "Products successfully imported.",
                title: "Import Successful",
                status: "success",
            });

            onClose();
        } catch (error) {
            notify({
                text: "Error during product import. Please try again.",
                title: "Import Failed",
                status: "error",
            });
            console.error('Error during product import:', error);
        } finally {
            setLoading(false);  // Stop loading spinner
        }
    };

    // 채널 업데이트 함수
    const updateProductChannelListing = async (result) => {
        try {
            await productChannelLisringUpdate({
                variables: {
                    id: result.product_id,
                    input: {
                        updateChannels: [
                            {
                                availableForPurchaseDate: null,
                                channelId: "Q2hhbm5lbDoz",
                                isAvailableForPurchase: false,
                                isPublished: false,
                                publicationDate: null,
                                visibleInListings: false
                            },
                            {
                                availableForPurchaseDate: null,
                                channelId: "Q2hhbm5lbDo4",
                                isAvailableForPurchase: false,
                                isPublished: false,
                                publicationDate: null,
                                visibleInListings: false
                            }
                        ]
                    }
                }
            });
        } catch (error) {
            console.error(`Error updating channel listing for product ${result.product_id}:`, error);
        }
    };

    // Attribute 업데이트 함수
    const updateProductAttributes = async (result) => {
        try {
            const row = selectedRows.find(row => row.product_no === parseInt(result.cafe24_product_no));
            const input = fomatAttributeData(row)
            await productUpdate({
                variables: {
                    id: result.product_id,
                    input: input,
                }
            });
        } catch (error) {
            console.error(`Error creating media for product ${result.product_id}:`, error);
        }
    };

    // 변형 생성 함수
    const createProductVariants = async (result, variants) => {
        try {
            const inputs = formatVariantData(variants[result.cafe24_product_no].variants, result.price);
            await productVariantBulkCreate({
                variables: { id: result.product_id, inputs }
            });
        } catch (error) {
            console.error(`Error creating variants for product ${result.product_id}:`, error);
        }
    };

    // 미디어 생성 함수
    const createProductMedia = async (result) => {
        try {
            const additionalimages = selectedRows.find(row => row.product_no === parseInt(result.cafe24_product_no)).additionalimages;
            const mediaUrls = additionalimages !== null ? additionalimages : []
            const detailImage = selectedRows.find(row => row.product_no === parseInt(result.cafe24_product_no)).detail_image;
            mediaUrls.push({ big: detailImage });
            mediaUrls.map(async mediaUrl => {
                const input = { product: result.product_id, mediaUrl: mediaUrl.big, alt: "", image: null };
                await productMediaCreate({ variables: input });
            })
        } catch (error) {
            console.error(`Error creating media for product ${result.product_id}:`, error);
        }
    };

    const getSelectedProductsInfo = async () => {
        const selectedRowNo: string[] = selectedRows.map(row => row.product_no);
        return await getCafe24ProductsInfo(vId, accessToken, selectedRowNo);
    };
    return (
        <Dialog onClose={onClose} open={open} maxWidth="md" fullWidth>
            <>
                <DialogTitle disableTypography>
                    <FormattedMessage id="productListDialogTitle" defaultMessage="Import Products from Cafe24" />
                </DialogTitle>
                <DialogContent>
                    {/* <ProductImportSteps
                        currentStep={step}
                        steps={steps}
                        onStepClick={setStep}
                    /> */}
                    {/* {
                        step === ProductImportStep.SELECTING && ( */}
                    <Typography variant="body1">
                        {/* Placeholder for data table */}
                        <FormattedMessage id="dataTablePlaceholder" defaultMessage={`총 ${data.count}개의 상품이 존재합니다.`} />
                        <DataTable
                            columns={columns}
                            data={data.products}
                            tableCellSelectable={true}
                            selectedRows={selectedRows}
                            rowsPerPageOptions={[10]}
                            handleCheckboxClick={handleCheckboxClick}
                            count={data.count}
                            loadMore={loadMore}
                        />
                    </Typography>
                    {/* )
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button variant="secondary" color="text" onClick={onClose} data-test-id="cancel">
                        <FormattedMessage {...buttonMessages.cancel} />
                    </Button>
                    <ConfirmButton transitionState={loading ? "loading" : "default"} type="submit" data-test-id="submit" onClick={importProducts}>
                        <FormattedMessage id="confirmButtonLabel" defaultMessage="Submit" />
                    </ConfirmButton>
                    {/* {
                        step === ProductImportStep.SETTINGS && (
                            <Button variant="secondary" color="text" onClick={onClose} data-test-id="cancel">
                                <FormattedMessage {...buttonMessages.cancel} />
                            </Button>
                        )
                    }
                    {
                        step === ProductImportStep.SETTINGS && (
                            <Button variant="primary" color="text" onClick={next} data-test-id="next">
                                <FormattedMessage {...buttonMessages.nextStep} />
                            </Button>
                        )
                    }
                    {
                        step === ProductImportStep.SELECTING && (
                            <Button variant="secondary" color="text" onClick={prev} data-test-id="back">
                                <FormattedMessage {...buttonMessages.back} />
                            </Button>
                        )
                    }
                    {
                        step === ProductImportStep.SELECTING && (
                            <ConfirmButton transitionState={confirmButtonState} type="submit" data-test-id="submit" onClick={importProducts}>
                                <FormattedMessage id="confirmButtonLabel" defaultMessage="Submit" />
                            </ConfirmButton>
                        )
                    } */}
                </DialogActions>
            </>
        </Dialog>
    );
};

ProductsListDialog.displayName = "ProductsListDialog";
export default ProductsListDialog;