import TextFieldWithLabel from '@dashboard/tng/components/TextFieldWithLabel';
import { addManager } from '@dashboard/stores/services/REST_apis/vendor';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, DialogContentText } from '@material-ui/core';
import React, { useState } from 'react';

interface PopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (email: string) => Promise<boolean>;
}


const AddManagerPopup: React.FC<PopupProps> = ({ open, onClose, onSubmit }) => {
  const [input, setInput] = useState({ email: "" });

  const onChange = (e) => {
    const { name, value } = e.target;
    setInput(prevInput => ({
      ...prevInput,
      [name]: value,
    }))
  }

  const handleClose = () => {
    setInput({ email: "" });
    onClose();
  }

  const handleSubmit = async () => {
    if (isValidEmail(input.email)) {
      const res = await onSubmit(input.email);
      if (res) {
        alert("등록되었습니다.");
        handleClose();
      } else {
        alert("오류가 발생했습니다.");
      }
    } else {
      alert("이메일이 유효하지 않습니다.")
    }
  }
  // 이메일 유효성 검사
  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}>
      <DialogTitle>매니저 초대</DialogTitle>
      <DialogContent>
        <DialogContentText>
          매니저의 정보 열람 및 이용 권한을 설정할 수 있습니다.
        </DialogContentText>
        <TextFieldWithLabel
          label={'email'}
          name={'email'}
          value={input.email}
          onChange={onChange}
          type='email'
          placeholder={'이메일 주소 입력'}
          gridSizes={{
            xs: false,
            sm: false
          }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>추가하기</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddManagerPopup;
