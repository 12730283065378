import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import useNavigator from '@dashboard/hooks/useNavigator';
import { useLocation } from 'react-router';
import { useUser } from "@dashboard/auth";
import { deleteNotice } from "@dashboard/stores/services/REST_apis/notice";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    headerCell: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
    },
    rowOdd: {
        backgroundColor: '#f9f9f9',
    },
    rowEven: {
        backgroundColor: '#ffffff',
    },
    button: {
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#303f9f',
        },
    },
    deleteButton: {
      backgroundColor: '#f50057',
      border: '1px solid #f50057',
      color: '#ffffff',
      marginBottom: '16px',
      alignSelf: 'flex-end',
      '&:hover': {
          backgroundColor: '#ffffff',
          color: '#f50057',
      },
    },
    pagination: {
        '& .MuiIconButton-root': {
            backgroundColor: '#f5f5f5',
            color: '#3f51b5',
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: '#adb5bd'
        },
        '& .MuiIconButton-root:not(.Mui-disabled)': {
            border: '1px solid #96B7E5'
        },
        '& .MuiIconButton-root:not(.Mui-disabled):hover': {
            backgroundColor: '#96B7E5',
        },
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px', // 원하는 최대 너비로 설정
    },
});

interface NoticeDataTableProps {
    columns: Column[];
    data: Data[];
    rowsPerPageOptions?: number[];
    defaultRowsPerPage?: number;
    vid: string;
    onRefresh?: (vid: string) => void;
}

const DataTable = ({ columns, data, rowsPerPageOptions = [], defaultRowsPerPage = 5, vid, onRefresh }: NoticeDataTableProps) => {
    const { user } = useUser();
    const navigate = useNavigator();
    const location = useLocation();
    const isNoticePage = location.pathname.includes('notice/list');
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);  // 선택된 항목의 ID를 관리

    useEffect(() => {
        setRowsPerPage(defaultRowsPerPage);
        setPage(0);
    }, [defaultRowsPerPage]);

    const handleRowClick = (id: string) => {
        navigate(`/notice/detail/${id}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleCheckboxClick = useCallback((id: string) => {
        setSelectedRowIds((prevSelectedRowIds) => {
            if (prevSelectedRowIds.includes(id)) {
                return prevSelectedRowIds.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelectedRowIds, id];
            }
        });
    }, []);

    const deleteSelectedData = useCallback(() => {
        Promise.all(selectedRowIds.map(id => deleteNotice(id)))
            .then((statuses) => {
                if (statuses.every(status => status === 200 || status === 204)) {
                    alert("성공적으로 삭제되었습니다.");
                    setSelectedRowIds([]); // 선택 초기화
                    if (onRefresh) {
                      onRefresh(vid);
                    }
                } else {
                    alert("오류가 발생했습니다.");
                }
            })
            .catch(() => {
                alert("오류가 발생했습니다.");
            });
    }, [selectedRowIds, navigate]);

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {isNoticePage && user.isSuperuser && (
                            <TableCell padding="checkbox">
                                {/* <Checkbox
                                    indeterminate={selectedRowIds.length > 0 && selectedRowIds.length < data.length}
                                    checked={selectedRowIds.length === data.length}
                                    onChange={(event) => {
                                        if (event.target.checked) {
                                            setSelectedRowIds(data.map(row => row.id));
                                        } else {
                                            setSelectedRowIds([]);
                                        }
                                    }}
                                    color="primary"
                                /> */}
                            </TableCell>
                        )}
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => handleRowClick(row.id)}
                                style={{ cursor: 'pointer' }}
                            >
                              {isNoticePage && user.isSuperuser && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedRowIds.includes(row.id)}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={() => handleCheckboxClick(row.id)}
                                        color="primary"
                                    />
                                </TableCell>
                              )}
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} className={classes.ellipsis}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{ root: classes.pagination }}
            />
            {user.isSuperuser && (
            <Button
                className={`${classes.button} ${classes.deleteButton}`}
                variant="contained"
                onClick={deleteSelectedData}
                disabled={selectedRowIds.length === 0}  // 선택된 항목이 없으면 버튼 비활성화
                style={{ margin: '16px' }}
            >
                선택 항목 삭제
            </Button>
            )}
        </Paper>
    );
};

export default DataTable;
