import { TopNav } from "@dashboard/components/AppLayout";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { Button, Card, CardContent, Divider, Grid, Input, makeStyles, TextField, } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import ToastEditor from "@dashboard/tng/components/ToastEditor/ToastEditor";
import useNavigator from "@dashboard/hooks/useNavigator";
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import { getNotice, modifyNotice, writeNotice, } from "@dashboard/stores/services/REST_apis/notice";

export interface noticeWritePageProps {
    data?: noticeData;
};
export interface NoticeWriteParam {
    id: string;
}

interface noticeData {
    id?: string;
    title?: string;
    content?: string;
    writer_name?: string;
    writer_email?: string;
    created_at?: any;
}

const useStyles = makeStyles({
    addButton: {
        backgroundColor: '#252929',
        border: '1px solid #252929',
        color: '#ffffff',
        marginBottom: '16px',
        alignSelf: 'flex-end',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#252929',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

const NoticeWritePage: React.FC<noticeWritePageProps> = () => {
    const { id } = useParams<NoticeWriteParam>();
    const { user } = useUser();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigator();
    const classes = useStyles();
    const [formData, setFormData] = useState<noticeData>({
        title: '',
        content: '',
        writer_name: user.firstName,
        writer_email: user.email,
    })
    const [content, setContent] = useState("");
    const navigateToDetail = () => navigate(`/notice/detail/${id}`);
    const navigateToList = () => navigate(`/notice/list`);

    const toList = () => {
        id ? navigateToDetail() : navigateToList();
    }

    useEffect(() => {
        if (id) {
            getNoticeData(id);
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const validateFormData = (data: noticeData) => {
        if (data.title.length <= 0) {
            alert("제목을 입력해 주세요.");
            return false;
        } else if (data.content.length <= 0) {
            alert("내용을 입력해 주세요.");
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = useCallback(() => {
        const data = {
            ...formData,
            content: content,
        };
        if (validateFormData(data)) {
            if (id) {
                modifyNotice(id, data).then(res => {
                    if (res === 200) {
                        alert("성공적으로 수정되었습니다.");
                        navigate(`/notice/detail/${id}`);
                    } else {
                        alert("오류가 발생했습니다.");
                    }
                });
            } else {
                writeNotice(data).then(res => {
                    if (res === 201) {
                        alert("성공적으로 등록되었습니다.");
                        navigate('/notice/list');
                    } else {
                        alert("오류가 발생했습니다.");
                    }
                });
            }
        }
    }, [content, formData, id, navigate]);

    const handleNoticeData = (data: noticeData) => {
        const dateCreatedAt = new Date(data.created_at);
        setFormData({
            ...data,
            created_at: dateCreatedAt.toLocaleString(),
            content: data.content,
        });
        setContent(data.content);
    };

    const getNoticeData = useCallback(async (id: string) => {
        try {
            const data: noticeData = await getNotice(id);
            handleNoticeData(data);
        } catch (error) {
            console.error('Error fetching notice data', error);
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <DetailPageLayout withSavebar={false}>
            <TopNav title="공지사항">
            </TopNav>
            <DetailPageLayout.Content>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name='title'
                                    value={formData.title}
                                    onChange={handleChange}
                                    placeholder='제목'
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                {
                                    !loading && (
                                        <ToastEditor body={content} setBody={setContent} />
                                    )
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <div className={classes.buttonContainer}>
                            <Button className={classes.addButton} variant="contained" onClick={toList} style={{ marginRight: "10px" }}>
                                뒤로 가기
                            </Button>
                            <Button className={classes.addButton} variant="contained" onClick={handleSubmit}>
                                저장
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <Card>
                </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
                <div></div>
            </DetailPageLayout.RightSidebar>
        </DetailPageLayout>
    );
};
NoticeWritePage.displayName = "NoticeWritePage";
export default NoticeWritePage;