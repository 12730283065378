import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

export const bannersSection = "/banners/";

const createSectionUrl = (section) => urlJoin(bannersSection, section);

export const bannerListSection = createSectionUrl("bannerList");
export const bannerDetailSection = createSectionUrl("bannerDetail");

const createListUrl = (sectionUrl) => (params = {}) => {
  const queryString = stringifyQs(params);
  return queryString ? `${sectionUrl}?${queryString}` : sectionUrl;
};

export const bannerListListUrl = createListUrl(bannerListSection);
export const bannerDetailUrl = createListUrl(bannerDetailSection);

export const bannerListPath = (id) => urlJoin(bannerListSection, id);
export const bannerDetailPath = (id) => urlJoin(bannerListSection, id);

// export const bannerListUrl = (id, params) =>
//     bannerListPath(encodeURIComponent(id)) + "?" + stringifyQs(params);

// export const bannerDetailUrl = (id, params) =>
//   bannerDetailPath(encodeURIComponent(id)) + "?" + stringifyQs(params);