import ToastViewer from '@dashboard/tng/components/ToastViewer/ToastViewer';
import { Avatar, Box, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { Viewer } from '@toast-ui/react-editor';
import React, { useEffect } from 'react';

interface noticeData {
    id: string;
    title: string;
    content: string;
    writer_name: string;
    writer_email: string;
    created_at: any;
}

export interface NoticeDatailProps {
    data: {
        id: string;
        title: string;
        content: string;
        writer_name: string;
        writer_email: string;
        created_at: any;
    };
}

const NoticeDetail = ({ data }) => {
    return (
        <CardContent>
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="h4" component="h1" >
                    {data.title}
                </Typography>
            </Box>
            <Divider />
            <Box display="flex" alignItems="center" mt={2} mb={2}>
                <Avatar></Avatar>
                <Box ml={2}>
                    <Typography variant="h6">{data.writer_name}</Typography>
                    <Typography color="textSecondary">{data.created_at}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box mt={3}>
                <ToastViewer contents={data.content} />
            </Box>
        </CardContent>
    );
}


export default NoticeDetail;
