import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox as MuiCheckbox,
  Box,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { bannerListListUrl } from "../../urls";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import useNavigator from "@dashboard/hooks/useNavigator";
import {
  deleteBanner,
  getBannersByCategory,
  upsertBanners
} from "@dashboard/stores/services/REST_apis/banner";

export interface BannerDetailsPageProps {
  loading: boolean;
  saveButtonBarState: ConfirmButtonTransitionState;
  onRemove: () => void;
}

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e0e0e0", 
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0", 
    height: 41,
  },
}))(TableCell);


const StyledTableRow = withStyles(() => ({
  root: {
    height: 41, 
    "&:last-child td": {
      borderBottom: "none", 
    },
  },
}))(TableRow);


const StyledCheckboxCell = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e0e0e0",
    width: 50, 
    height: 41,
    padding: 0,
  },
}))(TableCell);


const StyledImageUrlCell = withStyles(() => ({
  root: {
    borderBottom: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    height: 41,
    width: 500, 
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))(TableCell);


const Checkbox = withStyles(() => ({
  root: {
    height: 41,
    padding: 0, // 패딩 제거
  },
}))(MuiCheckbox);

const BannerDetailPage: React.FC<BannerDetailsPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  // console.log("ID:", id);

  // 1. 문자열을 "-"를 기준으로 먼저 분리
  const [category_id, fullBannerId] = id.split("-");

  // 2. fullBannerId에서 첫 번째 "_"를 기준으로 분리하고, 나머지 부분을 합침
  const [channel, ...bannerParts] = fullBannerId.split("_");
  const banner_id = bannerParts.join("_");

  // console.log("Category ID:", category_id); // 예: "BC_20240826_xzvEA55pbX"
  // console.log("Channel:", channel); // 예: "K"
  // console.log("Banner ID:", banner_id); // 예: "biz_S_b1"

  const navigate = useNavigator();
  const [banners, setBanners] = useState<any>({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]); // 선택된 행의 ID를 저장

  // 배너 추가 버튼을 클릭시 한줄 추가되는 로직
  const handleAddBanner =  () => {
    try {
      setBanners((prevBanners: any) => {
        const firstKey = Object.keys(prevBanners)[0];  
      
        return {
          ...prevBanners,
          [firstKey]: {
            ...prevBanners[firstKey],  
            banner: [
              ...prevBanners[firstKey].banner,  
              {
                id: new Date().toISOString(), 
                banner_img_url: "",
                banner_ref_url: "",
                banner_description: "",
                etc: "",
              }
            ],
          }
        };
      });
      
    } catch (error) {
      console.error("Error creating banner:", error);
    }
  };

  // 특정 카테고리의 배너 목록 가져오기
  const getBannerByCategory = async () => {
    setSelectedRows([]);
    const categoryId = `${category_id}`; 
  
    try {
      const data = await getBannersByCategory(categoryId);
      setBanners(data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  // 삭제 이벤트
  const handleDeleteBanner = async () => {
    let serverDeleteItems: string[] = [];

    const firstKey = Object.keys(banners)[0]; 
    const updatedBanners = banners[firstKey].banner.filter((banner: any) => {
        const isDate = !isNaN(Date.parse(banner.id));

        if (banner.id && selectedRows.includes(banner.id) && !isDate) {
            serverDeleteItems.push(banner.id); // 서버에서 삭제할 항목을 미리 수집
            return false;
        }
        if (!banner.id && selectedRows.includes(banner)) {
            return false;
        }
        return true;
    });

    setBanners((prevBanners: any) => ({
        ...prevBanners,
        [firstKey]: {
            ...prevBanners[firstKey],
            banner: updatedBanners,
        },
    }));

    if (serverDeleteItems.length > 0) {
        try {
            const status = await deleteBanner(serverDeleteItems);
            if (status) {
                alert('배너 삭제가 완료되었습니다.');
            } else {
                alert('배너 삭제 중 오류가 발생했습니다.');
            }
        } catch (error) {
            console.error('Error deleting banners:', error);
            alert('배너 삭제 중 오류가 발생했습니다.');
        }
    }

    getBannerByCategory();
  };


  /// 파일이 선택되었을 때 호출되는 함수
  const handleFileChange = (event, bannerId, index) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/tiff',
        'image/webp',
      ];
  
      if (!allowedTypes.includes(file.type)) {
        alert('이미지 파일만 첨부 가능합니다.');
        return;
      }
  
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
  
        setBanners(prevBanners => {
          const bannerData = prevBanners[bannerId] || {}; 
          const bannerList = bannerData.banner || [];
  
          return {
            ...prevBanners,
            [bannerId]: {
              ...bannerData,
              banner: bannerList.map((item, i) =>
                i === index ? { ...item, banner_img_url: base64String, image_name: file.name } : item
              ),
            },
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };
  

  const updateSaveClick = async () => {
    try {
        for (const bannerId of Object.keys(banners)) {
            for (const bannerItem of banners[bannerId].banner) {
                if (!bannerItem.banner_img_url || !bannerItem.banner_ref_url) {
                    alert('배너 이미지 URL 또는 참조 URL이 비어 있습니다.');
                    getBannerByCategory();
                    return;
                }
            }
        }

        const updatedBanners = Object.keys(banners).map((bannerId) => {
            return banners[bannerId].banner.map((bannerItem) => ({
                banner_category_id: category_id,
                banner_ref_url: bannerItem.banner_ref_url || "", 
                banner_img_url: bannerItem.banner_img_url,
                image_name: bannerItem.image_name || "",
                banner_description: bannerItem.banner_description || "", 
                banner_data_id: bannerItem.id, 
                etc: bannerItem.etc || "", 
            }));
        }).flat(); 

        const data = await upsertBanners({
            banners: updatedBanners
        });

        alert('저장되었습니다.');
        getBannerByCategory();
    } catch (error) {
        console.error('Error upserting banners:', error.response?.data || error.message);
        alert('저장 중 오류가 발생했습니다.');
        getBannerByCategory();
    }
  };


  // 행 선택 이벤트
  const handleRowSelect = (bannerId: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(bannerId)
        ? prevSelected.filter((id) => id !== bannerId)
        : [...prevSelected, bannerId]
    );
  };

  // 인풋값 변경 시 이벤트
  const handleInputChange = (bannerId: string, index: number, field: string, value: string) => {
    setBanners({
      ...banners,
      [bannerId]: {
        ...banners[bannerId],
        banner: banners[bannerId].banner.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      },
    });
  };

  useEffect(() => {
    getBannerByCategory(); 
  }, []);

  return (
    <div>
      <TopNav
        href={bannerListListUrl()}
        title={
          <span>
            <span>{banner_id}</span>
            <span style={{ marginLeft: "8px" }}>배너 수정</span>
          </span>
        }
      />
      {/* Table [START] */}
      <Card>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>노출 채널</StyledTableCell>
            <StyledTableCell>배너명</StyledTableCell>
            <StyledTableCell>노출 이미지</StyledTableCell>
            <StyledTableCell>Link url</StyledTableCell>
            <StyledTableCell>이미지 설명</StyledTableCell>
            <StyledTableCell>비고</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {Object.keys(banners).length > 0 && banners[Object.keys(banners)[0]].banner.length > 0 ? (
            Object.keys(banners).map((bannerId) => (
              banners[bannerId].banner.map((bannerItem, index) => (
                <StyledTableRow key={`${bannerId}-${index}`}>
                  <StyledCheckboxCell>
                    <Checkbox
                      checked={selectedRows.includes(bannerItem.id)}
                      onChange={() => handleRowSelect(bannerItem.id)}
                    />
                  </StyledCheckboxCell>
                  <StyledTableCell>
                    {channel === "K" ? "KOREA" : channel === "G" ? "GLOBAL" : channel}
                  </StyledTableCell>
                  <StyledTableCell>
                    {banners[bannerId].category.banner_id + banners[bannerId].category.banner_num}_{index + 1}
                  </StyledTableCell>
                  <StyledImageUrlCell>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <div>
                        <label
                          htmlFor={`bannerImgUrl-${bannerId}-${index}`}
                          style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            padding: "6px 12px",
                            backgroundColor: "#252929",
                            color: "white",
                            borderRadius: "4px",
                            display: "inline-block",
                          }}
                        >
                          파일 선택
                        </label>
                        <input
                          type="file"
                          id={`bannerImgUrl-${bannerId}-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            handleFileChange(event, bannerId, index);
                          }}
                        />
                        <span style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis", 
                          maxWidth: "200px",
                        }}>
                          {bannerItem.image_name ||
                            (bannerItem.banner_img_url
                              ? bannerItem.banner_img_url.split("/").pop()
                              : "선택된 파일 없음")}
                        </span>
                      </div>
                      
                      <Box display="flex" alignItems="center" ml={2}>
                        <img
                          src={bannerItem.banner_img_url || "/path_to_empty_thumbnail.png"}
                          alt="썸네일"
                          style={{
                            width: "100px",
                            height: "50px",
                            objectFit: "cover",
                            marginLeft: "15px",
                            border: "1px solid #ddd"
                          }}
                        />
                      </Box>
                    </Box>
                  </StyledImageUrlCell>



                  <StyledTableCell>
                    <TextField
                      value={bannerItem.banner_ref_url || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        handleInputChange(
                          bannerId,
                          index,
                          "banner_ref_url",
                          e.target.value
                        )
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      value={bannerItem.banner_description || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        handleInputChange(
                          bannerId,
                          index,
                          "banner_description",
                          e.target.value
                        )
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      value={bannerItem.etc || ""}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        handleInputChange(bannerId, index, "etc", e.target.value)
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={7} style={{textAlign: "center"}}>
                등록된 배너가 없습니다.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>

      </Table>

      {/* 안내 문구 추가 */}
      <Box mt={2} mb={2} p={2} bgcolor="#f9f9f9" borderTop="1px solid #ddd">
        <Typography variant="body2" color="error">
          * 노출 이미지와 Link URL은 필수로 기재해야 합니다.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={2} mb={-11.5} p={2}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 8 }}
            onClick={handleAddBanner}
          >
            배너 추가 +
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: selectedRows.length === 0 ? "#ddd" : "#B63755", 
              color: selectedRows.length === 0 ? "#aaa" : "#FFFFFF", 
              marginRight: 8,
              cursor: selectedRows.length === 0 ? "not-allowed" : "pointer",
            }}
            onClick={handleDeleteBanner}
            disabled={selectedRows.length === 0} 
          >
            배너 삭제 -
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/banners/bannerList')}  // 함수 참조를 전달
            style={{
              backgroundColor: "#FFFFFF", // 배경색을 화이트로 설정
              color: "#000000", // 텍스트 색상을 블랙으로 설정
              marginRight: 8,
              borderColor: "#ddd",
            }}
          >
            뒤로가기
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            style={{ marginRight: 20 }}
            onClick={updateSaveClick}
          >
            저장
          </Button>
        </Box>
      </Box>
    </Card>

  </div>
  );
};

export default BannerDetailPage;
