import React, { useEffect, useState } from "react";
import { Button } from "@dashboard/components/Button";
import CardTitle from "@dashboard/components/CardTitle";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import Skeleton from "@dashboard/components/Skeleton";
import { TableButtonWrapper } from "@dashboard/components/TableButtonWrapper/TableButtonWrapper";
import TableHead from "@dashboard/components/TableHead";
import TableRowLink from "@dashboard/components/TableRowLink";
import { Card, TableBody, TableCell } from "@material-ui/core";
import { DeleteIcon, IconButton, makeStyles } from "@saleor/macaw-ui";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateVendorCollection } from "@dashboard/stores/services/REST_apis/vendor";

const useStyles = makeStyles(
  theme => ({
    colActions: {
      width: `calc(76px + ${theme.spacing(1)})`,
      marginRight: theme.spacing(-2),
    },
    colName: {
      paddingLeft: 0,
      width: "auto",
      display: "flex",
      alignItems: "center",
    },
    dragHandle: {
      cursor: "grab",
      marginRight: theme.spacing(2),
    },
    table: {
      tableLayout: "auto",  // 테이블 레이아웃을 auto로 설정
      width: "100%",  // 테이블 전체 너비를 100%로 설정
    },
    tableRow: {
      cursor: "pointer",
      minHeight: 50, // 최소 높이를 설정하여 드래그 시 크기가 줄어들지 않도록 함
    },
    tableCell: {
      minWidth: 150, // 각 셀의 최소 너비를 설정
      whiteSpace: "nowrap", // 텍스트가 줄바꿈되지 않도록 설정
    },
  }),
  { name: "CollectionProducts" },
);

interface CollectionBrandsProps {
  collection: any;
  collection2: any[];
  onBrandAdd: () => void;
  onRefresh: () => void;
}

const CollectionBrands: React.FC<CollectionBrandsProps> = ({
  collection,
  collection2,
  onBrandAdd,
  onRefresh,
}) => {
  const classes = useStyles();
  const [sortedCollection, setSortedCollection] = useState(collection2);

  // order를 얻기 위한 함수
  const getOrder = (id: string) => {
    const metadataItem = collection.privateMetadata.find(
      (item) => item.key === id
    );

    if (metadataItem) {
      try {
        const correctedValue = metadataItem.value.replace(/'/g, '"');
        const value = JSON.parse(correctedValue);
        return value.order;
      } catch (error) {
        console.error("Failed to parse metadata value:", metadataItem.value);
        return Number.MAX_SAFE_INTEGER;
      }
    }

    return Number.MAX_SAFE_INTEGER; // 정의되지 않은 경우 가장 뒤로
  };

  // collection2가 변경될 때마다 정렬된 데이터를 업데이트
  useEffect(() => {
    const sorted = [...collection2].sort((a, b) => {
      const orderA = getOrder(a.id);
      const orderB = getOrder(b.id);
      return orderA - orderB;
    });

    setSortedCollection(sorted);
  }, [collection2]);

  const handleDeleteBrand = async (id: string) => {
    const remainingBrands = collection2
      .filter(brand => brand.id !== id)
      .map(brand => brand.id);

    const type = collection.metadata.find(item => item.key === "ZZ_TYPE")?.value;

    if (type) {
      const postCollection = {
        id: type,
        data: remainingBrands,
      };

      try {
        await updateVendorCollection(postCollection);
        // alert("할당 해제되었습니다.");

        if (onRefresh) {
          onRefresh();
        }
      } catch (e) {
        console.log(e);
        alert("할당 해제 중 오류가 발생했습니다.");
      }
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedBrands = Array.from(sortedCollection);
    const [removed] = reorderedBrands.splice(result.source.index, 1);
    reorderedBrands.splice(result.destination.index, 0, removed);

    const type = collection.metadata.find(item => item.key === "ZZ_TYPE")?.value;
    if (type) {
      const postCollection = {
        id: type,
        data: reorderedBrands.map(brand => brand.id),
      };

      try {
        await updateVendorCollection(postCollection);
        if (onRefresh) {
          onRefresh();
        }
      } catch (e) {
        console.log(e);
        alert("재정렬 중 오류가 발생했습니다.");
      }
    }
  };

  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id="collectionTitle"
            defaultMessage="할당된 브랜드 목록"
            description="collections table title"
          />
        }
        toolbar={
          <Button variant="tertiary" onClick={onBrandAdd}>
            <FormattedMessage
              id="addCollection"
              defaultMessage="브랜드 추가"
              description="button"
            />
          </Button>
        }
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="brands">
          {(provided) => (
            <div 
              {...provided.droppableProps} 
              ref={provided.innerRef} 
            >
              <ResponsiveTable>
                <TableHead colSpan={5} disabled={false} items={[]}>
                  <TableCell></TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>StoreName</TableCell>
                  <TableCell>MasterName</TableCell> 
                  <TableCell>Actions</TableCell>
                </TableHead>
                <TableBody>
                  {sortedCollection.length > 0 ? (
                    sortedCollection.map((collection, index) => (
                      <Draggable
                        key={collection.id}
                        draggableId={collection.id}
                        index={index}
                      >
                        {(provided) => (
                          <TableRowLink
                            hover
                            selected={false}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.tableRow}
                          >
                            <TableCell className={classes.dragHandle}>
                              <span>::</span> {/* 드래그 핸들러로 사용할 :: 표시 */}
                            </TableCell>
                            <TableCell>
                              {collection.vendor_thumbnail ? (
                                <img
                                  src={collection.vendor_thumbnail}
                                  alt={index.toString()}
                                  style={{ width: 50, height: 50 }}
                                />
                              ) : (
                                <Skeleton />
                              )}
                            </TableCell>
                            <TableCell>{collection.store_name_ko || <Skeleton />}</TableCell>
                            <TableCell>
                              {collection.master_first_name || <Skeleton />}
                            </TableCell>
                            <TableCell className={classes.colActions}>
                              <TableButtonWrapper>
                                <IconButton
                                  variant="secondary"
                                  onClick={() => handleDeleteBrand(collection.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableButtonWrapper>
                            </TableCell>
                          </TableRowLink>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <TableRowLink>
                      <TableCell colSpan={5} align="center">
                        <FormattedMessage
                          id="noCollections"
                          defaultMessage="No collections found"
                        />
                      </TableCell>
                    </TableRowLink>
                  )}
                </TableBody>
              </ResponsiveTable>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
};

CollectionBrands.displayName = "CollectionBrands";
export default CollectionBrands;
