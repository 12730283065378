import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, makeStyles, TablePagination } from '@material-ui/core';

interface DataTableProps {
  columns: Column[];
  data: Data[];
  rowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  },
  rowOdd: {
    backgroundColor: '#f9f9f9',
  },
  rowEven: {
    backgroundColor: '#ffffff',
  },
  button: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  pagination: {
    '& .MuiIconButton-root': {
      backgroundColor: '#f5f5f5',
      color: '#3f51b5',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: '#adb5bd'
    },
    '& .MuiIconButton-root:not(.Mui-disabled)': {
      border: '1px solid #96B7E5'
    },
    '& .MuiIconButton-root:not(.Mui-disabled):hover': {
      backgroundColor: '#96B7E5',
    },
  },
  buttonCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const ManagerDataTable: React.FC<DataTableProps> = ({ columns, data, rowsPerPageOptions = [5, 10, 25], defaultRowsPerPage = 5 }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} className={classes.headerCell}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex} className={rowIndex % 2 === 0 ? classes.rowEven : classes.rowOdd}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id} align={column.align} className={column.buttons ? classes.buttonCell : ''}>
                      {column.isButton ? (
                        value ? (
                          <Button
                            variant="contained"
                            className={classes.button}
                            style={{ backgroundColor: column.buttonColor || '#3f51b5' }}
                            onClick={() => column.onClick && column.onClick(row)}
                          >
                            {value}
                          </Button>) : (
                          ""
                        )
                      ) : column.buttons ? (
                        column.buttons
                          .filter(btnConfig => row[column.id].some((btn: { value: string }) => btn.value === btnConfig.value))
                          .map((btnConfig, buttonIndex) => (
                            <Button
                              key={buttonIndex}
                              variant="contained"
                              className={classes.button}
                              style={{ backgroundColor: btnConfig.buttonColor || '#3f51b5' }}
                              onClick={() => btnConfig.onClick(row)}
                            >
                              {btnConfig.value}
                            </Button>
                          ))
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{ root: classes.pagination }}
      />
    </Paper >
  );
};

export default ManagerDataTable;
