import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  TablePagination,
  Typography,
  Checkbox, 
  FormControlLabel
} from "@material-ui/core"; 

import {
  Text,
  Tooltip,
} from "@saleor/macaw-ui-next";

import { ChevronRight, ContactsOutlined } from "@material-ui/icons";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { ListPageLayout } from "@dashboard/components/Layouts";
import { useIntl } from "react-intl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SearchInput from "@dashboard/components/AppLayout/ListFilters/components/SearchInput";
import { useHistory } from "react-router";
import { bannerDetailPath } from '../../urls';
import { getBannersByCategories } from "@dashboard/stores/services/REST_apis/banner";
import { TGswitchLabel } from "@scripts/global"

export interface BannerListPageProps {
  banners?: Array<{
    channel: string;
    name: string;
    count: number;
    size: string;
    position: string;
    id: string;
  }>;
  onAdd: () => void;
  onSettingsOpen: () => void;
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderTop: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    height: 41,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    height: 41,
    cursor: "pointer",
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  paginationRoot: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    '& .MuiIconButton-root': {
      backgroundColor: '#fff0',
      color: 'rgba(37, 41, 41, 1)',
      borderRadius: '2px',
      marginLeft: '10px',
      width: '36px',
      height: '36px',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: 'rgba(168, 169, 169, 1)',
      border: '2px solid rgba(168, 169, 169, 1)'
    },
    '& .MuiIconButton-root:not(.Mui-disabled)': {
      border: '2px solid #000'
    },
    '& .MuiTablePagination-actions' : {
      marginLeft: '1415px',
    },
    '& .MuiTablePagination-input' : {
      color: 'rgba(37, 41, 41, 1)',
      marginLeft: '3px',
      fontSize: '13px'
    },
    '& .MuiTablePagination-toolbar' : {
      marginLeft: '10px',
      paddingTop: '30px',
      paddingBottom: '15px',
    },
    '& .MuiTypography-body2' : {
      fontSize: '11.3px'
    }
  },
}));

const filterOption2d = ['BIZ', '브랜드', '쿠폰/혜택', '핫딜', '홈']

const BannerListPage: React.FC<BannerListPageProps> = ({
  onAdd,
  onSettingsOpen
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const [banners, setBanners] = useState([]);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(20); 
  const [selectedChannels, setSelectedChannels] = useState({
    korea: true,
    global: true,
  });
  const [filterOptions, setFilterOptions] = useState<string[]>(filterOption2d);

  const handleChannelChange = (event) => {
    const { name, checked } = event.target;

    let updatedChannels = {
        ...selectedChannels,
        [name]: checked,
    };

    if (!updatedChannels.korea && !updatedChannels.global) {
        updatedChannels = {
            ...updatedChannels,
            [name]: true,
        };
    }

    setSelectedChannels(updatedChannels);
    setPage(0);
    getBannersByCategorie(updatedChannels);
  };

  const handleFilterOptionChange = (event) => {
    const { value, checked } = event.target;

    let updatedFilters = filterOptions;
    if (checked) {
      updatedFilters = [...filterOptions, value];
    } else {
      updatedFilters = filterOptions.filter((option) => option !== value);
    }

    if (updatedFilters.length === 0) {
      return;
    }

    setFilterOptions(updatedFilters);
  };

  const handleRowClick = (id: string) => {
    history.push(bannerDetailPath(id));
  };

  const getBannersByCategorie = async (channels) => {
    let channel_id = '';

    if (channels.korea && channels.global) {
        channel_id = 'all';
    } else if (channels.korea) {
        channel_id = 'KO';
    } else if (channels.global) {
        channel_id = 'EN';
    }

    try {
      const banners = await getBannersByCategories({
        channel: channel_id,
      });
      setBanners(banners);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  useEffect(() => {
    getBannersByCategorie(selectedChannels);
  }, []);

  const filteredBanners = Object.entries(banners).filter(([key, banner]) => {
    const label = TGswitchLabel(banner.category.banner_id.split("_")[0]);
    return filterOptions.length === 0 || filterOptions.includes(label);
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ListPageLayout>
      <TopNav
        title={intl.formatMessage({ id: "bannerManagement", defaultMessage: "배너관리" })}
        withoutBorder
        isAlignToRight={false}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex">
            <Box marginX={3} display="flex" alignItems="center">
              <ChevronRight />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            {!!onSettingsOpen && (
              <TopNav.Menu
                items={[
                  {
                    label: intl.formatMessage({
                      id: "orderSettings",
                      defaultMessage: "배너 설정",
                      description: "button",
                    }),
                    onSelect: onSettingsOpen,
                  },
                ]}
              />
            )}
            <Box display="flex" alignItems="center">
              <Tooltip>
                <Tooltip.Trigger>
                  <Text variant="title" size="small" width={28}>
                  {intl.formatMessage({
                    id: "hJrzlk",
                    defaultMessage: "Banner list",
                    description: "tab name",
                  })}
                  </Text>
                </Tooltip.Trigger>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </TopNav>

      <Card>
        <Box display="block">
          <Box>
            <Card>
            <Box display="flex" alignItems="center" mt={1} ml={4}>
              <Typography variant="subtitle1" style={{ marginRight: '16px'}}>
                노출 채널:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedChannels.korea}
                    onChange={handleChannelChange}
                    name="korea"
                    color="primary"
                  />
                }
                label="국내"
                style={{ marginRight: '16px', minWidth: '80px' }} 
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedChannels.global}
                    onChange={handleChannelChange}
                    name="global"
                    color="primary"
                  />
                }
                label="국외"
                style={{ minWidth: '80px' }} 
              />
            </Box>

            <Box display="flex" alignItems="center" ml={4}>
              <Typography variant="subtitle1" style={{ marginRight: '16px'}}>
                노출 위치:
              </Typography>
              {filterOption2d.map(option => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterOptions.includes(option)}
                      onChange={handleFilterOptionChange}
                      value={option}
                      color="primary"
                    />
                  }
                  label={option}
                  style={{ marginRight: '16px', minWidth: '80px' }} 
                  key={option} // key 추가
                />
              ))}
            </Box>

              <Table>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>노출 채널</StyledTableCell>
                    <StyledTableCell>노출 위치</StyledTableCell>
                    <StyledTableCell>배너명</StyledTableCell>
                    <StyledTableCell>배너수</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {filteredBanners
                    .sort(([keyA, bannerA], [keyB, bannerB]) => {
                      if (bannerA.category.banner_id < bannerB.category.banner_id) return -1;
                      if (bannerA.category.banner_id > bannerB.category.banner_id) return 1;
                      
                      return bannerA.category.banner_num - bannerB.category.banner_num;
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(([key, banner], index) => (
                      <StyledTableRow
                        key={index}
                        onClick={() => handleRowClick(banner.category.id + "-" + key)}
                      >
                        <StyledTableCell style={{fontWeight: "bold"}}>
                          {banner.category.channel_id === "3"
                            ? "국내(KOREA)"
                            : banner.category.channel_id === "8"
                            ? "국외(GLOBAL)"
                            : banner.category.channel_id}
                        </StyledTableCell>
                        <StyledTableCell>
                          {TGswitchLabel(banner.category.banner_id.split("_")[0])}
                        </StyledTableCell>
                        <StyledTableCell>
                          {
                            banner.category.channel_id === "3"
                              ? "K"
                              : banner.category.channel_id === "8"
                              ? "G"
                              : banner.category.channel_id
                          }_{banner.category?.banner_id + banner.category?.banner_num}
                        </StyledTableCell>
                        <StyledTableCell>
                          {banner.banner?.length || 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                classes={{ root: classes.paginationRoot }} 
                rowsPerPageOptions={[10, 20, 30, 50, 100]} // 옵션을 추가
                component="div"
                count={filteredBanners.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="행 수"
                labelDisplayedRows={() => null}
              />
            </Card>
          </Box>
        </Box>
      </Card>
    </ListPageLayout>
  );
};

export default BannerListPage;
