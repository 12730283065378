import React from "react";
import { Route, Switch } from "react-router-dom";
import { useIntl } from "react-intl";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import {
  storeInfoListUrl,
  manageManagersListUrl,
  contactInfoListUrl,
  shippingInfoListUrl,
  storeSyncListUrl,
  feeOverviewListUrl,

  storeListListUrl,
  storeInfoPath,
  manageManagersPath,
  contactInfoPath,
  shippingInfoPath,
  storeSyncPath,
  feeOverviewPath,
  shopifySyncListUrl,
} from "./urls";

import StoreListPage from "./views/StoreListPage";
import StoreInfoPage from "./views/StoreInfoPage";
import ManageManagerPage from "./views/ManageManagerPage";
import ContactInfoPage from "./views/ContactInfoPage";
import ShippingInfoPage from "./views/ShippingInfoPage";
import StoreSyncPage from "./views/StoreSyncPage";
import FeeOverviewPage from "./views/FeeOverviewPage";
import MyFeesPage from "./views/MyFeesPage";
import ShopifySyncPage from "./views/ShopifySyncPage";

const StoreSection: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <WindowTitle title={intl.formatMessage({ id: "store.section.title", defaultMessage: "Store" })} />
      <Switch>
        {/* 셀러 */}
        <Route exact path={storeInfoListUrl()} component={StoreInfoPage} />
        <Route exact path={manageManagersListUrl()} component={ManageManagerPage} />
        <Route exact path={contactInfoListUrl()} component={ContactInfoPage} />
        <Route exact path={shippingInfoListUrl()} component={ShippingInfoPage} />
        <Route exact path={storeSyncListUrl()} component={StoreSyncPage} />
        <Route exact path={feeOverviewListUrl()} component={FeeOverviewPage} />

        {/* 마스터 */}
        <Route exact path={storeListListUrl()} component={StoreListPage} />
        <Route exact path={shopifySyncListUrl()} component={ShopifySyncPage} />
        <Route exact path={storeInfoPath(":id")} component={StoreInfoPage} />
        <Route exact path={manageManagersPath(":id")} component={ManageManagerPage} />
        <Route exact path={shippingInfoPath(":id")} component={ShippingInfoPage} />
        <Route exact path={contactInfoPath(":id")} component={ContactInfoPage} />
        <Route exact path={storeSyncPath(":id")} component={StoreSyncPage} />
        <Route exact path={feeOverviewPath(":id")} component={FeeOverviewPage} />
      </Switch>
    </>
  );
};

export default StoreSection;
