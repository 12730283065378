// @ts-strict-ignore
import React from "react";

import { useManualTransactionContext } from "../context";

export const Form: React.FC<React.HTMLProps<HTMLFormElement>> = ({
  children,
  ...props
}) => {
  const { amount, description, pspReference, onAddTransaction } =
    useManualTransactionContext();

  return (
    <form
      {...props}
      onSubmit={e => {
        e.preventDefault();
        if (amount) {
          onAddTransaction({ amount, description, pspReference });
        }
      }}
    >
      {children}
    </form>
  );
};







// // @ts-strict-ignore
// import React from "react";
// import api from "@dashboard/stores/services/REST_apis/client";  // axios instance or your API client

// import { useManualTransactionContext } from "../context";

// export const Form: React.FC<React.HTMLProps<HTMLFormElement>> = ({
//   children,
//   ...props
// }) => {
//   const { amount, description, pspReference, onAddTransaction } =
//     useManualTransactionContext();

//   const handleEasypayRefund = async () => {
//     try {
//       const payload = {
//         shopTransactionId: 'VXNlcjoxMDI=-20240913030308',  // 가맹점 트랜젝션 ID
//         pgCno: "24091312031710952174",   // 예시 PG 거래 번호
//         clientIp: "121.167.155.105",         // 사용자 IP (적절히 설정)
//         clientId: "admin-user",          // 관리자 ID (적절히 설정)
//         reviseTypeCode: "40",            // 전체 취소 (부분 취소 시 "32" 사용)
//         amount: amount,                  // 취소 금액

//         cancelReqDate: new Date().toISOString().slice(0, 10).replace(/-/g, ""),  // 현재 날짜
//         reviseMessage: description || "고객 요청에 따른 취소",  // 취소 사유
//       };

//       // Easypay 취소 API 호출
//       const response = await api.post("/api-tg/pay/easypay/cancel", payload);

//       console.log('response',response);

//       if (response.data.success) {
//         alert("환불이 성공적으로 처리되었습니다.");
//         // 성공 시 추가 처리 (onAddTransaction 등)
//         onAddTransaction({ amount, description, pspReference });
//       } else {
//         alert(`환불 실패: ${response.data.message}`);
//       }
//     } catch (error) {
//       console.error("Easypay 환불 처리 중 오류 발생:", error);
//       alert("Easypay 환불 처리 중 오류가 발생했습니다.");
//     }
//   };

//   return (
//     <form
//       {...props}
//       onSubmit={e => {
//         e.preventDefault();
//         if (amount) {
//           // 이지페이 연동 호출
//           handleEasypayRefund();
//         }
//       }}
//     >
//       {children}
//     </form>
//   );
// };
