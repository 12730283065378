import { useUser } from "@dashboard/auth";
import { useVendor } from "@context/VendorContext"
import { UserProfile } from "@dashboard/components/UserProfile";
import { getUserInitials, getUserName } from "@dashboard/misc";
import { Box, Text } from "@saleor/macaw-ui-next";
import React from "react";


const boxStyle = {
  margin: "0 30px"
}

export const UserInfo = () => {
  const { user } = useUser();
  const { state } = useVendor();

  return (
    <Box
      display="flex"
      gap={3}
      paddingX={3}
      paddingY={4}
      alignItems="center"
      borderTopWidth={1}
      borderColor="default1"
      justifyContent="center"
    >
      <Box display="flex" gap={3} alignItems="center" justifyContent="center">
        <div style={boxStyle}>
          <UserProfile initials={getUserInitials(user!)} url={user?.avatar?.url} thumbnail={state.detail_info.vendor_thumbnail} />
          <Box __width={150} marginTop={2} textAlign="center" className="ellipsis">
            <Text variant="bodyStrong" size="medium">
              {getUserName(user!, true)}
            </Text>
          </Box>
        </div>
      </Box>
      {/* <UserControls /> */}
      {/* className="ellipsis" */}
    </Box>
  );
};
