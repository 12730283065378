// @ts-strict-ignore
import { gql } from "@apollo/client";
import {
  SearchWarehousesDocument,
  SearchWarehousesQuery,
  SearchWarehousesQueryVariables,
} from "@dashboard/graphql";
import makeTopLevelSearch from "@dashboard/hooks/makeTopLevelSearch";

export const searchWarehouses = gql`
  query SearchWarehouses($after: String, $first: Int!, $query: String!) {
    search: warehouses(
      after: $after
      first: $first
      sortBy: { direction: ASC, field: NAME }
      filter: { search: $query }
    ) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

// export default makeTopLevelSearch<
//   SearchWarehousesQuery,
//   SearchWarehousesQueryVariables
// >(SearchWarehousesDocument);

export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;

export const SearchWarehousesDocument2 = gql`
    query SearchWarehouses2($after: String, $first: Int!, $query: String!) {
  search: warehouses(
    after: $after
    first: $first
    sortBy: {direction: ASC, field: NAME}
    filter: {search: $query}
  ) {
    totalCount
    edges {
      node {
        id
        name
        metadata {
          key
          value
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export default makeTopLevelSearch<
  SearchWarehousesQuery,
  SearchWarehousesQueryVariables
>(SearchWarehousesDocument2);
