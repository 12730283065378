// @ts-strict-ignore
import BackButton from "@dashboard/components/BackButton";
import {
  ConfirmButton,
  ConfirmButtonTransitionState,
} from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import FormSpacer from "@dashboard/components/FormSpacer";
import { OrderErrorFragment } from "@dashboard/graphql";
import useModalDialogErrors from "@dashboard/hooks/useModalDialogErrors";
import { buttonMessages } from "@dashboard/intl";
import { getFormErrors } from "@dashboard/utils/errors";
import getOrderErrorMessage from "@dashboard/utils/errors/order";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface FormData {
  trackingNumber: string;
  transporter: string;
}

export interface OrderFulfillmentTrackingDialogProps {
  confirmButtonState: ConfirmButtonTransitionState;
  errors: OrderErrorFragment[];
  open: boolean;
  trackingNumber: string;
  transporter: string;
  onClose: () => any;
  onConfirm: (data: FormData) => Promise<any>; // Promise 형태로 에러 처리 가능
}

const OrderFulfillmentTrackingDialog: React.FC<
  OrderFulfillmentTrackingDialogProps
> = ({
  confirmButtonState,
  errors: apiErrors,
  open,
  trackingNumber,
  transporter,
  onConfirm,
  onClose,
}) => {
  const intl = useIntl();
  const errors = useModalDialogErrors(apiErrors, open);

  const formFields = ["trackingNumber", "transporter"];
  const formErrors = getFormErrors(formFields, errors);

  const initialData: FormData = {
    trackingNumber: trackingNumber || "",
    transporter: transporter || "",
  };

  // 운송업체 목록 정의
  const transporterOptions = [
    { label: "CJ대한통운", value: "KOREX" },
    { label: "우체국택배", value: "EPOST" },
    { label: "한진택배", value: "HANJIN" },
    { label: "롯데택배", value: "LOTTE" },
    { label: "로젠택배", value: "LOGEN" },
  ];

  const handleConfirm = async (data: FormData) => {
    try {
      // onConfirm을 실행하고 에러가 없으면 처리 완료
      await onConfirm(data);
    } catch (errors: any) {
      // 에러가 발생하면 alert로 메시지 표시
      alert(
        errors.message ||
          intl.formatMessage({
            id: "error-generic",
            defaultMessage: "Something went wrong. Please try again.",
          }),
      );
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="xs">
      <Form initial={initialData} onSubmit={handleConfirm}>
        {({ change, data, submit }) => {
          // 트래킹 번호와 운송업체가 모두 입력되지 않으면 확인 버튼 비활성화
          const isSubmitDisabled = !data.trackingNumber || !data.transporter;

          return (
            <>
              <DialogTitle disableTypography>
                <FormattedMessage
                  id="/BJQIq"
                  defaultMessage="Add Tracking Code"
                  description="dialog header"
                />
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth>
                  <InputLabel>
                    <FormattedMessage
                      id="transporter-label"
                      defaultMessage="배송 업체"
                    />
                  </InputLabel>
                  <Select
                    error={!!formErrors.transporter}
                    name="transporter"
                    value={data.transporter}
                    onChange={change}
                    fullWidth
                    style={{ backgroundColor: "white" }} // 셀렉트 박스 배경을 흰색으로 설정
                  >
                    {transporterOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormSpacer />
                <TextField
                  error={!!formErrors.trackingNumber}
                  helperText={getOrderErrorMessage(
                    formErrors.trackingNumber,
                    intl,
                  )}
                  label={intl.formatMessage({
                    id: "yT/GAp",
                    defaultMessage: "Tracking number",
                  })}
                  name="trackingNumber"
                  onChange={change}
                  value={data.trackingNumber}
                  fullWidth
                  data-test-id="tracking-number-input"
                />
                {errors.length > 0 && (
                  <>
                    <FormSpacer />
                    {errors
                      .filter(err => !formFields.includes(err.field))
                      .map((err, index) => (
                        <DialogContentText color="error" key={index}>
                          {getOrderErrorMessage(err, intl)}
                        </DialogContentText>
                      ))}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <BackButton onClick={onClose} />
                <ConfirmButton
                  data-test-id="confirm-tracking-number-button"
                  transitionState={confirmButtonState}
                  onClick={submit}
                  disabled={isSubmitDisabled} // 입력이 완료되지 않으면 버튼 비활성화
                >
                  <FormattedMessage {...buttonMessages.confirm} />
                </ConfirmButton>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </Dialog>
  );
};
OrderFulfillmentTrackingDialog.displayName = "OrderFulfillmentTrackingDialog";
export default OrderFulfillmentTrackingDialog;
