// @ts-strict-ignore
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Card,
  CardContent,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  TextField,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import Hr from "@dashboard/components/Hr";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import { extensionMountPoints, mapToMenuItemsForCustomerDetails, useExtensions } from "@dashboard/apps/hooks/useExtensions";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { storeInfoListUrl } from "../../urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import useMetadataChangeTrigger from "@dashboard/utils/metadata/useMetadataChangeTrigger";

import TextFieldWithLabel from '@dashboard/tng/components/TextFieldWithLabel';
import SelectField from '@dashboard/tng/components/SelectField';

import { useVendor } from '@context/VendorContext';
import { getShipInfoByVid, updateShipInfo } from "@dashboard/stores/services/REST_apis/vendor";
import { Skeleton } from "@material-ui/lab";
import MenuTabs from "@dashboard/stores/components/MenuTabs";
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import { sanitizeData } from "@dashboard/stores/services/utils/data";

export interface ShippingInfoPageParam {
  id: string;
}

export interface ShippingInfoPageFormData {
  id: string;
  ship_type: string;
  min_amt: string;
  ship_fee: string;
  jeju_ship: string;
  jeju_fee: string;
  island_fee: string;
  rtn_fee: string;
  exch_fee: string;
  primary_ship: string;
  additional_ship: string;
  rtn_ship: string;
  etc: string;
  etc2: string;
  created_at: string;
  updated_at: string;
}

export interface ShippingInfoPageProps {
  disabled: boolean;
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (data: ShippingInfoPageFormData) => void;
  onDelete: () => void;
}

const initialState = {
  id: "",
  ship_type: "",
  min_amt: "",
  ship_fee: "",
  jeju_ship: "",
  jeju_fee: "",
  island_fee: "",
  rtn_fee: "",
  exch_fee: "",
  primary_ship: "",
  additional_ship: "",
  rtn_ship: "",
  etc: "",
  etc2: "",
  created_at: "",
  updated_at: "",
};

const shippingData = [
  { size: "극소형", code: "B02", credit: 1000, cash: 1000, returnFee: 1000 },
  { size: "소형", code: "B05", credit: 2000, cash: 2000, returnFee: 2000 },
  { size: "중형", code: "B10", credit: 3000, cash: 3000, returnFee: 3000 },
  { size: "대형", code: "B20", credit: 4000, cash: 4000, returnFee: 4000 },
  { size: "특대형", code: "B25", credit: 5000, cash: 5000, returnFee: 5000 },
  { size: "이형", code: "B30", credit: 6000, cash: 6000, returnFee: 6000 },
];

const ShippingInfoPage: React.FC<ShippingInfoPageProps> = ({
  disabled,
  saveButtonBar,
  onSubmit,
  onDelete,
}: ShippingInfoPageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [formData, setFormData] = useState(initialState);
  const [shippingType, setShippingType] = useState<"domestic" | "international">("domestic");
  const [radioData, setRadioData] = useState({
    jejuShip: "notSet",
  });

  const { user } = useUser();
  const { id } = useParams<ShippingInfoPageParam>();
  const { state, dispatch } = useVendor();

  useEffect(() => {
    if (user.isSuperuser && id) {
      getShippingInfo(id);
    } else {
      getShippingInfo(state.id);
    }
  }, [state]);

  useEffect(() => {
    if (shippingType === "international") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        primary_ship: "롯데택배", // 국외인 경우 롯데택배로 기본 설정
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        primary_ship: "", // 국내 배송으로 변경 시 기본값 초기화
      }));
    }
  }, [shippingType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (shippingType === "international" && name === "primary_ship") {
      return; 
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setRadioData({
      ...radioData,
      [name]: value,
    });
  };

  const handleFormData = (data) => {
    setFormData(sanitizeData(data, true));
    if (data.jeju_ship !== "") {
      data.jeju_ship === true ? setRadioData({ ...radioData, jejuShip: "set" }) : setRadioData({ ...radioData, jejuShip: "notSet" });
    }
  };

  const getShippingInfo = useCallback(async (id) => {
    const data = await getShipInfoByVid(id).then((response) => {
      return response.data;
    });
    handleFormData(data);
  }, []);

  const handleSubmit = () => {
    const data = {
      ...formData,
      jeju_ship: radioData.jejuShip === "set" ? true : false,
    };
    updateShipInfo(state.id, sanitizeData(data, false)).then((response) => {
      response.status === 200 ? alert("등록되었습니다.") : alert("오류가 발생했습니다.");
      dispatch({
        type: "SET_VENDOR",
        payload: { full_info_provided: response.data.full_info_provided },
      });
    });
  };

  const renderDomesticForm = () => (
    <>
      <Typography>배송비 타입</Typography>
      <RadioGroup
        row
        name="ship_type"
        value={formData.ship_type} 
        onChange={(e) => {
          setFormData({
            ...formData,
            ship_type: e.target.value, 
            ship_fee: "",
            min_amt: "",
          });
        }}
      >
        <FormControlLabel control={<Radio />} label="무료배송" value="free" />
        <FormControlLabel control={<Radio />} label="고정 배송비" value="flat" />
        <FormControlLabel control={<Radio />} label="조건부 배송비" value="condition" />
      </RadioGroup>
      
      {formData.ship_type !== "free" && (
        <Typography>배송비</Typography>
      )}
      {formData.ship_type !== "free" && formData.ship_type !== "flat" && (
        <>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="min_amt"
              value={formData.min_amt}
              onChange={(e) => setFormData({ ...formData, min_amt: e.target.value })}
              placeholder="Enter minimum amount"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원 미만일 때,</Typography>
          </Grid>
        </>
      )}
      {formData.ship_type !== "free" && (
        <>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="ship_fee"
              value={formData.ship_fee}
              onChange={(e) => setFormData({ ...formData, ship_fee: e.target.value })}
              placeholder="Enter shipping fee"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원을 부과합니다.</Typography>
          </Grid>
        </>
      )}
      
      <CardSpacer />
      
      <Typography>제주/도서산간 배송비</Typography>
      <RadioGroup
        row
        name="jeju_ship"
        value={radioData.jejuShip}  // value 속성이 radioData.jejuShip과 연결되어 있는지 확인
        onChange={(e) => {
          setRadioData({
            ...radioData,
            jejuShip: e.target.value,  // jejuShip 값을 올바르게 업데이트
          });
        }}
      >
        <FormControlLabel control={<Radio />} label="설정함" value="set" />
        <FormControlLabel control={<Radio />} label="설정안함" value="notSet" />
      </RadioGroup>
  
      {radioData.jejuShip === "set" && (
        <>
          <Typography>도서 산간 배송비는 설정한 배송비(무료배송비일 경우 0원)와 도서산간 배송비가 합산된 금액을 입력해주세요.</Typography>
          <CardSpacer />
          <Typography>제주</Typography>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="jeju_fee"
              value={formData.jeju_fee}
              onChange={handleChange}
              placeholder="Enter shipping cost for Jeju"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원을 부과합니다.</Typography>
          </Grid>
          <Typography>ex) 입력한 배송비 2,500원 + 도서산간 추가 배송비 3,000원일 경우 5,500원 입력</Typography>
          
          <CardSpacer />
          
          <Typography>제주 외 도서산간</Typography>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="island_fee"
              value={formData.island_fee}
              onChange={handleChange}
              placeholder="Enter shipping cost for non-Jeju islands"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원을 부과합니다.</Typography>
          </Grid>
          <Typography>ex) 입력한 배송비 2,500원 + 도서산간 추가 배송비 3,000원일 경우 5,500원 입력</Typography>
        </>
      )}
    </>
  );
  
  

  const renderInternationalForm = () => (
    <>
      <Typography>배송비 타입</Typography>
      <RadioGroup
        row
        name="ship_type"  // name을 "ship_type"으로 맞추어야 합니다.
        value={formData.ship_type}
        onChange={(e) => {
          setFormData({
            ...formData,
            ship_type: e.target.value,  // 상태 업데이트
            ship_fee: "",  // 필요시 상태 초기화
            min_amt: "",   // 필요시 상태 초기화
          });
        }}
      >
        <FormControlLabel control={<Radio />} label="무료배송" value="free" />
        <FormControlLabel control={<Radio />} label="고정 배송비" value="flat" />
        <FormControlLabel control={<Radio />} label="조건부 배송비" value="condition" />
      </RadioGroup>
      {formData.ship_type !== "free" && (
        <>
          <Typography>배송비</Typography>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="min_amt"
              value={formData.min_amt}
              onChange={(e) => setFormData({ ...formData, min_amt: e.target.value })}
              placeholder="Enter minimum amount"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원 미만일 때,</Typography>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <TextFieldWithLabel
              type="number"
              label=""
              name="ship_fee"
              value={formData.ship_fee}
              onChange={(e) => setFormData({ ...formData, ship_fee: e.target.value })}
              placeholder="Enter shipping fee"
              gridSizes={{ xs: 12, sm: 6 }}
            />
            <Typography>원을 부과합니다.</Typography>
          </Grid>
        </>
      )}
    </>
  );
  

  return (
    <Form confirmLeave initial={initialState} onSubmit={onSubmit} disabled={disabled}>
      {({ isSaveDisabled, submit }) => (
        <DetailPageLayout>
          <TopNav title={intl.formatMessage({ id: "shippingInfo", defaultMessage: "배송 정보" })} />
          <DetailPageLayout.Content>
            {user.isSuperuser && <MenuTabs vId={id} current={"shippingInfo"} />}
            <Card>
              <CardContent>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button onClick={() => setShippingType("domestic")} variant={shippingType === "domestic" ? "contained" : "outlined"}>
                    국내
                  </Button>
                  <Button onClick={() => setShippingType("international")} variant={shippingType === "international" ? "contained" : "outlined"}>
                    국외
                  </Button>
                </ButtonGroup>
                <CardSpacer />
                <Grid container spacing={3}>
                  <SelectField
                    label="배송 업체 설정"
                    placeHolder="Select a primary delivery service"
                    name="primary_ship"
                    value={formData.primary_ship}
                    onChange={handleChange}
                    disabled={shippingType === "international"}
                    options={[
                      { value: "", label: "None" },
                      { value: "CJ 대한통운", label: "CJ 대한통운" },
                      { value: "한진택배", label: "한진택배" },
                      { value: "롯데택배", label: "롯데택배" },
                      { value: "로젠택배", label: "로젠택배" },
                    ]}
                    gridSizes={{ xs: 12, sm: 6 }}
                  />
                </Grid>
                <CardSpacer />
                {shippingType === "domestic" ? renderDomesticForm() : renderInternationalForm()}
                <CardSpacer />
                
                {/* 규격 Table */}
                <Hr/>
                <CardSpacer />
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="subtitle1">배송사규격</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="subtitle1">코드</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">신용운임</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">착불운임</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">반품운임</Typography>
                  </Grid>

                  {shippingData.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={2}>
                        <Typography>{item.size}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography>{item.code}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          defaultValue={item.credit.toLocaleString()}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          defaultValue={item.cash.toLocaleString()}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          defaultValue={item.returnFee.toLocaleString()}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <CardSpacer />
              </CardContent>
            </Card>
          </DetailPageLayout.Content>
          <DetailPageLayout.RightSidebar>
            <div></div>
          </DetailPageLayout.RightSidebar>
          <Savebar
            disabled={isSaveDisabled}
            state={saveButtonBar}
            onSubmit={handleSubmit}
            onCancel={() => navigate(storeInfoListUrl())}
            onDelete={onDelete}
          />
        </DetailPageLayout>
      )}
    </Form>
  );
};

ShippingInfoPage.displayName = "ShippingInfoPage";
export default ShippingInfoPage;
