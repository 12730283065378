// @ts-strict-ignore
import { DashboardCard } from "@dashboard/components/Card";
import ImageUpload from "@dashboard/components/ImageUpload";
import { ImageUploadProductMain, ImageUploadProductBody } from "@dashboard/components/ImageUpload";
import MediaTile from "@dashboard/components/MediaTile";
import Skeleton from "@dashboard/components/Skeleton";
import { Box as MUIBox, CircularProgress, Typography } from "@material-ui/core"; // Material-UI에서 가져온 Skeleton을 MaterialSkeleton으로 이름 변경
import {
  ProductMediaFragment,
  ProductMediaType,
  useUpdateMetadataMutation,
  ProductFragment,
} from "@dashboard/graphql";
import { ReorderAction } from "@dashboard/types";
import createMultiFileUploadHandler from "@dashboard/utils/handlers/multiFileUploadHandler";
import {
  Box,
  Button,
  Dropdown,
  List,
  sprinkles,
  Text,
} from "@saleor/macaw-ui-next";
import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { messages } from "./messages";

import {
  useProductMediaReorderMutation,
} from "@dashboard/graphql";

import { arrayMove } from "react-sortable-hoc";

interface SortableMediaProps {
  media: {
    id: string;
    alt?: string;
    url: string;
  };
  editHref: string;
  onDelete: () => void;
}

const SortableMedia = SortableElement<SortableMediaProps>(
  ({ media, editHref, onDelete }) => (
    <MediaTile media={media} editHref={editHref} onDelete={onDelete} />
  ),
);

interface MediaListContainerProps {
  className: string;
  product: ProductFragment;
  media: ProductMediaFragment[];
  preview: ProductMediaFragment[];
  onDelete: (id: string) => () => void;
  getEditHref: (id: string) => string;
}

const MediaListContainer = SortableContainer<MediaListContainerProps>(
  ({ media, preview, onDelete, getEditHref, ...props }) => (
    <div {...props}>
      {media.map((mediaObj, index) => (
        <SortableMedia
          key={`item-${index}`}
          index={index}
          media={mediaObj}
          editHref={getEditHref(mediaObj.id)}
          onDelete={onDelete(mediaObj.id)}
        />
      ))}
      {preview
        .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
        .map((mediaObj, index) => (
          <MediaTile loading={true} media={mediaObj} key={index} />
        ))}
    </div>
  ),
);

interface ProductMediaProps {
  media: ProductMediaFragment[];
  product: ProductFragment;
  loading?: boolean;
  getImageEditUrl: (id: string) => string;
  onImageDelete: (id: string) => () => void;
  onImageReorder?: ReorderAction;
  onImageUpload: (file: File) => any;
  openMediaUrlModal: () => any;
}

const ProductMedia: React.FC<ProductMediaProps> = props => {
  const {
    media,
    product,
    getImageEditUrl,
    onImageDelete,
    onImageReorder,
    // openMediaUrlModal,
    onImageUpload,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  const imagesUpload = React.useRef<HTMLInputElement>(null);
  const anchor = React.useRef<HTMLButtonElement>();
  const [imagesToUpload, setImagesToUpload] = React.useState<
    ProductMediaFragment[]
  >([]);
  const [uploadType, setUploadType] = useState('main');

  const handleImageUpload = createMultiFileUploadHandler(onImageUpload, {
    onAfterUpload: () =>
      setImagesToUpload(prevImagesToUpload => prevImagesToUpload.slice(1)),
    onStart: files => {

      setIsLoading(true);

      Array.from(files).forEach((file, fileIndex) => {
        const reader = new FileReader();
        reader.onload = event => {
          setImagesToUpload(prevImagesToUpload => [
            ...prevImagesToUpload,
            {
              __typename: "ProductMedia",
              alt: "",
              id: "",
              sortOrder: fileIndex,
              type: ProductMediaType.IMAGE,
              url: event.target.result as string,
              oembedData: null,
            },
          ]);
        };
        reader.readAsDataURL(file);
      });

    },
    onCompleted: () => {
      setIsLoading(false);
      location.reload();
    }
  });

  // mediaMain = 대표 미디어 이미지, mediaBody = 본문 이미지, mediaEtc = 그 외
  const { mediaMain, mediaBody, mediaEtc } = useMemo(() => {

    const main = [];
    const body = [];
    const etc = [];

    if (!media) return { mediaMain: main, mediaBody: body, mediaEtc: etc };

    media.forEach(item => {
      const view = item.metadata.find(meta => meta.key === 'view')?.value;
      if (view === 'main') main.push(item);
      else if (view === 'body') body.push(item);
      else if (!view) etc.push(item);
    });

    return { mediaMain: main, mediaBody: body, mediaEtc: etc };
  }, [media, isLoading]);

  // useProductMediaReorderMutation 함수
  const [reorderProductImages, reorderProductImagesOpts] = useProductMediaReorderMutation({});

  const handleReorder = (group, oldIndex, newIndex, type) => {

    // group: mediaMain 또는 mediaBody
    // type: 'main' 또는 'body'로 미디어 타입을 구분

    // 해당 그룹 내에서 id 순서 변경
    const reorderedGroup = arrayMove(group, oldIndex, newIndex);

    console.log('Original group:', group.map(item => item.id));
    console.log('Reordered group:', reorderedGroup.map(item => item.id));

    let updatedMedia;

    // type이 'main'이면 reorderedGroup을 앞쪽에 배치, 'body'이면 뒤쪽에 배치
    if (type === 'main') {
      updatedMedia = [...reorderedGroup, ...mediaBody];  // main을 앞에, body를 뒤에 배치
    } else if (type === 'body') {
      updatedMedia = [...mediaMain, ...reorderedGroup];  // main을 앞에 두고, body를 뒤에 배치
    }

    console.log('Updated media after reorder:', updatedMedia.map(item => item.id));

    // 업데이트된 미디어 배열의 id 추출
    const oldMediaIds = media.map(item => item.id);
    const updatedIds = updatedMedia.map(item => item.id);

    console.log('Old Media IDs:', oldMediaIds);
    console.log('Updated Media IDs:', updatedIds);

    // 서버로 업데이트된 전체 media 배열 전달
    reorderProductImages({
      variables: {
        productId: product.id,
        mediaIds: updatedIds,  // updatedIds 전달
      },
    }).then((res) => {

      window.getSelection().removeAllRanges();

      console.log('reorderProductImages result:', res);
      console.log('Old Media IDs after request:', oldMediaIds);
      console.log('Updated Media IDs after request:', updatedIds);
    });

    console.log('Old Media IDs:', oldMediaIds);
    console.log('Updated Media IDs:', updatedIds);
    console.log('oldIndex:', oldIndex);
    console.log('newIndex:', newIndex);
  };


  const [updateMetadata] = useUpdateMetadataMutation({});

  useEffect(() => {

    mediaEtc.forEach((m) => {
      updateMetadata({ variables: { id: m.id, input: [{ key: "view", value: uploadType }], keysToDelete: [] } });
    });

  }, [imagesToUpload])

  return (
    <>
      {/* 로딩 상태일 때 로딩 UI 표시 */}
      {isLoading ? (
        <MUIBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MUIBox>
      ) : (
        <>
          {/* 대표 이미지 [START] */}
          <DashboardCard>
            <DashboardCard.Title>
              <Box display="flex" justifyContent="space-between" cursor="pointer">
                대표 이미지
                <Dropdown>
                  <Dropdown.Trigger>
                    <Button
                      variant="secondary"
                      type="button"
                      data-test-id="button-upload-image"
                      ref={anchor}
                    >
                      {intl.formatMessage(messages.upload)}
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Content align="end">
                    <List padding={2} borderRadius={4} boxShadow="defaultOverlay" backgroundColor="default1">
                      <Dropdown.Item>
                        <List.Item
                          borderRadius={4}
                          paddingX={1.5}
                          paddingY={2}
                          onClick={() => {
                            setUploadType('main');
                            imagesUpload.current.click();
                          }}
                          data-test-id="upload-images"
                        >
                          <Text>{intl.formatMessage(messages.uploadImages)}</Text>
                        </List.Item>
                      </Dropdown.Item>
                    </List>
                  </Dropdown.Content>
                </Dropdown>
              </Box>
                <Typography>
                  ※ 이미지의 가로:세로 비율은 100:1에서 1:100 사이여야 합니다.
                </Typography>
            </DashboardCard.Title>
            <DashboardCard.Content>
              <Box onDragOver={() => { setUploadType('main'); }}>
                <Box
                  as="input"
                  display="none"
                  id="fileUpload"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleImageUpload(event.target.files);
                  }}
                  multiple
                  type="file"
                  ref={imagesUpload}
                  accept="image/*"
                />
              </Box>
              <Box position="relative" onDragOver={() => { setUploadType('main'); }}>
                {mediaMain === undefined ? (
                  <Box padding={5}>
                    <Skeleton />
                  </Box>
                ) : mediaMain.length > 0 ? (
                  <ImageUploadProductMain
                    className={sprinkles({
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    })}
                    isActiveClassName={sprinkles({ zIndex: "1" })}
                    disableClick={true}
                    hideUploadIcon={true}
                    iconContainerActiveClassName={sprinkles({ display: "block" })}
                    onImageUpload={handleImageUpload}
                  >
                    {({ isDragActive }) => (
                      <MediaListContainer
                        media={mediaMain}
                        preview={imagesToUpload}
                        distance={20}
                        helperClass="dragged"
                        axis="xy"
                        onSortEnd={({ oldIndex, newIndex }) => handleReorder(mediaMain, oldIndex, newIndex, 'main')}  // main 미디어 그룹만 전달
                        className={sprinkles({
                          display: "flex",
                          gap: 5,
                          flexWrap: "wrap",
                          opacity: isDragActive ? "0.2" : "1",
                        })}
                        onDelete={onImageDelete}
                        getEditHref={getImageEditUrl}
                        shouldCancelStart={() => false}  // 드래그 시작 취소 설정
                        disableAutoscroll={true}  // 자동 스크롤 비활성화
                      />
                    )}
                  </ImageUploadProductMain>
                ) : (
                  <ImageUpload onImageUpload={handleImageUpload} />
                )}
              </Box>
            </DashboardCard.Content>
          </DashboardCard>
          {/* 대표 이미지 [END] */}

          {/* 본문 이미지 [START] */}
          <DashboardCard>
            <DashboardCard.Title>
              <Box display="flex" justifyContent="space-between" cursor="pointer">
                본문 이미지
                <Dropdown>
                  <Dropdown.Trigger>
                    <Button
                      variant="secondary"
                      type="button"
                      data-test-id="button-upload-image"
                      ref={anchor}
                    >
                      {intl.formatMessage(messages.upload)}
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Content align="end">
                    <List padding={2} borderRadius={4} boxShadow="defaultOverlay" backgroundColor="default1">
                      <Dropdown.Item>
                        <List.Item
                          borderRadius={4}
                          paddingX={1.5}
                          paddingY={2}
                          onClick={() => {
                            setUploadType('body');
                            imagesUpload.current.click();
                          }}
                          data-test-id="upload-images"
                        >
                          <Text>{intl.formatMessage(messages.uploadImages)}</Text>
                        </List.Item>
                      </Dropdown.Item>
                    </List>
                  </Dropdown.Content>
                </Dropdown>
              </Box>
            </DashboardCard.Title>
            <DashboardCard.Content>
              <Box onDragOver={() => { setUploadType('body'); }}>
                <Box
                  as="input"
                  display="none"
                  id="fileUpload"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleImageUpload(event.target.files);
                  }}
                  multiple
                  type="file"
                  ref={imagesUpload}
                  accept="image/*"
                />
              </Box>
              <Box position="relative" onDragOver={() => { setUploadType('body'); }}>
                {mediaBody === undefined ? (
                  <Box padding={5}>
                    <Skeleton />
                  </Box>
                ) : mediaBody.length > 0 ? (
                  <ImageUploadProductBody
                    className={sprinkles({
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    })}
                    isActiveClassName={sprinkles({ zIndex: "1" })}
                    disableClick={true}
                    hideUploadIcon={true}
                    iconContainerActiveClassName={sprinkles({ display: "block" })}
                    onImageUpload={handleImageUpload}
                  >
                    {({ isDragActive }) => (
                      <MediaListContainer
                        media={mediaBody}
                        preview={imagesToUpload}
                        distance={20}
                        helperClass="dragged"
                        axis="xy"
                        onSortEnd={({ oldIndex, newIndex }) => handleReorder(mediaBody, oldIndex, newIndex, 'body')}  // body 미디어 그룹만 전달
                        className={sprinkles({
                          display: "flex",
                          gap: 5,
                          flexWrap: "wrap",
                          opacity: isDragActive ? "0.2" : "1",
                        })}
                        onDelete={onImageDelete}
                        getEditHref={getImageEditUrl}
                        shouldCancelStart={() => false}  // 드래그 시작 취소 설정
                        disableAutoscroll={true}  // 자동 스크롤 비활성화
                      />

                    )}
                  </ImageUploadProductBody>
                ) : (
                  <ImageUpload onImageUpload={handleImageUpload} />
                )}
              </Box>
            </DashboardCard.Content>
          </DashboardCard>
          <DashboardCard>
            <Box position="relative" padding={5} >

            </Box>
          </DashboardCard>
          {/* 본문 이미지 [END] */}
        </>
      )}
    </>
  );
}

ProductMedia.displayName = "ProductMedia";
export default ProductMedia;
