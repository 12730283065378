import {
  extensionMountPoints,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { Box, CheckboxIcon, ChevronLeftIcon, InfoIcon, List, sprinkles, Text } from "@saleor/macaw-ui-next";
import React from "react";
import { Link } from "react-router-dom";

import { SidebarMenuItem } from "./types";
import { getMenuItemExtension, isMenuActive } from "./utils";
import { CheckCircle, CheckCircleOutline, Error, ErrorOutline } from "@material-ui/icons";

const iconSettings = {
  color: "default2",
  size: "medium",
} as const;

interface Props {
  menuItem: SidebarMenuItem;
}

export const SingleItem: React.FC<Props> = ({ menuItem }) => {
  const extensions = useExtensions(extensionMountPoints.NAVIGATION_SIDEBAR);
  const active = isMenuActive(location.pathname, menuItem, location);

  const handleMenuItemClick = () => {
    const extension = getMenuItemExtension(extensions, menuItem.id);
    if (extension) {
      extension.open();
    }
  };
  return (
    <List.Item
      borderRadius={3}
      paddingX={2}
      active={active}
      onClick={handleMenuItemClick}
      data-test-id={`menu-item-label-${menuItem.id}`}
    >
      <Link
        to={menuItem.url || ""}
        replace={active}
        className={sprinkles({
          display: "block",
          width: "100%",
        })}
      >
        <Box
          className={sprinkles({
            paddingY: 1.5,
            gap: 3,
            display: "flex",
            alignItems: "center",
          })}
        >
          {menuItem.icon}
          <Text size="small" variant="bodyEmp">
            {menuItem.label}
          </Text>
          {
            (menuItem.id === "storeInfo" || menuItem.id === "contactInfo") && !menuItem.FullinfoProvided && !menuItem.isInfoRequired ? (
              <CheckCircle opacity={"70%"} style={{ color: "green" }} />
            ) : (menuItem.id === "storeInfo" || menuItem.id === "contactInfo") && !menuItem.FullinfoProvided && menuItem.isInfoRequired ?
              (
                <ErrorOutline opacity={"70%"} style={{ color: "red" }} />
              )
              : <></>
          }
        </Box>
      </Link>
    </List.Item>
  );
};
