import { FulfillmentStatus } from "@dashboard/graphql";
import { defineMessages, IntlShape } from "react-intl";

export const grantRefundPageMessages = defineMessages({
  pageHeader: {
    defaultMessage: "환불 승인",
    id: "+mmPxn",
    description: "page header",
  },
  pageHeaderEdit: {
    defaultMessage: "Edit grant refund",
    id: "TxYWkD",
    description: "page header, edit view",
  },
  pageSubtitle: {
    defaultMessage:
      "환불처리할 상품을 선택해주세요.",
    id: "Ys86kI",
  },
  // pageSubtitle: {
  //   defaultMessage:
  //     "Refunded amount will not be automatically returned to the customer. You’ll need to decide on a method and refund via balance sheet section of the order.",
  //   id: "Ys86kI",
  // },
  reasonForRefund: {
    defaultMessage: "환불 사유",
    id: "cBVHN5",
    description: "grant refund, reason input field placeholder",
  },
  refundTitle: {
    defaultMessage: "환불",
    id: "sFynTT",
    description: "grant refund, refund card title",
  },
  refundSubtitle: {
    defaultMessage:
      "How much money do you want to return to the customer for the order?",
    id: "iFM716",
    description: "grant refund, refund card subtitle",
  },
  refundShipment: {
    defaultMessage: "Refund shipment: {currency} {amount}",
    id: "Tenl9A",
    description: "grant refund, refund card toggle",
  },
  refundSelectedValue: {
    defaultMessage: "Selected refund value:",
    id: "kJYa8Y",
    description: "grant refund, refund card calculated refund value",
  },
  refundStepExplanation: {
    defaultMessage: "Funds will be returned in a separate step",
    id: "C035fF",
    description: "grant refund, refund card explanation next to submit button",
  },
  setMaxQuantity: {
    defaultMessage: "전부 선택",
    id: "xGC2Ge",
    description: "grant refund, button",
  },
  unfulfilledProducts: {
    defaultMessage: "배송 대기 상품",
    id: "zfjAc7",
    description: "grant refund, card header",
  },
  fulfilment: {
    defaultMessage: "Fulfillment",
    id: "WK62MN",
  },
  refundAmountLabel: {
    defaultMessage: "환불 금액",
    id: "5JUEIh",
    description: "field label, refund amount",
  },
  grantRefundBtn: {
    defaultMessage: "환불 최종 승인",
    id: "fsBsMy",
    description: "button, form submit, grant refund create",
  },
  editRefundBtn: {
    defaultMessage: "Edit granted refund",
    id: "TBftMD",
    description: "button. form submit, grant refund edit",
  },
});

export const productCardMessages = defineMessages({
  quantity: {
    defaultMessage: "요청 수량",
    id: "S5/nSq",
    description: "grant refund table, column header",
  },
  product: {
    defaultMessage: "제품",
    id: "rxlJJ/",
    description: "grant refund table, column header",
  },
  qtyToRefund: {
    defaultMessage: "제품 수량",
    id: "1/oauz",
    description: "grant refund table, column header",
  },
  unitPrice: {
    defaultMessage: "제품 가격",
    id: "KWXwFo",
    description: "grant refund table, column header",
  },
});

export const fulfilmentStatusMessages = defineMessages({
  fulfillment: {
    id: "MewrtN",
    defaultMessage: "Fulfillment",
    description: "section header",
  },
  fulfillmentReturned: {
    id: "H/f9KR",
    defaultMessage: "Fulfillment returned",
    description: "section header returned",
  },
  fulfillmentWaitingForApproval: {
    id: "i/ZhxL",
    defaultMessage: "Fulfillment waiting for approval",
    description: "section header returned",
  },
});

export const getTitle = (
  fulfillmentStatus: FulfillmentStatus,
  intl: IntlShape,
) => {
  switch (fulfillmentStatus) {
    case FulfillmentStatus.RETURNED:
      return '';
      // return intl.formatMessage(fulfilmentStatusMessages.fulfillmentReturned);
    case FulfillmentStatus.WAITING_FOR_APPROVAL:
      return intl.formatMessage(
        fulfilmentStatusMessages.fulfillmentWaitingForApproval,
      );
    default:
      return intl.formatMessage(fulfilmentStatusMessages.fulfillment);
  }
};
