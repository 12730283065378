import React from "react";
import { Route, Switch } from "react-router-dom";
import { useIntl } from "react-intl";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import { bannerListListUrl, bannerDetailPath } from "./urls";
import BannerListPage from './components/BannerListPage';
import BannerDetailPage from './components/BannerDetailPage'; // 상세 페이지 컴포넌트 임포트

const BannersSection: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <WindowTitle title={intl.formatMessage({ id: "banners.section.title", defaultMessage: "Banners" })} />
      <Switch>
        <Route exact path={bannerListListUrl()} component={BannerListPage} />
        <Route exact path={`${bannerDetailPath(":id")}`} component={BannerDetailPage} />
        {/* /banners/bannerDetail/:id 경로로 이동 시 BannerDetailPage를 렌더링 */}
      </Switch>                                                               
    </>
  );
};

export default BannersSection;
