import React from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';

interface TextFieldWithLabelProps {
  label: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  gridSizes: { xs: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12; sm: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 };
  type?: string;
  disabled?: boolean;
}

const TextFieldWithLabel: React.FC<TextFieldWithLabelProps> = ({ label, name, value, onChange, placeholder, gridSizes, type = "text", disabled = false }) => (
  <Grid item {...gridSizes}>
    <Typography>{label}</Typography>
    <TextField 
      fullWidth 
      name={name} 
      value={value} 
      onChange={onChange} 
      placeholder={placeholder} 
      type={type} 
      disabled={disabled}
    />
  </Grid>
);

export default TextFieldWithLabel;
