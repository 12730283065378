// @ts-strict-ignore
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Card, CardContent, Button, makeStyles } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";

import { extensionMountPoints, mapToMenuItemsForCustomerDetails, useExtensions } from "@dashboard/apps/hooks/useExtensions";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";

import { acceptManager, addManager, deleteManager, getManagers } from "@dashboard/stores/services/REST_apis/vendor";
import AddManagerPopup from "./AddManagerPopup";
import ManagerDataTable from "../../../tng/components/DataTable/ManagerDataTable";
import { useVendor } from "@context/VendorContext";
import { useParams } from "react-router";
import { useUser } from "@dashboard/auth";
import MenuTabs from "@dashboard/stores/components/MenuTabs";

export interface ManageManagerPageParam {
  id: string;
}
export interface ManageManagerPageFormData {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  note: string;
}

export interface ManageManagerPageProps {
  customerId: string;
  customer: CustomerDetailsQuery["user"];
  disabled: boolean;
  errors: AccountErrorFragment[];
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (
    data: ManageManagerPageFormData,
  ) => SubmitPromise<AccountErrorFragment[]>;
  onDelete: () => void;
}

interface managerData {
  account: string;
  level: string;
  name: string;
  created_at: string;
  auth: boolean;
  is_confirmed: boolean;
  action: { value: string }[];
}

const ManageManagerPage: React.FC<ManageManagerPageProps> = ({
  customerId,
  customer,
  disabled,
  saveButtonBar,
  onSubmit,
  onDelete,
}: ManageManagerPageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [vendorData, setVendorData] = useState(null);
  const [vId, setVId] = useState(undefined);
  const [popupState, setPopupState] = useState(false);
  const [managers, setManagers] = useState<managerData[]>([]);

  const { user } = useUser();
  const { id } = useParams<ManageManagerPageParam>();
  const { state, dispatch } = useVendor()

  useEffect(() => {
    const storedVendorData = JSON.parse(localStorage.getItem('vendor_info'));
    setVendorData(storedVendorData);
    const vendorId = storedVendorData?.id;
    setVId(vendorId);
  }, []);

  useEffect(() => {
    // if (vendorData !== null && vId !== undefined) {
    //   getManagerList(vId);
    // } else if (vendorData !== null && vId !== undefined) {
    //   getManagerList(vId);
    // }

    if (user.isSuperuser && id) {
      getManagerList(id);
    } else {
      getManagerList(state.id);
      setVId(state.id);
    }
  }, [state])

  const handlePopupState = () => {
    setPopupState(!popupState);
  };

  const handleManagerData = (data) => {
    setManagers(data);
  }

  const handleAcceptBtn = async (row) => {
    const res = await handleAccpetManager(row.account);
    if (res) {
      getManagerList(vId);
      alert("정상적으로 등록되었습니다.");
    } else {
      alert("오류가 발생했습니다.\n계속될 경우 시스템 관리자에게 문의주시기 바랍니다.");
    }
  }

  const handleDeleteBtn = async (row) => {
    if (row.is_confirmed == "승인") {
      if (window.confirm("해당 매니저의 데이터가 모두 삭제되며 삭제 내역이 유저의 이메일로 송부됩니다.\n계속하시겠습니까?")) {
        const res = await handleDeleteManager(row.account);
        if (res) {
          getManagerList(vId);
          alert("정상적으로 삭제되었습니다.");
        } else {
          alert("오류가 발생했습니다.\n계속될 경우 시스템 관리자에게 문의주시기 바랍니다.");
        }
      } else {
        alert("삭제를 취소했습니다.");
      }
    } else {
      if (window.confirm("신청 내역이 삭제되며 매니저 승인 거절 결과가 유저의 이메일로 송부됩니다.\n계속하시겠습니까?")) {
        const res = await handleDeleteManager(row.account);
        if (res) {
          getManagerList(vId);
          alert("매니저 신청이 거절되었습니다.");
        } else {
          alert("오류가 발생했습니다.\n계속될 경우 시스템 관리자에게 문의주시기 바랍니다.");
        }
      } else {
        alert("취소했습니다.");
      }
    }
  }

  const initialForm: ManageManagerPageFormData = {
    email: customer?.email || "",
    firstName: customer?.firstName || "",
    isActive: customer?.isActive || false,
    lastName: customer?.lastName || "",
    note: customer?.note || ""
  };

  const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
  const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

  // 매니저 목록 불러오기
  const getManagerList = useCallback(async (vid: string) => {
    const rawData = await getManagers(vid).then(data => {
      return data;
    });
    const newData = formatManagerData(rawData.data);
    handleManagerData(newData);
  }, []);

  const formatManagerData = (data) => {
    return data.map(acc => {
      const createdAt = new Date(acc.master ? acc.master.date_joined : acc.date_joined);
      const level = acc.master ? "Master" : acc.is_confirmed && acc.is_confirmed == true ? "Manager" : "-"
      const confirmState = acc.is_confirmed && acc.is_confirmed == true ? "승인" : acc.is_confirmed && acc.is_confirmed == false ? "미승인" : ""
      return {
        account: acc.master ? acc.master.email : acc.email,
        level: level,
        name: acc.master ? acc.master.first_name : acc.first_name,
        created_at: createdAt.toLocaleString(),
        is_confirmed: confirmState,
        action: level == "Master" ? [{ value: '' }] : level == "Manager" ? [{ value: '삭제' }] : [{ value: '승인' }, { value: '거절' }]
      }
    })
  }

  const handleAccpetManager = useCallback(async (email: string): Promise<boolean> => {
    return await acceptManager(vendorData.id, email)
      .then(res => {
        if (res == 200) {
          getManagerList(vId);
          return true;
        } else {
          return false
        }
      });
  }, [vendorData])

  const handleAddManager = async (email: string): Promise<boolean> => {
    return await addManager(vendorData.id, email)
      .then(res => {
        if (res == 200) {
          getManagerList(vId);
          return true;
        } else {
          return false
        }
      });
  }

  const handleDeleteManager = async (email: string) => {
    return await deleteManager(vendorData.id, email)
      .then(res => {
        if (res == 200) {
          return true;
        } else {
          return false
        }
      });
  }


  const columns: Column[] = [
    { id: 'level', label: '등급', minWidth: 100, align: 'center' },
    { id: 'account', label: '계정', minWidth: 50, align: 'center' },
    { id: 'name', label: '이름', minWidth: 50, align: 'center' },
    { id: 'created_at', label: '등록일시', minWidth: 50, align: 'center' },
    { id: 'is_confirmed', label: '승인여부', minWidth: 50, align: 'center' },
    // { id: 'action', label: '처리', minWidth: 100, isButton: true, onClick: handleDeleteBtn, buttonColor: '#f06548' },
    {
      id: 'action', label: '처리', align: 'center', minWidth: 100, buttons: [
        {
          value: '삭제',
          onClick: handleDeleteBtn,
          buttonColor: '#f06548'
        }, {
          value: '승인',
          onClick: handleAcceptBtn,
        }, {
          value: '거절',
          onClick: handleDeleteBtn,
        }]
    }
    // { id: 'action', label: '처리', minWidth: 100, isButton: true, onClick: handleDeleteBtn, buttonColor: '#f06548' },
    // { id: 'action', label: '삭제', minWidth: 100, isButton: true, onClick: (test) => alert(`Button clicked: ${test}`), buttonColor: '#f06548' },
  ];

  return (
    <Form
      confirmLeave
      initial={initialForm}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {() => {
        return (
          <DetailPageLayout>
            <TopNav title={intl.formatMessage(sectionNames.manageManagers)}>
              {extensionMenuItems.length > 0 && (
                <CardMenu menuItems={extensionMenuItems} />
              )}
            </TopNav>
            <DetailPageLayout.Content>
              {
                user.isSuperuser && (
                  <MenuTabs vId={id} current={'manageManagers'} />
                )
              }
              <Card>
                <CardTitle title="매니저 관리" />
                <CardContent>
                  <ManagerDataTable columns={columns} data={managers} />
                  <CardSpacer />
                </CardContent>
              </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
              <div></div>
            </DetailPageLayout.RightSidebar>
            <AddManagerPopup
              open={popupState}
              onClose={handlePopupState}
              onSubmit={handleAddManager}
            />
          </DetailPageLayout>
        );
      }}
    </Form>
  );

};

ManageManagerPage.displayName = "ManageManagerPage";
export default ManageManagerPage;
