import { LogLevels, OutputData } from "@editorjs/editorjs";
import { FormControl, FormHelperText } from "@material-ui/core";
import { useId } from "@reach/auto-id";
import { EditorCore, Props as ReactEditorJSProps } from "@react-editor-js/core";
import { Box } from "@saleor/macaw-ui-next";
import clsx from "clsx";
import React, { useState, useEffect } from "react";

import { tools } from "./consts";
import { useHasRendered } from "./hooks";
import { ReactEditorJS } from "./ReactEditorJS";
import useStyles from "./styles";
import { Editor } from "@toast-ui/react-editor";
import { BlockSharp } from "@material-ui/icons";

export type EditorJsProps = Omit<ReactEditorJSProps, "factory">;

export interface RichTextEditorProps extends Omit<EditorJsProps, "onChange"> {
  id?: string;
  disabled: boolean;
  error: boolean;
  helperText?: string;
  label: string;
  name: string;
  editorRef:
  | React.RefCallback<EditorCore>
  | React.MutableRefObject<EditorCore | null>
  | null | any;
  // onChange with value shouldn't be used due to issues with React and EditorJS integration
  onChange?: (data?: OutputData) => void;
  onBlur?: () => void;
  for?: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  id: defaultId,
  disabled,
  error,
  label,
  name,
  helperText,
  editorRef,
  onInitialize,
  onChange,
  onBlur,
  ...props
}) => {
  const classes = useStyles({});
  const id = useId(defaultId);
  const [isFocused, setIsFocused] = React.useState(false);
  const [hasValue, setHasValue] = React.useState(false);

  const [description, setDescription] = React.useState('');

  const isTyped = Boolean(hasValue || isFocused);

  const handleInitialize = React.useCallback((editor: EditorCore) => {
    if (onInitialize) {
      onInitialize(editor);
    }

    if (typeof editorRef === "function") {
      return editorRef(editor);
    }
    if (editorRef) {
      return (editorRef.current = editor);
    }
  }, []);

  const convertOutputDataToHTML = (data: OutputData): string => {
    if (!data) return ''

    return data.blocks
      .map(block => {
        switch (block.type) {
          case "paragraph":
            const text = block.data.text;
            const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
            if (htmlTagPattern.test(text)) {
              return `${text}`
            } else {
              return `<p>${text}</p>`;
            }
          case "header":
            return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
          case "quote":
            return `<blockquote>${block.data.text}</blockquote>`;
          case "list":
            const listTag = block.data.style === "unordered" ? "ul" : "ol";
            const listItems = block.data.items
              .map((item: string) => `<li>${item}</li>`)
              .join("");
            return `<${listTag}>${listItems}</${listTag}>`;
          case "image":
            return `<img src="${block.data.file.url}" alt="${block.data.caption}" />`;
          default:
            return `<div>-</div>`;
        }
      })
      .join("");
  };

  const onChangeGetHTML = () => {
    if (editorRef.current) {
      const data = editorRef.current.getInstance().getHTML();
      setDescription(data);
    }
  };

  // We need to render FormControl first to get id from @reach/auto-id
  const hasRendered = useHasRendered();

  return (
    <FormControl
      data-test-id={"rich-text-editor-" + name}
      disabled={disabled}
      error={error}
      fullWidth
      variant="outlined"
    >
      <Box
        as="label"
        color={error ? "critical2" : "default2"}
        fontWeight="bodySmall"
        fontSize="captionSmall"
        position="absolute"
        htmlFor={id}
        zIndex="2"
        __top={9}
        __left={9}
      >
        {label}
      </Box>
      {
        hasRendered && (
          props.for === "productDetails" ? (
            <Editor
              toolbarItems={[
                // 툴바 옵션 설정
                ['heading', 'bold', 'italic', 'strike'],
                ['hr', 'quote'],
                ['ul', 'ol', 'task', 'indent', 'outdent'],
                ['table', 'image', 'link'],
                ['code', 'codeblock']
              ]}
              height="500px" // 에디터 창 높이
              initialEditType="wysiwyg" // 기본 에디터 타입
              initialValue={convertOutputDataToHTML(props.defaultValue)}
              ref={editorRef} // ref 참조
              onChange={onChangeGetHTML} // onChange 핸들러 연결
              hideModeSwitch={true}
            />
          ) : (
            <ReactEditorJS
              // match with the id of holder div
              holder={id}
              tools={tools}
              // Log level is undefined at runtime
              logLevel={"ERROR" as LogLevels.ERROR}
              onInitialize={handleInitialize}
              onChange={async event => {
                const editorJsValue = await event.saver.save();
                setHasValue(editorJsValue.blocks.length > 0);
                return onChange?.(editorJsValue);
              }}
              {...props}
            >
              <div
                id={id}
                className={clsx(classes.editor, classes.root, {
                  [classes.rootErrorFocus]: isFocused && error,
                  [classes.rootActive]: isFocused,
                  [classes.rootDisabled]: disabled,
                  [classes.rootError]: error,
                  [classes.rootHasLabel]: label !== "",
                  [classes.rootTyped]:
                    isTyped || props.defaultValue?.blocks?.length! > 0,
                })}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                  setIsFocused(false);
                  onBlur?.();
                }}
              />
            </ReactEditorJS>
          )
        )
      }
      {/* {
        hasRendered && (
          <ReactEditorJS
            // match with the id of holder div
            holder={id}
            tools={tools}
            // Log level is undefined at runtime
            logLevel={"ERROR" as LogLevels.ERROR}
            onInitialize={handleInitialize}
            onChange={async event => {
              const editorJsValue = await event.saver.save();
              setHasValue(editorJsValue.blocks.length > 0);
              return onChange?.(editorJsValue);
            }}
            {...props}
          >
            <div
              id={id}
              className={clsx(classes.editor, classes.root, {
                [classes.rootErrorFocus]: isFocused && error,
                [classes.rootActive]: isFocused,
                [classes.rootDisabled]: disabled,
                [classes.rootError]: error,
                [classes.rootHasLabel]: label !== "",
                [classes.rootTyped]:
                  isTyped || props.defaultValue?.blocks?.length! > 0,
              })}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
                onBlur?.();
              }}
            />
          </ReactEditorJS>
          <Editor
            toolbarItems={[
              // 툴바 옵션 설정
              ['heading', 'bold', 'italic', 'strike'],
              ['hr', 'quote'],
              ['ul', 'ol', 'task', 'indent', 'outdent'],
              ['table', 'image', 'link'],
              ['code', 'codeblock']
            ]}
            height="500px" // 에디터 창 높이
            initialEditType="wysiwyg" // 기본 에디터 타입
            initialValue={convertOutputDataToHTML(props.defaultValue)}
            ref={editorRef} // ref 참조
            onChange={onChangeGetHTML} // onChange 핸들러 연결
            hideModeSwitch={true}
          />
        )
      } */}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl >
  );
};

RichTextEditor.displayName = "RichTextEditor";
export default RichTextEditor;
