import { gql } from '@apollo/client';
import { nonTokenClient } from './apolloClient';

const GET_EXPORTED_PRODUCTS = gql`
  query  GetExportedProducts {
    exportedProductIds
  }
`;

export function getExportedProducts() {
  return nonTokenClient.query({
    query: GET_EXPORTED_PRODUCTS,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}