// @ts-strict-ignore
import CardTitle from "@dashboard/components/CardTitle";
import FormSpacer from "@dashboard/components/FormSpacer";
import RichTextEditor from "@dashboard/components/RichTextEditor";
import { RichTextEditorLoading } from "@dashboard/components/RichTextEditor/RichTextEditorLoading";
import { PageErrorFragment } from "@dashboard/graphql";
import { commonMessages } from "@dashboard/intl";
import { getFormErrors } from "@dashboard/utils/errors";
import getPageErrorMessage from "@dashboard/utils/errors/page";
import { useRichTextContext } from "@dashboard/utils/richText/context";
import { Card, CardContent, TextField, InputLabel, Modal, Backdrop, Fade } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import { PageData } from "../PageDetailsPage/form";
import Rating from '@material-ui/lab/Rating'; // 별점 컴포넌트 추가
import { useLocation } from "react-router";
import CloseIcon from "@material-ui/icons/Close"; // 닫기 아이콘
import IconButton from "@material-ui/core/IconButton"; // IconButton 가져오기

export interface PageInfoProps {
  data: PageData;
  disabled: boolean;
  errors: PageErrorFragment[];
  onChange: (...args: any[]) => any;
  onMetaChange: (...args: any[]) => any;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflow: "visible",
    },
    imageContainer: {
      display: "flex",
      flexWrap: "wrap", // 이미지를 가로로 배치하고 줄바꿈을 설정
      justifyContent: "flex-start", // 이미지를 왼쪽으로 정렬
      gap: "12px", // 이미지 간격 설정
    },
    imageItem: {
      width: "140px", // 일정한 크기 유지
      height: "140px", 
      objectFit: "cover", // 이미지의 비율을 유지하며 크기에 맞게 조정
      borderRadius: "8px", // 이미지를 둥글게 처리
      border: "1px solid #ddd", // 테두리 추가
      padding: "4px",
      backgroundColor: "#fff", // 이미지 배경색
      cursor: "pointer", // 이미지에 클릭 가능한 커서 표시
    },
    // 모달 스타일
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContent: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      position: "relative", // 닫기 버튼 위치를 설정하기 위한 상대 위치
      outline: "none",
    },
    largeImage: {
      maxWidth: "90%", // 모달 내 이미지 크기 조정
      maxHeight: "90%", // 모달 내 이미지 크기 조정
    },
    // 닫기 버튼 스타일
    closeButton: {
      position: "absolute", // 모달 내에서의 위치 고정
      right: theme.spacing(1), // 우측 상단으로 위치
      top: theme.spacing(1), // 상단으로 위치
      color: theme.palette.grey[500], // 회색 톤으로 닫기 버튼 색상 설정
    },
    // 읽기 전용 텍스트 색상
    readOnlyInput: {
      "& .MuiOutlinedInput-input": {
        color: "#757575", // 원하는 읽기 전용 스타일 색상
      },
    },
  }),
  { name: "PageInfo" },
);

const PageInfo: React.FC<PageInfoProps> = props => {
  const { data, disabled, errors, onChange, onMetaChange } = props;

  const classes = useStyles(props);
  const intl = useIntl();
  // 페이지 데이터 사용 예시
  const pageTitle = data.page?.title || "제목 없음";
  // console.log(pageTitle);
  // console.log('data.page',data.page);
  // const userData = data.page.writer;
  // console.log('userData',userData);
  const { defaultValue, editorRef, isReadyForMount, handleChange } =
    useRichTextContext();
  const formErrors = getFormErrors(["title", "content"], errors);

  // 불러온 이미지 URL 리스트
  const [imageUrls, setImageUrls] = useState<string[]>([
    "https://via.placeholder.com/150", // 기본 이미지 예시
    "https://via.placeholder.com/150",
  ]);

  // 추가된 상태 변수들
  const [reviewImages, setReviewImages] = useState<FileList | null>(null);
  const location = useLocation(); // 현재 URL 정보를 가져옴
  // console.log('location', location);
  console.log(data);

  // 이미지 모달 상태 관리
  const [open, setOpen] = useState(false); // 모달의 열림/닫힘 상태 관리
  const [selectedImage, setSelectedImage] = useState(""); // 클릭된 이미지 URL 관리

  // 모달 열기 함수
  const handleOpen = (imgUrl) => {
    setSelectedImage(imgUrl); // 선택한 이미지 URL 설정
    setOpen(true); // 모달 열기
  };

  // 모달 닫기 함수
  const handleClose = () => {
    setOpen(false); // 모달 닫기
  };

  return (
    <Card className={classes.root}>
      <CardTitle
        title={intl.formatMessage(commonMessages.generalInformations)}
      />
      <CardContent>
        {/* 콘텐츠 유형 출력 */}
        <TextField
          disabled={disabled}
          fullWidth
          label="콘텐츠 유형"
          name="pageType"
          value={data.pageType?.name || "유형 없음"} // 콘텐츠 유형이 있으면 표시, 없으면 "유형 없음"
          InputProps={{
            readOnly: true, // 읽기 전용 설정
            className: classes.readOnlyInput, 
          }}
        />
        <FormSpacer />

        {/* 기존 제목 입력 */}
        <TextField
          disabled={disabled}
          error={!!formErrors.title}
          fullWidth
          helperText={getPageErrorMessage(formErrors.title, intl)}
          label={intl.formatMessage({
            id: "gr+oXW",
            defaultMessage: "Title",
            description: "page title",
          })}
          name={"title" as keyof PageData}
          value={data.title}
          onChange={onChange}
        />
        <FormSpacer />
        {isReadyForMount ? (
          <RichTextEditor
            defaultValue={defaultValue}
            editorRef={editorRef}
            onChange={handleChange}
            disabled={disabled}
            error={!!formErrors.content}
            helperText={getPageErrorMessage(formErrors.content, intl)}
            label={intl.formatMessage({
              id: "gMwpNC",
              defaultMessage: "Content",
              description: "page content",
            })}
            name={"content" as keyof PageData}
          />
        ) : (
          <RichTextEditorLoading
            label={intl.formatMessage({
              id: "gMwpNC",
              defaultMessage: "Content",
              description: "page content",
            })}
            name={"content" as keyof PageData}
          />
        )}

        <FormSpacer />

        {/* 리뷰 페이지 정보 */}
        {data.pageType?.name === "리뷰" && (
          <>
            <TextField
              fullWidth
              label="이름"
              name="name"
              value={data.page?.writer?.firstName || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="닉네임"
              name="nickname"
              value={data.page?.writer?.metafields?.nickName || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="이메일"
              name="email"
              value={data.page?.writer?.email || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="전화번호"
              name="phone"
              value={data.page?.writer?.metafields?.phone || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="별점"
              name="rating"
              value={data.page?.metadata?.find(meta => meta.key === "rating")?.value || ""} 
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />

            <FormSpacer />
            <TextField
              fullWidth
              label="등록일자"
              name="createDate"
              value={data.page?.publicationDate || ""} // publicationDate가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            {/* 리뷰 이미지 */}
            <InputLabel>리뷰 이미지</InputLabel>
            <FormSpacer />
            <div className={classes.imageContainer}>
              {data.page?.media && data.page?.media.length > 0 ? (
                data.page.media.slice(0, 10).map((mediaItem, index) => (
                  <img
                    key={index}
                    src={mediaItem?.pageImgUrl ?? "https://via.placeholder.com/150"}
                    alt={`Review Image ${index}`}
                    className={classes.imageItem}
                    onClick={() => handleOpen(mediaItem?.pageImgUrl)} // 이미지 클릭 시 모달 열기
                  />
                ))
              ) : (
                <p>등록된 이미지가 없습니다.</p> // 이미지가 없을 때 표시되는 문구
              )}
            </div>

            <Modal
              open={open} // 모달 상태에 따른 열기/닫기
              onClose={handleClose} // 모달 닫기 함수
              className={classes.modal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.modalContent}>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose} // 닫기 버튼 클릭 시 모달 닫기
                  >
                    <CloseIcon />
                  </IconButton>
                  <img src={selectedImage} alt="Large Preview" className={classes.largeImage} />
                </div>
              </Fade>
            </Modal>
          </>
        )}
        {/* 문의 페이지 정보*/}
        {data.pageType?.name === "문의" && (
          <>
            <TextField
              fullWidth
              label="이름"
              name="name"
              value={data.page?.writer?.firstName || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="닉네임"
              name="nickname"
              value={data.page?.writer?.metafields?.nickName || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="이메일"
              name="email"
              value={data.page?.writer?.email || ""} // writer가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="전화번호"
              name="phone"
              value={data.page?.writer?.metafields?.phone || ""} // writer 및 metafields가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            <TextField
              fullWidth
              label="답변입력란"
              name="answer"
              value={data.answer || ""} // answer 필드의 상태 값을 사용
              disabled={disabled}
              onChange={onMetaChange} 
              InputProps={{
                name: "answer", 
              }}
            />

            <FormSpacer />
            <TextField
              fullWidth
              label="등록일자"
              name="createDate"
              value={data.page?.publicationDate || ""} // publicationDate가 없는 경우 대비
              InputProps={{
                readOnly: true, // 읽기 전용 설정
                className: classes.readOnlyInput, 
              }}
              disabled={disabled}
            />
            <FormSpacer />
            {/* 문의 이미지 */}
            <InputLabel>문의 이미지</InputLabel>
            <FormSpacer />
            <div className={classes.imageContainer}>
              {data.page?.media && data.page?.media.length > 0 ? (
                data.page.media.slice(0, 10).map((mediaItem, index) => (
                  <img
                    key={index}
                    src={mediaItem?.pageImgUrl ?? "https://via.placeholder.com/150"}
                    alt={`Review Image ${index}`}
                    className={classes.imageItem}
                    onClick={() => handleOpen(mediaItem?.pageImgUrl)} // 이미지 클릭 시 모달 열기
                  />
                ))
              ) : (
                <p>등록된 이미지가 없습니다.</p> // 이미지가 없을 때 표시되는 문구
              )}
            </div>

            <Modal
              open={open} // 모달 상태에 따른 열기/닫기
              onClose={handleClose} // 모달 닫기 함수
              className={classes.modal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.modalContent}>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose} // 닫기 버튼 클릭 시 모달 닫기
                  >
                    <CloseIcon />
                  </IconButton>
                  <img src={selectedImage} alt="Large Preview" className={classes.largeImage} />
                </div>
              </Fade>
            </Modal>
          </>
        )}
      </CardContent>
    </Card>
  );
};
PageInfo.displayName = "PageInfo";
export default PageInfo;
