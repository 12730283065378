// @ts-strict-ignore
import { DashboardCard } from "@dashboard/components/Card";
import { Combobox } from "@dashboard/components/Combobox";
import Link from "@dashboard/components/Link";
import {
  ProductChannelListingErrorFragment,
  ProductErrorCode,
  ProductErrorFragment,
} from "@dashboard/graphql";
import { ChangeEvent } from "@dashboard/hooks/useForm";
import { productTypeUrl } from "@dashboard/productTypes/urls";
import { FetchMoreProps } from "@dashboard/types";
import { getFormErrors, getProductErrorMessage } from "@dashboard/utils/errors";
import { Box, Option, Text } from "@saleor/macaw-ui-next";
import React, { useState, useMemo, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useCategorySearch from "@dashboard/searches/useCategorySearch";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getChoices2 } from "@dashboard/products/utils/data";
import { useCategoryDetailsQuery } from "@dashboard/graphql";

interface ProductType {
  hasVariants: boolean;
  id: string;
  name: string;
}

interface ProductOrganizationProps {
  canChangeType: boolean;
  categories?: Option[];
  categoryInputDisplayValue: string;
  collections?: Option[];
  collectionsInputDisplayValue: Option[];
  data: {
    category: string;
    collections: Option[];
    productType?: ProductType;
  };
  disabled: boolean;
  errors: Array<ProductErrorFragment | ProductChannelListingErrorFragment>;
  productType?: ProductType;
  productTypeInputDisplayValue?: string;
  productTypes?: Option[];
  fetchCategories: (query: string) => void;
  fetchCollections: (query: string) => void;
  fetchMoreCategories: FetchMoreProps;
  fetchMoreCollections: FetchMoreProps;
  fetchMoreProductTypes?: FetchMoreProps;
  loadMoreCategories1: FetchMoreProps;
  loadMoreCategories2: FetchMoreProps;
  loadMoreCategories3: FetchMoreProps;
  loadMoreCategories4: FetchMoreProps;
  fetchProductTypes?: (data: string) => void;
  onCategoryChange: (event: ChangeEvent) => void;
  onCollectionChange: (event: ChangeEvent) => void;
  onProductTypeChange?: (event: ChangeEvent) => void;
}

export const ProductOrganization: React.FC<
  ProductOrganizationProps
> = props => {
  const {
    canChangeType,
    // categories,
    // categoryInputDisplayValue,
    // collections,
    // collectionsInputDisplayValue,
    data,
    disabled,
    errors,
    // fetchCategories,
    // fetchCollections,
    // fetchMoreCategories,
    // fetchMoreCollections,
    fetchMoreProductTypes,
    fetchProductTypes,
    productType,
    productTypeInputDisplayValue,
    productTypes,
    onCategoryChange,
    // onCollectionChange,
    onProductTypeChange,
  } = props;

  const intl = useIntl();

  const [selectedCat1, setSelectedCat1] = useState({value:'', decryptId:'0'}); 
  const [selectedCat2, setSelectedCat2] = useState({value:'', decryptId:'0'}); 
  const [selectedCat3, setSelectedCat3] = useState({value:'', decryptId:'0'}); 
  const [selectedCat4, setSelectedCat4] = useState({value:'', decryptId:'0'}); 

  const formErrors = getFormErrors(
    ["productType", "category", "collections", "isPublished"],
    errors,
  );

  const noCategoryError =
    (formErrors.isPublished?.code === ProductErrorCode.PRODUCT_WITHOUT_CATEGORY)
      ? formErrors.isPublished
      : null;

      const createCategorySearch = (level, parentId = null) => {
        return useCategorySearch({
          variables: { first: 100, level, parentId },
        });
      };
      
      // 1차 카테고리 불러오기
      const { loadMore: loadMoreCategories1, search: searchCategory1, result: searchCategoryOpts1 } = createCategorySearch(0);
      // 2차 카테고리 불러오기
      const { loadMore: loadMoreCategories2, search: searchCategory2, result: searchCategoryOpts2 } = createCategorySearch(1, selectedCat1.decryptId);
      // 3차 카테고리 불러오기
      const { loadMore: loadMoreCategories3, search: searchCategory3, result: searchCategoryOpts3 } = createCategorySearch(2, selectedCat2.decryptId);
      // 4차 카테고리 불러오기
      const { loadMore: loadMoreCategories4, search: searchCategory4, result: searchCategoryOpts4 } = createCategorySearch(3, selectedCat3.decryptId);

  // 카테고리 상세 정보 불러오기
  const { data: categoryDetails, refetch } = useCategoryDetailsQuery({
    displayLoader: true,
    variables: { id: data.category, first: 100 },
    skip: !data.category
  });

  // 1~4차 카테고리
  const { categories1, categories2, categories3, categories4 } = useMemo(() => {
    return {
      categories1: getChoices2(mapEdgesToItems(searchCategoryOpts1?.data?.search) || []),
      categories2: getChoices2(mapEdgesToItems(searchCategoryOpts2?.data?.search) || []),
      categories3: getChoices2(mapEdgesToItems(searchCategoryOpts3?.data?.search) || []),
      categories4: getChoices2(mapEdgesToItems(searchCategoryOpts4?.data?.search) || []),
    };
  }, [searchCategoryOpts1, searchCategoryOpts2, searchCategoryOpts3, searchCategoryOpts4]);


  // 카테고리 부모 데이터
  const transformedAncestors = useMemo(() => {
    if (!categoryDetails?.category?.ancestors?.edges) {
        return []; // 데이터가 없는 경우 빈 배열 반환
    }

    return categoryDetails.category.ancestors.edges.map(edge => ({
        value: edge.node.id,
        label: edge.node.name,
        decryptId: edge.node.decryptId
    }));
  }, [categoryDetails]);

  // 선택된 카테고리 데이터
  const selectedCategory = useMemo(() => {
    if (!categoryDetails?.category) {
        return []; // 데이터가 없는 경우 빈 배열 반환
    }
    return {
      value : categoryDetails.category.id,
      label : categoryDetails.category.name,
      decryptId : categoryDetails.category.decryptId,
    }
  }, [categoryDetails]);

  const category1Change = (event) => {
    const selectedCategory = categories1.find(category => category.value === event.target.value);
    setSelectedCat1(selectedCategory ? selectedCategory : {value:'', decryptId:'0'});
    setSelectedCat2({value:'', decryptId:'0'});
    setSelectedCat3({value:'', decryptId:'0'});
    setSelectedCat4({value:'', decryptId:'0'});
    onCategoryChange(event); // 카테고리 부여 함수
  };

  const category2Change = (event) => {
    const selectedCategory = categories2.find(category => category.value === event.target.value);
    setSelectedCat2(selectedCategory ? selectedCategory : {value:'', decryptId:'0'});
    setSelectedCat3({value:'', decryptId:'0'});
    setSelectedCat4({value:'', decryptId:'0'});
    onCategoryChange(event); // 카테고리 부여 함수
  };

  const category3Change = (event) => {
    const selectedCategory = categories3.find(category => category.value === event.target.value);
    setSelectedCat3(selectedCategory ? selectedCategory : {value:'', decryptId:'0'});
    setSelectedCat4({value:'', decryptId:'0'});
    onCategoryChange(event); // 카테고리 부여 함수
  };

  const category4Change = (event) => {
    const selectedCategory = categories4.find(category => category.value === event.target.value);
    setSelectedCat4(selectedCategory ? selectedCategory : {decryptId:'0'});
    onCategoryChange(event); // 카테고리 부여 함수
  };
  
  // 상품 업데이트로 접근시 카테고리 체크상태
  useEffect(()=> {
    if(categoryDetails && !selectedCat1.value) {
      refetch().then((res)=>{
        
        if(transformedAncestors.length === 0) {
          setSelectedCat1(selectedCategory);
        }
        else if(transformedAncestors.length === 1) {
          setSelectedCat1(transformedAncestors[0]);
          setSelectedCat2(selectedCategory);
        }
        else if(transformedAncestors.length === 2) {
          setSelectedCat1(transformedAncestors[0]);
          setSelectedCat2(transformedAncestors[1]);
          setSelectedCat3(selectedCategory);
        }
        else if(transformedAncestors.length === 3) {
          setSelectedCat1(transformedAncestors[0]);
          setSelectedCat2(transformedAncestors[1]);
          setSelectedCat3(transformedAncestors[2]);
          setSelectedCat4(selectedCategory);
        }

      })
      .catch(err => {
        console.log(err);
      })
    }
  },[categoryDetails])

  return (
    <DashboardCard>
      <DashboardCard.Title>
        {intl.formatMessage({
          id: "JjeZEG",
          defaultMessage: "Organize Product",
          description: "section header",
        })}
      </DashboardCard.Title>
      <DashboardCard.Content gap={2} display="flex" flexDirection="column">
        {canChangeType ? (
          <Combobox
            disabled={disabled}
            data-test-id="product-type"
            options={productTypes}
            value={
              data.productType?.id
                ? {
                    value: data.productType.id,
                    label: productTypeInputDisplayValue,
                  }
                : null
            }
            error={!!formErrors.productType}
            helperText={getProductErrorMessage(formErrors.productType, intl)}
            onChange={onProductTypeChange}
            fetchOptions={fetchProductTypes}
            fetchMore={fetchMoreProductTypes}
            name="productType"
            label={intl.formatMessage({
              id: "anK7jD",
              defaultMessage: "Product Type",
            })}
          />
        ) : (
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" flexDirection="column">
              <Text variant="bodyEmp">
                <FormattedMessage id="anK7jD" defaultMessage="Product Type" />
              </Text>
              {productType?.id ? (
                <Text variant="caption">
                  <Link href={productTypeUrl(productType?.id) ?? ""}>
                    {productType?.name ?? "..."}
                  </Link>
                </Text>
              ) : (
                <Text variant="caption">{productType?.name ?? "..."}</Text>
              )}
            </Box>
          </Box>
        )}

        {/* 1차 카테고리 [START] */}
        {
          categories1.length > 0 &&
          <Box data-test-id="category1">
            <Combobox
              disabled={disabled || (categories1.length === 0)}
              options={disabled ? [] : categories1}
              value={selectedCat1}
              error={!!(formErrors.category || noCategoryError)}
              helperText={getProductErrorMessage(
                formErrors.category || noCategoryError,
                intl,
              )}
              onChange={category1Change}
              fetchOptions={searchCategory1}
              fetchMore={loadMoreCategories1}
              name="category"
              label={intl.formatMessage({
                id: "ccXLVi",
                defaultMessage: "Category",
              })}
            />
          </Box>
        }
        {/* 1차 카테고리 [END] */}

        {/* 2차 카테고리 [START] */}
        {
          categories2.length > 0 &&
          <Box data-test-id="category2">
            <Combobox
              disabled={disabled || (categories2.length === 0)}
              options={disabled ? [] : categories2}
              value={selectedCat2}
              // error={!!(formErrors.category || noCategoryError)}
              // helperText={getProductErrorMessage(
              //   formErrors.category || noCategoryError,
              //   intl,
              // )}
              onChange={category2Change}
              fetchOptions={searchCategory2}
              fetchMore={loadMoreCategories2}
              name="category"
              label={intl.formatMessage({
                id: "ccXLVi2",
                defaultMessage: "Category",
              })}
            />
          </Box>
        }
        {/* 2차 카테고리 [END] */}

        {/* 3차 카테고리 [START] */}
        {
          categories3.length > 0 &&
          <Box data-test-id="category3">
            <Combobox
              disabled={disabled || (categories3.length === 0)}
              options={disabled ? [] : categories3}
              value={selectedCat3}
              // error={!!(formErrors.category || noCategoryError)}
              // helperText={getProductErrorMessage(
              //   formErrors.category || noCategoryError,
              //   intl,
              // )}
              onChange={category3Change}
              fetchOptions={searchCategory3}
              fetchMore={loadMoreCategories3}
              name="category"
              label={intl.formatMessage({
                id: "ccXLVi3",
                defaultMessage: "Category",
              })}
            />
          </Box>
        }
        {/* 3차 카테고리 [END] */}

        {/* 4차 카테고리 [START] */}
        {
          categories4.length > 0 &&
          <Box data-test-id="category4">
            <Combobox
              disabled={disabled || (categories4.length === 0)}
              options={disabled ? [] : categories4}
              value={selectedCat4}
              // error={!!(formErrors.category || noCategoryError)}
              // helperText={getProductErrorMessage(
              //   formErrors.category || noCategoryError,
              //   intl,
              // )}
              onChange={category4Change}
              fetchOptions={searchCategory4}
              fetchMore={loadMoreCategories4}
              name="category"
              label={intl.formatMessage({
                id: "ccXLVi4",
                defaultMessage: "Category",
              })}
            />
          </Box>
        }
        {/* 4차 카테고리 [END] */}
{/* 

// 컬렉션 선택 못하게 주석처리
        <Multiselect
          disabled={disabled}
          options={collections}
          data-test-id="collections"
          value={collectionsInputDisplayValue}
          error={!!formErrors.collections}
          name="collections"
          onChange={onCollectionChange}
          fetchOptions={fetchCollections}
          fetchMore={fetchMoreCollections}
          label={intl.formatMessage({
            id: "ulh3kf",
            defaultMessage: "Collections",
          })}
          helperText={getProductErrorMessage(formErrors.collections, intl)}
        /> */}
      </DashboardCard.Content>
    </DashboardCard>
  );
};
