import { defineMessages } from "react-intl";

export const actionButtonsMessages = defineMessages({
  refund: {
    id: "K//bUK",
    defaultMessage: "Refund",
    description: "refund button",
  },
  editTracking: {
    id: "dTkmON",
    defaultMessage: "Edit tracking",
    description: "edit tracking button",
  },
  addTracking: {
    id: "bS7A8u",
    defaultMessage: "Add tracking",
    description: "add tracking button",
  },
});

export const extraInfoMessages = defineMessages({
  fulfilled: {
    id: "lOMgms",
    defaultMessage: "출고지: ",
    description: "fulfillment group",
  },
  restocked: {
    id: "f/R1Ln",
    defaultMessage: "출고지: ",
    description: "restocked group",
  },
  tracking: {
    id: "4PlW0w",
    defaultMessage: "송장 번호: {trackingNumber}",
    description: "tracking number",
  },
});
