import { PermissionEnum } from "@dashboard/graphql";
import React, { useEffect, useState } from "react";
import { Route, RouteProps } from "react-router-dom";

import NotFound from "../../NotFound";
import { useUser } from "..";
import { hasAllPermissions, hasAnyPermissions } from "../misc";
import FullInfoProvidedFalsePage from "@dashboard/stores/views/FullInfoProvidedFalsePage";

type MatchPermissionType = "all" | "any";

interface SectionRouteProps extends RouteProps {
  initialState?: any;
  permissions?: PermissionEnum[];
  matchPermission?: MatchPermissionType;
}

const matchAll = (match: MatchPermissionType) => match === "all";

export const SectionRoute: React.FC<SectionRouteProps> = ({
  permissions,
  matchPermission = "all",
  ...props
}) => {
  const { user } = useUser();
  const superAdmin = user.isSuperuser;
  const { path } = props;
  const _initialState = props.initialState;
  const vendorData = JSON.parse(localStorage.getItem("vendor_info"));
  const [fullInfoState, setFullInfoState] = useState();

  useEffect(() => {
    if (_initialState) {
      // console.log("full-info-state set with initial state");
      setFullInfoState(_initialState);
    } else {
      // console.log("full-info-state set with vendor-data");
      setFullInfoState(vendorData.full_info_provided);
    }
  })

  // Prevents race condition
  if (user === undefined) {
    return null;
  }

  const hasSectionPermissions = () => {
    if (!permissions) {
      return true;
    }

    if (matchAll(matchPermission)) {
      return hasAllPermissions(permissions, user!);
    }

    return hasAnyPermissions(permissions, user!);
  };

  const isFullInfoProvided = () => {
    return true; // 08-14 kdy: 임시처리(스토어 정보 없어도 보이도록)
    if (superAdmin) {
      return true;
    } else {
      if (fullInfoState) {
        return true;
      } else {
        if (path == "/stores") {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  return hasSectionPermissions() ? (isFullInfoProvided() ? <Route {...props} /> : <Route component={FullInfoProvidedFalsePage} />) : <NotFound />;
};
SectionRoute.displayName = "Route";
export default SectionRoute;
