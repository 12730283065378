import { Button } from "@dashboard/components/Button";
import { FulfillmentStatus } from "@dashboard/graphql";
import { buttonMessages, commonMessages } from "@dashboard/intl";
import { orderPaymentRefundUrl } from "@dashboard/orders/urls";
import { CardActions, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import useNavigator from "@dashboard/hooks/useNavigator";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { ORDER_DELIVERED } from "@dashboard/orders/queries";
import { actionButtonsMessages } from "./messages";
import useStyles from "./styles";

interface ActionButtonsProps {
  orderId: string;
  order_service_id: string;
  status: FulfillmentStatus;
  trackingNumber?: string;
  orderIsPaid?: boolean;
  fulfillmentAllowUnpaid: boolean;
  hasTransactions: boolean;
  onTrackingCodeAdd: () => any;
  onApprove: () => any;
}

const statusesToShow = [
  FulfillmentStatus.FULFILLED,
  FulfillmentStatus.RETURNED,
  FulfillmentStatus.WAITING_FOR_APPROVAL,
  FulfillmentStatus.DELIVERED,
];

const ActionButtons: React.FC<ActionButtonsProps> = ({
  orderId,
  order_service_id,
  status,
  trackingNumber,
  orderIsPaid,
  fulfillmentAllowUnpaid,
  hasTransactions,
  onTrackingCodeAdd,
  onApprove,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigator();

  const hasTrackingNumber = !!trackingNumber;

  if (!statusesToShow.includes(status)) {
    return null;
  }

  useEffect(()=>{
    console.log('status',status);
    console.log('status',status);
    console.log('status',status);
  },[])

  const [executeOrderDelivered, { loading, error }] = useMutation(ORDER_DELIVERED);

  const openDeliveredPopup = async () => {
    const userConfirmed = window.confirm(
      intl.formatMessage({
        id: "confirmDelivered",
        defaultMessage: "배송완료 상태로 변경하시겠습니까?",
      })
    );

    if (userConfirmed) {
      try {
        const response = await executeOrderDelivered({
          variables: { id: orderId },
        });
        const { errors } = response.data.orderDelivered;

        if (errors.length > 0) {
          console.error("Error marking order as delivered:", errors);
        } else {
          console.log("Order marked as delivered successfully.");
          navigate('/orders?asc=false&sort=number');
          setTimeout(() => {
            navigate(window.location.pathname);
          }, 100); 

        }
      } catch (error) {
        console.error("An error occurred while marking the order as delivered:", error);
      }

    }
  };

  const onGoodsflowPopup = () => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const url = `https://test.goodsflow.io/tracking/ZJVnnFkqLY/` + order_service_id;

    window.open(
      url,
      "_blank",
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );
  };

  if (status === FulfillmentStatus.WAITING_FOR_APPROVAL) {
    const cannotFulfill = !orderIsPaid && !fulfillmentAllowUnpaid;

    return (
      <CardActions className={classes.actions}>
        <Button color="primary" onClick={onApprove} disabled={cannotFulfill}>
          <FormattedMessage {...buttonMessages.approve} />
        </Button>
        {cannotFulfill && (
          <Typography color="error" variant="caption">
            <FormattedMessage {...commonMessages.cannotFullfillUnpaidOrder} />
          </Typography>
        )}
      </CardActions>
    );
  }

  if (status === FulfillmentStatus.RETURNED && !hasTransactions) {
    return (
      <CardActions>
        <Button variant="primary" href={orderPaymentRefundUrl(orderId)}>
          <FormattedMessage {...actionButtonsMessages.refund} />
        </Button>
      </CardActions>
    );
  }

  return (hasTrackingNumber)? (
    <>
      {
        (status === FulfillmentStatus.FULFILLED || status !== FulfillmentStatus.DELIVERED) &&
        <>
          <CardActions className={classes.actions}>
            <Button
              data-test-id="edit-tracking-button"
              variant="primary"
              onClick={onTrackingCodeAdd}
            >
              <FormattedMessage {...actionButtonsMessages.editTracking} />
            </Button>
          </CardActions>
          {
            (status !== FulfillmentStatus.RETURNED && status !== FulfillmentStatus.REPLACED)&&
            <CardActions className={classes.actions}>
              <Button
                data-test-id="add-tracking-button"
                style={{ height: "38px" }}
                disabled={loading}
                onClick={openDeliveredPopup}
              >
                {loading ? "처리중..." : "배송완료 수동처리"}
              </Button>
            </CardActions>
          }
        </>
      }

      <CardActions className={classes.actions}>
        <Button
          onClick={onGoodsflowPopup}
          data-test-id="add-tracking-button"
          style={{ height: "38px", background: "#2e93e6", color: "#fff" }}
        >
          배송 현황보기
        </Button>
      </CardActions>

      {error && <Typography color="error">Error: {error.message}</Typography>}
    </>
  ) : (
    <>
      <CardActions className={classes.actions}>
        <Button
          variant="primary"
          onClick={onTrackingCodeAdd}
          data-test-id="add-tracking-button"
        >
          <FormattedMessage {...actionButtonsMessages.addTracking} />
        </Button>
      </CardActions>
    </>
  );
};

export default ActionButtons;
