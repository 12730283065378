import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { getApiUrl } from "@dashboard/config";
import { createFetch } from "@saleor/sdk";
import { createUploadLink } from "apollo-upload-client";

const attachVariablesLink = new ApolloLink((operation, forward) =>
    forward(operation).map(data => ({
        ...data,
        extensions: {
            ...data.extensions,
            variables: operation.variables,
        },
    })),
);

export const link = attachVariablesLink.concat(
    createUploadLink({
        credentials: "include",
        uri: getApiUrl(),
        fetch: createFetch(),
    }) as unknown as ApolloLink, // type mismatch between apollo-upload-client and @apollo/cient
);
// 토큰을 전혀 추가하지 않는 Apollo 링크
export const nonTokenLink = new ApolloLink((operation, forward) => {
    // 토큰을 추가하지 않고 그대로 요청을 전달
    return forward(operation);
});

// nonTokenClient Apollo Client 설정
export const nonTokenClient = new ApolloClient({
    link: nonTokenLink.concat(link),
    cache: new InMemoryCache()
});
