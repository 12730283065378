import { defineMessages } from "react-intl";

export const refundPageMessages = defineMessages({
  pageSubtitle: {
    defaultMessage: "환불 최종 처리",
    id: "/nZy6A",
    description: "page subtitle",
  },
  refundBalance: {
    defaultMessage: "환불 잔액",
    id: "2x9ZgK",
    description: "card title",
  },
  totalCaptured: {
    defaultMessage: "총 결제된 금액",
    id: "zf48rQ",
    description: "sum of captured amount of all transactions",
  },
  grantedRefund: {
    defaultMessage: "승인된 환불",
    id: "1oYjZc",
    description: "sum of all granted refunds for an order",
  },
  pendingRefunds: {
    defaultMessage: "대기 중인 환불",
    id: "0whAeC",
    description: "sum of all pending refunds inside an order", // 주문 내에서 대기 중인 모든 환불의 합계
  },
  balanceAfterRequests: {
    defaultMessage: "환불 후 잔액",
    id: "Hc58JL",
    description: "card title", // 카드 제목
  },
  refundName: {
    defaultMessage: "{transactionType} 환불",
    id: "NnhrxZ",
    description: "amount of sent refund for transaction",
  },
  result: {
    defaultMessage: "결과",
    id: "bT71VU",
    description: "total of all sent refunds",
  },
  requestRefund: {
    defaultMessage: "환불 최종 진행(사용 안함)",
    id: "fkgack",
    description: "button, sends refund for transaction",
  },
  refundAmount: {
    defaultMessage: "환불 금액",
    id: "ng0ZDW",
    description: "label, input for amount to refund in transaction",
  },
  setMax: {
    defaultMessage: "최대",
    id: "d7dT8o",
    description: "button, sets granted refund amount in input",
  },
});

export const manualRefundMessages = defineMessages({
  refundManual: {
    defaultMessage: "환불 사항",
    id: "PLX6FH",
    description: "sum of all manual refunds for transaction",
  },
  refundManualDescription: {
    defaultMessage: "환불하실 금액을 하단에 입력해주세요.",
    id: "LLrOK3",
    description: "paragraph, description in manual refund card",
  },
  refund: {
    defaultMessage: "환불 최종 진행",
    id: "bfuxy3",
    description: "button, create new transaction with refund amount",
  },
});

export const dataLineMessages = defineMessages({
  settled: {
    defaultMessage: "처리 완료",
    id: "wJep/X",
    description: "refund amounts were settled",
  },
  unsettled: {
    defaultMessage: "처리 미완료",
    id: "9pLPLn",
    description: "refund amounts were unsettled",
  },
});
