// @ts-strict-ignore
import { gql } from "@apollo/client";
import {
  SearchCategoriesDocument,
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables,
} from "@dashboard/graphql";
import makeTopLevelSearch from "@dashboard/hooks/makeTopLevelSearch";

export const searchCategories = gql`
  query SearchCategories($first: Int!, $query: String, $level: Int, $after: String, $parentId: String) {
    search: categories(
      after: $after
      first: $first
      level: $level
      filter: { search: $query, parentId: $parentId }
    ) {
      edges {
        node {
          id
          decryptId
          name
          level
          parent {
            id
            decryptId
            name
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
`;

export default makeTopLevelSearch<
  SearchCategoriesQuery,
  SearchCategoriesQueryVariables
>(SearchCategoriesDocument);
