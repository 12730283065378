// @ts-strict-ignore
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { UserFragment } from "@dashboard/graphql";
import { sectionNames } from "@dashboard/intl";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, NavigationCard } from "@saleor/macaw-ui";
import { Box, vars } from "@saleor/macaw-ui-next";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import VersionInfo from "../components/VersionInfo";
import { MenuSection } from "./types";
import { hasUserMenuItemPermissions } from "./utils";
import { useUser } from "@dashboard/auth";

interface VersionInfo {
  dashboardVersion: string;
  coreVersion: string;
}

const useStyles = makeStyles(
  theme => ({
    configurationCategory: {
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
      },
      display: "grid",
      gap: theme.spacing(4),
      gridTemplateColumns: "1fr 3fr",
      padding: theme.spacing(4, 0),
    },

    configurationItem: {
      display: "grid",
      gap: theme.spacing(4),
      gridTemplateColumns: "1fr 1fr",
    },
    configurationLabel: {
      paddingBottom: 20,
    },

    link: {
      display: "contents",
      marginBottom: theme.spacing(4),
    },
    icon: {
      "& path": {
        fill: theme.palette.primary.main,
      },
      fontSize: 48,
    },
    sectionDescription: {},
    sectionTitle: {
      fontSize: 20,
      fontWeight: 600 as 600,
    },
    navigationCard: {
      border: `1px solid ${vars.colors.border.default1}`,
      height: 130,
      boxShadow: "none !important",
      "& .MuiCardContent-root": {
        borderRadius: vars.borderRadius[3],
      },
    },
  }),
  { name: "ConfigurationPage" },
);

export interface ConfigurationPageProps {
  menu: MenuSection[];
  user: UserFragment;
  versionInfo: VersionInfo;
}

export const ConfigurationPage: React.FC<ConfigurationPageProps> = props => {
  const {
    menu: menus,
    user,
    versionInfo: { dashboardVersion, coreVersion },
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const renderVersionInfo = (
    <VersionInfo
      dashboardVersion={dashboardVersion}
      coreVersion={coreVersion}
    />
  );

  const intl = useIntl();
  const { user: userInfo } = useUser();
  const superAdmin = userInfo.isSuperuser

  return (
    <DetailPageLayout gridTemplateColumns={1} withSavebar={false}>
      <TopNav title={intl.formatMessage(sectionNames.configuration)}>
        {isSmUp && renderVersionInfo}
      </TopNav>
      <DetailPageLayout.Content data-test-id="configuration-menu">
        <Box paddingX={6} __maxWidth={"1024px"} margin="auto">
          {menus
            .filter(menu =>
              menu.menuItems.some(menuItem =>
                hasUserMenuItemPermissions(menuItem, user),
              ),
            )
            .map((menu, menuIndex) => {
              if (!superAdmin && (menuIndex === 0 || menuIndex === 1 || menuIndex === 2 || menuIndex === 4 || menuIndex === 5)) {
                /// 임시 처리(menu2 => 스태프 관리)
                // menuIndex 0: 속성 및 상품 유형,
                // menuIndex 1: 제품 설정 (세금),
                // menuIndex 2: 스태프 설정,
                // menuIndex 3: 배송 설정 - 배송 방법, 창고(출고지),
                // menuIndex 4: 다중 채널 - 판매 채널 설정,
                // menuIndex 5: 운영 관리 - 페이지 유형 설정,
                // menuIndex 6: 기타 - 네비게이션 (번역), 사이트 설정, 플러그인, 웹훅 & 이벤트
                return null;
              }

              return (
                <div className={classes.configurationCategory} key={menuIndex}>
                  <div className={classes.configurationLabel}>
                    <Typography>{menu.label}</Typography>
                  </div>
                  <div className={classes.configurationItem}>
                    {menu.menuItems
                      .filter(menuItem =>
                        hasUserMenuItemPermissions(menuItem, userInfo),
                      )
                      .map((item, itemIndex) => {
                        if (!superAdmin && menuIndex === 6 && (itemIndex === 0 || itemIndex === 1 || itemIndex === 2)) {
                          // itemIndex 0: 네비게이션 (번역),
                          // itemIndex 1: 사이트 설정,
                          // itemIndex 2: 플러그인,
                          // itemIndex 3: 웹 훅 & 이벤트
                          return null;
                        }
                        return (
                          <Link
                            className={classes.link}
                            to={item.url}
                            key={`${item.title}-${itemIndex}`}
                          >
                            <NavigationCard
                              className={classes.navigationCard}
                              key={itemIndex}
                              icon={item.icon}
                              title={item.title}
                              description={item.description}
                              data-test-id={
                                item.testId +
                                "-settings-subsection-" +
                                item.title.toLowerCase()
                              }
                            />
                          </Link>
                        )
                      })}
                  </div>
                </div>
              );
            })}
        </Box>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};

ConfigurationPage.displayName = "ConfigurationPage";
