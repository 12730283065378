import { defineMessages } from "react-intl";

export const messages = defineMessages({
  assignVariantDialogHeader: {
    id: "jupCA2",
    defaultMessage: "Assign brands",
    description: "dialog header",
  },
  assignProductDialogButton: {
    id: "Nv/toB",
    defaultMessage: "Assign and save",
    description: "button",
  },
  assignProductDialogContent: {
    id: "un+VWt",
    defaultMessage: "Search brands",
  },
  assignProductDialogSearch: {
    id: "SPd7ee",
    defaultMessage: "Search by brand name, attribute, brand type etc...",
  },
});
