// @ts-strict-ignore
import {
  extensionMountPoints,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { AppPaths } from "@dashboard/apps/urls";
import { useUser } from "@dashboard/auth";
import { categoryListUrl } from "@dashboard/categories/urls";
import { collectionListUrl } from "@dashboard/collections/urls";
import { configurationMenuUrl } from "@dashboard/configuration";
import { getConfigMenuItemsPermissions } from "@dashboard/configuration/utils";
import { customerListUrl } from "@dashboard/customers/urls";
import { saleListUrl, voucherListUrl } from "@dashboard/discounts/urls";
import { storeInfoListUrl, manageManagersListUrl, contactInfoListUrl, shippingInfoListUrl, storeSyncListUrl, shopifySyncListUrl } from "@dashboard/stores/urls";
import { giftCardListUrl } from "@dashboard/giftCards/urls";
import { PermissionEnum } from "@dashboard/graphql";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { orderDraftListUrl, orderListUrl } from "@dashboard/orders/urls";
import { shippingZonesListUrl } from "@dashboard/shipping/urls";
import { pageListPath, inquiriesListUrl, reviewsListUrl, faqListUrl, noticeListUrl, pageListUrl } from "@dashboard/pages/urls";
import { productListPath, productListUrl } from "@dashboard/products/urls";
import { languageListUrl } from "@dashboard/translations/urls";
import { collectionListUrl as brandListUrl } from "@dashboard/brand/urls";
import {
  ConfigurationIcon,
  CustomersIcon,
  HomeIcon,
  MarketplaceIcon,
  OrdersIcon,
  ProductsIcons,
  StorefrontIcon,
  TranslationsIcon,
  VouchersIcon,
  InfoIcon,
  CatalogIcon,
  SellsIcon,
  TableEditIcon
} from "@saleor/macaw-ui-next";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { SidebarMenuItem } from "./types";
import { mapToExtensionsItems } from "./utils";
import { advertisementListUrl, customerQueriesListUrl, customerReviewsListUrl } from "@dashboard/operations/urls";
import { feeOverviewListUrl, storeListListUrl } from "@dashboard/stores/urls";
import { settlementsListListUrl } from "@dashboard/settlements/urls";
import { bannerListListUrl } from "@dashboard/banners/urls";
import { useVendor } from "@context/VendorContext";
import { getShipInfoByVid } from "@dashboard/stores/services/REST_apis/vendor";

const iconSettings = {
  color: "default2",
  size: "medium",
} as const;

export function useMenuStructure() {
  const extensions = useExtensions(extensionMountPoints.NAVIGATION_SIDEBAR);
  const intl = useIntl();
  const { user } = useUser();
  const { state } = useVendor();
  const [isStoreMaster, setIsStoreMaster] = useState(false);
  const [infoProvided, setInfoProvided] = useState({
    store: true,  // 정상적으로 있으면 true 
    // shipping: true, // 정보가 없으면 false
    contact: true,
  });

  useEffect(() => {
    if (user.isSuperuser) return;

    if (user.metadata.find((e) => e.key === "store_role").value === "master") setIsStoreMaster(true);

    const updateInfoProvided = () => {
      const contactInfo = {
        contact_name: state.detail_info.contact_name,
        contact_mobile: state.detail_info.contact_mobile,
        contact_phone: state.detail_info.contact_phone,
        contact_email: state.detail_info.contact_email,
        cs_manager_name: state.detail_info.cs_manager_name,
        cs_manager_mobile: state.detail_info.cs_manager_mobile,
        tax_invoice_manager_name: state.detail_info.tax_invoice_manager_name,
        tax_invoice_manager_mobile: state.detail_info.tax_invoice_manager_mobile,
        tax_invoice_manager_email: state.detail_info.tax_invoice_manager_email,
      };

      // storeInfo 어떤 데이터를 필수로 받을 것인지 확정 필요함
      const storeInfo = {
        store_name_ko: state.store_name_ko,
        detail_info: {
          store_category: state.detail_info.store_category,
          category: state.detail_info.category_no > 0 ? state.detail_info.fashion_category : state.detail_info.category_no_life > 0 ? state.detail_info.life_category : "",
          // gender_category: state.detail_info.gender_category,
          age_range: state.detail_info.category_no > 0 ? state.detail_info.age_range : state.detail_info.category_no_life > 0 ? state.detail_info.life_age_range : "",
          // store_style: state.detail_info.store_style,
          size_chart_id: state.detail_info.size_chart_id,
          parallel_import: state.detail_info.category_no > 0 ? state.detail_info.parallel_import : state.detail_info.category_no_life > 0 ? state.detail_info.life_parallel_import : "",
          // life_category: state.detail_info.life_category,
          // life_age_range: state.detail_info.life_age_range,
          // life_parallel_import: state.detail_info.life_parallel_import,
          sale_countries: state.detail_info.sale_countries,
          contact_name: state.detail_info.contact_name,
          contact_mobile: state.detail_info.contact_mobile,
          contact_phone: state.detail_info.contact_phone,
          e_commerce_license_number: state.detail_info.e_commerce_license_number,
          company_name: state.detail_info.company_name,
          // certification_info_1: state.detail_info.certification_info_1,
          // certification_info_2: state.detail_info.certification_info_2,
          // certification_info_3: state.detail_info.certification_info_3,
          contact_email: state.detail_info.contact_email,
          // vendor_thumbnail: state.detail_info.vendor_thumbnail,
          customer_center_info: state.detail_info.customer_center_info,
          business_location: state.detail_info.business_location
        },
      };

      // setInfoProvided로 상태 업데이트
      setInfoProvided({
        ...infoProvided,
        contact: isInfoValid(contactInfo),
        store: isInfoValid(storeInfo),
      });
    };
    if (!user.isSuperuser && state.full_info_provided === false) {
      updateInfoProvided();
    }
  }, [state])

  const isInfoValid = (info) => {
    for (const key in info) {
      if (
        info[key] === "" ||
        info[key] === null ||
        info[key] === undefined
      ) {
        return false;
      }
    }
    return true;
  }

  const appExtensionsHeaderItem: SidebarMenuItem = {
    id: "extensions",
    label: intl.formatMessage(sectionNames.appExtensions),
    type: "divider",
    paddingY: 1.5,
  };

  const getAppSection = (): SidebarMenuItem => ({
    icon: <MarketplaceIcon {...iconSettings} />,
    label: intl.formatMessage(sectionNames.apps),
    permissions: [],
    id: "apps",
    url: AppPaths.appListPath,
    type: "item",
  });

  const menuItems: SidebarMenuItem[] = [
    {
      icon: <HomeIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.home),
      id: "home",
      url: "/",
      type: "item",
    },

    // 상품관리
    {
      children: user.isSuperuser ? [
        {
          label: intl.formatMessage(sectionNames.allProducts),
          id: "products-all",
          url: productListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        // {
        //   label: intl.formatMessage(sectionNames.exportedProducts),
        //   id: "exported-products",
        //   url: productListPath + '?exported=true',
        //   permissions: [PermissionEnum.MANAGE_PRODUCTS],
        //   type: "item",
        // },
        {
          label: intl.formatMessage(sectionNames.categories),
          id: "categories",
          url: categoryListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.collections),
          id: "collections",
          url: collectionListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.brand),
          id: "brand",
          url: brandListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        ...(process.env.SHOW_GIFTCARD === 'true'
          ? [{
            label: intl.formatMessage(sectionNames.giftCards),
            id: "giftCards",
            url: giftCardListUrl(),
            permissions: [PermissionEnum.MANAGE_GIFT_CARD],
            type: "item",
          }]
          : []),
        ...mapToExtensionsItems(
          extensions.NAVIGATION_CATALOG,
          appExtensionsHeaderItem,
        ),
      ] : [
        {
          label: intl.formatMessage(sectionNames.allProducts),
          id: "products-all",
          url: productListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.collections),
          id: "collections",
          url: collectionListUrl(),
          permissions: [PermissionEnum.MANAGE_PRODUCTS],
          type: "item",
        },
        ...(process.env.SHOW_GIFTCARD === 'true'
          ? [{
            label: intl.formatMessage(sectionNames.giftCards),
            id: "giftCards",
            url: giftCardListUrl(),
            permissions: [PermissionEnum.MANAGE_GIFT_CARD],
            type: "item",
          }]
          : []),
        ...mapToExtensionsItems(
          extensions.NAVIGATION_CATALOG,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <ProductsIcons {...iconSettings} />,
      url: productListUrl(),
      label: intl.formatMessage(commonMessages.products),
      permissions: [
        PermissionEnum.MANAGE_GIFT_CARD,
        PermissionEnum.MANAGE_PRODUCTS,
      ],
      id: "products",
      type: "itemGroup",
    },

    // 주문관리
    {
      children: [
        {
          label: intl.formatMessage(commonMessages.allOrders),
          permissions: [PermissionEnum.MANAGE_ORDERS],
          id: "order-all",
          url: orderListUrl(),
          type: "item",
        },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_ORDERS,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <OrdersIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.orders),
      permissions: [PermissionEnum.MANAGE_ORDERS],
      id: "orders",
      url: orderListUrl(),
      type: "itemGroup",
    },

    // 정산 관리
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.settlementsList),
          id: "settlementsList",
          url: settlementsListListUrl(),
          type: "item",
        },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_DISCOUNTS,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <SellsIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.settlements),
      // permissions: [PermissionEnum.MANAGE_DISCOUNTS],
      url: settlementsListListUrl(),
      id: "settlements",
      type: "itemGroup",
    },

    // 고객관리
    user.isSuperuser &&
    {
      children: !isEmpty(extensions.NAVIGATION_CUSTOMERS)
        ? [
          {
            label: intl.formatMessage(sectionNames.customers),
            permissions: [PermissionEnum.MANAGE_USERS],
            id: "customers",
            url: customerListUrl(),
            type: "item",
          },
          ...mapToExtensionsItems(
            extensions.NAVIGATION_CUSTOMERS,
            appExtensionsHeaderItem,
          ),
        ]
        : [],
      icon: <CustomersIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.customers),
      permissions: [PermissionEnum.MANAGE_USERS],
      id: "customers",
      url: customerListUrl(),
      type: !isEmpty(extensions.NAVIGATION_CUSTOMERS) ? "itemGroup" : "item",
    },

    // 배너 관리
    user.isSuperuser &&
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.banners),  // banners
          id: "banners",
          url: bannerListListUrl(),
          type: "item",
        },
      ],
      icon: <TableEditIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.banners),
      permissions: [PermissionEnum.MANAGE_USERS],
      id: "banners",
      url: bannerListListUrl(),
      type: !isEmpty(extensions.NAVIGATION_CUSTOMERS) ? "itemGroup" : "item",
    },

    // 할인
    // user.isSuperuser &&
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.vouchers),
          id: "vouchers",
          url: voucherListUrl(),
          type: "item",
        },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_DISCOUNTS,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <VouchersIcon {...iconSettings} />,
      label: intl.formatMessage(commonMessages.discounts),
      permissions: [PermissionEnum.MANAGE_DISCOUNTS],
      url: saleListUrl(),
      id: "discounts",
      type: "itemGroup",
    },

    // 기존 운영관리 주석처리
    // {
    //   children: [
    //     // {
    //     //   label: intl.formatMessage(sectionNames.advertisement),
    //     //   id: "advertisement",
    //     //   url: advertisementListUrl(),
    //     //   type: "item",
    //     // },
    //     {
    //       label: intl.formatMessage(sectionNames.vouchers),
    //       id: "vouchers",
    //       url: voucherListUrl(),
    //       type: "item",
    //     },
    //     {
    //       label: intl.formatMessage(sectionNames.customerQueries),
    //       id: "customerQueries",
    //       url: customerQueriesListUrl(),
    //       type: "item",
    //     },
    //     {
    //       label: intl.formatMessage(sectionNames.customerReviews),
    //       id: "customerReviews",
    //       url: customerReviewsListUrl(),
    //       type: "item",
    //     },
    //   ],
    //   icon: <CatalogIcon {...iconSettings} />,
    //   label: intl.formatMessage(sectionNames.operations),
    //   id: "systemAdmin",
    //   url: advertisementListUrl(),
    //   type: "itemGroup",
    // },

    // 운영(콘텐츠) 관리 (마스터)
    user.isSuperuser &&
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.faq),  // faq
          id: "faq",
          url: faqListUrl(),
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.notice),  // notice
          id: "notice",
          url: noticeListUrl(),
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.inquiries),  // 문의
          id: "inquiries",
          url: inquiriesListUrl(),
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.reviews),  // 리뷰
          id: "reviews",
          url: reviewsListUrl(),
          type: "item",
        },
        // {
        //   label: intl.formatMessage(sectionNames.vouchers),
        //   id: "vouchers",
        //   url: voucherListUrl(),
        //   type: "item",
        // },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_PAGES,
          appExtensionsHeaderItem,
        ),

      ],
      icon: <CatalogIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.operations),
      permissions: [PermissionEnum.MANAGE_PAGES],  // 권한 설정
      id: "page",
      url: pageListUrl(),
      type: "itemGroup",
    },

    // 운영(콘텐츠) 관리 (셀러)
    !user.isSuperuser &&
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.inquiries),  // 문의
          id: "inquiries",
          url: inquiriesListUrl(),
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.reviews),  // 리뷰
          id: "reviews",
          url: reviewsListUrl(),
          type: "item",
        },
        // {
        //   label: intl.formatMessage(sectionNames.vouchers),
        //   id: "vouchers",
        //   url: voucherListUrl(),
        //   type: "item",
        // },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_PAGES,
          appExtensionsHeaderItem,
        ),

      ],
      icon: <CatalogIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.operations),
      permissions: [PermissionEnum.MANAGE_PAGES],  // 권한 설정
      id: "inquiries",
      url: inquiriesListUrl(),
      type: "itemGroup",
    },

    // // 쿠폰 관리
    // !user.isSuperuser &&
    // {
    //   children: [
    //     {
    //       label: intl.formatMessage(sectionNames.vouchers),
    //       id: "vouchers",
    //       url: voucherListUrl(),
    //       type: "item",
    //     },
    //   ],
    //   icon: <VouchersIcon {...iconSettings} />,
    //   label: intl.formatMessage(sectionNames.vouchers),
    //   permissions: [PermissionEnum.MANAGE_USERS],
    //   id: "vouchers",
    //   url: voucherListUrl(),
    //   type: !isEmpty(extensions.NAVIGATION_CUSTOMERS) ? "itemGroup" : "item",
    // },

    // 스토어관리(셀러)
    !user.isSuperuser && isStoreMaster && {
      children: [
        {
          label: intl.formatMessage(sectionNames.storeInfo),
          id: "storeInfo",
          url: storeInfoListUrl(),
          type: "item",
          FullinfoProvided: state.full_info_provided,
          isInfoRequired: !infoProvided.store,
        },
        {
          label: intl.formatMessage(sectionNames.manageManagers),
          id: "manageManagers",
          url: manageManagersListUrl(),
          type: "item",
        },
        {
          label: intl.formatMessage(sectionNames.contactInfo),
          id: "contactInfo",
          url: contactInfoListUrl(),
          type: "item",
          FullinfoProvided: state.full_info_provided,
          isInfoRequired: !infoProvided.contact,
        },
        {
          label: intl.formatMessage(sectionNames.shippingInfo),
          id: "shippingInfo",
          url: shippingZonesListUrl(),
          type: "item",
          // FullinfoProvided: state.full_info_provided,
          // isInfoRequired: !infoProvided.shipping,
        },
        {
          label: intl.formatMessage(sectionNames.storeSync),
          id: "storeSync",
          url: storeSyncListUrl(),
          type: "item",

        },
        {
          label: intl.formatMessage(sectionNames.feeOverview),
          id: "feeOverview",
          url: feeOverviewListUrl(),
          type: "item",
        },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_DISCOUNTS,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <InfoIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.stores),
      // permissions: [PermissionEnum.MANAGE_DISCOUNTS],
      url: storeInfoListUrl(),
      id: "storeinfo",
      type: "itemGroup",
      FullinfoProvided: state.full_info_provided,
      isInfoRequired: infoProvided.store === false || infoProvided.contact === false ? true : false,
      // isInfoRequired: infoProvided.store === false || infoProvided.contact === false || infoProvided.shipping === false ? true : false,
    },

    // 스토어관리(마스터)
    user.isSuperuser &&
    {
      children: [
        {
          label: intl.formatMessage(sectionNames.storeList),
          id: "storeList",
          url: storeListListUrl(),
          type: "item",
        },
        // {
        //   label: intl.formatMessage(sectionNames.feeOverview),
        //   id: "feeOverview",
        //   url: feeOverviewListUrl(),
        //   type: "item",
        // },
        {
          label: intl.formatMessage(sectionNames.shopifySync),
          id: "shopifySync",
          url: shopifySyncListUrl(),
          type: "item",
        },
        ...mapToExtensionsItems(
          extensions.NAVIGATION_DISCOUNTS,
          appExtensionsHeaderItem,
        ),
      ],
      icon: <InfoIcon {...iconSettings} />,
      label: intl.formatMessage(commonMessages.stores),
      // permissions: [PermissionEnum.MANAGE_DISCOUNTS],
      url: storeListListUrl(),
      id: "store",
      type: "itemGroup",
    },

    // 기존 콘텐츠 관리
    // {
    //   children: !isEmpty(extensions.NAVIGATION_PAGES)
    //     ? [
    //       ...mapToExtensionsItems(
    //         extensions.NAVIGATION_PAGES,
    //         appExtensionsHeaderItem,
    //       ),
    //     ]
    //     : [],
    //   icon: <StorefrontIcon {...iconSettings} />,
    //   label: intl.formatMessage(sectionNames.content),
    //   permissions: [PermissionEnum.MANAGE_PAGES],
    //   id: "pages",
    //   url: pageListPath,
    //   type: !isEmpty(extensions.NAVIGATION_PAGES) ? "itemGroup" : "item",
    // },

    ...(process.env.SHOW_TRANSLATIONS === 'true'
      ? [
        user.isSuperuser && {
          children: !isEmpty(extensions.NAVIGATION_TRANSLATIONS)
            ? [
              ...mapToExtensionsItems(
                extensions.NAVIGATION_TRANSLATIONS,
                appExtensionsHeaderItem,
              ),
            ]
            : [],
          icon: <TranslationsIcon {...iconSettings} />,
          label: intl.formatMessage(sectionNames.translations),
          permissions: [PermissionEnum.MANAGE_TRANSLATIONS],
          id: "translations",
          url: languageListUrl,
          type: !isEmpty(extensions.NAVIGATION_TRANSLATIONS)
            ? "itemGroup"
            : "item",
        },
      ]
      : []),
    // 앱 부분 숨기기
    // getAppSection(),
    {
      icon: <ConfigurationIcon {...iconSettings} />,
      label: intl.formatMessage(sectionNames.configuration),
      permissions: getConfigMenuItemsPermissions(intl),
      id: "configure",
      url: configurationMenuUrl,
      type: "item",
    },
  ].filter(Boolean) as SidebarMenuItem[];

  const isMenuItemPermitted = (menuItem: SidebarMenuItem) => {
    const userPermissions = (user?.userPermissions || []).map(
      permission => permission.code,
    );
    if (!menuItem?.permissions || menuItem?.permissions?.length < 1) {
      return true;
    }
    return menuItem.permissions.some(permission =>
      userPermissions.includes(permission),
    );
  };

  const getFilteredMenuItems = (menuItems: SidebarMenuItem[]) =>
    menuItems.filter(isMenuItemPermitted);

  return menuItems.reduce(
    (resultItems: SidebarMenuItem[], menuItem: SidebarMenuItem) => {
      if (!isMenuItemPermitted(menuItem)) {
        return resultItems;
      }
      const { children } = menuItem;
      const filteredChildren = children
        ? getFilteredMenuItems(children)
        : undefined;

      return [...resultItems, { ...menuItem, children: filteredChildren }];
    },
    [],
  );
}
