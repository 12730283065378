// @ts-strict-ignore
import { LazyQueryResult } from "@apollo/client/react";
import {
  extensionMountPoints,
  mapToMenuItems,
  mapToMenuItemsForProductOverviewActions,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { ListFilters } from "@dashboard/components/AppLayout/ListFilters";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import { BulkDeleteButton } from "@dashboard/components/BulkDeleteButton";
import { ButtonWithDropdown } from "@dashboard/components/ButtonWithDropdown";
import { getByName } from "@dashboard/components/Filter/utils";
import { FilterPresetsSelect } from "@dashboard/components/FilterPresetsSelect";
import { ListPageLayout } from "@dashboard/components/Layouts";
import LimitReachedAlert from "@dashboard/components/LimitReachedAlert";
import { ProductListColumns } from "@dashboard/config";
import {
  Exact,
  GridAttributesQuery,
  ProductListQuery,
  RefreshLimitsQuery,
  useAvailableColumnAttributesLazyQuery,
} from "@dashboard/graphql";
import useLocalStorage from "@dashboard/hooks/useLocalStorage";
import useNavigator from "@dashboard/hooks/useNavigator";
import { sectionNames } from "@dashboard/intl";
import {
  ChannelProps,
  FilterPageProps,
  PageListProps,
  RelayToFlat,
  SortPage,
} from "@dashboard/types";
import { hasLimits, isLimitReached } from "@dashboard/utils/limits";
import { Card } from "@material-ui/core";
import { Box, Button, ChevronRightIcon, Text } from "@saleor/macaw-ui-next";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useUser } from "@dashboard/auth";
import { ProductListUrlSortField, productUrl } from "../../urls";
import { ProductListDatagrid } from "../ProductListDatagrid";
import { ProductListTiles } from "../ProductListTiles/ProductListTiles";
import { ProductListViewSwitch } from "../ProductListViewSwitch";
import ImportProductsDialog from '../smartStore/ImportProductsDialog/ImportProductsDialog';
import {
  productListUrl,
  productSmartStoreDialogUrl
} from "@dashboard/products/urls";

import {
  createFilterStructure,
  ProductFilterKeys,
  ProductListFilterOpts,
} from "./filters";

export interface ProductListPageProps
  extends PageListProps<ProductListColumns>,
  Omit<
    FilterPageProps<ProductFilterKeys, ProductListFilterOpts>,
    "onTabDelete"
  >,
  SortPage<ProductListUrlSortField>,
  ChannelProps {
  activeAttributeSortId: string;
  currencySymbol: string;
  gridAttributesOpts: LazyQueryResult<
    GridAttributesQuery,
    Exact<{
      ids: string | string[];
    }>
  >;
  limits: RefreshLimitsQuery["shop"]["limits"];
  products: RelayToFlat<ProductListQuery["products"]>;
  selectedProductIds: string[];
  hasPresetsChanged: boolean;
  onAdd: () => void;
  onExport: () => void;
  onExportShopify: () => void;
  importProduct: () => void;
  onTabUpdate: (tabName: string) => void;
  onTabDelete: (tabIndex: number) => void;
  availableColumnsAttributesOpts: ReturnType<
    typeof useAvailableColumnAttributesLazyQuery
  >;
  onProductsDelete: () => void;
  onSelectProductIds: (ids: number[], clearSelection: () => void) => void;
  clearRowSelection: () => void;
}

export type ProductListViewType = "datagrid" | "tile";
const DEFAULT_PRODUCT_LIST_VIEW_TYPE: ProductListViewType = "datagrid";

export const ProductListPage: React.FC<ProductListPageProps> = props => {
  const {
    currencySymbol,
    defaultSettings,
    gridAttributesOpts,
    limits,
    availableColumnsAttributesOpts,
    filterOpts,
    initialSearch,
    settings,
    onAdd,
    onExport,
    onExportShopify,
    importProduct,
    onFilterChange,
    onFilterAttributeFocus,
    onSearchChange,
    onUpdateListSettings,
    selectedChannelId,
    activeAttributeSortId,
    onTabChange,
    onTabDelete,
    onTabSave,
    onAll,
    currentTab,
    tabs,
    onTabUpdate,
    hasPresetsChanged,
    selectedProductIds,
    onProductsDelete,
    clearRowSelection,
    ...listProps
  } = props;
  const intl = useIntl();
  const navigate = useNavigator();
  const filterStructure = createFilterStructure(intl, filterOpts);
  const [isFilterPresetOpen, setFilterPresetOpen] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [products, setProducts] = useState([]); // 상태 정의
  const [totalElements, setTotalElements] = useState([]); // 상태 정의
  const { user } = useUser();

  useEffect(() => {
    console.log('listProps');
    console.log(listProps);
    console.log('listProps');
  }, [listProps])

  console.log('productSmartStoreDialogUrl', productSmartStoreDialogUrl());

  // 스마트스토어 상품 가져오기 팝업 여는 함수
  const handleOpenDialog = async () => {
    setDialogOpen(true);
    setIsImporting(true);
    try {
      setIsImporting(false); // 로딩 상태 종료
    } catch (error) {
      console.error('Error importing products:', error);
      setIsImporting(false); // 에러 시에도 로딩 상태 종료
    }
  };

  // 팝업창 취소(닫기) 함수
  const handleCloseDialog = async () => {
    // 팝업창을 닫으면서 상태값 초기화
    setDialogOpen(false); // 팝업 닫기
    // closeDialogWithUrl(); // 팝업창 닫기와 URL 복귀
    await refetchProductList(); // 제품 리스트를 다시 불러오기
  };

  // 팝업창 제출 submit 함수
  const handleSubmitDialog = async () => {
    // closeDialogWithUrl(); // 팝업창 닫기와 URL 복귀
    setDialogOpen(false); // 팝업 닫기
    await refetchProductList(); // 제품 리스트를 다시 불러오기
  };

  // 제품 리스트를 다시 가져오는 함수
  const refetchProductList = async () => {
    console.log('상품리스트 새로고침');

    const currentUrlParams = new URLSearchParams(window.location.search);
    const currentAsc = currentUrlParams.get("asc") === "true";  // asc 값이 true인지 확인

    if (currentAsc) {
      navigate("/products?asc=false&sort=date");
    } else {
      navigate("/products?asc=true&sort=date");
      setTimeout(() => {
        navigate("/products?asc=false&sort=date");
      }, 10);
    }
  };

  const filterDependency = filterStructure.find(getByName("channel"));

  const limitReached = isLimitReached(limits, "productVariants");
  const { PRODUCT_OVERVIEW_CREATE, PRODUCT_OVERVIEW_MORE_ACTIONS } =
    useExtensions(extensionMountPoints.PRODUCT_LIST);

  const extensionMenuItems = mapToMenuItemsForProductOverviewActions(
    PRODUCT_OVERVIEW_MORE_ACTIONS,
    selectedProductIds,
  );
  const extensionCreateButtonItems = mapToMenuItems(PRODUCT_OVERVIEW_CREATE);

  const [storedProductListViewType, setProductListViewType] =
    useLocalStorage<ProductListViewType>(
      "productListViewType",
      DEFAULT_PRODUCT_LIST_VIEW_TYPE,
    );

  const isDatagridView = storedProductListViewType === "datagrid";

  return (
    <ListPageLayout>
      <TopNav
        withoutBorder
        isAlignToRight={false}
        title={intl.formatMessage(sectionNames.products)}
      >
        <Box
          __flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box marginX={3} display="flex" alignItems="center">
              <ChevronRightIcon />
            </Box>

            <FilterPresetsSelect
              presetsChanged={hasPresetsChanged}
              onSelect={onTabChange}
              onRemove={onTabDelete}
              onUpdate={onTabUpdate}
              savedPresets={tabs}
              activePreset={currentTab}
              onSelectAll={onAll}
              onSave={onTabSave}
              isOpen={isFilterPresetOpen}
              onOpenChange={setFilterPresetOpen}
              selectAllLabel={!location.search.includes("exported") ? intl.formatMessage({
                id: "tCLTCb",
                defaultMessage: "All products",
                description: "tab name",
              }) : intl.formatMessage({
                id: "kMg3Gl",
                defaultMessage: "Exported Products",
                description: "tab name"
              })}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            {hasLimits(limits, "productVariants") && (
              <Text variant="caption">
                {intl.formatMessage(
                  {
                    id: "Kw0jHS",
                    defaultMessage: "{count}/{max} SKUs used",
                    description: "created products counter",
                  },
                  {
                    count: limits.currentUsage.productVariants,
                    max: limits.allowedUsage.productVariants,
                  },
                )}
              </Text>
            )}
            <TopNav.Menu
              dataTestId="menu"
              items={[
                {
                  label: intl.formatMessage({
                    id: "7FL+WZ",
                    defaultMessage: "Export Products",
                    description: "export products to csv file, button",
                  }),
                  onSelect: onExport,
                  testId: "export",
                },
                ...(user.isSuperuser
                  ? [
                    {
                      label: intl.formatMessage({
                        id: "gicq2L",
                        defaultMessage: "export items to Shopify",
                        description: "export products to Shopify, button",
                      }),
                      onSelect: onExportShopify,
                      testId: "export-shopify",
                    },
                  ]
                  : [{
                    label: intl.formatMessage({
                      id: "7FL+WX",
                      defaultMessage: "Import Products from SMARTSTORE",
                      description: "import products from Smartstore.",
                    }),
                    onSelect: handleOpenDialog,
                    testId: "import",
                  },
                  {
                    label: intl.formatMessage({
                      id: "8FL+WX",
                      defaultMessage: "Import Products from CAFE24",
                      description: "import products from Cafe24.",
                    }),
                    onSelect: importProduct,
                    testId: "import",
                  },]),
                ...extensionMenuItems,
              ]}
            />


            {/* ImportProductsDialog 팝업창 렌더링 */}
            <ImportProductsDialog
              open={isDialogOpen}          // 다이얼로그 열림 상태 제어
              onClose={handleCloseDialog}  // 다이얼로그 닫기 핸들러
              onConfirm={handleSubmitDialog}  // 확인 버튼 클릭 시 호출될 함수
              loading={isImporting}        // 가져오기 중 로딩 상태 전달
            />

            {!user.isSuperuser && extensionCreateButtonItems.length > 0 ? (
              <ButtonWithDropdown
                onClick={onAdd}
                testId={"add-product"}
                options={extensionCreateButtonItems}
              >
                <FormattedMessage
                  id="JFmOfi"
                  defaultMessage="Create Product"
                  description="button"
                />
              </ButtonWithDropdown>
            ) : !user.isSuperuser && !location.search.includes("exported") ? (
              <Button data-test-id="add-product" onClick={onAdd}>
                <FormattedMessage
                  id="JFmOfi"
                  defaultMessage="Create Product"
                  description="button"
                />
              </Button>
            ) : <></>}
          </Box>
        </Box>
      </TopNav>
      {limitReached && (
        <LimitReachedAlert
          title={intl.formatMessage({
            id: "FwHWUm",
            defaultMessage: "SKU limit reached",
            description: "alert",
          })}
        >
          <FormattedMessage
            id="5Vwnu+"
            defaultMessage="You have reached your SKU limit, you will be no longer able to add SKUs to your store. If you would like to up your limit, contact your administration staff about raising your limits."
          />
        </LimitReachedAlert>
      )}
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <ListFilters
            currencySymbol={currencySymbol}
            initialSearch={initialSearch}
            onFilterChange={onFilterChange}
            onFilterAttributeFocus={onFilterAttributeFocus}
            onSearchChange={onSearchChange}
            filterStructure={filterStructure}
            searchPlaceholder={intl.formatMessage({
              id: "kIvvax",
              defaultMessage: "Search Products...",
            })}
            actions={
              <Box display="flex" gap={4}>
                {!location.search.includes("exported") && selectedProductIds.length > 0 && (
                  <BulkDeleteButton onClick={onProductsDelete}>
                    <FormattedMessage
                      defaultMessage="Delete products"
                      id="uwk5e9"
                    />
                  </BulkDeleteButton>
                )}
                <ProductListViewSwitch
                  defaultValue={storedProductListViewType}
                  setProductListViewType={props => {
                    setProductListViewType(props);
                    clearRowSelection();
                  }}
                />
              </Box>
            }
          />
        </Box>
        {isDatagridView ? (
          // 제품 리스트
          <ProductListDatagrid
            {...listProps}
            hasRowHover={!isFilterPresetOpen}
            filterDependency={filterDependency}
            activeAttributeSortId={activeAttributeSortId}
            defaultSettings={defaultSettings}
            availableColumnsAttributesOpts={availableColumnsAttributesOpts}
            loading={listProps.disabled}
            gridAttributesOpts={gridAttributesOpts}
            products={listProps.products}
            settings={settings}
            selectedChannelId={selectedChannelId}
            onUpdateListSettings={onUpdateListSettings}
            rowAnchor={productUrl}
            onRowClick={id => {
              navigate(productUrl(id));
            }}
          />
        ) : (
          <ProductListTiles
            {...listProps}
            settings={settings}
            loading={listProps.disabled}
            onUpdateListSettings={onUpdateListSettings}
            products={listProps.products}
            onTileClick={id => {
              navigate(productUrl(id));
            }}
          />
        )}
      </Card>
    </ListPageLayout>
  );
};
ProductListPage.displayName = "ProductListPage";
export default ProductListPage;
