export const sanitizeData = (data, nullToBlank) => {
    if (nullToBlank) {
        if (data === null || data === undefined) {
            return "";
        }

        if (typeof data === "object" && !Array.isArray(data)) {
            const sanitizedData = {};

            for (const key in data) {
                sanitizedData[key] = sanitizeData(data[key], true);
            }

            return sanitizedData;
        }

        if (Array.isArray(data)) {
            return data.map(item => sanitizeData(item, true));
        }

        return data;
    } else {
        if (data === "") {
            return null;
        }

        if (typeof data === "object" && !Array.isArray(data)) {
            const sanitizedData = {};

            for (const key in data) {
                sanitizedData[key] = sanitizeData(data[key], false);
            }

            return sanitizedData;
        }

        if (Array.isArray(data)) {
            return data.map(item => sanitizeData(item, false));
        }

        return data;
    }
};