import { defineMessages } from "react-intl";

export const exitFormPromptMessages = defineMessages({
  unableToSaveTitle: {
    id: "c5fFin",
    defaultMessage: "변경 사항을 저장하지 않고 나가시겠습니까?",
    description: "ExitFormPrompt title",
  },
  keepEditing: {
    id: "ySqncG",
    defaultMessage: "취소",
    description: "ExitFormPrompt keep editing button",
  },
  ignoreChanges: {
    id: "blZJmA",
    defaultMessage: "저장없이 나가기",
    description: "ExitFormPrompt ignore changes button",
  },
});
