import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ButtonGroup,
  Button,
} from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import Hr from "@dashboard/components/Hr";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import {
  extensionMountPoints,
  mapToMenuItemsForCustomerDetails,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { sectionNames } from "@dashboard/intl";
import { storeInfoListUrl } from "../../urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import useMetadataChangeTrigger from "@dashboard/utils/metadata/useMetadataChangeTrigger";

import TextFieldWithLabel from "@dashboard/tng/components/TextFieldWithLabel";
import { updateLocalStorageData, useVendor } from "@context/VendorContext";
import MenuTabs from "@dashboard/stores/components/MenuTabs";
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import { sanitizeData } from "@dashboard/stores/services/utils/data";
import { getShopifySyncInfo, getStoreSyncInfoByVid, updateShopifySyncInfo, updateVendorInfo } from "@dashboard/stores/services/REST_apis/vendor";


export interface ShopifySyncPageFormData {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  note: string;
}

export interface ShopifySyncPageProps {
  customerId: string;
  customer: CustomerDetailsQuery["user"];
  disabled: boolean;
  errors: AccountErrorFragment[];
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (data: ShopifySyncPageFormData) => SubmitPromise<AccountErrorFragment[]>;
  onDelete: () => void;
}

const initialState = {
  shopify_shop_name: "",
  shopify_api_key: "",
  shopify_admin_access_token: "",
};

const ShopifySyncPage: React.FC<ShopifySyncPageProps> = ({
  customerId,
  customer,
  disabled,
  errors,
  saveButtonBar,
  onSubmit,
  onDelete,
}: ShopifySyncPageProps) => {
  const navigator = useNavigator();
  const intl = useIntl();
  const navigate = useNavigator();
  const [formData, setFormData] = useState(initialState);

  const { user } = useUser();

  useEffect(() => {
    if (user.isSuperuser) {
      getShopifySyncData(user.email);
    } else {
      alert("접근할 수 없는 페이지입니다.")
      navigate("/")
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    })

  };

  const initialForm: ShopifySyncPageFormData = {
    email: customer?.email || "",
    firstName: customer?.firstName || "",
    isActive: customer?.isActive || false,
    lastName: customer?.lastName || "",
    note: customer?.note || "",
  };

  const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
  const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

  const handleFormData = (data) => {
    setFormData(sanitizeData(data, true));
  }

  const getShopifySyncData = useCallback(async (email) => {
    const data = await getShopifySyncInfo(email).then(data => { return data; });
    handleFormData(data);
  }, [])

  const handleOnSubmit = useCallback(() => {
    updateShopifySyncInfo({ email: user.email, ...formData })
      .then(response => {
        if (response === 200) {
          alert("등록되었습니다.");
        } else {
          alert("오류가 발생했습니다.")
        }
      });
  }, [formData])

  return (
    <Form confirmLeave initial={initialForm} onSubmit={handleOnSubmit} disabled={disabled}>
      {({ change, data, isSaveDisabled, submit }) => {

        return (
          <DetailPageLayout>
            <TopNav title={intl.formatMessage(sectionNames.shopifySync)}>
              {extensionMenuItems.length > 0 && <CardMenu menuItems={extensionMenuItems} />}
            </TopNav>
            <DetailPageLayout.Content>
              <Card>
                <CardTitle title="스토어 연동 정보" />
                <CardContent>
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="Shopify Shop Name"
                      name="shopify_shop_name"
                      value={formData.shopify_shop_name}
                      onChange={handleChange}
                      placeholder="Enter text for Shop Name"
                      gridSizes={{ xs: 12, sm: 6 }}
                    // disabled={true}
                    />
                  </Grid>
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="Shopify API Key"
                      name="shopify_api_key"
                      value={formData.shopify_api_key}
                      onChange={handleChange}
                      placeholder="Enter text for API key"
                      gridSizes={{ xs: 12, sm: 6 }}
                    // disabled={true}
                    />
                  </Grid>
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="Shopify Admin Access Token"
                      name="shopify_admin_access_token"
                      value={formData.shopify_admin_access_token}
                      onChange={handleChange}
                      placeholder="Enter text for Admin Access Token"
                      gridSizes={{ xs: 12, sm: 6 }}
                    // disabled={true}
                    />
                  </Grid>
                  <CardSpacer />
                </CardContent>
              </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
              <div></div>
            </DetailPageLayout.RightSidebar>
            <Savebar
              disabled={isSaveDisabled}
              state={saveButtonBar}
              onSubmit={submit}
              onCancel={() => navigate(storeInfoListUrl())}
              onDelete={onDelete}
            />
          </DetailPageLayout>
        );
      }}
    </Form>
  );
};

ShopifySyncPage.displayName = "ShopifySyncPage";
export default ShopifySyncPage;
