import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';

const DaumAddress = ({ onSelectAddress }) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
        script.async = true
        script.onload = () => console.log("Daum Postcode script loaded successfully.");

        document.body.appendChild(script);

        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);

    const handleOpenPostcode = () => {
        new window.daum.Postcode({
            oncomplete: onSelectAddress
        }).open();
    };

    return (
        <>
            <Button onClick={handleOpenPostcode} style={{ padding: '14px 16px', marginLeft: '8px', width: '200px', marginTop: "19.5px" }}>주소 검색</Button>
        </>
    );
};

export default DaumAddress;
