import React, { useEffect, useState, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ConfirmButton,
  ConfirmButtonTransitionState,
} from "@dashboard/components/ConfirmButton";
import ResponsiveTable from "@dashboard/components/ResponsiveTable";
import TableCellAvatar from "@dashboard/components/TableCellAvatar";
import TableRowLink from "@dashboard/components/TableRowLink";
import BackButton from "../BackButton";
import Checkbox from "../Checkbox";
import { messages } from "./messages";
import { useStyles } from "./styles";
import { getVendors } from "@dashboard/stores/services/REST_apis/vendor";

export interface AssignBrandDialogProps {
  confirmButtonState: ConfirmButtonTransitionState;
  selectedIds?: any[];
  collection?: any; 
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (data: { id: string; name: string }[]) => void;
}

const AssignBrandDialog: React.FC<AssignBrandDialogProps> = ({
  confirmButtonState,
  open,
  collection,
  loading,
  onClose,
  onSubmit,
  selectedIds = [], // 기본값으로 빈 배열 설정
}) => {
  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [query, setQuery] = useState("");
  const [channel, setChannel] = useState("all");
  const [selectedBrandIds, setSelectedBrandIds] = useState<string[]>(
    Array.isArray(selectedIds) ? selectedIds : []
  );

  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    if (collection?.slug?.includes("global")) {
      setChannel("en")
    } else {
      setChannel("ko")
    }
  }, [collection]);

  useEffect(() => {
    if (open) {
      fetchBrandList();
      setQuery("");
      setSelectedBrandIds(Array.isArray(selectedIds) ? selectedIds : []);
    }
  }, [open, channel]);

  const fetchBrandList = useCallback(async () => {
    const rawData = await getVendors([], false, false, channel);
    const brandsData = rawData.data || [];
    setBrands(brandsData);
    setFilteredBrands(brandsData);
  }, [channel]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setQuery(value);

    const filtered = brands.filter((brand) =>
      brand.store_name_ko.toLowerCase().includes(value)
    );
    setFilteredBrands(filtered);
  };

  const handleBrandSelection = (brandId: string) => {
    setSelectedBrandIds((prevSelectedIds) =>
      prevSelectedIds.includes(brandId)
        ? prevSelectedIds.filter((id) => id !== brandId)
        : [...prevSelectedIds, brandId]
    );
  };

  const handleSubmit = () => {
    const selectedBrandIdsArray = brands
      .filter((brand) => selectedBrandIds.includes(brand.id))
      .map((brand) => brand.id);
  
    onSubmit(selectedBrandIdsArray);
    handleClose();
  };
  
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle disableTypography>
        <FormattedMessage {...messages.assignVariantDialogHeader} />
      </DialogTitle>
      <DialogContent>
        <TextField
          value={query}
          onChange={handleSearchChange}
          label={intl.formatMessage(messages.assignProductDialogSearch)}
          placeholder={intl.formatMessage(messages.assignProductDialogContent)}
          fullWidth
          InputProps={{
            autoComplete: "off",
            endAdornment: loading && <CircularProgress size={16} />,
          }}
        />
      </DialogContent>
      <DialogContent>
        <ResponsiveTable>
          <TableBody>
            {filteredBrands
              .filter((brand) => !selectedIds.includes(brand.id)) // 이미 선택된 항목은 제외
              .map((brand) => {
                const isSelected = selectedBrandIds.includes(brand.id);

                return (
                  <TableRowLink
                    key={brand.id}
                    data-test-id="assign-product-table-row"
                    onClick={() => handleBrandSelection(brand.id)}
                  >
                    <TableCellAvatar
                      className={classes.avatar}
                      thumbnail={brand.vendor_thumbnail}
                    />
                    <TableCell className={classes.colName}>
                      {brand.store_name_ko}
                    </TableCell>
                    <TableCell padding="checkbox" className={classes.checkboxCell}>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                  </TableRowLink>
                );
              })}
          </TableBody>
        </ResponsiveTable>
      </DialogContent>
      <DialogActions>
        <BackButton onClick={onClose} children="취소"/>
        <ConfirmButton
          data-test-id="submit"
          transitionState={confirmButtonState}
          type="submit"
          onClick={handleSubmit}
        >
          <FormattedMessage id="jupCA2" defaultMessage="Assign Brands" />
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignBrandDialog;
