import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@saleor/macaw-ui';
import { useUser } from '@dashboard/auth';
import useNavigator from '@dashboard/hooks/useNavigator';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        marginTop: 20,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        marginBottom: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ddd',
        borderRadius: 4,
        cursor: "pointer"
    },
    label: {
        textAlign: 'center',
        marginBottom: 5,
        fontSize: 14,
        fontWeight: 500,
    },
    count: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 700,
    },
    categoryHeader: {
        textAlign: 'center',
        marginBottom: 10,
        fontSize: 18,
        fontWeight: 600,
    },
    categoryContainer: {
        marginBottom: 5,
    },
});

const Dashboard = ({ rawData }) => {
    const navigate = useNavigator();
    const classes = useStyles();
    const { user } = useUser();
    const initialState = user.isSuperuser ? [
        {
            id: 'order_status',
            elements: [
                { label: '주문건수', count: 0, link: "/orders" },
                { label: '총 주문액 (\)', count: 0, link: "/orders" },
                { label: '총 주문액 ($)', count: 0, link: "/orders" },
            ],
        },
        {
            id: 'members_status',
            elements: [
                { label: '총 회원수', count: 0, link: "/customers" },
                { label: '신규 가입', count: 0, link: "/customers" },
                // { label: '탈퇴', count: 3 },
            ],
        },
        {
            id: 'seller_status',
            elements: [
                { label: '총 스토어수', count: 0, link: "/stores/storeList" },
                { label: '신규 스토어', count: 0, link: "/stores/storeList" },
                // { label: '탈퇴', count: 0 },
            ],
        },
        {
            id: 'products_status',
            elements: [
                { label: '총 상품수', count: 0, link: "/products" },
                { label: '신규 등록', count: 0, link: "/products" },
                // { label: '삭제', count: 20 },
            ],
        },
    ] : [
        {
            id: 'order_status',
            label: '주문',
            elements: [
                { label: '주문확인', count: 0, link: '/orders' },
                { label: '배송준비중', count: 0, link: '/orders' },
                { label: '배송중', count: 0, link: '/orders' },
            ],
        },
        {
            id: 'cancel_return_status',
            label: '취소/반품',
            elements: [
                { label: '취소요청', count: 0, link: '/orders' },
                { label: '반품요청', count: 0, link: '/orders' },
                { label: '반품수거중', count: 0, link: '/orders' },
            ],
        },
        {
            id: 'exchange_status',
            label: '교환',
            elements: [
                { label: '교환요청', count: 0, link: '/orders' },
                { label: '교환수거중', count: 0, link: '/orders' },
                { label: '교환수거완료', count: 0, link: '/orders' },
                { label: '교환배송준비중', count: 0, link: '/orders' },
                { label: '교환배송중', count: 0, link: '/orders' },
            ],
        },
        {
            id: 'inquiry_status',
            label: '문의',
            elements: [
                { label: '주문문의', count: 0, link: '/pages/inquiries?pageTypes=UGFnZVR5cGU6MjA%3D&asc=true&sort=title' },
                { label: '상품문의', count: 0, link: '/pages/reviews?pageTypes=UGFnZVR5cGU6MTI%3D&asc=true&sort=title' },
                { label: '리뷰', count: 0, link: '/pages' },
            ],
        },
    ];
    const [data, setData] = useState(initialState);

    useEffect(() => {
        if (rawData) {
            setData(formattingData(rawData))
        }
    }, [rawData])

    const formattingData = (data) => {
        let total_order_amount_ko = 0
        if (data.total_order_amount_ko && data.total_order_amount_ko >= 0) {
            total_order_amount_ko = parseInt(data.total_order_amount_ko);
        }
        let total_order_amount_en = 0
        if (data.total_order_amount_en && data.total_order_amount_en >= 0) {
            total_order_amount_en = parseFloat(data.total_order_amount_en);
        }
        return user.isSuperuser ? [
            {
                id: 'order_status',
                elements: [
                    { label: '주문건수', count: data.orders_count, link: "/orders" },
                    { label: '총 주문액 (₩)', count: total_order_amount_ko, link: "/orders" },
                    { label: '총 주문액 ($)', count: total_order_amount_en, link: "/orders" },
                ],
            },
            {
                id: 'members_status',
                elements: [
                    { label: '총 회원수', count: data.members, link: "/customers" },
                    { label: '신규 가입', count: data.new_members, link: "/customers" },
                    // { label: '탈퇴', count: 0 },
                ],
            },
            {
                id: 'seller_status',
                elements: [
                    { label: '총 스토어수', count: data.stores, link: "/stores/storeList" },
                    { label: '신규 스토어', count: data.new_stores, link: "/stores/storeList" },
                    // { label: '탈퇴', count: 0 },
                ],
            },
            {
                id: 'products_status',
                elements: [
                    { label: '총 상품수', count: data.products, link: "/products" },
                    { label: '신규 등록', count: data.new_products, link: "/products" },
                    // { label: '삭제', count: 20 },
                ],
            },
        ] : [
            {
                id: 'order_status',
                label: '주문',
                elements: [
                    { label: '주문 확인', count: data.orders_unconfirmed, link: '/orders' },
                    { label: '상품 준비 중', count: data.orders_unfulfilled, link: '/orders' },
                    { label: '배송 중', count: data.orders_fulfilled, link: '/orders' },
                    { label: '배송 완료', count: data.orders_delivered, link: '/orders' },
                ],
            },
            {
                id: 'cancel_return_status',
                label: '취소/반품',
                elements: [
                    { label: '취소 요청', count: data.orders_request_cancel, link: '/orders' },
                    { label: '취소 완료', count: data.orders_canceled, link: '/orders' },
                    { label: '반품 요청', count: data.orders_request_return, link: '/orders' },
                    { label: '반품 완료', count: data.orders_returned, link: '/orders' },
                    // { label: '반품요청', count: 0 },
                    // { label: '반품수거중', count: 0 },
                ],
            },
            {
                id: 'exchange_status',
                label: '교환',
                elements: [
                    { label: '교환 요청', count: data.orders_request_replace, link: '/orders' },
                    { label: '교환 처리됨', count: data.orders_replaced, link: '/orders' },
                    // { label: '교환수거중', count: 0 },
                    // { label: '교환수거완료', count: 0 },
                    // { label: '교환배송준비중', count: 0 },
                    // { label: '교환배송중', count: 0 },
                ],
            },
            {
                id: 'inquiry_status',
                label: '문의',
                elements: [
                    // { label: '주문문의', count: 0 },
                    { label: '상품문의', count: data.pages_inquiry, link: '/pages/inquiries?pageTypes=UGFnZVR5cGU6MjA%3D&asc=true&sort=title' },
                    { label: '리뷰', count: data.pages_review, link: '/pages/reviews?pageTypes=UGFnZVR5cGU6MTI%3D&asc=true&sort=title' },
                ],
            },
        ]
    }

    const renderStatusCards = (elements) =>
        elements.map((element, index) => (
            <Grid
                item
                xs={12}
                key={index}
                {...(user.isSuperuser && { sm: 6, md: 4, lg: 4 })}
            >
                <Box className={classes.card} onClick={() => navigate(element.link)}>
                    <Typography className={classes.label}>{element.label}</Typography>
                    <Typography className={classes.count}>
                        {typeof element.count === 'number'
                            ? element.count.toLocaleString('ko-KR')
                            : element.count}
                    </Typography>
                </Box>
            </Grid>
        ));

    return (
        <Container className={classes.root}>
            <Grid container spacing={3}>
                {
                    user.isSuperuser ? (
                        data.map((category) => (
                            <Grid item xs={12} className={classes.categoryContainer} key={category.id}>
                                <Grid container spacing={2}>
                                    {renderStatusCards(category.elements)}
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        data.map((category) => (
                            <Grid item xs={12} className={classes.categoryContainer} key={category.id} sm={3}>
                                <Typography variant="h5" className={classes.categoryHeader}>
                                    {category.label}
                                </Typography>
                                <Grid container spacing={2} direction="column">
                                    {renderStatusCards(category.elements)}
                                </Grid>
                            </Grid>
                        ))
                    )
                }
            </Grid>
        </Container >
    );
};

export default Dashboard;