import React, { useEffect, useRef } from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/i18n/ko-kr';
import '@toast-ui/editor/dist/toastui-editor.css';

interface ToastEditorProps {
    body?: string;
    setBody?: (body: string) => void;
}

const ToastEditor: React.FC<ToastEditorProps> = ({ body, setBody }) => {
    const editorRef = useRef<Editor>(null);

    const onChangeGetHTML = () => {
        if (editorRef.current) {
            // 에디터에 입력된 내용을 HTML 태그 형태로 취득
            const data = editorRef.current.getInstance().getHTML();
            // Body에 담기
            setBody(data);
        }
    };

    return (
        <Editor
            toolbarItems={[
                // 툴바 옵션 설정
                ['heading', 'bold', 'italic', 'strike'],
                ['hr', 'quote'],
                ['ul', 'ol', 'task', 'indent', 'outdent'],
                ['table', 'image', 'link'],
                ['code', 'codeblock']
            ]}
            height="500px" // 에디터 창 높이
            initialEditType="wysiwyg" // 기본 에디터 타입
            initialValue={body ? body : ""}
            ref={editorRef} // ref 참조
            onChange={onChangeGetHTML} // onChange 이벤트
            hideModeSwitch={true}
        />
    );
};

export default ToastEditor;