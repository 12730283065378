import { useCloud } from "@dashboard/auth/hooks/useCloud";
import { Box, List } from "@saleor/macaw-ui-next";
import React from "react";

import { EnvironmentLink } from "./EnvironmentLink";
import { MenuItem } from "./Item";
import { useMenuStructure } from "./useMenuStructure";

import { UserControls } from "../user/Controls";

const flexWrapStyle = {
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "space-between" as const,
  height: "100%"
}
const btnWrapStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "end"
}

export const Menu = () => {
  const menuStructure = useMenuStructure();
  const { isAuthenticatedViaCloud } = useCloud();

  return (
    <Box padding={3} overflowY="auto" className="hide-scrollbar">
      <div style={flexWrapStyle}>
        <List as="ol" display="grid" gap={1} data-test-id="menu-list">
          {isAuthenticatedViaCloud && <EnvironmentLink />}
          {menuStructure.map(menuItem => (
            <MenuItem menuItem={menuItem} key={menuItem.id} />
          ))}
        </List>
        <div style={btnWrapStyle}>
          <UserControls />
        </div>
      </div>
    </Box>
  );
};
