import React, { createContext, useContext, useReducer, useEffect } from 'react';

interface VendorState {
  id: string;
  b_no: string;
  created_at: string;
  detail_info: {
    store_category: string;
    fashion_category: string;
    gender_category: string;
    age_range: string[];
    store_style: string;
    size_chart_id: string;
    parallel_import: boolean;
    life_category: string;
    life_age_range: string;
    life_parallel_import: boolean;
    sale_countries: string;
    contact_name: string;
    contact_mobile: string;
    contact_phone: string;
    e_commerce_license_number: string;
    company_name: string;
    certification_info_1: string;
    certification_info_2: string;
    certification_info_3: string;
    contact_email: string;
    cs_manager_name: string;
    cs_manager_mobile: string;
    vendor_thumbnail: string;
    tax_invoice_manager_name: string;
    tax_invoice_manager_mobile: string;
    tax_invoice_manager_email: string;
    customer_center_info: string[];
    business_location: string[];
    category_no: number;
    category_no_life: number;
  };
  etc: any;
  etc2: any;
  etc3: any;
  full_info_provided: boolean;
  introduction_en: any;
  introduction_ko: any;
  m_uid: string;
  manager_emails: any;
  shipping_info_id: string;
  store_name_en: any;
  store_name_ko: string;
  store_type: string;
  updated_at: string;
  shipping_info_updated_at: string;
}

interface Action {
  type: string;
  payload: any;
}


const initialState: VendorState = {
  id: "",
  b_no: "",
  created_at: "",
  detail_info: {
    store_category: "",
    fashion_category: "",
    gender_category: "",
    age_range: [],
    store_style: "",
    size_chart_id: "",
    parallel_import: false,
    life_category: "",
    life_age_range: "",
    life_parallel_import: false,
    sale_countries: "",
    contact_name: "",
    contact_mobile: "",
    contact_phone: "",
    e_commerce_license_number: "",
    company_name: "",
    certification_info_1: "",
    certification_info_2: "",
    certification_info_3: "",
    contact_email: "",
    cs_manager_name: "",
    cs_manager_mobile: "",
    tax_invoice_manager_name: "",
    tax_invoice_manager_mobile: "",
    tax_invoice_manager_email: "",
    vendor_thumbnail: "",
    customer_center_info: [],
    business_location: [],
    category_no: 0,
    category_no_life: 0,
  },
  etc: null,
  etc2: null,
  etc3: null,
  full_info_provided: true,
  introduction_en: null,
  introduction_ko: null,
  m_uid: "",
  manager_emails: null,
  shipping_info_id: "",
  store_name_en: null,
  store_name_ko: "",
  store_type: "",
  updated_at: "",
  shipping_info_updated_at: ""
};

const storeReducer = (state: VendorState, action: Action): VendorState => {
  switch (action.type) {
    case 'SET_VENDOR':
      return { ...state, ...action.payload };
    case 'UPDATE_DETAIL_INFO':
      return {
        ...state,
        detail_info: {
          ...state.detail_info,
          ...action.payload,
        }
      };
    default:
      return state;
  }
};

const VendorContext = createContext<{ state: VendorState; dispatch: React.Dispatch<Action> }>({
  state: initialState,
  dispatch: (action: Action) => { }
});

export const VendorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  useEffect(() => {
    const storedState = localStorage.getItem('vendor_info');
    if (storedState) {
      dispatch({ type: 'SET_VENDOR', payload: JSON.parse(storedState) });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('vendor_info', JSON.stringify(state));
  }, [state]);

  return (
    <VendorContext.Provider value={{ state, dispatch }}>
      {children}
    </VendorContext.Provider>
  );
};

export const useVendor = () => useContext(VendorContext);

export const updateVendorData = (dispatch, data, isDetail) => {
  dispatch({
    type: 'SET_VENDOR',
    payload: isDetail
      ? { detail_info: { ...data } }
      : { ...data }
  });
};

export const updateLocalStorageData = (data, isDetail) => {
  const vendorInfo = JSON.parse(localStorage.getItem("vendor_info"));
  const newVendorInfo = isDetail ? {
    ...vendorInfo,
    detail_info: {
      ...vendorInfo.detail_info,
      ...data
    }
  } : { ...vendorInfo, ...data }
  localStorage.setItem('vendor_info', JSON.stringify(newVendorInfo));
}