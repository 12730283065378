import React from 'react';
import { Box, Button } from '@material-ui/core';
import useNavigator from '@dashboard/hooks/useNavigator';

const MenuTabs = ({ vId, current }) => {
    const navigate = useNavigator();

    return (
        <Box display="flex" justifyContent="left" marginY={2} marginLeft={3}>
            <Button variant={current === 'storeInfo' ? "contained" : "outlined"} onClick={() => navigate(`/stores/${vId}`)} style={{ marginRight: '10px' }}>
                정보관리
            </Button>
            <Button variant={current === 'manageManagers' ? "contained" : "outlined"} onClick={() => navigate(`/stores/manageManagers/${vId}`)} style={{ marginRight: '10px' }}>
                매니저관리
            </Button>
            <Button variant={current === 'contactInfo' ? "contained" : "outlined"} onClick={() => navigate(`/stores/contactInfo/${vId}`)} style={{ marginRight: '10px' }}>
                담당자정보
            </Button>
            {/* <Button variant={current === 'shippingInfo' ? "contained" : "outlined"} onClick={() => navigate(`/stores/shippingInfo/${vId}`)} style={{ marginRight: '10px' }}>
                배송정보
            </Button> */}
            <Button variant={current === 'storeSync' ? "contained" : "outlined"} onClick={() => navigate(`/stores/storeSync/${vId}`)} style={{ marginRight: '10px' }}>
                스토어연동
            </Button>
            <Button variant={current === 'feeOverview' ? "contained" : "outlined"} onClick={() => navigate(`/stores/feeOverview/${vId}`)} style={{ marginRight: '10px' }}>
                수수료
            </Button>
        </Box>
    );
};

export default MenuTabs;