import api from "./client";

// 모든 배너 목록 가져오기
export const getBannerList = async () => {
    try {
        const response = await api.get(`/api-tg/vendor/banner-list`)
            .then(data => { return data.data; });
        return response;
    } catch (error) {
        console.error('Error fetching banners:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 특정 배너 세부 정보 가져오기
export const getBanner = async (id: string) => {
    try {
        const response = await api.get(`/api-tg/vendor/banner-detail/${id}`)
            .then(data => {
                return data.data;
            });
        return response;
    } catch (error) {
        console.error('Error fetching banner details:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 특정 배너 삭제하기
export const deleteBanner = async (array: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/delete-banner`,{
          delete_item: array
        })
            .then(res => {
                return res.status;
            });
        return response;
    } catch (error) {
        console.error('Error deleting banner:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 특정 배너 업데이트하기
export const modifyBanner = async (id: string, data: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/update-banner/${id}`, data)
            .then(data => {
                return data.status;
            });
        return response;
    } catch (error) {
        console.error('Error modifying banner:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 배너 생성하기
export const createBanner = async (data: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/create-banner`, data)
            .then(res => {
                return res.status;
            });
        return response;
    } catch (error) {
        console.error('Error creating banner:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 카테고리별로 그룹화된 배너 목록 가져오기
export const getBannersByCategories = async (data: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/banners/by-categories`, data)
            .then(res => {
                return res.data;
            });
        return response;
    } catch (error) {
        console.error('Error fetching banners by categories:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 특정 카테고리의 배너 목록 가져오기
export const getBannersByCategory = async (categoryId: string) => {
    try {
        const response = await api.post('/api-tg/vendor/banners/category', {
            category_id: categoryId,  // 요청 본문에 category_id를 포함
        }).then(res => {
            return res.data;
        });
        return response;
    } catch (error) {
        console.error('Error fetching banners by category:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

// 여러 배너 데이터를 업서트(upsert)하기
export const upsertBanners = async (data: any) => {
    try {
        const response = await api.post(`/api-tg/vendor/banners/upsert`, data)
            .then(res => {
                return res.data;
            });
        return response;
    } catch (error) {
        console.error('Error upserting banners:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};