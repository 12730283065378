import axios from 'axios';

//const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

//const API_URL = 'http://3.36.209.168:8000';
const API_URL = 'https://api.kistor.kr'

const normalizedAPIUrl = API_URL.replace(/\/+$/, '');

const api = axios.create({
  baseURL: normalizedAPIUrl
});

export default api;