import { OutputData } from "@editorjs/editorjs";
import { EditorCore } from "@react-editor-js/core";
import { MutableRefObject, useMemo, useRef, useState } from "react";

export interface UseRichTextOptions {
  initial: string | null | undefined;
  loading?: boolean;
  triggerChange: () => void;
}

interface UseRichTextResult {
  editorRef: MutableRefObject<EditorCore | null>;
  handleChange: () => void;
  getValue: () => Promise<OutputData>;
  defaultValue: OutputData | undefined;
  isReadyForMount: boolean;
}

export function useRichText({
  initial,
  loading,
  triggerChange,
}: UseRichTextOptions): UseRichTextResult {
  const editorRef = useRef<EditorCore | null | any>(null);
  const [isReadyForMount, setIsReadyForMount] = useState(false);

  const handleChange = () => {
    triggerChange();
  };

  const getValue = async () => {
    if (editorRef.current) {
      // 상품 description 에디터 변경을 위한 수정,
      // editorRef의 형식 점검, editorRef가 editorJS가 아니라면 HTML return
      if (!editorRef.current._editorJS) {
        const instance = editorRef.current.getInstance();
        return instance.getHTML();
      } else {
        // editorRef가 editorJS라면 
        return editorRef.current.save();
      }
    } else {
      throw new Error("Editor instance is not available");
    }
  };

  const defaultValue = useMemo<OutputData | undefined>(() => {
    if (loading) {
      return;
    }

    if (!initial) {
      setIsReadyForMount(true);
      return "";
    }

    try {
      const result = JSON.parse(initial);
      setIsReadyForMount(true);
      return result;
    } catch (e) {
      return undefined;
    }
  }, [initial, loading]);

  return {
    editorRef,
    handleChange,
    getValue,
    defaultValue,
    isReadyForMount,
  };
}

export default useRichText;
