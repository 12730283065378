import React from 'react';
import api from "../../../stores/services/REST_apis/client";
import { Typography, Button, Grid, makeStyles, Link } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CardSpacer from "@dashboard/components/CardSpacer";

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none',
  },
  button: {
    height: '100%',
    padding: '10px 16px',
  },
  textField: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  downloadIcon: {
    marginLeft: theme.spacing(1),
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: '#f5f5f5',
  },
}));

interface FileUploadSectionProps {
  title: string;
  onFileUpload: (file: File, name?: string) => void;
  name?: string;
  file?: string;
  file_name?: string;
  disabled?: boolean;
}

const FileUploadSection: React.FC<FileUploadSectionProps> = ({ title, onFileUpload, name, file, file_name, disabled }) => {
  const classes = useStyles();

  const [selectedFileName, setSelectedFileName] = React.useState<string>("");

  React.useEffect(() => {
    if (file && file_name) {
      setSelectedFileName(file_name);
    }
  }, [file, file_name]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0 && !disabled) {
      const file = event.target.files[0];
      setSelectedFileName(file.name);
      onFileUpload(file, name);
    }
  };

  const handleDownload = async () => {
    if (file && !disabled) {
        try {
            const response = await api.post('/api-tg/vendor/download-file', 
                { path: file }, 
                { responseType: 'blob' }
            );

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = selectedFileName; // 다운로드할 파일 이름
                link.click();
                window.URL.revokeObjectURL(link.href); // 메모리 해제
            } else {
                console.error('Failed to download file:', response.statusText);
            }
        } catch (error) {
            console.error('Error during file download:', error);
        }
      }
  };

  return (
    <>
      {title && onFileUpload && name && (
        <>
          <Typography>{title}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9} sm={10}>
              {selectedFileName ? (
                <div className={`${classes.textField} ${disabled ? classes.disabled : ''}`} onClick={handleDownload}>
                  <span>{selectedFileName}</span>
                  <CloudDownloadIcon className={classes.downloadIcon} />
                </div>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  선택된 파일이 없습니다.
                </Typography>
              )}
            </Grid>
            <Grid item xs={2} sm={2}>
              <input
                accept=".pdf,.jpg,.png,.jpeg,.gif,.bmp,.tiff,.webp"
                className={classes.input}
                id={`${name}-upload`}
                type="file"
                onChange={handleFileChange}
                disabled={disabled}
              />
              <label htmlFor={`${name}-upload`}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  fullWidth
                  disabled={disabled}
                >
                  파일 선택
                </Button>
              </label>
            </Grid>
          </Grid>
          <CardSpacer />
        </>
      )}
    </>
  );
};

export default FileUploadSection;
