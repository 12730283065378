import { Dialog, DialogActions, DialogContent, TextField, Button, DialogContentText } from '@material-ui/core';
import React, { useState } from 'react';
import api from '@dashboard/stores/services/REST_apis/client';

interface PopupProps {
  uid: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteStorePopup: React.FC<PopupProps> = ({ uid, open, onClose, onSubmit }) => {
  const [message, setMessage] = useState("" );

  const onChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };

  const handleClose = () => {
    setMessage("");
    onClose();
  };

  const handleDelete = async () => {
    api.post('/api-tg/vendor/reject-entry', {
      uuid: uid,
      body: message
    }).then(() => {
      alert('승인 거절되었습니다.');
      onSubmit();
    }).catch(e => {
      alert('오류가 발생했습니다.\n다시 시도해주세요.');
    });

    return;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <DialogContentText style={{ marginTop: "10px", color: "#222" }}>
          안녕하세요. 재재셀러오피스 담당자입니다.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="reason"
          name="message"
          label=""
          type="text"
          fullWidth
          multiline
          rows={8}
          placeholder="메일로 전송될 상점 삭제 안내문을 입력해주세요."
          value={message}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          취소
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained">
          메일 전송
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStorePopup;
