import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Card, CardContent, Button, Grid, TextField, Typography, makeStyles } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import CardSpacer from "@dashboard/components/CardSpacer";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import MenuTabs from "@dashboard/stores/components/MenuTabs";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import TextFieldWithLabel from '@dashboard/tng/components/TextFieldWithLabel';
import SelectField from '@dashboard/tng/components/SelectField';
import Savebar from "@dashboard/components/Savebar";
import { commonMessages, sectionNames } from "@dashboard/intl";

import { useUser } from "@dashboard/auth";
import useNavigator from "@dashboard/hooks/useNavigator";
import { useParams } from "react-router";
import { useVendor } from '@context/VendorContext';
import { storeListListUrl } from "@dashboard/stores/urls";
import { getVendorCommision, updateVendorCommision } from "@dashboard/stores/services/REST_apis/vendor";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(2),
    },
    sectionHeader: {
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    sectionTitle: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
    },
    inputGroup: {
        marginBottom: theme.spacing(2),
    },
    inputLabel: {
        fontWeight: "bold",
        marginRight: theme.spacing(2),
    },
    inputField: {
        width: "100%",
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

export interface FeeOverviewPageParam {
    id: string;
}
export interface FeeOverviewPageFormData {
    channel_id: number,
    total_rate: string,
    rate: string,
    discount_01: string,
    discount_02: string,
    category_id: string,
    discount_01_info: string,
    discount_02_info: string,
    email: string;
}

export interface FeeOverviewPageProps {
    customerId: string;
    customer: any;
    disabled: boolean;
    errors: any[];
    saveButtonBar: ConfirmButtonTransitionState;
    onSubmit: (data: FeeOverviewPageFormData) => any;
    onDelete: () => void;
}

const FeeOverviewPage: React.FC<FeeOverviewPageProps> = ({
    customerId,
    customer,
    disabled,
    errors,
    saveButtonBar,
    onSubmit,
    onDelete,
}: FeeOverviewPageProps) => {

    const initialForm: FeeOverviewPageFormData = {
        channel_id: -1,
        total_rate: '',
        rate: '',
        discount_01: '',
        discount_02: '',
        category_id: '',
        discount_01_info: '',
        discount_02_info: '',

        email: customer?.email || "",
    };

    const [formData, setFormData] = useState(initialForm);
    const intl = useIntl();
    const classes = useStyles();
    const navigate = useNavigator();
    const { state, dispatch } = useVendor()

    const { user } = useUser();
    const { id } = useParams<FeeOverviewPageParam>();

    useEffect(() => {
        if (user.isSuperuser && id) {
            getCommisionInfo(id, formData.channel_id)
        } else {
            getCommisionInfo(state.id, formData.channel_id)
        }
    }, [state, id, user.isSuperuser, formData.channel_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "rate" || name === "discount_01" || name === "discount_02") {
            const numericValue = value.replace(/[^0-9]/g, "");

            const validValue = numericValue === "" ? "" : Math.min(Math.max(parseInt(numericValue, 10), 0), 100);

            setFormData({
                ...formData,
                [name]: validValue
            });
            dispatch({
                type: 'UPDATE_FIELD',
                payload: { [name]: validValue + "%" }
            });

            const input = e.target;
            setTimeout(() => {
                input.setSelectionRange(input.value.length - 1, input.value.length - 1);
            }, 0);
        } else if (name === "channel_id") {
            setFormData({ ...formData, [name]: parseInt(value) });
            dispatch({ type: 'UPDATE_FIELD', payload: { [name]: parseInt(value) } });
        } else {
            setFormData({ ...formData, [name]: value });
            dispatch({ type: 'UPDATE_FIELD', payload: { [name]: value } });
        }
    };

    const handleFormData = (data) => {
        setFormData(formattingData(data, true));
    }

    const formattingData = (data, forDisplay) => {
        return forDisplay ? {
            ...data,
            total_rate: data.total_rate * 100,
            rate: data.rate * 100,
            discount_01: data.discount_01 * 100,
            discount_02: data.discount_02 * 100,
        } : {
            ...data,
            total_rate: data.total_rate / 100,
            rate: data.rate / 100,
            discount_01: data.discount_01 / 100,
            discount_02: data.discount_02 / 100,
        }
    }

    const getCommisionInfo = useCallback(async (id, channel_id) => {
        const data = await getVendorCommision(id, channel_id).then(response => {
            console.log(response.data);
            return response.data;
        });
        handleFormData(data);
    }, [])

    const handleSubmit = () => {
        const data = {
            ...formData,
            id: id ? id : state.id,
            email: user.email,
        }
        updateVendorCommision(formattingData(data, false))
            .then(status => {
                status === 200 ? alert("등록되었습니다.") : alert("오류가 발생했습니다.")
            });
    }

    return (
        <Form
            confirmLeave
            initial={initialForm}
            onSubmit={handleSubmit}
            disabled={disabled}
        >
            {({ change, data, isSaveDisabled, submit }) => {
                return (
                    <DetailPageLayout>
                        <TopNav title={intl.formatMessage(sectionNames.feeOverview)} />
                        <DetailPageLayout.Content>
                            {user.isSuperuser && <MenuTabs vId={id} current={'feeOverview'} />}
                            <Card>
                                <CardTitle title="수수료 적용 기준" />
                                <CardContent>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} sm={2} container justifyContent="center">
                                            <Typography variant="body1" align="center">
                                                판매 채널 선택
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <SelectField
                                                label=""
                                                placeHolder="판매 채널을 선택하세요"
                                                name="channel_id"
                                                value={`${formData.channel_id}`}
                                                onChange={handleChange}
                                                options={[
                                                    { value: '-1', label: '-', disabled: true },
                                                    { value: '3', label: '대한민국' },
                                                    { value: '8', label: '글로벌' }
                                                ]}
                                                gridSizes={{ xs: 12, sm: 12 }}
                                            // disabled={!user.isSuperuser}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}></Grid>
                                        <Grid item xs={12} sm={2} container justifyContent="center">
                                            <Typography variant="body1" align="center">
                                                적용 수수료
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                name="total_rate"
                                                variant="outlined"
                                                value={`${formData.total_rate}%`}  // 이미 %가 붙은 상태
                                                onChange={handleChange}
                                                placeholder="20%"
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}></Grid>
                                        <Grid item xs={12} sm={2} container justifyContent="center">
                                            <Typography variant="body1" align="center">
                                                기본 수수료
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                name="rate"
                                                variant="outlined"
                                                value={`${formData.rate}%`}  // 이미 %가 붙은 상태
                                                onChange={handleChange}
                                                placeholder="20%"
                                                disabled={!user.isSuperuser}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}></Grid>
                                    </Grid>
                                    <CardSpacer />
                                </CardContent>
                            </Card>
                            <Card>
                                <CardTitle title="특별할인" />
                                <CardContent>
                                    <Grid container spacing={3} alignItems="center">
                                        <Grid item xs={12} sm={2} container justifyContent="center">
                                            <Typography variant="body1" align="center">
                                                할인1
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextFieldWithLabel
                                                label="할인율"
                                                name="discount_01"
                                                value={`${formData.discount_01}%`}
                                                onChange={handleChange}
                                                placeholder="할인율"
                                                disabled={!user.isSuperuser}
                                                gridSizes={{ xs: 12, sm: 12 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextFieldWithLabel
                                                label="할인 내역"
                                                name="discount_01_info"
                                                value={formData.discount_01_info}
                                                onChange={handleChange}
                                                placeholder="할인 내역"
                                                gridSizes={{ xs: 12, sm: 12 }}
                                                disabled={!user.isSuperuser}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2} container justifyContent="center">
                                            <Typography variant="body1" align="center">
                                                할인2
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextFieldWithLabel
                                                label="할인율"
                                                name="discount_02"
                                                value={`${formData.discount_02}%`}
                                                onChange={handleChange}
                                                placeholder="할인율"
                                                gridSizes={{ xs: 12, sm: 12 }}
                                                disabled={!user.isSuperuser}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <TextFieldWithLabel
                                                label="할인 내역"
                                                name="discount_02_info"
                                                value={formData.discount_02_info}
                                                onChange={handleChange}
                                                placeholder="할인 내역"
                                                gridSizes={{ xs: 12, sm: 12 }}
                                                disabled={!user.isSuperuser}
                                            />
                                        </Grid>
                                    </Grid>
                                    <CardSpacer />
                                </CardContent>
                            </Card>
                        </DetailPageLayout.Content>
                        <DetailPageLayout.RightSidebar>
                            <></>
                        </DetailPageLayout.RightSidebar>
                        {
                            user.isSuperuser && (
                                <Savebar
                                    disabled={isSaveDisabled}
                                    state={saveButtonBar}
                                    onSubmit={submit}
                                    onCancel={() => navigate(storeListListUrl())}
                                    onDelete={onDelete}
                                />
                            )
                        }
                    </DetailPageLayout>
                );
            }}
        </Form>
    );
};


FeeOverviewPage.displayName = "FeeOverviewPage";
export default FeeOverviewPage;