import { TopNav } from "@dashboard/components/AppLayout";
import CardSpacer from "@dashboard/components/CardSpacer";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { Button, Card, CardContent, Divider, makeStyles, } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from 'react-router';
import NoticeDetail from "./NoticeDetail";
import useNavigator from "@dashboard/hooks/useNavigator";
import { useUser } from "@dashboard/auth";
import NoticeDataTable from "@dashboard/tng/components/DataTable/NoticeDataTable";
import { deleteNotice, getNotice, getNoticeList } from "@dashboard/stores/services/REST_apis/notice";

export interface HomeNoticeParam {
    id: string
};

export interface stroeNoticePageProps {
};

interface noticeData {
    id: string;
    title: string;
    content: string;
    writer_name: string;
    writer_email: string;
    created_at: any;
}

const useStyles = makeStyles({
    addButton: {
        backgroundColor: '#252929',
        border: '1px solid #252929',
        color: '#ffffff',
        marginBottom: '16px',
        alignSelf: 'flex-end',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#252929',
        },
    },
    deleteButton: {
      backgroundColor: '#f50057',
      border: '1px solid #f50057',
      color: '#ffffff',
      marginBottom: '16px',
      alignSelf: 'flex-end',
      '&:hover': {
          backgroundColor: '#ffffff',
          color: '#f50057',
      },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

const HomeNoticePage: React.FC<stroeNoticePageProps> = () => {
    const { user } = useUser();
    const { id } = useParams<HomeNoticeParam>();
    const navigate = useNavigator();
    const location = useLocation();
    const isDetailPage = location.pathname.includes('notice/detail');
    const isNoticePage = location.pathname.includes('notice/list');
    const classes = useStyles();

    const [isLoaded, setIsLoaded] = useState(false);

    const [data, setData] = useState<noticeData>({
        id: '',
        title: '',
        content: '',
        writer_name: '',
        writer_email: '',
        created_at: '',
    });
    const [noticeList, setNoticeList] = useState<noticeData[]>([]);
    const columns = [
        { id: 'title', label: '제목', minWidth: 100 },
        { id: 'writer_name', label: '작성자', minWidth: 50 },
        { id: 'created_at', label: '작성일자', minWidth: 50 },
    ];
    const storedVendorData = JSON.parse(localStorage.getItem('vendor_info'));
    const vendorId = storedVendorData?.id;

    useEffect(() => {
        if (vendorId) {
            getNoticeDataList(vendorId);
        }
    }, []);

    useEffect(() => {
        setIsLoaded(false);
        if (id !== undefined) {
            getNoticeData(id);
        }
    }, [id]);

    const toList = useCallback(() => {
        getNoticeDataList(vendorId);
        setData({
            id: '',
            title: '',
            content: '',
            writer_name: '',
            writer_email: '',
            created_at: '',
        });
        navigate('/notice/list');
    }, [navigate, vendorId]);

    const toWrite = useCallback(() => {
        navigate('/notice/write');
    }, [navigate]);

    const toModify = useCallback(() => {
        navigate(`/notice/modify/${id}`);
    }, [id, navigate]);

    const deleteData = useCallback(() => {
        deleteNotice(id).then(status => {
            if (status === 200 || status === 204) {
                alert("성공적으로 삭제되었습니다.");
                toList();
            } else {
                alert("오류가 발생했습니다.");
            }
        });
    }, [id, toList]);

    const handleNoticeData = (data: noticeData) => {
        const dateCreatedAt = new Date(data.created_at);
        setData({
            ...data,
            created_at: dateCreatedAt.toLocaleString(),
        });
    };

    const handleNoticeListData = (data: noticeData[]) => {
        const formattingData = data.map(e => ({
            ...e,
            created_at: new Date(e.created_at).toLocaleDateString(),
        }));
        setNoticeList(formattingData);
    };

    const getNoticeData = useCallback(async (id: string) => {
        const data: noticeData = await getNotice(id).then(data => { return data; });
        handleNoticeData(data);
        setIsLoaded(true);
    }, []);

    const getNoticeDataList = useCallback(async (vid: string) => {
        const list = await getNoticeList(vid).then(data => data.data);
        handleNoticeListData(list);
    }, []);

    return (
        <DetailPageLayout withSavebar={false}>
            <TopNav title="대시보드 > 공지사항">
            </TopNav>
            <DetailPageLayout.Content>
                {
                    isDetailPage ? (
                        <Card>
                            <CardContent>
                                {
                                    isLoaded && (
                                        <NoticeDetail data={data} />
                                    )
                                }
                            </CardContent>
                            <CardSpacer />
                            <CardContent className={classes.buttonContainer}>
                              {user.isSuperuser && (
                                  <>
                                    <Button
                                        className={classes.deleteButton}
                                        variant="contained"
                                        style={{ marginRight: '10px' }}
                                        onClick={deleteData}
                                    >
                                        삭제
                                    </Button>
                                    <Button
                                        className={classes.addButton}
                                        variant="contained"
                                        style={{ marginRight: '10px' }}
                                        onClick={toModify}
                                    >
                                        수정
                                    </Button>
                                  </>
                              )}
                              <Button
                                  className={classes.addButton}
                                  variant="contained"
                                  onClick={toList}
                              >
                                  목록
                              </Button>
                            </CardContent>
                            <Divider />
                        </Card>
                    ) : <></>
                }
                {
                  isNoticePage ? (
                  <Card>
                    <CardContent>
                        <NoticeDataTable 
                            columns={columns} 
                            data={noticeList} 
                            defaultRowsPerPage={id == undefined ? 10 : 5}
                            vid={vendorId}
                            onRefresh={getNoticeDataList}
                        />
                    </CardContent>
                    <CardContent>
                        <div className={classes.buttonContainer}>
                            {
                                user.isSuperuser && (
                                    <Button className={classes.addButton} variant="contained" style={{ marginRight: '10px' }} onClick={toWrite}>
                                        공지생성
                                    </Button>
                                )
                            }
                            {
                                id && (
                                    <Button className={classes.addButton} variant="contained" onClick={toList}>
                                        목록
                                    </Button>
                                )
                            }
                        </div>
                    </CardContent>
                </Card> ) : <></>
                }
                <Card>
                </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
                <div></div>
            </DetailPageLayout.RightSidebar>
        </DetailPageLayout>
    );
};
HomeNoticePage.displayName = "HomeNoticePage";
export default HomeNoticePage;