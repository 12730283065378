import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

const StoreSection = "/stores/";

const createSectionUrl = (section) => urlJoin(StoreSection, section);

export const storeListSection = createSectionUrl("storeList");
export const storeInfoSection = createSectionUrl("storeInfo");
export const manageManagersSection = createSectionUrl("manageManagers");
export const contactInfoSection = createSectionUrl("contactInfo");
export const shippingInfoSection = createSectionUrl("shippingInfo");
export const storeSyncSection = createSectionUrl("storeSync");
export const feeOverviewSection = createSectionUrl("feeOverview");
export const shopifySyncSection = createSectionUrl("shopifySync");

const createListUrl = (sectionUrl) => (params = {}) => {
  const queryString = stringifyQs(params);
  return queryString ? `${sectionUrl}?${queryString}` : sectionUrl;
};

export const storeListListUrl = createListUrl(storeListSection);
export const storeInfoListUrl = createListUrl(storeInfoSection);
export const manageManagersListUrl = createListUrl(manageManagersSection);
export const contactInfoListUrl = createListUrl(contactInfoSection);
export const shippingInfoListUrl = createListUrl(shippingInfoSection);
export const storeSyncListUrl = createListUrl(storeSyncSection);
export const feeOverviewListUrl = createListUrl(feeOverviewSection);
export const shopifySyncListUrl = createListUrl(shopifySyncSection);

export const storeListPath = (id) => urlJoin(storeListSection, id);
export const storeInfoPath = (id) => urlJoin(StoreSection, id);
export const manageManagersPath = (id) => urlJoin(manageManagersSection, id);
export const contactInfoPath = (id) => urlJoin(contactInfoSection, id);
export const shippingInfoPath = (id) => urlJoin(shippingInfoSection, id);
export const storeSyncPath = (id) => urlJoin(storeSyncSection, id);
export const feeOverviewPath = (id) => urlJoin(feeOverviewSection, id);
export const shopifySyncPath = (id) => urlJoin(shopifySyncSection, id);

export const storeListUrl = (id, params) =>
  storeListPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const storeInfoUrl = (id, params) =>
  storeInfoPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const manageManagersUrl = (id, params) =>
  manageManagersPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const contactInfoUrl = (id, params) =>
  contactInfoPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const shippingInfoUrl = (id, params) =>
  shippingInfoPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const storeSyncUrl = (id, params) =>
  storeSyncPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const feeOverviewUrl = (id, params) =>
  feeOverviewPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
export const shopifySyncUrl = (id, params) =>
  shopifySyncPath(encodeURIComponent(id)) + "?" + stringifyQs(params);
