import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Card, CardContent, Button, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Select, MenuItem } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardSpacer from "@dashboard/components/CardSpacer";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import { useUser } from "@dashboard/auth";
import { sectionNames } from "@dashboard/intl";
import DeleteStorePopup from './popup/DeleteStorePopup';
import useNavigator from "@dashboard/hooks/useNavigator";
import api from '@dashboard/stores/services/REST_apis/client';

import { getVendors } from "@dashboard/stores/services/REST_apis/vendor";
import moment from "moment-timezone";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    height: '50px',
  },
  rowOdd: {
    backgroundColor: '#f9f9f9',
  },
  rowEven: {
    backgroundColor: '#ffffff',
  },
  button: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  pagination: {
    '& .MuiIconButton-root': {
      backgroundColor: '#f5f5f5',
      color: '#3f51b5',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: '#adb5bd'
    },
    '& .MuiIconButton-root:not(.Mui-disabled)': {
      border: '1px solid #96B7E5'
    },
    '& .MuiIconButton-root:not(.Mui-disabled):hover': {
      backgroundColor: '#96B7E5',
    },
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
  imageCell: {
    paddingRight: '0 !important',
    textAlign: "center"
  },
  image: {
    maxWidth: '50px',
    maxHeight: '100px',
    display: 'block',
    margin: '0 auto',
  },
});

interface vendorData {
  id: string; 
  m_uid: string;
  store_name_ko: string;
  master_first_name: string;
  created_at: string;
  is_active: boolean;
  is_confirmed: boolean;
  master_email: string;
}

const StoreListPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [vendors, setVendors] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<null | vendorData>(null);
  const { user } = useUser();
  const superAdmin = user.isSuperuser;

  useEffect(() => {
    getVendorList();
  }, []);

  const handleVendorList = (data) => {
    setVendors(data);
  };

  const handleClickOpen = (vendor) => {
    setSelectedVendor(vendor);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVendor(null);
  };

  const getVendorList = useCallback(async () => {
    const rawData = await getVendors([], false, superAdmin).then(data => data);
    const newData = formatData(rawData.data);
    handleVendorList(newData);
  }, []);

  const columns = [
    { id: 'vendor_thumbnail', label: '', minWidth: 10, align: 'center' },
    { id: 'store_name_ko', label: '상점명', minWidth: 10, align: 'center' },
    { id: 'master_first_name', label: '관리자ID', minWidth: 50, align: 'center' },
    { id: 'introduction_ko', label: '설명', minWidth: 100, align: 'center' },
    { id: 'created_at', label: '등록일시', minWidth: 50, align: 'center' },
    { id: 'master_email', label: '연락처', minWidth: 50, align: 'center' },
    { id: 'is_confirmed', label: '상점상태', minWidth: 10, align: 'center' },
    { id: 'action', label: '처리', minWidth: 20, align: 'center', isButton: true },
  ];

  const formatData = (data) => {
    return data.map(v => {
      const createdAt = moment(v.created_at);
      const confirmState = v.is_confirmed && v.is_active
        ? "진열중"
        : v.is_confirmed && !v.is_active
        ? "진열중지"
        : !v.is_confirmed && !v.is_active
        ? "미승인"
        : "에러";

      return {
        ...v,
        created_at: createdAt.format('YYYY-MM-DD'),
        is_confirmed: confirmState,
        checked: false,
      };
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleApprove = (data) => {
    api.post('/api-tg/vendor/approve-entry', {
      uuid: data.m_uid,
    }).then(() => {
      alert('승인 완료되었습니다.');
      getVendorList();
    }).catch(e => {
      alert('오류가 발생했습니다.\n다시 시도해주세요.');
    });
  };

  const handleStatusChange = (e, vendor) => {
    console.log(user)
    api.post('/api-tg/vendor/state-change', {
      vid: vendor.id,
      email: user.email,
    }).then(() => {
      getVendorList();
    }).catch(e => {
      alert('오류가 발생했습니다.\n다시 시도해주세요.');
    });
  }

  const handleDeleteStore = () => {
    alert('기능 개발중')
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <DetailPageLayout withSavebar={false} fullScreen={true}>
      <TopNav title={intl.formatMessage(sectionNames.storeList)} />
      <DetailPageLayout.Content>
        <Card>
          <CardTitle title={`등록된 상점 총 ${vendors.length}개`} />
          <CardContent>
            <Paper>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align as "left" | "right" | "inherit" | "center" | "justify" || "left"} 
                          className={classes.headerCell}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex}
                        className={rowIndex % 2 === 0 ? classes.rowEven : classes.rowOdd}
                        onClick={() => {
                          if (!open) { 
                            navigate(`/stores/${row.id}`);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {columns.map((column) => {
                          let value;
                          if(column.id != 'action') {
                            value = row[column.id];
                          } else {
                            value = row['is_confirmed']
                          }
                          const isThumbnail = column.id.includes("thumbnail");

                          return (
                            <TableCell
                              key={column.id}
                              align={column.align as "left" | "right" | "inherit" | "center" | "justify" || "left"} 
                              className={`${classes.ellipsis} ${isThumbnail ? classes.imageCell : ''}`}
                            >
                              {column.id === "is_confirmed" ? (
                                value === "미승인" ? (
                                  <div>{value}</div>
                                ) : (
                                  <Select
                                    value={value}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      handleStatusChange(event, row);
                                    }}
                                    onClick={(event) => event.stopPropagation()} 
                                    variant="outlined"
                                    style={{ 
                                      width: "130px",  
                                      padding: "5px 12px",   
                                      height: "40px",     
                                      lineHeight: "1.2", 
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200,   
                                        },
                                      },
                                    }}
                                  >
                                    <MenuItem value="진열중">진열중</MenuItem>
                                    <MenuItem value="진열중지">진열중지</MenuItem>
                                  </Select>
                                )
                              ) : column.id === "action" ? (
                                value === "진열중지" ? (
                                  <Button
                                    variant="contained"
                                    className={classes.button}
                                    style={{ backgroundColor: '#f44336', width: '100%' }} 
                                    onClick={(event) => {
                                      event.stopPropagation(); // 셀 클릭 이벤트 전파 중지
                                      handleDeleteStore()
                                    }}
                                  >
                                    삭제
                                  </Button>
                                ) : value === "미승인" ? (
                                  <div>
                                    <Button
                                      variant="contained"
                                      className={classes.button}
                                      style={{ backgroundColor: '#3f51b5', marginRight: "5px", width: '48%' }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // 셀 클릭 이벤트 전파 중지
                                        handleApprove(row);
                                      }}
                                    >
                                      승인
                                    </Button>
                                    <Button
                                      variant="contained"
                                      className={classes.button}
                                      style={{ backgroundColor: '#3f51b5', width: '48%' }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // 셀 클릭 이벤트 전파 중지
                                        handleClickOpen(row);
                                      }}
                                    >
                                      거절
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      maxWidth: "200px", 
                                      wordWrap: "break-word", 
                                      whiteSpace: "normal", 
                                    }}
                                  >
                                    상점상태를 ‘진열중지’로 변경 시 [삭제]버튼이 활성화됩니다.
                                  </div>
                                )
                              ) : isThumbnail ? (
                                <img
                                  src={value}
                                  alt=""
                                  className={classes.image}
                                  onError={(e) => e.currentTarget.style.display = 'none'}
                                />
                              ) : (
                                value || '-'  
                              )}
                              {column.id === "action" && selectedVendor && selectedVendor.id === row.id && (
                                <DeleteStorePopup uid={selectedVendor.m_uid} open={open} onClose={handleClose} onSubmit={() => getVendorList()}/>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={vendors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{ root: classes.pagination }}
              />
            </Paper>
            <CardSpacer />
          </CardContent>
        </Card>
      </DetailPageLayout.Content>
    </DetailPageLayout>
  );
};

export default StoreListPage;
