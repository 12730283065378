import React from "react";
import { Route, Switch } from "react-router-dom";
import { useIntl } from "react-intl";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import { settlementsListListAddUrl, settlementsListListUrl } from "./urls";

import SettlementsListPage from "./components/SettlementsListPage";

const SettlementsSection: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <WindowTitle title={intl.formatMessage({ id: "settlements.section.title", defaultMessage: "Settlements" })} />
      <Switch>
        <Route exact path={settlementsListListUrl()} component={SettlementsListPage} />
      </Switch>
    </>
  );
};

export default SettlementsSection;
