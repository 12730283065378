import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ButtonGroup,
  Button,
} from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import Hr from "@dashboard/components/Hr";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import {
  extensionMountPoints,
  mapToMenuItemsForCustomerDetails,
  useExtensions,
} from "@dashboard/apps/hooks/useExtensions";
import { sectionNames } from "@dashboard/intl";
import { storeListListUrl } from "../../urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";
import useMetadataChangeTrigger from "@dashboard/utils/metadata/useMetadataChangeTrigger";

import TextFieldWithLabel from "@dashboard/tng/components/TextFieldWithLabel";
import { updateLocalStorageData, useVendor } from "@context/VendorContext";
import MenuTabs from "@dashboard/stores/components/MenuTabs";
import { useUser } from "@dashboard/auth";
import { useParams } from "react-router";
import { sanitizeData } from "@dashboard/stores/services/utils/data";
import { getStoreSyncInfoByVid, updateVendorInfo } from "@dashboard/stores/services/REST_apis/vendor";

export interface StoreSyncPageParam {
  id: string;
}
export interface StoreSyncPageFormData {
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  note: string;
}

export interface StoreSyncPageProps {
  customerId: string;
  customer: CustomerDetailsQuery["user"];
  disabled: boolean;
  errors: AccountErrorFragment[];
  saveButtonBar: ConfirmButtonTransitionState;
  onSubmit: (data: StoreSyncPageFormData) => SubmitPromise<AccountErrorFragment[]>;
  onDelete: () => void;
}

const initialState = {
  cafe24_sync_info: {
    mall_id: "",
    client_id: "",
    client_secret: ""
  },
  smartstore_sync_info: {
    client_id: "",
    client_secret: ""
  },
  easypay_sync_info: {
    mall_id: ""
  }
};

const StoreSyncPage: React.FC<StoreSyncPageProps> = ({
  customerId,
  customer,
  disabled,
  errors,
  saveButtonBar,
  onSubmit,
  onDelete,
}: StoreSyncPageProps) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [formData, setFormData] = useState(initialState);
  const [selectedOption, setSelectedOption] = useState("cafe24");

  const { user } = useUser();
  const { id } = useParams<StoreSyncPageParam>();
  const { state, dispatch } = useVendor();
  const [vid, setVId] = useState("");

  useEffect(() => {
    if (user.isSuperuser && id) {
      // 슈퍼 유저일 때 데이터 로드
      getStoreSyncData(id);
      setVId(id);
    } else {
      // 일반 유저일 때 데이터 로드
      getStoreSyncData(state.id);
      setVId(state.id);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    // 재귀적으로 상태를 업데이트하는 함수
    const updateState = (obj, keys, value) => {
      if (keys.length === 1) {
        value === 'true' ? value = true : value === 'false' ? value = false : value
        obj[keys[0]] = value;
      } else {
        const key = keys.shift();
        if (!obj[key]) obj[key] = {};  // 중첩된 객체가 없으면 새로 생성
        updateState(obj[key], keys, value);
      }
    };

    // formData를 복사하여 새로운 상태로 만듦
    const newFormData = { ...formData };
    updateState(newFormData, keys, value);

    // 상태 업데이트 및 dispatch 호출
    setFormData(newFormData);
  };

  const initialForm: StoreSyncPageFormData = {
    email: customer?.email || "",
    firstName: customer?.firstName || "",
    isActive: customer?.isActive || false,
    lastName: customer?.lastName || "",
    note: customer?.note || "",
  };

  const { makeChangeHandler: makeMetadataChangeHandler } = useMetadataChangeTrigger();
  const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
  const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

  const handleFormData = (data) => {
    const sanitizedData = {
      cafe24_sync_info: {
        mall_id: data.cafe24_sync_info.mall_id ? data.cafe24_sync_info.mall_id : "",
        client_id: data.cafe24_sync_info.client_id ? data.cafe24_sync_info.client_id : "",
        client_secret: data.cafe24_sync_info.client_secret ? data.cafe24_sync_info.client_secret : ""
      },
      smartstore_sync_info: {
        client_id: data.smartstore_sync_info.client_id ? data.smartstore_sync_info.client_id : "",
        client_secret: data.smartstore_sync_info.client_secret ? data.smartstore_sync_info.client_secret : ""
      },
      easypay_sync_info: {
        mall_id: data.easypay_sync_info.mall_id ? data.easypay_sync_info.mall_id : ""
      }
    }
    setFormData(sanitizedData);
  }

  const getStoreSyncData = useCallback(async (id) => {
    const data = await getStoreSyncInfoByVid(id).then(data => { return data.data; });
    handleFormData(data);
  }, [])

  const handleOnSubmit = useCallback(() => {
    updateVendorInfo({ id: vid, detail_info: sanitizeData(formData, false) })
      .then(response => {
        if (response.status === 200) {
          alert("등록되었습니다.");
          dispatch({
            type: 'SET_VENDOR',
            payload: response.data,
          });
          dispatch({
            type: 'UPDATE_DETAIL_INFO',
            payload: response.data.detail_info,
          });  // 상태를 업데이트하고 useVendor의 dispatch를 호출
          updateLocalStorageData(response.data, false);
        } else {
          alert("오류가 발생했습니다.")
        }
      });
  }, [formData])

  const renderContent = () => {
    if (selectedOption === "cafe24") {
      return (
        <>
          <Grid container spacing={3}>
            <TextFieldWithLabel
              label="카페24 Mall ID"
              name="cafe24_sync_info.mall_id"
              value={formData.cafe24_sync_info.mall_id}
              onChange={handleChange}
              placeholder="Enter text for mail ID"
              gridSizes={{ xs: 12, sm: 6 }}
            // disabled={true}
            />
          </Grid>
          <Grid container spacing={3}>
            <TextFieldWithLabel
              label="카페24 Client ID"
              name="cafe24_sync_info.client_id"
              value={formData.cafe24_sync_info.client_id}
              onChange={handleChange}
              placeholder="Enter text for client ID"
              gridSizes={{ xs: 12, sm: 6 }}
            // disabled={true}
            />
          </Grid>
          <Grid container spacing={3}>
            <TextFieldWithLabel
              label="카페24 client Secret"
              name="cafe24_sync_info.client_secret"
              value={formData.cafe24_sync_info.client_secret}
              onChange={handleChange}
              placeholder="Enter text for client secret"
              gridSizes={{ xs: 12, sm: 6 }}
            // disabled={true}
            />
          </Grid>
        </>
      );
    } else if (selectedOption === "smartstore") {
      return (
        <>
          <Grid container spacing={3}>
            <TextFieldWithLabel
              label="스마트스토어 Client ID"
              name="smartstore_sync_info.client_id"
              value={formData.smartstore_sync_info.client_id}
              onChange={handleChange}
              placeholder="Enter text for client ID"
              gridSizes={{ xs: 12, sm: 6 }}
            // disabled={true}
            />
          </Grid>
          <Grid container spacing={3}>
            <TextFieldWithLabel
              label="스마트스토어 Client Secret"
              name="smartstore_sync_info.client_secret"
              value={formData.smartstore_sync_info.client_secret}
              onChange={handleChange}
              placeholder="Enter text for mail ID"
              gridSizes={{ xs: 12, sm: 6 }}
            // disabled={true}
            />
          </Grid>
        </>
      );
    }
  };

  return (
    <Form confirmLeave initial={initialForm} onSubmit={handleOnSubmit} disabled={disabled}>
      {({ change, data, isSaveDisabled, submit }) => {
        const changeMetadata = makeMetadataChangeHandler(change);

        return (
          <DetailPageLayout>
            <TopNav title={intl.formatMessage(sectionNames.storeSync)}>
              {extensionMenuItems.length > 0 && <CardMenu menuItems={extensionMenuItems} />}
            </TopNav>
            <DetailPageLayout.Content>
              {user.isSuperuser && <MenuTabs vId={id} current={"storeSync"} />}
              <Card>
                <CardContent>
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Button onClick={() => setSelectedOption("cafe24")} variant={selectedOption === "cafe24" ? "contained" : "outlined"}>
                      카페24
                    </Button>
                    <Button onClick={() => setSelectedOption("smartstore")} variant={selectedOption === "smartstore" ? "contained" : "outlined"}>
                      스마트스토어
                    </Button>
                  </ButtonGroup>
                </CardContent>
              </Card>
              <Card>
                <CardTitle title="스토어 연동 정보" />
                <CardContent>
                  {renderContent()}
                  <CardSpacer />
                </CardContent>
              </Card>
              {/* <Hr />
              <Card>
                <CardTitle title="스토어 연동" />
                <CardContent>
                  <CardSpacer />
                  <Grid container spacing={3}>
                    <TextFieldWithLabel
                      label="신청 상태"
                      name="detail_info.contact_phone"
                      value={formData.detail_info.contact_phone}
                      onChange={handleChange}
                      placeholder="Customer Center Number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                    <TextFieldWithLabel
                      label="연동 카테고리"
                      name="detail_info.contact_phone"
                      value={formData.detail_info.contact_phone}
                      onChange={handleChange}
                      placeholder="Customer Center Number"
                      gridSizes={{ xs: 12, sm: 6 }}
                    />
                  </Grid>
                  <CardSpacer />
                </CardContent>
              </Card> */}
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
              <div></div>
            </DetailPageLayout.RightSidebar>
            <Savebar
              disabled={isSaveDisabled}
              state={saveButtonBar}
              onSubmit={submit}
              onCancel={() => navigate(storeListListUrl())}
              onDelete={onDelete}
            />
          </DetailPageLayout>
        );
      }}
    </Form>
  );
};

StoreSyncPage.displayName = "StoreSyncPage";
export default StoreSyncPage;
