import zzLogo from "@assets/zz/kistore_logo.svg";
import { Avatar, Box, Text } from "@saleor/macaw-ui-next";
import React from "react";

export const MountingPoint = () => {
  const logo2 = zzLogo;

  return (
    <Box display="flex" gap={3} paddingX={4} paddingTop={5} alignItems="center" justifyContent="center">
      <img src={logo2} width="140px"></img>
    </Box>
  );
};
