import Skeleton from "@dashboard/components/Skeleton";
import { Text } from "@saleor/macaw-ui-next";
import { Button } from "@dashboard/components/Button";
import { useUser } from "@dashboard/auth";
import React from "react";
import { FormattedMessage } from "react-intl";
import { TGswitchLink } from "@scripts/global"

interface HomeHeaderProps {
  userName: string;
}

const handleLinkClick = (e, keyword) => {
  e.preventDefault();
  
  TGswitchLink(keyword)
};

export const HomeHeader: React.FC<HomeHeaderProps> = ({
  userName,
}: HomeHeaderProps) => {
  const { user } = useUser();
  const superAdmin = user.isSuperuser;

  return (
    <div data-test-id="home-header">
      <Text
        variant="heading"
        lineHeight="captionSmall"
        size="small"
        as="h4"
        data-test-id="welcome-header"
      >
        {userName ? (
          <FormattedMessage
            id="By5ZBp"
            defaultMessage="Hello there, {userName}"
            description="header"
            values={{
              userName,
            }}
          />
        ) : (
          <Skeleton style={{ width: "10em" }} />
        )}
      </Text>
      <Text variant="caption" size="large">
        {userName ? (
          <FormattedMessage
            id="aCX8rl"
            defaultMessage="Here is some information we gathered about your store"
            description="subheader"
          />
        ) : (
          <Skeleton style={{ width: "10em" }} />
        )}
      </Text>

      {/* { superAdmin &&
      <Button
        style={{ float: "right", marginTop: "6px" }}
        data-test-id="create-warehouse"
        variant="primary"
        onClick={(e) => handleLinkClick(e, 'APPROVE')}
      >
        <FormattedMessage
          id="Test"
          defaultMessage="입점승인 페이지 이동"
          description="description"
        />
      </Button>
      } */}
    </div>
  );
};
