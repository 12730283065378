import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

const OAuthCallback = () => {
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const authCode = params.get("code");

        if (authCode) {
            // 부모 창에 인증 코드를 전달하고 팝업을 닫음
            window.opener.postMessage({ authCode }, "*");
            window.close();
        } else {
            alert("오류가 발생했습니다.\n새로고침 후 다시 시도해 주세요.")
            window.close();
        }
    }, [location]);

    return <div>처리 중...</div>;
};

export default OAuthCallback;
