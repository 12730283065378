import { UserContextError } from "@dashboard/auth/types";
import { passwordResetUrl } from "@dashboard/auth/urls";
import { Button } from "@dashboard/components/Button";
import { FormSpacer } from "@dashboard/components/FormSpacer";
import { AvailableExternalAuthenticationsQuery } from "@dashboard/graphql";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import { commonMessages } from "@dashboard/intl";
import {
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { EyeIcon, IconButton } from "@saleor/macaw-ui";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useStyles from "../styles";
import LoginForm, { LoginFormData } from "./form";
import { getErrorMessage } from "./messages";
import { TGswitchLink } from "@scripts/global"



export interface LoginCardProps {
  errors: UserContextError[];
  disabled: boolean;
  loading: boolean;
  externalAuthentications?: AvailableExternalAuthenticationsQuery["shop"]["availableExternalAuthentications"];
  onExternalAuthentication: (pluginId: string) => void;
  onSubmit: (event: LoginFormData) => SubmitPromise;
}

const LoginPage: React.FC<LoginCardProps> = props => {

  const handleLinkClick = (e, keyword) => {
    e.preventDefault();
    
    TGswitchLink(keyword)
  };

  const {
    errors,
    disabled,
    loading,
    externalAuthentications = [],
    onExternalAuthentication,
    onSubmit,
  } = props;

  const classes = useStyles(props);
  const useStyles2 = makeStyles({
    btnWrap: {
      display: "flex",
      justifyContent: "end",
      '& a': {
      textDecoration: "none",
        '&:hover': {
          textDecoration: "underline",
          color: "#932EF4"
        },
      }
    },
    btn: {
      padding: "7px 16px",
    },
    primary: {
      backgroundColor: "#932EF4",
      color: "#fff",
      '&:hover': {
        border: "1px solid #932EF4",
        color: "#fff",
      },
    },
    subPrimary: {
      backgroundColor: "#fff",
      border: "1px solid #932EF4",
      color: "#932EF4",
      '&:hover': {
        border: "1px solid #932EF4",
        backgroundColor: "#932EF4",
        color: "#fff",
      },
    },
    division: {
      fontSize: "13px",
      color: "#6c757d",
      width: "100%",
      textAlign: "center",
      marginTop: "10px",
      '&:before, &:after': {
        content: '""',
        width: "30%",
        marginBottom: "4px",
        height: "1px",
        backgroundColor: "#d9dee3",
        display: "inline-block"
      },
      '&:before': {
        marginRight: "6px"
      },
      '&:after': {
        marginLeft: "6px"
      },
    }
  });
  const zaeClasses = useStyles2();
  
  const intl = useIntl();
  const [showPassword, setShowPassword] = React.useState(false);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={128} />
      </div>
    );
  }

  return (
    <LoginForm onSubmit={onSubmit}>
      {({ change: handleChange, data, submit }) => (
        <>
          {/* <button type="button" onClick={setVendorInfo}>Update Details</button>
          <p>Login</p>
          <p>{state.store_name_ko}</p> */}
          <Typography variant="h3" className={classes.header}>
            <FormattedMessage
              id="vzgZ3U"
              defaultMessage="Sign In"
              description="card header"
            />
          </Typography>
          {errors.map(error => (
            <div
              className={classes.panel}
              key={error}
              data-test-id="login-error-message"
            >
              {getErrorMessage(error, intl)}
            </div>
          ))}
          <TextField
            autoFocus
            fullWidth
            autoComplete="username"
            label={intl.formatMessage(commonMessages.email)}
            name="email"
            onChange={handleChange}
            value={data.email}
            inputProps={{
              "data-test-id": "email",
              spellCheck: false,
            }}
            disabled={disabled}
          />
          <FormSpacer />
          <div className={classes.passwordWrapper}>
            <TextField
              fullWidth
              autoComplete="password"
              label={intl.formatMessage({
                id: "5sg7KC",
                defaultMessage: "Password",
              })}
              name="password"
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              value={data.password}
              inputProps={{
                "data-test-id": "password",
                spellCheck: false,
              }}
              disabled={disabled}
            />
            {/* Not using endAdornment as it looks weird with autocomplete */}
            <IconButton
              className={classes.showPasswordBtn}
              variant="ghost"
              hoverOutline={false}
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
            >
              <EyeIcon />
            </IconButton>
          </div>
          <div className={zaeClasses.btnWrap}>
            {/* 입점승인 테스트 버튼 */}
            {/* <Typography
              component={Link}
              className={classes.link}
              to={passwordResetUrl}
              variant="body2"
              data-test-id="reset-password-link"
              onClick={(e) => handleLinkClick(e, 'APPROVE')}
            >
              <FormattedMessage
                id="Test"
                defaultMessage="입점승인"
                description="description"
              />
            </Typography> */}

            {/* 아이디/비밀번호 찾기 버튼 */}
            <Typography
              component={Link}
              className={classes.link}
              to={passwordResetUrl}
              variant="body2"
              style={{marginLeft: "10px"}}
              data-test-id="reset-password-link"
              onClick={(e) => handleLinkClick(e, 'FIND_ID')}
            >
              <FormattedMessage
                id="3tbL7x"
                defaultMessage="Forgot password?"
                description="description"
              />
            </Typography>
            {/* 회원가입 버튼 */}
            <Typography
              component={Link}
              className={classes.link}
              variant="body2"
              to=""
              style={{marginLeft: "10px"}}
              onClick={(e) => handleLinkClick(e, 'REGISTER')}
            >
              <FormattedMessage
                id="TTKhHR"
                defaultMessage="Register"
                description="description"
              />
            </Typography>
          </div>
          
          {/* 로그인 버튼 */}
          <div className={classes.buttonContainer}>
            <Button
              className={`${classes.loginButton} ${zaeClasses.btn} ${zaeClasses.primary}`}
              disabled={disabled}
              onClick={submit}
              type="submit"
              data-test-id="submit"
            >
              <FormattedMessage
                id="AubJ/S"
                defaultMessage="Sign in"
                description="button"
              />
            </Button>
          </div>
          <div className={zaeClasses.division}>
            <FormattedMessage
              id="6PVAt5"
              defaultMessage="Are you not a seller?"
              description="entry button division"
            />
            
          </div>
          {/* 입점신청 버튼 */}
          <div className={classes.buttonContainer} style={{marginTop: "10px"}}>
            <Button
              className={`${classes.loginButton} ${zaeClasses.btn} ${zaeClasses.subPrimary}`}
              disabled={disabled} 
              onClick={(e) => handleLinkClick(e, 'ENTRY_APPLY')}
            >
              <FormattedMessage
                id="2VDD4H"
                defaultMessage="Apply entry"
                description="button"
              />
            </Button>
          </div>
          {externalAuthentications.length > 0 && (
            <>
              <FormSpacer />
              <Divider />
              <FormSpacer />
              <Typography>
                <FormattedMessage
                  id="aFU0vm"
                  defaultMessage="or continue with"
                  description="description"
                />
              </Typography>
            </>
          )}
          {externalAuthentications.map(externalAuthentication => (
            <React.Fragment key={externalAuthentication.id}>
              <FormSpacer />
              <Button
                fullWidth
                variant="secondary"
                onClick={() =>
                  onExternalAuthentication(externalAuthentication.id)
                }
                data-test-id="external-authentication"
                disabled={disabled}
              >
                {externalAuthentication.name}
              </Button>
            </React.Fragment>
          ))}
        </>
      )}
    </LoginForm>
  );
};
LoginPage.displayName = "LoginPage";
export default LoginPage;
