import { TopNav } from '@dashboard/components/AppLayout';
import CardSpacer from '@dashboard/components/CardSpacer';
import { DetailPageLayout } from '@dashboard/components/Layouts';
import useNavigator from '@dashboard/hooks/useNavigator';
import { Box, Button, Card, CardContent, Container, Typography } from '@material-ui/core'
import React from 'react'

const FullInfoProvidedFalsePage: React.FC = () => {
    const navigate = useNavigator();
    return (
        <DetailPageLayout withSavebar={false}>
            <TopNav title="">
            </TopNav>
            <DetailPageLayout.Content>
                <Card>
                    <CardContent>
                        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
                            <Box>
                                <Typography variant="h4" component="h2" gutterBottom>
                                    스토어 기본 정보 입력 후 정상적인 운영이 가능합니다.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    스토어관리 {`>`} 정보관리 {`>`} 고객센터/배송 입력 정보를 확인해 주세요.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={() => navigate('/stores/storeInfo')}>
                                    데이터 입력 페이지로 이동
                                </Button>
                            </Box>
                        </Container>
                    </CardContent>
                    <CardSpacer />
                </Card>
            </DetailPageLayout.Content>
            <DetailPageLayout.RightSidebar>
                <div></div>
            </DetailPageLayout.RightSidebar>
        </DetailPageLayout>
    )
}

export default FullInfoProvidedFalsePage;