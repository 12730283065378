import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

const settlementsSection = "/settlements/";

const createSectionUrl = (section) => urlJoin(settlementsSection, section);

export const settlementsListSection = createSectionUrl("settlementsListList");

const createListUrl = (sectionUrl) => (params = {}) => {
  const queryString = stringifyQs(params);
  return queryString ? `${sectionUrl}?${queryString}` : sectionUrl;
};

export const settlementsListListUrl = createListUrl(settlementsListSection);

export const settlementsListListPath = (id) => urlJoin(settlementsListSection, id);

export const settlementsListUrl = (id, params) =>
  settlementsListListPath(encodeURIComponent(id)) + "?" + stringifyQs(params);

export const settlementsListListAddPath = urlJoin(settlementsListSection, "add");

export const settlementsListListAddUrl = (params) =>
  settlementsListListAddPath + "?" + stringifyQs(params);