import { defineMessages } from "react-intl";

export const orderGrantedRefundsMessages = defineMessages({
  grantedRefunds: {
    defaultMessage: "요청된 환불",
    id: "OAcl78",
    description: "order, granted refunds card, title",
  },
  unsettled: {
    defaultMessage: "미처리 {money}",
    id: "7t+oJp",
    description: "orde, granted refunds card, unsettled amount",
  },
});
