import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  TablePagination,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { productBulkCreate2 } from "@dashboard/products/mutations";
import api from "@dashboard/stores/services/REST_apis/client";
import { useMutation } from '@apollo/client';
import { useVendor } from "@context/VendorContext";
import { useUser } from "@dashboard/auth";

const CustomTablePagination = styled(TablePagination)({
  '& .MuiTablePagination-actions button.Mui-disabled': { // 비활성화
    color: 'rgba(211, 212, 212, 1)',
    background: '#f5f5f5',
  },
  '&.MuiTablePagination-root': {
    display: 'block !important',

  },
  '& .MuiIconButton-root': {
    // 호버 시 스타일 적용
    '&:hover': {
      backgroundColor: 'white', // 호버 시 배경을 흰색으로
      color: 'black', // 호버 시 아이콘 색상을 검정색으로
      border: '1px solid black', // 호버 시 테두리를 검정색으로 설정
    },
    
  },
  '& .MuiIconButton-colorInherit': {
    color: 'white',
    background: 'black'
  }
  
});

// ImportProductsDialogProps 타입 정의
interface ImportProductsDialogProps {
  open: boolean;  // 다이얼로그가 열렸는지 여부
  onClose: () => void;  // 다이얼로그를 닫는 함수
  onConfirm: () => void;  // 다이얼로그를 닫는 함수
  // 필요한 props를 여기에 추가하세요
}

const ImportProductsDialog: React.FC<ImportProductsDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10); // 한 페이지에 표시할 상품의 수를 나타내는 상태
  const [page, setPage] = useState(0); // 현재 페이지 번호를 나타내는 상태
  const [products, setProducts] = useState([]); // 상태 정의
  const [loadingProducts, setLoadingProducts] = useState(false);  // 상품 조회 로딩 상태
  const [loadingSubmit, setLoadingSubmit] = useState(false); // 상품 등록 로딩 상태

  // 페이지네이션 관련 상태 변수 추가
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalElements, setTotalElements] = useState(0); // 전체 상품 개수 상태

  const [bulkCreate] = useMutation(productBulkCreate2);
  const { user } = useUser();
  const { state } = useVendor();

  // 컴포넌트가 마운트될 때 handleImport를 실행하기 위한 useEffect 추가
  useEffect(() => {
    if (open) {
      // 팝업이 열릴 때마다 상태를 초기화
      setSelectedProducts([]);
      setPage(0);
      setPageIndex(1);
      setRowsPerPage(1);
      // handleImport();
    }
  }, [open]); // 상태 변경 시 handleImport 함수가 실행됩니다.

  useEffect(() => {
    if (open) {
      setLoadingProducts(true);  // 상품 조회 시작 시 로딩 상태 활성화
      onImport(setProducts, setTotalElements, pageIndex, pageSize).finally(() => {
        setLoadingProducts(false);  // 조회가 끝나면 로딩 상태 비활성화
      });
    }
  }, [pageIndex, pageSize, open]); // 상태 변경 시 handleImport 함수가 실행됩니다.

  // 함수 시작
  // 429 에러 발생 시 대기 후 재시도하는 함수
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // api 인스턴스에 인터셉터 등록
  api.interceptors.response.use(
    (response) => response, // 응답이 성공적일 때 처리
    async (error) => {
      const { config, response } = error;
      const maxRetries = 3; // 최대 재시도 횟수
      const retryDelay = 1000; // 재시도 간격 (밀리초)
      await wait(retryDelay * Math.pow(2, config._retryCount)); // 기하급수적으로 대기 시간 증가
      // 429 에러가 발생하고 재시도 가능할 경우
      if (response && response.status === 429 && config._retryCount < maxRetries) {
        config._retryCount = config._retryCount || 0;
        config._retryCount += 1;

        console.warn(`429 Too Many Requests: Waiting for ${retryDelay} ms before retrying...`);
        await wait(retryDelay * config._retryCount); // 재시도 간격 증가

        // 요청 재시도
        return api(config);
      }

      // 재시도가 불가능하거나 최대 재시도 횟수에 도달한 경우
      return Promise.reject(error);
    }
  );

  // 상품정보 json Data 변환
  const formatChannelProductsToJsonData = (channelProductsData, channelProductNos) => {
    return channelProductsData.flatMap((product, index) => {
      // console.log("Processing product:", product);  // 상품 데이터 확인

      const optionStandards = product.originProduct?.detailAttribute?.optionInfo?.optionStandards || [];

      // 대표 이미지와 모든 이미지 URL을 배열로 추출
      const representativeImage = product.originProduct?.images?.representativeImage?.url || null; // 대표 이미지
      const productImages = product.originProduct?.images?.optionalImages?.map(image => image.url) || [];

      // 대표 이미지가 있을 경우, 배열의 첫 번째 요소로 추가
      if (representativeImage) {
        productImages.unshift(representativeImage);
      }

      // channelProductNos에서 대응하는 product_id 할당
      const channelProductId = channelProductNos[index] || "X";

      // 고유한 랜덤 ID를 생성하는 함수
      const generateRandomId = () => {
        return `${Date.now()}-${Math.floor(Math.random() * 100000)}`;  // Date.now()와 Math.random()을 결합한 고유 ID 생성
      };

      // 옵션이 없으면 기본 상품만 추가
      if (optionStandards.length === 0) {
        return {
          product_id: channelProductId,  // channelProductNo 또는 기본값 설정
          product_name: product.originProduct?.name || "Unnamed Product",  // null 또는 빈 값일 경우 기본값 제공
          product_price: product.originProduct?.salePrice || 0,  // 가격도 기본값 설정
          product_description: product.originProduct?.detailContent || "",  // 상품 설명 기본값 설정
          variant_name: "default_name",  // 옵션이 없으므로 기본 이름 설정
          variant_sku: "",  // 임의의 고유 ID 생성
          product_media: productImages,  // 상품 이미지 (대표 이미지 포함)
        };
      }

      // 옵션이 있는 경우, 옵션 수대로 상품을 생성
      return optionStandards.map(option => {
        return {
          product_id: channelProductId,  // channelProductNo 또는 기본값 설정
          product_name: product.originProduct?.name || "Unnamed Product",  // null 또는 빈 값일 경우 기본값 제공
          product_price: product.originProduct?.salePrice || 0,  // 가격도 기본값 설정
          product_description: product.originProduct?.detailContent || "",  // 상품 설명 기본값 설정
          variant_name: option.optionName1 || "default_name",  // 옵션 이름
          variant_sku: option.id || generateRandomId(),  // 임의의 고유 ID 생성
          product_media: productImages,  // 상품 이미지 (대표 이미지 포함)
        };
      });
    });

  };

  const onImport = async (setProducts, setTotalElements, pageIndex, pageSize) => {
    try {

      const timestamp = Date.now(); // 밀리초 단위 타임스탬프 생성
  
      // 토큰 발급 요청 함수 정의
      const getAccessToken = async () => {
        try {
          const storedVendorData = JSON.parse(localStorage.getItem("vendor_info"));
          const vendorId = storedVendorData?.id;
      
          if (!vendorId) {
            alert("스토어 정보가 없습니다.");
            return;
          }
      
          const response = await api.post(
            "/api-tg/post-token/",
            {
              timestamp: timestamp, // 초 단위 타임스탬프 사용
              type: "SELF",
              vid: vendorId
              // vid: "VD_20240719_9iQBGRwNbz",
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
      
          return response.data;
        } catch (error) {
          // 서버로부터 받은 에러 응답을 체크
          onClose();
          if(error.response && error.response.data && error.response.data.error) {
            alert(error.response.data.error);
            return
          }
        }
      };
  
      // 토큰 재발급 요청 함수 정의
      const refreshAccessToken = async (refreshToken) => {
        const response = await api.post("/api-tg/refresh-token", {
          refresh_token: refreshToken,
        }, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
  
        return response.data;
      };
  
      // 토큰 발급
      let tokenData = await getAccessToken();
      let { access_token, refresh_token } = tokenData;
  
      // 상품 목록 조회 API 요청
      const productResponse = await api.post("/api-tg/post-products/", {
        access_token: access_token, // 발급된 Access Token 전달
        page_index: pageIndex, // 페이지 번호
        page_size: pageSize, // 한 페이지 최대 상품 개수
      }, {
        headers: {
          "Authorization": `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (productResponse.status === 401) {
        console.log("액세스 토큰이 만료되었습니다. 갱신을 시도합니다...");
        const refreshedTokenData = await refreshAccessToken(refresh_token);
        access_token = refreshedTokenData.access_token;
      }
  
      const productList = productResponse.data.contents || [];
      const totalElements = productResponse.data.totalElements || 0; // 전체 상품 개수
  
      // totalElements 값을 상태에 저장
      setTotalElements(totalElements);
  
      console.log("Product productResponse:", productResponse);
      console.log("Product List:", productList);
  
      // 전체 상품 목록에서 channelProductNo를 추출
      const channelProductNos = productList.flatMap(product =>
        product.channelProducts.map(channelProduct => channelProduct.channelProductNo)
      );

      console.log('channelProductNos', channelProductNos);
  
      // 캐시를 저장할 Map 생성
      const cachedChannelProducts = new Map();

      // 여러 채널 상품 조회 함수 정의 (캐시 적용)
      const getChannelProducts = async (channelProductNos, access_token) => {
        const channelProductResponses = [];
        const nonCachedProductNos = channelProductNos.filter(no => !cachedChannelProducts.has(no)); // 캐시에 없는 상품 번호만 요청

        // 캐시에 없는 상품 번호만 API 호출
        for (const channelProductNo of nonCachedProductNos) {
          try {
            const channelProductResponse = await api.get(`/api-tg/get-channel-product/`, {
              params: {
                access_token: access_token,
                channelProductNo: channelProductNo,
              },
              headers: {
                "Authorization": `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            });

            // 성공적으로 받아온 상품을 캐시에 저장
            cachedChannelProducts.set(channelProductNo, channelProductResponse.data);
            console.log('cachedChannelProducts', cachedChannelProducts);
            
            // 요청 간 간격을 200ms로 설정
            // await new Promise(resolve => setTimeout(resolve, 100));
          } catch (error) {
            console.error(`Error fetching product for channelProductNo: ${channelProductNo}`, error);
          }
        }

        // 캐시된 데이터와 새로 받아온 데이터를 결합하여 반환
        const channelProductsData = channelProductNos.map(no => cachedChannelProducts.get(no)); 
        return channelProductsData;
      };

      // 추출한 channelProductNos 배열 및 access_token을 사용하여 데이터 가져오기
      const channelProductsData = await getChannelProducts(channelProductNos, access_token);
      console.log("Channel Product Data:", channelProductsData);
  
      // channelProductsData를 jsonData 형식으로 변환
      const formattedProductList = channelProductsData.flatMap((product, index) => ({
        product_id: channelProductNos[index] || "",  // channelProductNos에서 값 할당, 없을 경우 기본값 사용
        product_name: product.originProduct?.name || "Unnamed Product",  // null 또는 빈 값일 경우 기본값 제공
        product_price: product.originProduct?.salePrice || 0,  // 기본값 설정
      }));
  
      const formattedJsonData = formatChannelProductsToJsonData(channelProductsData, channelProductNos);
      console.log("Formatted JSON Data:", formattedJsonData);
      console.log("formattedProductList:", formattedProductList);
      setProducts(formattedProductList);  // 가져온 데이터를 상태에 저장
  
      return formattedJsonData;
    } catch (error) {
      console.error("Error fetching token, product list, or channel product data:", error);
      throw error;
    }
  };
  
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = products.map((product) => product.product_id);
      setSelectedProducts(newSelecteds);
      return;
    }
    setSelectedProducts([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, product_id: string) => {
    const selectedIndex = selectedProducts.indexOf(product_id);
    let newSelected: string[] = [];
  
    if (selectedIndex === -1) {
      // 선택되지 않은 상품을 선택 상태로 변경
      newSelected = [...selectedProducts, product_id];
    } else {
      // 이미 선택된 상품을 선택 해제
      newSelected = selectedProducts.filter(id => id !== product_id);
    }
  
    setSelectedProducts(newSelected);
  };
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPageIndex(newPage + 1); // 페이지 인덱스는 1부터 시작하므로 newPage에 1을 더합니다.
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0);
    setPageIndex(1); // 페이지를 첫 페이지로 초기화
    setPageSize(newPageSize); // 페이지 크기 상태 업데이트
  };

  const isSelected = (product_id: string) => selectedProducts.indexOf(product_id) !== -1;

  // 상품 등록 함수
  const handleBulkCreate = async (jsonData) => {
    try {
      setLoadingSubmit(true);  // 등록 시작 시 로딩 상태 활성화
      const response = await bulkCreate({
        variables: {
          jsonData: JSON.stringify(jsonData),
          vid: user.isSuperuser ? 'VD_MASTER' : state.id,
        },
      });
      console.log(response.data.productBulkCreate2);
    } catch (error) {
      console.error('Error during product bulk creation:', error);
    } finally {
      setLoadingSubmit(false);  // 등록이 끝나면 로딩 상태 비활성화
    }
  };

  // 상품 불러온 후에 상품등록하는 함수
  const onImportProduct = async () => {
    try {
      // 1. 상품 데이터를 먼저 불러옵니다.
      const formattedJsonData = await onImport(setProducts, setTotalElements, pageIndex, pageSize);  // onImport의 반환값을 받아옵니다.
      console.log('추출된 상품 데이터:', formattedJsonData);
  
      // 2. 중복된 상품 제거 (product_id 기준)
      const uniqueProducts = Array.from(new Set(formattedJsonData.map(product => product.variant_sku)))
      .map(sku => formattedJsonData.find(product => product.variant_sku === sku));

      console.log('중복 제거된 상품 데이터:', uniqueProducts);

      // 선택된 상품만 필터링
      const filteredProducts = formattedJsonData.filter(product =>
        selectedProducts.includes(product.product_id)
      );

      console.log('선택된 상품 데이터:', filteredProducts);
  
      // 3. 불러온 데이터를 사용해 BulkCreate 실행
      if(filteredProducts.length === 0) {
        alert('추가할 상품을 선택해주세요');
      }else {
        await handleBulkCreate(filteredProducts);  // 중복 제거된 데이터를 BulkCreate에 전달
        onConfirm(); 
      }
  
    } catch (error) {
      console.error('Error during product import or bulk create process:', error);
    }
  };
  
  // 컴포넌트 시작
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle style={{ fontWeight: 'bold'}}>
        Import Products from SmartStore
      </DialogTitle>
      <DialogContent style={{ padding: '35px 24px' }}>
        {loadingProducts || loadingSubmit  ? (
          <CircularProgress style={{ color: 'blue' }} />  // 상품 조회 중 파란색 로딩 스피너
        ) : (
          <>
            <Typography style={{ marginBottom: '16px' }}>
              총 {totalElements}개의 상품을 불러왔습니다.
            </Typography>
            <TableContainer>
              <Table style={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                        checked={products.length > 0 && selectedProducts.length === products.length}
                        onChange={handleSelectAllClick}
                        inputProps={{ 'aria-label': 'select all products' }}
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#757575' }}>product_id</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#757575' }}>product_name</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: '#757575' }}>product_price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products
                    .map((product, index) => {
                      const isItemSelected = isSelected(product.product_id);
                      const labelId = `import-products-checkbox-${product.product_id}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, product.product_id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={`${product.product_id}-${index}`} // 중복 방지를 위해 index 추가
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row">
                            {product.product_id}
                          </TableCell>
                          <TableCell>{product.product_name}</TableCell>
                          <TableCell>{product.product_price.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW'})}</TableCell>
                          {/* <TableCell>{product.product_price.toLocaleString('en-US', { style: 'currency', currency: 'USD'})}</TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomTablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={totalElements} // 총 상품 개수
              rowsPerPage={pageSize} // 현재 보이는 상품 개수
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={onClose} color="primary" style={{ fontWeight: 'bold' }}>
            취소
          </Button>
          <Button
          onClick={onImportProduct}
          color="primary"
          variant="contained"
          style={{ fontWeight: 'bold' }}
          disabled={loadingSubmit}
          >
            {loadingSubmit ? 'Loading...' : 'submit'}  
          </Button>
      </DialogActions>

    </Dialog>
  );
};

export default ImportProductsDialog;
