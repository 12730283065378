import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Box, Text } from "@saleor/macaw-ui-next";
import { Card } from '@material-ui/core';
import useNavigator from "@dashboard/hooks/useNavigator";
import DataTable from "@dashboard/tng/components/DataTable";

interface HomeTopProductsCardProps {
    data?: any[];
}

interface TopProductsData {
    rank: number;
    product_name: string;
    sales_volume: number;
}

export const HomeTopProductsCard = ({
    data,
}: HomeTopProductsCardProps) => {
    const navigate = useNavigator();
    const [topProductsData, setTopProductsData] = useState<TopProductsData[]>([]);

    useEffect(() => {
        setTopProductsData(formattingData(data))
    }, [data])

    const columns: Column[] = [
        { id: 'rank', label: '순위', minWidth: 10 },
        { id: 'product_name', label: '상품명', minWidth: 50 },
        { id: 'sales_volume', label: '판매량', minWidth: 50 },
    ];

    // const dummyData: TopProductsData[] = [
    //     { rank: 1, product_name: "test1", sales_volume: 100 },
    //     { rank: 2, product_name: "test2", sales_volume: 67 },
    //     { rank: 3, product_name: "test3", sales_volume: 50 },
    //     { rank: 4, product_name: "test4", sales_volume: 7 },
    //     { rank: 5, product_name: "tes5t", sales_volume: 3 },
    // ]
    const emptyData: {}[] = [
        { product_name: "해당하는 데이터가 존재하지 않습니다." },
    ]

    const formattingData = (data) => {
        return data.map((data, index) => {
            return {
                rank: index + 1,
                product_name: data.product__name,
                sales_volume: data.checkout_count,
            }
        })
    }

    return (
        <Box>
            <Box paddingTop={7} marginBottom={2}>
                <Text variant="heading" display="block">
                    Top Products
                </Text>
            </Box>
            <Box>
                {
                    topProductsData.length > 0 ? (
                        <Card>
                            <DataTable columns={columns} data={topProductsData} defaultRowsPerPage={5} tablePaginationing={false} />
                        </Card>
                    ) : (
                        <Card>
                            <DataTable columns={columns} data={emptyData} defaultRowsPerPage={5} tablePaginationing={false} />
                        </Card>
                    )
                }
            </Box>
        </Box>
    );
};

HomeTopProductsCard.displayName = "HomeTopProductsCard";
export default HomeTopProductsCard;
