import api from './client';

export const getAccessToken = async (vid, OAuthCode) => {
    try {
        const response = await api.post('/api-tg/import/cafe24/get-access-token', { vid: vid, authorization_code: OAuthCode })
            .then(response => {
                return response
            });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getCafe24Info = async (vid, access_token) => {
    try {
        const response = await api.post('/api-tg/import/cafe24/get-cafe24-info', { vid: vid, access_token: access_token })
            .then(response => {
                return response
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        return error;
    }
};
export const getCafe24ProductsList = async (vid, access_token, offset) => {
    try {
        const response = await api.post('/api-tg/import/cafe24/get-products-list', { vid: vid, access_token: access_token, offset: offset })
            .then(response => {
                return response
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const getCafe24ProductsInfo = async (vid, access_token, productNo: string[]) => {
    try {
        const response = await api.post('/api-tg/import/cafe24/get-products-info', { vid: vid, access_token: access_token, product_no: productNo })
            .then(response => {
                return response
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};