import React from "react";
import { vars } from "@saleor/macaw-ui-next";
import { makeStyles } from "@saleor/macaw-ui";
import { useUser } from "@dashboard/auth";

interface UserProfileProps {
  url?: string;
  initials?: string;
  className?: string;
  thumbnail?: string;
}

const useStyles = makeStyles(
  theme => ({
    tmpContainer: {
      background: "#ffffff",
      border: `1px solid ${vars.colors.border.default1}`,
      height: 150,
      justifySelf: "start",
      overflow: "hidden",
      position: "relative",
      transition: theme.transitions.duration.standard + "s",
      width: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    imagePreview: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      color: "#ccc",
    },
  }),
  { name: "UserProfile" },
);

const avatarStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '150px',
  width: '150px',
  backgroundColor: "var(--mu-colors-background-accent1)",
  color: "#fff",
  fontSize: "18px",
  fontWeight: 500,
};

export const UserProfile: React.FC<UserProfileProps> = ({
  initials,
  thumbnail,
  className,
}) => {
  const classes = useStyles();
  const { user } = useUser();
  

  return (
    <div className={className} style={avatarStyle}>
      {!user.isSuperuser && thumbnail ? (
        <div className={classes.tmpContainer}>
          <img src={thumbnail} alt="Avatar" className={classes.imagePreview} />
        </div>
      ) : (
        <span>{initials}</span>
      )}
    </div>
  );
};

export default UserProfile;
