import React, { useCallback, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, makeStyles, TablePagination, Checkbox } from '@material-ui/core';

interface DataTableProps {
  columns: Column[];
  data: Data[];
  rowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  rowOnClick?: (id) => void;
  tablePaginationing?: boolean;
  tableCellSelectable?: boolean;
  selectedRows?: any[]
  handleCheckboxClick?: (id) => void;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    height: '50px',
  },
  rowOdd: {
    backgroundColor: '#f9f9f9',
  },
  rowEven: {
    backgroundColor: '#ffffff',
  },
  button: {
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  pagination: {
    '& .MuiIconButton-root': {
      backgroundColor: '#f5f5f5',
      color: '#3f51b5',
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: '#adb5bd'
    },
    '& .MuiIconButton-root:not(.Mui-disabled)': {
      border: '1px solid #96B7E5'
    },
    '& .MuiIconButton-root:not(.Mui-disabled):hover': {
      backgroundColor: '#96B7E5',
    },
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  },
  imageCell: {
    paddingRight: '0 !important', // 이미지 셀의 패딩 제거
    textAlign: 'center', // 가운데 정렬
  },
  image: {
    maxWidth: '50px',
    maxHeight: '100px',
    display: 'block',
    margin: '0 auto', // 이미지 자체도 가운데 정렬
  },
});

const DataTable: React.FC<DataTableProps> = ({ columns, data, rowsPerPageOptions = [5, 10, 25], defaultRowsPerPage = 5, rowOnClick, tablePaginationing = true, tableCellSelectable = false, selectedRows = [], handleCheckboxClick }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableCellSelectable && (
                <TableCell
                  padding="checkbox"
                  className={classes.headerCell}>
                </TableCell>
              )}
              {columns.map((column) => {
                const isThumbnail = column.id.includes("thumbnail");
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.headerCell}
                    style={{
                      minWidth: column.minWidth,
                      ...(isThumbnail ? { padding: '0' } : {})
                    }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={rowIndex % 2 === 0 ? classes.rowEven : classes.rowOdd}
                {...(rowOnClick && {
                  onClick: () => rowOnClick && rowOnClick(row),
                  style: { cursor: "pointer" }
                })}
              >
                {tableCellSelectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.some((selectedRow) => selectedRow.id === row.id)}
                      onClick={(event) => event.stopPropagation()}
                      onChange={() => handleCheckboxClick(row)}
                      color="primary"
                    />
                  </TableCell>
                )}
                {columns.map((column) => {
                  const value = row[column.id];
                  const isThumbnail = column.id.includes("thumbnail");

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={`${classes.ellipsis} ${isThumbnail ? classes.imageCell : ''}`}
                    >
                      {column.isButton ? (
                        value ? (
                          <Button
                            variant="contained"
                            className={classes.button}
                            style={{ backgroundColor: column.buttonColor || '#3f51b5' }}
                            onClick={() => column.onClick && column.onClick(row)}
                          >
                            {value}
                          </Button>
                        ) : (
                          ""
                        )
                      ) : isThumbnail ? (
                        <img
                          src={value}
                          alt=""
                          className={classes.image}
                          onError={(e) => e.currentTarget.style.display = 'none'}
                        />
                      ) : (
                        value || '-'  // 값이 없으면 '-' 표시
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
      {
        tablePaginationing && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{ root: classes.pagination }}
          />
        )
      }
    </Paper>
  );
};

export default DataTable;
