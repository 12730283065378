export const vendorInitialState = {
  id: "",
  b_no: "",
  created_at: "",
  detail_info: {
    store_category: "",
    fashion_category: "",
    gender_category: "",
    age_range: [],
    store_style: "",
    size_chart_id: "",
    parallel_import: false,
    life_category: "",
    life_age_range: "",
    life_parallel_import: false,
    sale_countries: "",
    contact_name: "",
    contact_mobile: "",
    contact_phone: "",
    e_commerce_license_number: "",
    company_name: "",
    certification_info_1: "",
    certification_info_2: "",
    certification_info_3: "",
    working_days: [] 
  },
  etc: null,
  etc2: null,
  etc3: null,
  full_info_provided: false,
  introduction_en: null,
  introduction_ko: null,
  m_uid: "",
  manager_emails: null,
  shipping_info_id: "",
  store_name_en: null,
  store_name_ko: "",
  store_type: "",
  updated_at: "",
  op_start_hours: "",
  op_end_hours: "",
  lunch_start_hours: "",
  lunch_end_hours: ""
};