import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { ImageIcon, makeStyles } from "@saleor/macaw-ui";
import { vars } from "@saleor/macaw-ui-next";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Dropzone from "src/components/Dropzone";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"; // PDF 아이콘 추가
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"; // 구름 다운로드 아이콘 추가

interface ImageUploadCustomProps {
  children?: (props: { isDragActive: boolean }) => React.ReactNode;
  className?: string;
  disableClick?: boolean;
  isActiveClassName?: string;
  iconContainerClassName?: string;
  iconContainerActiveClassName?: string;
  hideUploadIcon?: boolean;
  onImageUpload: (file: FileList) => boolean;
  vendorThumbnail?: string;
}

const useStyles = makeStyles(
  theme => ({
    backdrop: {
      background: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
    fileField: {
      display: "none",
    },
    tmpContainer: {
      background: "#ffffff",
      border: `1px solid ${vars.colors.border.default1}`,
      borderRadius: theme.spacing(),
      height: 250,
      justifySelf: "start",
      overflow: "hidden",
      position: "relative",
      transition: theme.transitions.duration.standard + "s",
      width: 250,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column", // 이미지와 파일명을 수직 배치
      "&:hover $downloadButton": {
        opacity: 1,
      },
    },
    photosIcon: {
      height: 32,
      margin: "0 auto",
      width: 32,
    },
    photosIconContainer: {
      padding: theme.spacing(5, 0),
      textAlign: "center",
    },
    uploadText: {
      color: theme.typography.body1.color,
      fontSize: 12,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    imagePreview: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    pdfPreview: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
    },
    pdfIcon: {
      fontSize: 50,
    },
    pdfText: {
      fontSize: 12,
      marginTop: theme.spacing(1),
    },
    downloadButton: {
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      color: theme.palette.primary.main,
      opacity: 0,
      transition: "opacity 0.3s ease",
      zIndex: 10,
      "&:hover": {
        opacity: 1,
      },
    },
    fileName: {
      marginTop: theme.spacing(2),
      width: 200,
      textAlign: "center",
      wordBreak: "keep-all",
      fontSize: "1.5rem",
    },
  }),
  { name: "ImageUpload" },
);

export const ImageUploadCustom: React.FC<ImageUploadCustomProps> = props => {
  const {
    children,
    className,
    disableClick,
    iconContainerActiveClassName,
    iconContainerClassName,
    isActiveClassName,
    hideUploadIcon,
    onImageUpload,
    vendorThumbnail,
  } = props;

  const classes = useStyles(props);

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  useEffect(() => {
    if (vendorThumbnail) {
      setPreviewUrl(vendorThumbnail);
  
      // PDF인지 이미지인지 판별
      if (vendorThumbnail.endsWith(".pdf")) {
        setIsPdf(true);
      } else {
        setIsPdf(false);
      }
    }
  }, [vendorThumbnail]);
  

  const handleDrop = (fileList: FileList) => {
    const success = onImageUpload(fileList);
  
    if (!success) {
      return;
    }
  
    const file = fileList[0];
    if (file) {
      const fileType = file.type;
      setFileName(file.name); // 파일명 저장
  
      if (fileType === "application/pdf") {
        setIsPdf(true);
        setPreviewUrl(URL.createObjectURL(file));
      } else if (fileType.startsWith("image/")) {
        setIsPdf(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        setIsPdf(false);
        setPreviewUrl(null);
      }
    }
  };
  

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // 업로드 창이 뜨지 않도록 이벤트 버블링 방지
  };

  return (
    <Dropzone disableClick={disableClick} onDrop={handleDrop}>
      {({ isDragActive, getInputProps, getRootProps }) => (
        <>
          <div
            {...getRootProps()}
            className={clsx(className, classes.photosIconContainer, {
              [classes.backdrop]: isDragActive,
              [isActiveClassName]: isDragActive,
            })}
          >
            <input
              {...getInputProps()}
              className={classes.fileField}
              accept="image/*"
            />
            {!previewUrl && !hideUploadIcon && !isPdf && (
              <div
                className={clsx(iconContainerClassName, {
                  [iconContainerActiveClassName]: isDragActive,
                })}
              >
                <ImageIcon className={classes.photosIcon} />
                <Typography className={classes.uploadText}>
                  <FormattedMessage
                    id="NxeDbG"
                    defaultMessage="Drop here to upload"
                    description="image upload"
                  />
                </Typography>
              </div>
            )}
            {previewUrl && !isPdf && (
              <div className={classes.tmpContainer}>
                <img
                  src={previewUrl}
                  alt="Preview"
                  className={classes.imagePreview}
                />
                <a
                  href={previewUrl}
                  download={fileName} // 기존 파일명으로 다운로드
                  className={classes.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <CloudDownloadIcon /> {/* 구름 다운로드 아이콘 사용 */}
                </a>
              </div>
            )}

            {isPdf && (
              <div className={classes.tmpContainer}>
                <div className={classes.pdfPreview}>
                  <PictureAsPdfIcon className={classes.pdfIcon} />
                </div>
                <a
                  href={previewUrl}
                  download={fileName} // 기존 파일명으로 다운로드
                  className={classes.downloadButton}
                  onClick={handleDownloadClick}
                >
                  <CloudDownloadIcon /> {/* 구름 다운로드 아이콘 사용 */}
                </a>
              </div>
            )}
          </div>
          {children && children({ isDragActive })}
        </>
      )}
    </Dropzone>
  );
};
ImageUploadCustom.displayName = "ImageUploadCustom";
export default ImageUploadCustom;
