import { stringifyQs } from "@dashboard/utils/urls";
import urlJoin from "url-join";

import {
  ActiveTab,
  BulkAction,
  Dialog,
  Filters,
  FiltersWithMultipleValues,
  Pagination,
  SingleAction,
  Sort,
  TabActionDialog,
} from "../types";

import {
  pageTypeUrl,
} from "@dashboard/pageTypes/urls";

export const pagesSection = "/pages/";

const createSectionUrl = (section) => urlJoin(pagesSection, section);

export const inquiriesSection = createSectionUrl("inquiries");
export const reviewsSection = createSectionUrl("reviews");
export const faqSection = createSectionUrl("faq");
export const noticeSection = createSectionUrl("notice");

export const pageListPath = pagesSection;
export type PageListUrlDialog =
  | "publish"
  | "unpublish"
  | "remove"
  | "create-page"
  | TabActionDialog;

export enum PageListUrlSortField {
  title = "title",
  slug = "slug",
  visible = "visible",
}

export enum PageListUrlFiltersEnum {
  query = "query",
}

export enum PageListUrlFiltersWithMultipleValues {
  pageTypes = "pageTypes",
}

export type PageListUrlFilters = Filters<PageListUrlFiltersEnum> &
  FiltersWithMultipleValues<PageListUrlFiltersWithMultipleValues>;
export type PageListUrlSort = Sort<PageListUrlSortField>;
export type PageListUrlQueryParams = BulkAction &
  PageListUrlFilters &
  Dialog<PageListUrlDialog> &
  PageListUrlSort &
  Pagination &
  ActiveTab;

export const pageListUrl = (params?: PageListUrlQueryParams) =>
  pageListPath + "?" + stringifyQs(params);

export const pagePath = (id: string) => urlJoin(pagesSection, id);
export type PageUrlDialog = "remove" | "assign-attribute-value";
export interface PageCreateUrlPageType {
  "page-type-id"?: string;
}
export type PageUrlQueryParams = Dialog<PageUrlDialog> & SingleAction;
export type PageCreateUrlQueryParams = Dialog<PageUrlDialog> &
  SingleAction &
  PageCreateUrlPageType;

export const pageUrl = (id: string, params?: PageUrlQueryParams) =>
  pagePath(encodeURIComponent(id)) + "?" + stringifyQs(params);

export const pageCreatePath = urlJoin(pagesSection, "add");
export const pageCreateUrl = (params?: PageCreateUrlQueryParams) =>
  pageCreatePath + "?" + stringifyQs(params);

// inquiries, reviews, faq, notice 관련 URL 생성 함수들 추가
export const faqListUrl = (params?: PageListUrlQueryParams) => {
  const pageType = getPageType("faq");
  const baseParams = stringifyQs({
    ...params,
    pageTypes: pageType.id, 
    asc: true,
    sort: "title",
  });

  return urlJoin(pagesSection, "faq", "?" + baseParams);
};

export const inquiriesListUrl = (params?: PageListUrlQueryParams) => {
  const pageType = getPageType("inquiries");
  const baseParams = stringifyQs({
    ...params,
    pageTypes: pageType.id,  
    asc: true,
    sort: "title",
  });

  return urlJoin(pagesSection, "inquiries", "?" + baseParams);
};

export const noticeListUrl = (params?: PageListUrlQueryParams) => {
  const pageType = getPageType("notice");
  const baseParams = stringifyQs({
    ...params,
    pageTypes: pageType.id, 
    asc: true,
    sort: "title",
  });

  return urlJoin(pagesSection, "notice", "?" + baseParams);
};

export const reviewsListUrl = (params?: PageListUrlQueryParams) => {
  const pageType = getPageType("reviews");
  const baseParams = stringifyQs({
    ...params,
    pageTypes: pageType.id,  
    asc: true,
    sort: "title",
  });

  return urlJoin(pagesSection, "reviews", "?" + baseParams);
};

export function getPageType(typeName: string) {
  const pageTypes = {
    faq: { id: "UGFnZVR5cGU6OA==", name: "FAQ" },
    inquiries: { id: "UGFnZVR5cGU6MjA=", name: "Inquiries" },
    notice: { id: "UGFnZVR5cGU6Nw==", name: "Notice" },
    reviews: { id: "UGFnZVR5cGU6MTI=", name: "Reviews" },
  };

  return pageTypes[typeName] || { id: "", name: "" };
}

// console.log('getPageType : ', getPageType("faq").id);
// console.log('pageTypeUrl(pageType.id) : ', pageTypeUrl(getPageType("faq").id));
