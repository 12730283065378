import api from "./client";

export const addAlert = async (data: any) => {
    try {
        const response = await api.post(`/api-tg/alert/add`, data)
            .then(res => {
                return res.status;
            });
        return response;
    } catch (error) {
        console.error('Error register notices:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};