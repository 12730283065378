// @ts-strict-ignore
import { filterable } from "@dashboard/attributes/utils/data";
import ActionDialog from "@dashboard/components/ActionDialog";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { useConditionalFilterContext } from "@dashboard/components/ConditionalFilter/context";
import DeleteFilterTabDialog from "@dashboard/components/DeleteFilterTabDialog";
import SaveFilterTabDialog from "@dashboard/components/SaveFilterTabDialog";
import { useShopLimitsQuery } from "@dashboard/components/Shop/queries";
import {
  DEFAULT_INITIAL_PAGINATION_DATA,
  DEFAULT_INITIAL_SEARCH_DATA,
  defaultListSettings,
  ProductListColumns,
} from "@dashboard/config";
import { Task } from "@dashboard/containers/BackgroundTasks/types";
import { useFlag } from "@dashboard/featureFlags";
import {
  ExportErrorFragment,
  ProductListQueryVariables,
  ProductsWithVendorQueryVariables,
  useAvailableColumnAttributesLazyQuery,
  useGridAttributesLazyQuery,
  useInitialProductFilterAttributesQuery,
  useInitialProductFilterCategoriesQuery,
  useInitialProductFilterCollectionsQuery,
  useInitialProductFilterProductTypesQuery,
  useProductBulkDeleteMutation,
  useProductCountQuery,
  useProductExportMutation,
  useProductListQuery,
  useProductsWithVendorQuery,
  useWarehouseListQuery,
} from "@dashboard/graphql";
import useBackgroundTask from "@dashboard/hooks/useBackgroundTask";
import { useFilterHandlers } from "@dashboard/hooks/useFilterHandlers";
import { useUser } from "@dashboard/auth";
import { useFilterPresets } from "@dashboard/hooks/useFilterPresets";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { useRowSelection } from "@dashboard/hooks/useRowSelection";
import { commonMessages } from "@dashboard/intl";
import ProductExportDialog from "@dashboard/products/components/ProductExportDialog";
import {
  getAttributeIdFromColumnValue,
  isAttributeColumnValue,
} from "@dashboard/products/components/ProductListPage/utils";
import ProductTypePickerDialog from "@dashboard/products/components/ProductTypePickerDialog";
import {
  productAddUrl,
  productListUrl,
  ProductListUrlDialog,
  ProductListUrlQueryParams,
  ProductListUrlSortField,
} from "@dashboard/products/urls";
import useAttributeSearch from "@dashboard/searches/useAttributeSearch";
import useAttributeValueSearch from "@dashboard/searches/useAttributeValueSearch";
import useCategorySearch from "@dashboard/searches/useCategorySearch";
import useCollectionSearch from "@dashboard/searches/useCollectionSearch";
import useProductTypeSearch from "@dashboard/searches/useProductTypeSearch";
import { ListViews } from "@dashboard/types";
import createDialogActionHandlers from "@dashboard/utils/handlers/dialogActionHandlers";
import { mapEdgesToItems, mapNodeToChoice } from "@dashboard/utils/maps";
import { getSortUrlVariables } from "@dashboard/utils/sort";
import { DialogContentText } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ProductListPage from "../../components/ProductListPage";
import { ProductsExportParameters, ProductsExportToShopifyParameters } from "./export";
import {
  getFilterOpts,
  getFilterQueryParam,
  getFilterVariables,
  storageUtils,
} from "./filters";
import { DEFAULT_SORT_KEY, getSortQueryVariables } from "./sort";
import {
  getAvailableProductKinds,
  getProductKindOpts,
  obtainChannelFromFilter,
} from "./utils";
import { useProductExportToShopifyMutation } from "@dashboard/stores/services/GQ_apis/exportProduct";
import { getExportedProducts } from "@dashboard/stores/services/GQ_apis/products";
import { getAccessToken, getCafe24Info, getCafe24ProductsList } from "@dashboard/stores/services/REST_apis/cafe24";
import ProductsListDialog from "@dashboard/products/components/ImportProduct/ProductsListDialog/ProductsListDialog";
import { getStoreSyncInfoByVid } from "@dashboard/stores/services/REST_apis/vendor";

interface ProductListProps {
  params: ProductListUrlQueryParams;
}

export const ProductList: React.FC<ProductListProps> = ({ params }) => {
  const navigate = useNavigator();
  const notify = useNotifier();
  const { queue } = useBackgroundTask();
  // const { state, dispatch } = useVendor()

  const [vendorData, setVendorData] = useState(null);
  const [vId, setVId] = useState(undefined);
  const { valueProvider } = useConditionalFilterContext();
  const productListingPageFiltersFlag = useFlag("product_filters");

  const selectedChannelSlug = obtainChannelFromFilter(valueProvider);

  const { updateListSettings, settings } = useListSettings<ProductListColumns>(
    ListViews.PRODUCT_LIST,
  );

  usePaginationReset(productListUrl, params, settings.rowNumber);

  const intl = useIntl();
  const { data: initialFilterAttributes } =
    useInitialProductFilterAttributesQuery({
      skip: productListingPageFiltersFlag.enabled,
    });
  const { data: initialFilterCategories } =
    useInitialProductFilterCategoriesQuery({
      variables: {
        categories: params.categories,
      },
      skip: !params.categories?.length || productListingPageFiltersFlag.enabled,
    });
  const { data: initialFilterCollections } =
    useInitialProductFilterCollectionsQuery({
      variables: {
        collections: params.collections,
      },
      skip:
        !params.collections?.length || productListingPageFiltersFlag.enabled,
    });
  const { data: initialFilterProductTypes } =
    useInitialProductFilterProductTypesQuery({
      variables: {
        productTypes: params.productTypes,
      },
      skip:
        !params.productTypes?.length || productListingPageFiltersFlag.enabled,
    });
  const searchCategories = useCategorySearch({
    variables: {
      ...DEFAULT_INITIAL_SEARCH_DATA,
      first: 5,
    },
    skip: productListingPageFiltersFlag.enabled,
  });
  const searchCollections = useCollectionSearch({
    variables: {
      ...DEFAULT_INITIAL_SEARCH_DATA,
      first: 5,
    },
    skip: productListingPageFiltersFlag.enabled,
  });
  const searchProductTypes = useProductTypeSearch({
    variables: {
      ...DEFAULT_INITIAL_SEARCH_DATA,
      first: 5,
    },
    skip: productListingPageFiltersFlag.enabled,
  });
  const searchAttributes = useAttributeSearch({
    variables: {
      ...DEFAULT_INITIAL_SEARCH_DATA,
      first: 10,
    },
    skip: productListingPageFiltersFlag.enabled,
  });
  const [focusedAttribute, setFocusedAttribute] = useState<string>();
  const searchAttributeValues = useAttributeValueSearch({
    variables: {
      id: focusedAttribute,
      ...DEFAULT_INITIAL_SEARCH_DATA,
      first: 10,
    },
    skip: !focusedAttribute,
  });
  const warehouses = useWarehouseListQuery({
    variables: {
      first: 100,
    },
    skip: params.action !== "export",
  });
  const availableProductKinds = getAvailableProductKinds();
  const { availableChannels } = useAppChannel(false);
  const limitOpts = useShopLimitsQuery({
    variables: {
      productVariants: true,
    },
  });

  const selectedChannel = availableChannels.find(
    channel =>
      channel.slug ===
      (productListingPageFiltersFlag.enabled
        ? selectedChannelSlug
        : params.channel),
  );

  const [openModal, closeModal] = createDialogActionHandlers<
    ProductListUrlDialog,
    ProductListUrlQueryParams
  >(navigate, productListUrl, params);

  const {
    clearRowSelection,
    selectedRowIds,
    setClearDatagridRowSelectionCallback,
    setSelectedRowIds,
  } = useRowSelection(params);

  const {
    hasPresetsChanged,
    onPresetChange,
    onPresetDelete,
    onPresetSave,
    onPresetUpdate,
    getPresetNameToDelete,
    presets,
    selectedPreset,
    setPresetIdToDelete,
  } = useFilterPresets({
    params,
    getUrl: productListUrl,
    storageUtils,
    reset: clearRowSelection,
  });

  const countAllProducts = useProductCountQuery({
    skip: params.action !== "export",
  });

  const [exportProducts, exportProductsOpts] = useProductExportMutation({
    onCompleted: data => {
      if (data.exportProducts.errors.length === 0) {
        notify({
          text: intl.formatMessage({
            id: "dPYqy0",
            defaultMessage:
              "We are currently exporting your requested CSV. As soon as it is available it will be sent to your email address",
          }),
          title: intl.formatMessage({
            id: "5QKsu+",
            defaultMessage: "Exporting CSV",
            description: "waiting for export to end, header",
          }),
        });
        queue(Task.EXPORT, {
          id: data.exportProducts.exportFile.id,
        });
        closeModal();
        clearRowSelection();
      }
    },
  });

  const [productBulkDelete, productBulkDeleteOpts] =
    useProductBulkDeleteMutation({
      onCompleted: data => {
        if (data.productBulkDelete.errors.length === 0) {
          closeModal();
          notify({
            status: "success",
            text: intl.formatMessage(commonMessages.savedChanges),
          });
          refetch();
          limitOpts.refetch();
          clearRowSelection();
        }
      },
    });

  const [changeFilters, resetFilters, handleSearchChange] = useFilterHandlers({
    cleanupFn: clearRowSelection,
    createUrl: productListUrl,
    getFilterQueryParam,
    params,
    keepActiveTab: true,
    defaultSortField: DEFAULT_SORT_KEY,
    hasSortWithRank: true,
  });

  const handleSort = (field: ProductListUrlSortField, attributeId?: string) =>
    navigate(
      productListUrl({
        ...params,
        ...getSortUrlVariables(field, params),
        attributeId,
        ...DEFAULT_INITIAL_PAGINATION_DATA,
      }),
    );

  const handleSubmitBulkDelete = () => {
    productBulkDelete({
      variables: { ids: selectedRowIds },
    });
    clearRowSelection();
  };

  const kindOpts = getProductKindOpts(availableProductKinds, intl);
  const paginationState = createPaginationState(settings.rowNumber, params);
  const channelOpts = availableChannels
    ? mapNodeToChoice(availableChannels, channel => channel.slug)
    : null;

  const filterVariables = getFilterVariables({
    isProductListingPageFiltersFlagEnabled:
      productListingPageFiltersFlag.enabled,
    filterContainer: valueProvider.value,
    queryParams: params,
    isChannelSelected: !!selectedChannel,
    channelSlug: selectedChannel?.slug,
  });

  const sort = getSortQueryVariables(params, !!selectedChannel);

  const filteredColumnIds = (settings.columns ?? [])
    .filter(isAttributeColumnValue)
    .map(getAttributeIdFromColumnValue);

  ///////////////// add code
  ////// 
  const { user } = useUser();

  useEffect(() => {
    const storedVendorData = JSON.parse(localStorage.getItem('vendor_info'));
    setVendorData(storedVendorData);
    const vendorId = storedVendorData?.id;
    setVId(vendorId);

    if (!user.isSuperuser && (!vendorId || !vendorId.startsWith('VD_'))) {
      alert('상점 정보가 없는 사용자로 상품 목록을 불러올 수 없습니다.\n시스템 관리자에게 문의해주세요.');
      navigate("/");
    }
  }, []);

  const superAdmin = user.isSuperuser
  let qr, products, queryVariables;
  let vari = {
    hasChannel: !!selectedChannel,
    includeCategories: settings.columns.includes("productCategory"),
    includeCollections: settings.columns.includes("productCollections")
  }

  qr = useProductListQuery;

  const baseVariables = {
    ...paginationState,
    ...filterVariables,
    sort,
  };

  queryVariables = React.useMemo(() => {
    if (!superAdmin) {
      baseVariables.where.metadata =
          [{
            key: "ZZ_VID",
            value: vId,
          },]
    }

    return baseVariables;
  }, [superAdmin, params, settings.rowNumber, valueProvider.value]);


  useEffect(()=>{
    console.log('filterVariables', filterVariables);
    console.log('queryVariables', queryVariables);
  },[filterVariables, queryVariables])

  //////////////////////////////////////////////
  const [ids, setIds] = useState([]);

  useEffect(() => {
    const getProductsIds = async () => {
      return await getExportedProducts()
        .then(res => {
          return res.data.exportedProductIds
        })
    }
    const fetchData = async () => {
      const data = await getProductsIds(); // 비동기 함수 결과 기다림
      setIds(data);
    };
    if (params.exported === true || params.exported === 'true') {
      fetchData();
    }
  }, [params])

  queryVariables = React.useMemo(() => {
    if (params.exported === true || params.exported === 'true') {
      baseVariables.where = {
        ids: ids
      };
    }
    return baseVariables;
  }, [ids, params, settings.rowNumber, valueProvider.value]);

  const [exportProductsToShopify, exportProductsToShopifyOpts] = useProductExportToShopifyMutation({
    onCompleted: data => {
      console.log(data);
      if (data.exportProductsToShopify?.errors.length === 0) {
        notify({
          text: intl.formatMessage({
            id: "Z5vioh",
            defaultMessage:
              "We are exporting your selected product.",
          }),
          title: intl.formatMessage({
            id: "gicq2L",
            defaultMessage: "Export to Shopify",
          }),
        });
      } else {
        console.log("fail T.T")
      }
      // closeModal();
      // clearRowSelection();
    },
  });

  ///////////////////////////////////

  const { data, loading, refetch } = qr({
    displayLoader: true,
    variables: {
      ...queryVariables,
      ...vari
    },
    skip: valueProvider.loading,
  });

  let resultProducts
  resultProducts = data?.products
  products = mapEdgesToItems(resultProducts)

  const handleSetSelectedProductIds = useCallback(
    (rows: number[], clearSelection: () => void) => {
      if (!products) {
        return;
      }

      const rowsIds = rows.map(row => products[row].id);
      const haveSaveValues = isEqual(rowsIds, selectedRowIds);

      if (!haveSaveValues) {
        setSelectedRowIds(rowsIds);
      }

      setClearDatagridRowSelectionCallback(clearSelection);
    },
    [products, selectedRowIds],
  );

  const availableColumnsAttributesOpts =
    useAvailableColumnAttributesLazyQuery();

  const [gridAttributesQuery, gridAttributesOpts] =
    useGridAttributesLazyQuery();

  useEffect(() => {
    // Fetch this only on initial render
    gridAttributesQuery({
      variables: {
        ids: filteredColumnIds,
        hasAttributes: !!filteredColumnIds.length,
      },
    });
  }, []);

  const {
    loadMore: loadMoreDialogProductTypes,
    search: searchDialogProductTypes,
    result: searchDialogProductTypesOpts,
  } = useProductTypeSearch({
    variables: DEFAULT_INITIAL_SEARCH_DATA,
  });

  const fetchMoreDialogProductTypes = {
    hasMore: searchDialogProductTypesOpts.data?.search?.pageInfo?.hasNextPage,
    loading: searchDialogProductTypesOpts.loading,
    onFetchMore: loadMoreDialogProductTypes,
  };

  const filterOpts = getFilterOpts(
    params,
    (mapEdgesToItems(initialFilterAttributes?.attributes) || []).filter(
      filterable,
    ),
    searchAttributeValues,
    {
      initial: mapEdgesToItems(initialFilterCategories?.categories) || [],
      search: searchCategories,
    },
    {
      initial: mapEdgesToItems(initialFilterCollections?.collections) || [],
      search: searchCollections,
    },
    {
      initial: mapEdgesToItems(initialFilterProductTypes?.productTypes) || [],
      search: searchProductTypes,
    },
    kindOpts,
    channelOpts,
  );

  const paginationValues = usePaginator({
    pageInfo: resultProducts?.pageInfo,
    paginationState,
    queryString: params,
  });

  ///////////////////////////////////////////////////////////////
  const [productsList, setProductsList] = useState({ products: [], count: 0 });
  const [cafe24SyncInfo, setCafe24SyncInfo] = useState({ mall_id: "", client_id: "", client_secret: "" });
  const [cafe24AccessToken, setCafe24AccessToken] = useState("");

  useEffect(() => {
    if (vId) {
      if (vId === "VD_MASTER") {
        getStoreSyncData("VD_20240719_9iQBGRwNbz"); // 임시
      } else {
        getStoreSyncData(vId);
      }
    }
  }, [vId])

  async function openOAuthPopup(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (cafe24SyncInfo.mall_id && cafe24SyncInfo.client_id) {
        const oauthUrl = `https://${cafe24SyncInfo.mall_id}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${cafe24SyncInfo.client_id}&state=testtest&redirect_uri=https://seller.kistor.kr/cafe24&scope=mall.read_product&code_challenge_method=S256`;
        const popup = window.open(oauthUrl, "oauthPopup", "width=600,height=600");

        const messageListener = async (event: MessageEvent) => {
          if (event.data.authCode) {
            try {
              const authorizationCode = event.data.authCode; // 받은 인증 코드
              // Access Token 발급
              const tokenResponse = await getAccessToken(vId, authorizationCode)
              if (tokenResponse.message) {
                reject(tokenResponse.message);
              } else {
                const accessToken = tokenResponse.access_token;
                setCafe24AccessToken(accessToken);
                resolve(accessToken); // 새로운 Access Token을 resolve
              }
            } catch (error) {
              reject(error); // 에러 발생 시 reject
            } finally {
              window.removeEventListener("message", messageListener);
              if (popup) popup.close();
            }
          }
        };
        window.addEventListener("message", messageListener);
      } else {
        alert("Cafe24 정보가 등록되어 있지 않습니다.");
        reject("No cafe24 sync info");
      }
    });
  }

  // Cafe24 제품 정보를 불러오는 함수
  async function importProductsFromCafe24() {
    try {
      let accessToken = cafe24AccessToken;

      // 1. Access Token이 없거나 만료됐는지 확인
      if (!accessToken) {
        accessToken = await openOAuthPopup(); // 토큰이 없으면 새로 발급
      }

      // 2. Cafe24 API에서 제품 목록 가져오기
      const cafe24Response = await getCafe24Info(vId, accessToken);

      // 만약 'token_expired' 에러가 발생하면 다시 새 토큰을 발급받아서 재시도
      if (cafe24Response.error) {
        if (cafe24Response.error.code === 400 || cafe24Response.error.code === 401) {
          accessToken = await openOAuthPopup(); // 새 토큰 발급
          const newCafe24Response = await getCafe24Info(vId, accessToken); // 새로운 토큰으로 다시 호출
          handleCafe24Products(newCafe24Response); // 성공 시 제품 목록 처리
        }
      } else {
        handleCafe24Products(cafe24Response); // 정상 처리
      }
    } catch (error) {
      console.error("Error importing products from Cafe24:", error);
      if (error !== "No cafe24 sync info") {
        if (error.error_description !== "Code time expired.") {
          alert("오류가 발생했습니다.\n" + error)
        }
      }
    }
  }

  // Cafe24 제품 목록을 처리하는 함수
  function handleCafe24Products(cafe24Response) {
    const updatedProducts = cafe24Response.products.map((product) => ({
      ...product,
      id: product.product_code,
    }));

    // productsList 상태 업데이트
    setProductsList({
      products: updatedProducts,
      count: cafe24Response.count,
    });

    // 3. 모달 열기
    openModal("import");
  }

  const loadCafe24ProductsList = async (offset) => {
    const cafe24response = await getCafe24ProductsList(vId, cafe24AccessToken, offset);
    const updateProducts = cafe24response.products.map(product => ({
      ...product,
      id: product.product_code,
    }));
    setProductsList({
      ...productsList,
      products: updateProducts
    })
  }

  const handleFormData = (data) => {
    setCafe24SyncInfo(data.cafe24_sync_info);
  }

  const getStoreSyncData = useCallback(async (id) => {
    const data = await getStoreSyncInfoByVid(id).then(data => { return data.data; });
    handleFormData(data);
  }, [])

  const closeImportModal = () => {
    clearRowSelection();
    refetch();
    closeModal();
  }

  const exportData = exportProductsToShopifyOpts.data?.exportProductsToShopify?.exportResult || [];

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <ProductListPage
        activeAttributeSortId={params.attributeId}
        sort={{
          asc: params.asc,
          sort: params.sort,
        }}
        onSort={handleSort}
        currencySymbol={selectedChannel?.currencyCode || ""}
        currentTab={selectedPreset}
        defaultSettings={defaultListSettings[ListViews.PRODUCT_LIST]}
        filterOpts={filterOpts}
        gridAttributesOpts={gridAttributesOpts}
        settings={settings}
        availableColumnsAttributesOpts={availableColumnsAttributesOpts}
        disabled={loading || valueProvider.loading}
        limits={limitOpts.data?.shop.limits}
        products={products}
        onUpdateListSettings={(...props) => {
          clearRowSelection();
          updateListSettings(...props);
        }}
        onAdd={() => openModal("create-product")}
        onAll={resetFilters}
        onSearchChange={handleSearchChange}
        onFilterChange={changeFilters}
        onFilterAttributeFocus={setFocusedAttribute}
        onTabSave={() => openModal("save-search")}
        onTabUpdate={onPresetUpdate}
        onTabDelete={(tabIndex: number) => {
          setPresetIdToDelete(tabIndex);
          openModal("delete-search");
        }}
        onProductsDelete={() => openModal("delete")}
        onTabChange={onPresetChange}
        hasPresetsChanged={hasPresetsChanged()}
        initialSearch={params.query || ""}
        tabs={presets.map(tab => tab.name)}
        onExport={() => openModal("export")}
        onExportShopify={selectedRowIds.length < 21 && selectedRowIds.length > 0 ? () => openModal("export-shopify")
          : selectedRowIds.length > 20 ? () => alert("한 번에 최대 20개의 상품만 내보내기가 가능합니다.")
            : () => alert("Shopify에 내보낼 상품을 선택해 주세요.")}
        importProduct={importProductsFromCafe24}
        selectedChannelId={selectedChannel?.id}
        selectedProductIds={selectedRowIds}
        onSelectProductIds={handleSetSelectedProductIds}
        clearRowSelection={clearRowSelection}
      />
      <ActionDialog
        open={params.action === "delete"}
        confirmButtonState={productBulkDeleteOpts.status}
        onClose={closeModal}
        onConfirm={handleSubmitBulkDelete}
        title={intl.formatMessage({
          id: "F4WdSO",
          defaultMessage: "상품 삭제",
          description: "dialog header",
        })}
        variant="delete"
        confirmButtonLabel="삭제"
        backButtonText="취소"
      >
        <DialogContentText>
          <FormattedMessage
            id="yDkmX7"
            defaultMessage="{counter,plural,one{선택하신 상품을 삭제하시겠습니까?} other{선택하신 {displayQuantity} 개의 상품을 삭제하시겠습니까?}}"
            description="dialog content"
            values={{
              counter: selectedRowIds.length,
              displayQuantity: <strong>{selectedRowIds.length}</strong>,
            }}
          />
        </DialogContentText>
      </ActionDialog>
      <ProductExportDialog
        attributes={
          mapEdgesToItems(searchAttributes?.result?.data?.search) || []
        }
        hasMore={searchAttributes.result.data?.search.pageInfo.hasNextPage}
        loading={
          searchAttributes.result.loading ||
          countAllProducts.loading ||
          warehouses.loading
        }
        onFetch={searchAttributes.search}
        onFetchMore={searchAttributes.loadMore}
        open={params.action === "export"}
        confirmButtonState={exportProductsOpts.status}
        errors={exportProductsOpts.data?.exportProducts.errors || []}
        productQuantity={{
          all: countAllProducts.data?.products?.totalCount,
          filter: data?.productsWithVendor?.totalCount,
        }}
        selectedProducts={selectedRowIds.length}
        warehouses={mapEdgesToItems(warehouses?.data?.warehouses) || []}
        channels={availableChannels}
        onClose={closeModal}
        onSubmit={data => {
          const productsExportParams = new ProductsExportParameters({
            ...data,
            ...filterVariables,
            ids: selectedRowIds,
          });

          exportProducts({
            variables: {
              input: productsExportParams.asExportProductsInput(),
            },
          });
        }}
      />
      {/* 쇼피파이 export dialog */}
      <ProductExportDialog
        type="shopify"
        attributes={
          mapEdgesToItems(searchAttributes?.result?.data?.search) || []
        }
        hasMore={searchAttributes.result.data?.search.pageInfo.hasNextPage}
        loading={
          searchAttributes.result.loading ||
          countAllProducts.loading ||
          warehouses.loading
        }
        onFetch={searchAttributes.search}
        onFetchMore={searchAttributes.loadMore}
        open={params.action === "export-shopify"}
        confirmButtonState={exportProductsToShopifyOpts.status}
        errors={exportProductsToShopifyOpts.data?.exportProductsToShopify.errors as ExportErrorFragment[] || []}
        productQuantity={{
          all: countAllProducts.data?.products?.totalCount,
          filter: data?.productsWithVendor?.totalCount,
        }}
        selectedProducts={selectedRowIds.length}
        warehouses={mapEdgesToItems(warehouses?.data?.warehouses) || []}
        channels={availableChannels}
        onClose={closeModal}
        onSubmit={data => {
          const productsExportParams = new ProductsExportToShopifyParameters({
            ...data,
            ...filterVariables,
            ids: selectedRowIds,
          });
          exportProductsToShopify({
            variables: {
              input: productsExportParams.asExportProductsInput(),
            },
          });
        }}
        exportResult={exportData}
      />
      <ProductsListDialog
        accessToken={cafe24AccessToken}
        data={productsList}
        onClose={closeImportModal}
        onSubmit={closeImportModal}
        open={params.action === "import"}
        loadMore={loadCafe24ProductsList} />
      <SaveFilterTabDialog
        open={params.action === "save-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetSave}
      />
      <DeleteFilterTabDialog
        open={params.action === "delete-search"}
        confirmButtonState="default"
        onClose={closeModal}
        onSubmit={onPresetDelete}
        tabName={getPresetNameToDelete()}
      />
      <ProductTypePickerDialog
        confirmButtonState="success"
        open={params.action === "create-product"}
        productTypes={mapNodeToChoice(
          mapEdgesToItems(searchDialogProductTypesOpts?.data?.search),
        )}
        fetchProductTypes={searchDialogProductTypes}
        fetchMoreProductTypes={fetchMoreDialogProductTypes}
        onClose={closeModal}
        onConfirm={productTypeId =>
          navigate(
            productAddUrl({
              "product-type-id": productTypeId,
            }),
          )
        }
      />
    </PaginatorContext.Provider>
  );
};
export default ProductList;
