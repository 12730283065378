import api from "./client";

export const vendorInfoInitialState = {
    b_no: "",
    created_at: "",
    detail_info: {
        store_category: "",
        fashion_category: {
            top: "",
            middle: "",
            bottom: "",
        },
        age_range: { "max": "", "min": "" },
        gender_category: {
            Unisex: false,
            girl: false,
            boy: false,
        },
        store_style: {
            Americana: false,
            CampusLook: false,
            Casual: false,
            Contemporary: false,
            Lovely: false,
            ModernChic: false,
            OfficeLook: false,
            Romantic: false,
            SimpleBasic: false,
            Unique: false,
            Unisex: false,
            Vintage: false,
        },
        size_chart_id: "",
        parallel_import: false,
        life_category: {
            top: "",
            middle: "",
            bottom: "",
        },
        life_age_range: { "max": "", "min": "" },
        life_parallel_import: false,
        sale_countries: "",
        contact_name: "",
        contact_mobile: "",
        contact_phone: "",
        e_commerce_license_number: "",
        company_name: "",
        business_location: {
            country_area: "",
            city: "",
            address: "",
            postal_code: "",
            company_name: ""
        },
        business_customs_code: "",
        certification_info_1: "",
        certification_info_2: "",
        certification_info_3: "",
        customer_center_info: {
            op_start_hours: "",
            op_end_hours: "",
            lunch_start_hours: "",
            lunch_end_hours: "",
            working_days: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            }
        }
    },
    etc: "",
    etc2: "",
    etc3: "",
    full_info_provided: false,
    introduction_en: "",
    introduction_ko: "",
    m_uid: "",
    manager_emails: "",
    shipping_info_id: "",
    store_name_en: "",
    store_name_ko: "",
    store_type: "",
    updated_at: "",
};

export const getVendors = async (list = [], flag = false, isSuperAdmin=false, channel='all') => {
    try {
        const response = await api.post('/api-tg/vendor/vendor-list', {
            ids: list,
            flag,
            channel,
            page: 'admin',
            isSuperAdmin
        })
            .then(data => { return data.data; });
        return response;
    } catch (error) {
        console.error('Error fetching vendors:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};
export const getVendorByVid = async (id: string) => {
    try {
        const response = await api.get(`/api-tg/vendor/vendor-data?id=${id}`)
            .then(data => { return data.data; });
        return response;
    } catch (error) {
        console.error('Error fetching vendors:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const getManagers = async (vid: string) => {
    try {
        const response = await api.post('/api-tg/vendor/manager-list', { id: vid })
            .then(data => { return data.data; });
        return response;
    } catch (error) {
        console.error('Error fetching managers:', error);
        throw error; // 필요에 따라 오류를 다시 던지거나 처리
    }
};

export const addManager = async (vid: string, email: string) => {
    return await api.post('/api-tg/vendor/add-manager', { id: vid, email: email })
        .then(response => { return response.status; })
        .catch(error => { return error.response });
};

export const acceptManager = async (vid: string, email: string) => {
    console.log(email);
    return await api.post('/api-tg/vendor/accept-manager', { id: vid, email: email })
        .then(response => { return response.status; })
        .catch(error => { return error.response });
};

export const deleteManager = async (vid: string, email: string) => {
    return await api.post('/api-tg/vendor/delete-manager', { id: vid, email: email })
        .then(response => { return response.status; })
        .catch(error => { return error.response });
};

export const getShipInfoByVid = async (vid: string) => {
    return await api.post('/api-tg/vendor/vendor-info?info_type=ship', { id: vid })
        .then(response => { return response.data; })
        .catch(error => { return error.response });
}

export const updateShipInfo = async (id: string, data) => {
    return await api.post(`/api-tg/vendor/update-shipping-info?id=${id}`, data)
        .then(response => {
            return { status: response.status, data: response.data.data };
        })
        .catch(error => { return error.response });
}

export const getContactInfoByVid = async (vid: string) => {
    return await api.post('/api-tg/vendor/vendor-info?info_type=contact', { id: vid })
        .then(response => { return response.data; })
        .catch(error => { return error.response });
}

export const getStoreSyncInfoByVid = async (vid: string) => {
    return await api.post('/api-tg/vendor/vendor-info?info_type=storesync', { id: vid })
        .then(response => { return response.data; })
        .catch(error => { return error.response });
}

export const getShopifySyncInfo = async (email: string) => {
    return await api.post('/api-tg/vendor/load-shopify-sync-info', { email: email })
        .then(response => { return response.data; })
        .catch(error => { return error.response });
}

export const updateVendorDetailByVId = async (id: string, data) => {
    return await api.post(`/api-tg/vendor/update-detail/${id}`, data)
        .then(response => { return response.status; })
        .catch(error => { return error.response });
}

export const updateShopifySyncInfo = async (data) => {
    return await api.post(`/api-tg/vendor/update-shopify-sync-info`, data)
        .then(response => { return response.status; })
        .catch(error => { return error.response });
}

export const updateVendorDetail = async (id: string, data) => {
    console.log(data);
    return await api.post(`/api-tg/vendor/update-detail?id=${id}`, data)
        .then(response => {
            console.log(response);
            return response.status;
        })
        .catch(error => {
            console.log(error.response);
            return error
        });
}
export const updateVendorInfo = async (data) => {
    return await api.post(`/api-tg/vendor/update-vendor`, data)
        .then(response => {
            return { status: response.status, data: response.data.data };
        })
        .catch(error => {
            console.log(error.response);
            return error
        });
}

export const getVendorCommision = async (vid: string, channel_id: number) => {
    return await api.post('/api-tg/vendor/vendor-commision', { id: vid, channel_id: channel_id })
        .then(response => { return response.data; })
        .catch(error => { return error.response });
}

export const updateVendorCommision = async (data) => {
    return await api.post(`/api-tg/vendor/set-vendor-commision`, data)
        .then(response => {
            return response.status;
        })
        .catch(error => {
            console.log(error.response);
            return error
        });
}

export const updateVendorCollection = async (collection) => {
    return await api.post(`/api-tg/vendor/set-vendor-collection`, {
        id: collection.id,
        brands: collection.data
    })
        .then(response => {
            return response.status;
        })
        .catch(error => {
            console.log(error.response);
            return error;
        });
};

export const assignVendorCollection = async (channel='KO') => {
    return await api.post(`/api-tg/log/assign-brand`, {
      channel
    })
        .then(response => {
            alert('할당 완료되었습니다.')
            return response.status;
        })
        .catch(error => {
            console.log(error.response);
            return error;
        });
};

export const assignProductCollection = async (type, category, channel) => {
    return await api.post(`/api-tg/log/assign-product`, {
        type,
        category,
        channel
    })
        .then(response => {
            alert('할당 완료되었습니다.')
            return response.status;
        })
        .catch(error => {
            console.log(error.response);
            return error;
        });
};

