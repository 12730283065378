import React from "react";
import { Card, CardContent, Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@saleor/macaw-ui";

interface ExportResultProps {
    successData: { id: string; title: string }[];
    failData: {
        response: any; id: string; title: string; errorMessage?: string
    }[];
    skipData: { id: string; title: string }[];
}


const useStyles = makeStyles(
    theme => ({
        sectionTitle: {
            margin: theme.spacing(2, 0, 1),
            fontWeight: 600,
        },
        listItem: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        responseMessage: {
            color: theme.palette.error.main,
        },
        cardContent: {
            padding: theme.spacing(3),
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
        scrollArea: {
            maxHeight: "calc(100vh - 350px)",
            minHeight: "auto",
            "@media (min-height: 800px)": {
              minHeight: 440,
            },
            overflowY: "auto",
            overflowX: "hidden",
          },
    }),
    {
        name: "ExportResult",
    },
);

const ExportResult: React.FC<ExportResultProps> = ({
    successData,
    failData,
    skipData,
}) => {
    const classes = useStyles({});

    const getErrorMessage = (errorType: string) => {
        switch (errorType) {
            case "SERVER ERROR":
                return "서버 에러: 잠시 후에 다시 시도해 주세요.";
            case "IMAGE ERROR":
                return "이미지 관련 에러: 상품에 등록된 이미지를 확인해 주세요.";
            case "VARIANT ERROR":
                return "변형 에러: 상품 변형 데이터를 확인해 주세요.";
            default:
                return "알 수 없는 에러: 잠시 후에 다시 시도해 주세요.";
        }
    };

    return (
        <Card>
            <CardContent className={classes.cardContent}>
                <div className={classes.scrollArea}>
                    <Typography variant="subtitle1" className={classes.sectionTitle}>성공: {successData.length}건</Typography>
                    <List>
                        {successData.map((item, index) => (
                            <ListItem key={index} className={classes.listItem}>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        ))}
                    </List>

                    <Typography variant="subtitle1" className={classes.sectionTitle}>등록 실패: {failData.length}건</Typography>
                    <List>
                        {failData.map((item, index) => (
                            <ListItem key={index} className={classes.listItem}>
                                <ListItemText
                                    primary={item.title}
                                    secondary={
                                        <span className={classes.responseMessage}>
                                            {getErrorMessage(item.response.errorType)}
                                        </span>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Typography variant="subtitle1" className={classes.sectionTitle}>중복 처리 (등록 취소됨): {skipData.length}건</Typography>
                    <List>
                        {skipData.map((item, index) => (
                            <ListItem key={index} className={classes.listItem}>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </CardContent>
        </Card>
    )
};

export default ExportResult;
