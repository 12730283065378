// @ts-strict-ignore
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { Card, CardContent, Button, makeStyles } from "@material-ui/core";

import CardTitle from "@dashboard/components/CardTitle";
import { TopNav } from "@dashboard/components/AppLayout/TopNav";
import CardMenu from "@dashboard/components/CardMenu/CardMenu";
import CardSpacer from "@dashboard/components/CardSpacer";
import { ConfirmButtonTransitionState } from "@dashboard/components/ConfirmButton";
import Form from "@dashboard/components/Form";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";

import { extensionMountPoints, mapToMenuItemsForCustomerDetails, useExtensions } from "@dashboard/apps/hooks/useExtensions";
import { commonMessages, sectionNames } from "@dashboard/intl";
import { storeInfoListUrl } from "../../../stores/urls";
import { AccountErrorFragment, CustomerDetailsQuery } from "@dashboard/graphql";
import { SubmitPromise } from "@dashboard/hooks/useForm";
import useNavigator from "@dashboard/hooks/useNavigator";

import { acceptManager, addManager, deleteManager, getVendors } from "@dashboard/stores/services/REST_apis/vendor";
import ManagerDataTable from "@dashboard/tng/components/DataTable/ManagerDataTable";
import DataTable from "@dashboard/tng/components/DataTable";
import moment from "moment-timezone";

export interface SettlementsListPageFormData {
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    note: string;
}

const useStyles = makeStyles({
    addButton: {
        backgroundColor: '#252929',
        border: '1px solid #252929',
        color: '#ffffff',
        marginBottom: '16px',
        alignSelf: 'flex-end',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#252929',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export interface SettlementsListPageProps {
    customerId: string;
    customer: CustomerDetailsQuery["user"];
    disabled: boolean;
    errors: AccountErrorFragment[];
    saveButtonBar: ConfirmButtonTransitionState;
    onSubmit: (
        data: SettlementsListPageFormData,
    ) => SubmitPromise<AccountErrorFragment[]>;
    onDelete: () => void;
}

interface vendorData {
    store_name_ko: string;
    master_first_name: string;
    created_at: string;
    is_confirmed: boolean;
    master_email: string;
}

const SettlementsListPage: React.FC<SettlementsListPageProps> = ({
    customerId,
    customer,
    disabled,
    errors,
    saveButtonBar,
    onSubmit,
    onDelete,
}: SettlementsListPageProps) => {
    const intl = useIntl();
    const navigate = useNavigator();
    // const [vendorData, setVendorData] = useState(null);
    // const [vId, setVId] = useState(undefined);
    const [vendors, setVendors] = useState<vendorData[]>([]);

    // useEffect(() => {
    //   const storedVendorData = JSON.parse(localStorage.getItem('vendor_info'));
    //   setVendorData(storedVendorData);
    //   const vendorId = storedVendorData?.id;
    //   setVId(vendorId);
    // }, []);

    useEffect(() => {
        getVendorList();
    }, [])

    const handleManagerData = (data) => {
        setVendors(data);
    }
    const initialForm: SettlementsListPageFormData = {
        email: customer?.email || "",
        firstName: customer?.firstName || "",
        isActive: customer?.isActive || false,
        lastName: customer?.lastName || "",
        note: customer?.note || ""
    };

    const { CUSTOMER_DETAILS_MORE_ACTIONS } = useExtensions(extensionMountPoints.CUSTOMER_DETAILS);
    const extensionMenuItems = mapToMenuItemsForCustomerDetails(CUSTOMER_DETAILS_MORE_ACTIONS, customerId);

    const getVendorList = useCallback(async () => {
        const rawData = await getVendors().then(data => {
            return data;
        });
        const newData = formatData(rawData.data);
        handleManagerData(newData);
    }, []);

    const formatData = (data) => {
        return data.map(v => {
            const createdAt = moment(v.created_at);
            const confirmState = v.is_confirmed == true ? "승인" : "미승인"
            return {
                ...v,
                created_at: createdAt.format('YYYY-MM-DD'),
                is_confirmed: confirmState,
                checked: false,
            }
        })
    }

    const columns: Column[] = [
        { id: 'store_name_ko', label: '상점명', minWidth: 10, align: 'center' },
        { id: 'master_first_name', label: '관리자ID', minWidth: 50, align: 'center' },
        { id: 'introduction_ko', label: '설명', minWidth: 100, align: 'center' },
        { id: 'created_at', label: '등록일시', minWidth: 50, align: 'center' },
        { id: 'is_confirmed', label: '상점상태', minWidth: 10, align: 'center' },
        { id: 'master_email', label: '연락처', minWidth: 50, align: 'center' },
    ];

    return (
        <Form
            confirmLeave
            initial={initialForm}
            onSubmit={onSubmit}
            disabled={disabled}
        >
            {({ isSaveDisabled, submit }) => {
                return (
                    <DetailPageLayout>
                        <TopNav title={intl.formatMessage(sectionNames.settlementsList)}>
                            {extensionMenuItems.length > 0 && (
                                <CardMenu menuItems={extensionMenuItems} />
                            )}
                        </TopNav>
                        <DetailPageLayout.Content>
                            <Card>
                                <CardTitle title={`등록된 상점 총 ${vendors.length}개`} />
                                <CardContent>
                                    <DataTable columns={columns} data={vendors} defaultRowsPerPage={10} rowOnClick={(row) => navigate(`/stores/settlementsList/${row.id}`)} />
                                    <CardSpacer />
                                    {/* <div className={classes.buttonContainer}>
                    <Button className={classes.addButton} variant="contained">
                      삭제
                    </Button>
                  </div> */}
                                </CardContent>
                            </Card>
                        </DetailPageLayout.Content>
                        <Savebar
                            disabled={isSaveDisabled}
                            state={saveButtonBar}
                            onSubmit={submit}
                            onCancel={() => navigate(storeInfoListUrl())}
                            onDelete={onDelete}
                        />
                    </DetailPageLayout>
                );
            }}
        </Form>
    );

};

SettlementsListPage.displayName = "SettlementsListPage";
export default SettlementsListPage;
